export default class AgGridUtils {
  //TODO: This default code from AG Grid needs to be de-bugged,
  // filter by date isn't actually working.
  static comparatorHelper = (filterLocalDateAtMidnight, cellValue) => {
    // TODO: Handle UTC gracefully, write unit tests againts this.
    const dateAsString = cellValue
      ? new Date(cellValue).toLocaleDateString()
      : null;
    if (dateAsString === null) return -1;
    const dateParts = dateAsString.split('/');
    const cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0])
    );
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
  };
}
