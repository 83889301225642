import Fetcher from 'services/Fetcher';
import Notifier from 'services/Notifier';
import Validator from 'services/Validator';
import { Utils } from 'utils';
import { skuValidationRules } from '../validationRules';

export const COUNTRIES_LIST = {
  AND: 'ANDORRA',
  ARE: 'UNITED ARAB EMIRATES',
  AFG: 'AFGHANISTAN',
  ATG: 'ANTIGUA AND BARBUDA',
  AIA: 'ANGUILLA',
  ALB: 'ALBANIA',
  ARM: 'ARMENIA',
  ANT: 'NETHERLANDS ANTILLES',
  AGO: 'ANGOLA',
  ATA: 'ANTARCTICA',
  ARG: 'ARGENTINA',
  ASM: 'AMERICAN SAMOA',
  AUT: 'AUSTRIA',
  AUS: 'AUSTRALIA',
  ABW: 'ARUBA',
  ALA: 'ALAND ISLANDS',
  AZE: 'AZERBAIJAN',
  BIH: 'BOSNIA AND HERZEGOVINA',
  BRB: 'BARBADOS',
  BGD: 'BANGLADESH',
  BEL: 'BELGIUM',
  BFA: 'BURKINA FASO',
  BGR: 'BULGARIA',
  BHR: 'BAHRAIN',
  BDI: 'BURUNDI',
  BEN: 'BENIN',
  BMU: 'BERMUDA',
  BRN: 'BRUNEI DARUSSALAM',
  BOL: 'BOLIVIA, PLURINATIONAL STATE OF',
  BRA: 'BRAZIL',
  BHS: 'BAHAMAS',
  BTN: 'BHUTAN',
  BVT: 'BOUVET ISLAND',
  BWA: 'BOTSWANA',
  BLR: 'BELARUS',
  BLZ: 'BELIZE',
  CAN: 'CANADA',
  CCK: 'COCOS (KEELING) ISLANDS',
  COD: 'CONGO, THE DEMOCRATIC REPUBLIC OF THE',
  CAF: 'CENTRAL AFRICAN REPUBLIC',
  COG: 'CONGO',
  CHE: 'SWITZERLAND',
  CIV: "COTE D'IVOIRE",
  COK: 'COOK ISLANDS',
  CHL: 'CHILE',
  CMR: 'CAMEROON',
  CHN: 'CHINA',
  COL: 'COLOMBIA',
  CRI: 'COSTA RICA',
  CUB: 'CUBA',
  CPV: 'CAPE VERDE',
  CUR: 'CURACAO',
  CXR: 'CHRISTMAS ISLAND',
  CYP: 'CYPRUS',
  CZE: 'CZECH REPUBLIC',
  DEU: 'GERMANY',
  DJI: 'DJIBOUTI',
  DNK: 'DENMARK',
  DMA: 'DOMINICA',
  DOM: 'DOMINICAN REPUBLIC',
  DZA: 'ALGERIA',
  ECU: 'ECUADOR',
  EST: 'ESTONIA',
  EGY: 'EGYPT',
  ESH: 'WESTERN SAHARA',
  ERI: 'ERITREA',
  ESP: 'SPAIN',
  ETH: 'ETHIOPIA',
  FIN: 'FINLAND',
  FJI: 'FIJI',
  FLK: 'FALKLAND ISLANDS (MALVINAS)',
  FSM: 'MICRONESIA, FEDERATED STATES OF',
  FRO: 'FAROE ISLANDS',
  FRA: 'FRANCE',
  GAB: 'GABON',
  GBR: 'UNITED KINGDOM',
  GRD: 'GRENADA',
  GEO: 'GEORGIA',
  GUF: 'FRENCH GUIANA',
  GGY: 'GUERNSEY',
  GHA: 'GHANA',
  GIB: 'GIBRALTAR',
  GRL: 'GREENLAND',
  GMB: 'GAMBIA',
  GIN: 'GUINEA',
  GLP: 'GUADELOUPE',
  GNQ: 'EQUATORIAL GUINEA',
  GRC: 'GREECE',
  SGS: 'SOUTH GEORGIA AND THE SOUTH SANDWICH ISLANDS',
  GTM: 'GUATEMALA',
  GUM: 'GUAM',
  GNB: 'GUINEA-BISSAU',
  GUY: 'GUYANA',
  HKG: 'HONG KONG',
  HMD: 'HEARD ISLAND AND MCDONALD ISLANDS',
  HND: 'HONDURAS',
  HRV: 'CROATIA',
  HTI: 'HAITI',
  HUN: 'HUNGARY',
  IDN: 'INDONESIA'
};

export const CHANNEL_NCOM = 'N.com';
export const CHANNEL_FLS = 'FLS';
export const CHANNEL_NCOM_AND_FLS = 'N.com, FLS';
export const CHANNELS_LIST = [CHANNEL_NCOM, CHANNEL_FLS, CHANNEL_NCOM_AND_FLS];

export function numericFormatter(value, type) {
  // Decorates number with commas and decimal place. EX: '1111' becomes '1,111.00'
  let currencyFormatter = (symbol, value) => {
    return (
      symbol +
      value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      })
    );
  };

  if (type) {
    switch (type.toLowerCase()) {
      case 'usd':
        return currencyFormatter('\x24', value);
      case 'percent':
        return value; // + "\x25"; TODO: Check if UX wants percent symbol here.
      default:
        return value;
    }
  }
}

export function getChannelValue(nDirect, fls) {
  if (nDirect && fls) {
    return CHANNEL_NCOM_AND_FLS;
  } else if (nDirect) {
    return CHANNEL_NCOM;
  } else if (fls) {
    return CHANNEL_FLS;
  }
  return '';
}

export function setChannelValue(sku, newValue) {
  sku.nDirect.error = null;
  sku.fls.error = null;

  switch (newValue) {
    case CHANNEL_NCOM:
      sku.nDirect.value = 'y';
      sku.fls.value = 'n';
      break;
    case CHANNEL_FLS:
      sku.nDirect.value = 'n';
      sku.fls.value = 'y';
      break;
    case CHANNEL_NCOM_AND_FLS:
      sku.nDirect.value = 'y';
      sku.fls.value = 'y';
      break;
    default:
      sku.nDirect.value = 'n';
      sku.fls.value = 'n';
  }

  let { details, issuesCount } = Validator.check(skuValidationRules, sku);

  sku.numberOfIssues = issuesCount;
  sku.nDirect.error = details.nDirect;
  sku.fls.error = details.fls;
  sku.nDirect.changed = true;
  sku.fls.changed = true;
}

/**
 * Function that will be applied to new value before setting it to data store in validation setter
 * @callback IValueFormatter
 * @param {string} value - New value to be set
 */

/**
 * Validation Setter
 * @param {Object} ctx - React Class Component context
 * @param {Object} options - Options object for validation setter
 * @param {Object} options.validationRules - Rules for "Validator" service
 * @param {IValueFormatter} [options.valueFormatter] - Function that will be applied to new value before setting it to data store
 * @param {string} [options.aggrField] - ID of aggregated field (field or colId in column defs)
 */
export function buildValidationSetter(ctx, options) {
  return async params => {
    const { validationRules, valueFormatter, aggrField } = options;

    const hasValueChanged = params => {
      return !(
        (params.oldValue === null && params.newValue === '') || // oldValue comes as null from AG-Grid when it is empty
        params.oldValue === params.newValue
      );
    };

    // Do nothing if value hasn't changed
    if (!hasValueChanged(params)) {
      return;
    }

    // Take attribute name from field (field can be dotted string, e.g.: "pricePerUnit.value")
    let attrName = params.colDef.field.split('.')[0];

    // In case if data doesn't exist for specified attribute - throw an error
    if (!params.data[attrName]) {
      throw new Error(`Data for ${attrName} attribute was not found`);
    }

    // Apply value formatter on newValue if one is provided
    const value = valueFormatter
      ? valueFormatter(params.newValue)
      : params.newValue;

    // NOTE: UI shouldn't make any assumptions on what attributes are related,
    // so we can't clear errors of related attributes yet.

    // Set "changed" flag, so we can detect which fields should be sent to writeback service
    params.data[attrName].changed = true;
    // Set old error field value to null as new attribute value should be revalidated
    params.data[attrName].error = null;
    // Set new value to actual data store
    params.data[attrName].value = value;

    // Run UI validator
    let { details, issuesCount } = Validator.check(
      validationRules,
      params.data,
      { departments: ctx.props.departments }
    );

    // If UI validator catched an error - set it to data store, update issues count,
    // refresh parent row again in case of aggregated field to reflect issues
    params.data.numberOfIssues = issuesCount;
    params.data[attrName].error = details[attrName];

    // Aggregated field case - find and refresh parent node
    let parentNode = null;
    if (aggrField) {
      parentNode = findParentNode(ctx.gridApi, params.data.pieId);
      if (parentNode) {
        ctx.gridApi.refreshCells({
          rowNodes: [parentNode],
          columns: [aggrField],
          force: true
        });
      }
    }

    try {
      // Construct request payload for Rules service
      let payloadAttr = buildPayloadAttr(params.data, attrName, value);
      let payload = { attributes: [payloadAttr] };

      // Dispatch Redux action, but handle it right away instead of using reducer,
      // as AG-Grid has issues with Redux integration as of now
      let url = '/rules/validate';
      let res = await Fetcher.post(url, payload);

      let validationResult = res.attributes[0];

      // Case when rule has not been implemented yet
      if (!validationResult) {
        return;
      }

      // Ignore pieId attribute and take all the rest
      let { pieId: _pieId, ...restAttrs } = validationResult;

      for (let attrKey in restAttrs) {
        // Check that attribute exists in UI data store
        if (restAttrs.hasOwnProperty(attrKey) && params.data[attrKey]) {
          // Update state field in data store
          params.data[attrKey].state = restAttrs[attrKey].state;

          // Set changed flag if error has changed
          // TODO: Remove as that should be done by rules workflow. Temporarily implemented in UI.
          let errorMsg =
            params.data[attrKey].error && params.data[attrKey].error.reason;
          let newErrorMsg =
            restAttrs[attrKey].error && restAttrs[attrKey].error.reason;
          if (errorMsg !== newErrorMsg) {
            params.data[attrKey].changed = true;
          }

          // Update error field in data store
          params.data[attrKey].error = restAttrs[attrKey].error;

          // Set field value in case if it has been re-calculated by the Rules service
          // NOTE: all values comes as a string from API, so for numeric comparsion strings should be cast to Number
          // to prevent cases like: "10.00" !== "10". But when value is not numeric comparsion should make no casting.
          let oldVal = params.data[attrKey].value;
          let newVal = restAttrs[attrKey].value;
          if (
            (isFinite(oldVal) &&
              isFinite(newVal) &&
              Number(oldVal) !== Number(newVal)) ||
            ((!isFinite(oldVal) || !isFinite(newVal)) && oldVal !== newVal)
          ) {
            params.data[attrKey].value = restAttrs[attrKey].value;
            params.data[attrKey].changed = true;
          }
        }
      }

      // Re-calculate issues count for the row
      let { issuesCount } = Validator.check(validationRules, params.data, {
        departments: ctx.props.departments
      });
      params.data.numberOfIssues = issuesCount;

      // As validation with Rules svc is async process cells should be refreshed manually
      params.api.refreshCells({
        rowNodes: [params.node],
        // List of fields to update in AG-Grid
        columns: ['numberOfIssues'].concat(
          Object.keys(restAttrs).map(attr => `${attr}.value`)
        ),
        force: true
      });

      // If current field is bound to some aggregated field - update parent row as well
      // TODO: aggregated fields doesn't reflected in numberOfIssues field, that should be fixed
      if (parentNode) {
        ctx.gridApi.refreshCells({
          rowNodes: [parentNode],
          // List of aggregated fields (colIds) to update in AG-Grid
          // NOTE: there is an assumption that aggregated colIds have same names as validated attributes
          columns: ['numberOfIssues'].concat(Object.keys(restAttrs)),
          force: true
        });
      }
    } catch {
      Notifier.error('Attribute validation has failed');
    }
  };
}

function findParentNode(gridApi, pieId) {
  let parentNode = null;
  gridApi.forEachNode(node => {
    let isParentRow = Boolean(node.data.skus.find(sku => pieId === sku.pieId));
    if (isParentRow) {
      parentNode = node;
    }
  });
  return parentNode;
}

export function buildPayloadAttr(attributes, attribute, value) {
  const buildRowAttr = attr => {
    // Do not add primitive attributes or self to the payload
    if (!Utils.isObject(attributes[attr]) || attr === attribute) {
      return null;
    }
    return {
      attribute: attr,
      value: attributes[attr].value
    };
  };

  return {
    pieId: attributes.pieId,
    attribute: attribute,
    value: value,
    row: Object.keys(attributes)
      .map(buildRowAttr)
      .filter(Boolean)
  };
}
