import Fetcher from 'services/Fetcher';
import Notifier from 'services/Notifier';
import Validator from 'services/Validator';
import { skuValidationRules } from '../../validationRules';
import { numericFormatter, buildPayloadAttr } from '../columnDefsShared';

export function ccBeforeTextSet(params) {
  if (!params.newValue) {
    return false;
  }
}

export function ccBeforeNumericSet(params) {
  if (!params.newValue || params.newValue.includes('-')) {
    return false;
  }
}

export function ccCurrencyFormatter(value) {
  return value.replace(/^\$|,/g, '');
}

export function ccTooltipValueGetter(field) {
  return params => params.data[field].error;
}

export function ccRollupFromSkuTooltipValueGetter(field) {
  return params => {
    for (let sku of params.data.skus) {
      if (sku[field].error) {
        return { state: 'error', reason: 'Issue with a SKU' };
      }
    }
    return null;
  };
}

export function ccListSkusRenderer(field) {
  return params => {
    let skuValues = params.data.skus
      .map(sku => {
        let value = sku[field] && sku[field].value;
        return value ? value : null;
      })
      .filter(Boolean);

    if (!skuValues.length) {
      return '';
    }

    const trimmedSkuValues = skuValues.map(item => item.trim());
    return [...new Set(trimmedSkuValues)].join(', ');
  };
}

export function buildCcAggregatedCellClassRules(field) {
  return {
    hardDanger: function(params) {
      for (let sku of params.data.skus) {
        if (sku[field].error) {
          return true;
        }
      }
      return false;
    }
  };
}

export function ccCellClassRules(field) {
  return {
    hardDanger: params => !!params.data[field].error
  };
}

export function ccNumericGetter(field, type) {
  return params => {
    // If NaN found: display comma separated list.
    // Else: display range
    let hasError = false;

    let skuValues = params.data.skus
      .map(sku => {
        let value = sku[field] && sku[field].value;

        // If valid integer, add to array
        if (value && isFinite(value)) {
          return Number(value);
        }

        // If value has content at this point, trigger hasError flag
        // If value is empty string, don't trigger flag
        else if (!!value) {
          hasError = true;
        }
        return value;
      })
      // Remove empty strings
      .filter(Boolean);

    if (!skuValues.length) {
      return '';
    }

    if (hasError) {
      return skuValues.join(', ');
    }

    let min = Math.min(...skuValues);
    let max = Math.max(...skuValues);

    max = type ? numericFormatter(max, type) : max;
    min = type ? numericFormatter(min, type) : min;

    if (min === max) {
      return max;
    }
    return `${min} - ${max}`;
  };
}

/**
 * Function that will run before any setter logic. If function returns "false" - default setter logic will never run.
 * @callback IBeforeSet
 * @param {Object} params - AG-Grid params object
 */

/**
 * Validation Setter for aggregated fields
 * @param {Object} options - Options object for validation setter
 * @param {string} options.relatedField - ID of field that should be aggregated (field or colId in column defs)
 * @param {IBeforeSet} [options.beforeSet] - Function that will run before any setter logic. If function returns "false" - default setter logic will never run
 * @param {import('./columnDefsShared').IValueFormatter} [options.valueFormatter] - Function that will be applied to new value before setting it to data store
 */
export function buildAggrValidationSetter(options) {
  return async params => {
    // TODO: check if "relatedField" still needed. Probably can be replaced with attribute name
    const { relatedField, beforeSet, valueFormatter } = options;

    // Run beforeSet callback if one is provided
    if (beforeSet) {
      let result = beforeSet(params);
      if (result === false) {
        return;
      }
    }

    // Apply value formatter on newValue if one is provided
    const value = valueFormatter
      ? valueFormatter(params.newValue)
      : params.newValue;

    // Take attribute name from relatedField (field can be dotted string, e.g.: "pricePerUnit.value")
    let attrName = relatedField.split('.')[0];

    // Attributes that will be sent to Rules service for validation
    let attributes = [];

    params.data.skus.forEach(sku => {
      // In case if data doesn't exist for specified attribute - throw an error
      if (!sku[attrName]) {
        throw new Error(`Data for ${attrName} attribute was not found`);
      }

      // NOTE: UI shouldn't make any assumptions on what attributes are related,
      // so we can't clear errors of related attribtes yet.

      // Set "changed" flag, so we can detect which fields should be sent to writeback service
      sku[attrName].changed = true;
      // Set old error field value to null as new attribute value should be revalidated
      sku[attrName].error = null;
      // Set new value to actual data store
      sku[attrName].value = value;

      // Run UI validator and update issue-related fields
      let { details, issuesCount } = Validator.check(skuValidationRules, sku);
      sku.numberOfIssues = issuesCount;
      sku[attrName].error = details[attrName];

      // Append changed attribute to request to Rules service
      attributes.push(buildPayloadAttr(sku, attrName, value));
    });

    // Update related fields
    let { detailNode } = params.node;
    if (detailNode && detailNode.detailGridInfo) {
      detailNode.detailGridInfo.api.refreshCells({
        columns: ['numberOfIssues', relatedField],
        force: true
      });
    }

    // If there is nothing to send to Rules service - stop
    if (!attributes.length) {
      return;
    }

    try {
      // Dispatch Redux action, but handle it right away instead of using reducer,
      // as ag-grid has issues with Redux integration as of now
      let url = '/rules/validate';
      let res = await Fetcher.post(url, { attributes });

      // This will be used later to refresh aggregated fields
      let validatedAttributeNames = new Set();

      let validationResults = res.attributes;

      for (let validationResult of validationResults) {
        // Ignore pieId attribute and take all the rest
        let { pieId, ...restAttrs } = validationResult;

        // Find SKU row to revalidate
        const sku = params.data.skus.find(s => s.pieId === pieId.value);

        if (!sku) {
          continue;
        }

        for (let attrKey in restAttrs) {
          if (restAttrs.hasOwnProperty(attrKey) && sku[attrKey]) {
            validatedAttributeNames.add(attrKey);

            // Update state field in data store
            sku[attrKey].state = restAttrs[attrKey].state;

            // Set changed flag if error has changed
            // TODO: Remove as that should be done by rules workflow. Temporarily implemented in UI.
            let errorMsg = sku[attrKey].error && sku[attrKey].error.reason;
            let newErrorMsg =
              restAttrs[attrKey].error && restAttrs[attrKey].error.reason;
            if (errorMsg !== newErrorMsg) {
              sku[attrKey].changed = true;
            }

            // Update error field in data store
            sku[attrKey].error = restAttrs[attrKey].error;

            // TODO: that could be done just once outside of a loop, when any of SKUs has an issue
            if (restAttrs[attrKey].error) {
              params.data[attrKey].error = {
                state: 'error',
                reason: 'Issue with a SKU'
              };
            } else {
              params.data[attrKey].error = null;
            }

            // Set field value in case if it has been re-calculated by the Rules service
            // NOTE: all values comes as a string from API, so for numeric comparsion strings should be cast to Number
            // to prevent cases like: "10.00" !== "10". But when value is not numeric comparsion should make no casting.
            let oldVal = sku[attrKey].value;
            let newVal = restAttrs[attrKey].value;
            if (
              (isFinite(oldVal) &&
                isFinite(newVal) &&
                Number(oldVal) !== Number(newVal)) ||
              ((!isFinite(oldVal) || !isFinite(newVal)) && oldVal !== newVal)
            ) {
              sku[attrKey].value = restAttrs[attrKey].value;
              sku[attrKey].changed = true;
            }
          }
        }

        // Re-calculate issues count for the row
        let { issuesCount } = Validator.check(skuValidationRules, sku);
        sku.numberOfIssues = issuesCount;

        if (detailNode && detailNode.detailGridInfo) {
          params.node.detailNode.detailGridInfo.api.forEachNode(node => {
            if (sku.pieId === node.data.pieId) {
              detailNode.detailGridInfo.api.refreshCells({
                rowNodes: [node],
                // List of fields for SKU to update in AG-Grid
                columns: ['numberOfIssues'].concat(
                  Object.keys(restAttrs).map(attr => `${attr}.value`)
                ),
                force: true
              });
            }
          });
        }
      }

      // TODO: how to map validation-related fields to aggregated fields?
      params.api.refreshCells({
        rowNodes: [params.node],
        // List of aggregated fields (colIds) to update in AG-Grid
        // NOTE: there is an assumption that aggregated colIds have same names as validated attributes
        columns: ['numberOfIssues'].concat(Array.from(validatedAttributeNames)),
        force: true
      });
    } catch {
      Notifier.error('Attributes validation has failed');
    }
  };
}
