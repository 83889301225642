import { Chip, MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Utils } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
      height: 'auto'
    },
    '& .MuiInputBase-multiline': {
      width: '26ch',
      height: 'auto'
    }
  },
  multi: {
    '& .MuiInputBase-multiline': {
      margin: theme.spacing(1),
      width: '50ch',
      height: 'auto'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}));

const MaterialEditUI = ({ supplierState, handleSupplierChange }) => {
  const classes = useStyles();

  var readOnly = false;
  if (supplierState.materialEditable === 'false') {
    readOnly = true;
  }

  const bulkValues = supplierState.bulkValuesDD;

  var osrCertification;
  var osrCertificationKeys;
  var osrCertificationField;
  var hasOsr = false;
  // Safely extracting to make sure old dtos still work!
  if (supplierState.osrCertificationDD) {
    hasOsr = true;
    osrCertification = supplierState.osrCertificationDD;
    osrCertificationKeys = Object.keys(osrCertification);

    osrCertificationField = () => {
      return (
        <div>
          <TextField
            label="OSR Certification"
            variant="outlined"
            multiline
            select
            name="osrCertification"
            id="osrCertification"
            SelectProps={{
              multiple: true,
              renderValue: selected => (
                <div className={classes.chips}>
                  {selected.map(value => (
                    <Chip
                      key={value}
                      label={osrCertification[value]}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )
            }}
            value={supplierState.osrCertification}
            onChange={handleSupplierChange}
          >
            {osrCertificationKeys.map(option => (
              <MenuItem key={option} value={option}>
                {osrCertification[option]}
              </MenuItem>
            ))}
          </TextField>
          <br />
        </div>
      );
    };
  }

  return (
    <div>
      <div className={classes.root}>
        <TextField
          label="Bulk Value"
          variant="outlined"
          select
          name="bulkValue1"
          id="bulkValue1"
          value={supplierState.bulkValue1}
          onChange={handleSupplierChange}
        >
          {Utils.sortObject(bulkValues)}
        </TextField>
        <TextField
          label="Initial Price"
          variant="outlined"
          type="text"
          name="initialPrice1"
          id="initialPrice1"
          value={supplierState.initialPrice1}
          onChange={handleSupplierChange}
        />
        <TextField
          label="Order Minimum"
          variant="outlined"
          type="text"
          name="orderMinimum1"
          id="orderMinimum1"
          value={supplierState.orderMinimum1}
          onChange={handleSupplierChange}
        />
        <TextField
          label="Color Minimum"
          variant="outlined"
          type="text"
          name="colorMinimum1"
          id="colorMinimum1"
          value={supplierState.colorMinimum1}
          onChange={handleSupplierChange}
        />
        <TextField
          label="Lead Time (days)"
          variant="outlined"
          type="text"
          name="leadTime1"
          id="leadTime1"
          value={supplierState.leadTime1}
          onChange={handleSupplierChange}
        />
        <br />
        <TextField
          label="Bulk Value"
          variant="outlined"
          select
          name="bulkValue2"
          id="bulkValue2"
          value={supplierState.bulkValue2}
          onChange={handleSupplierChange}
        >
          {Utils.sortObject(bulkValues)}
        </TextField>
        <TextField
          label="Initial Price"
          variant="outlined"
          type="text"
          name="initialPrice2"
          id="initialPrice2"
          value={supplierState.initialPrice2}
          onChange={handleSupplierChange}
        />
        <TextField
          label="Order Minimum"
          variant="outlined"
          type="text"
          name="orderMinimum2"
          id="orderMinimum2"
          value={supplierState.orderMinimum2}
          onChange={handleSupplierChange}
        />
        <TextField
          label="Color Minimum"
          variant="outlined"
          type="text"
          name="colorMinimum2"
          id="colorMinimum2"
          value={supplierState.colorMinimum2}
          onChange={handleSupplierChange}
        />
        <TextField
          label="Lead Time (days)"
          variant="outlined"
          type="text"
          name="leadTime2"
          id="leadTime2"
          value={supplierState.leadTime2}
          onChange={handleSupplierChange}
        />
        <br />
        <TextField
          label="Bulk Value"
          variant="outlined"
          select
          name="bulkValue3"
          id="bulkValue3"
          value={supplierState.bulkValue3}
          onChange={handleSupplierChange}
        >
          {Utils.sortObject(bulkValues)}
        </TextField>
        <TextField
          label="Initial Price"
          variant="outlined"
          type="text"
          name="initialPrice3"
          id="initialPrice3"
          value={supplierState.initialPrice3}
          onChange={handleSupplierChange}
        />
        <TextField
          label="Order Minimum"
          variant="outlined"
          type="text"
          name="orderMinimum3"
          id="orderMinimum3"
          value={supplierState.orderMinimum3}
          onChange={handleSupplierChange}
        />
        <TextField
          label="Color Minimum"
          variant="outlined"
          type="text"
          name="colorMinimum3"
          id="colorMinimum3"
          value={supplierState.colorMinimum3}
          onChange={handleSupplierChange}
        />
        <TextField
          label="Lead Time (days)"
          variant="outlined"
          type="text"
          name="leadTime3"
          id="leadTime3"
          value={supplierState.leadTime3}
          onChange={handleSupplierChange}
        />
      </div>
      <div className={classes.multi}>
        <div className="page-break" />
        <br />
        <TextField
          label="Supplier Comments"
          variant="outlined"
          type="text"
          multiline={true}
          inputProps={{ maxLength: 4000 }}
          name="supplierComments"
          id="supplierComments"
          rows={10}
          rowsMax={Infinity}
          helperText="Enter any comments pertaining to this material
          that is not specified otherwise on this form"
          value={supplierState.supplierComments}
          onChange={handleSupplierChange}
        />
        <br className="print-show" />
        <br className="print-show" />
        <br className="print-show" />
        <TextField
          label="Recommended Care Instructions"
          variant="outlined"
          type="text"
          multiline={true}
          inputProps={{ maxLength: 250 }}
          name="recommendedCare"
          id="recommendedCare"
          rows={10}
          rowsMax={Infinity}
          value={supplierState.recommendedCare}
          onChange={handleSupplierChange}
        />
        <br />
        <br />
        <div className="page-break" />
        {hasOsr && osrCertificationField()}
        <br />
        <br />
        <TextField
          label="How to Color"
          variant="outlined"
          type="text"
          multiline={true}
          inputProps={{ maxLength: 4000 }}
          name="howToColor"
          id="howToColor"
          rows={10}
          rowsMax={Infinity}
          disabled={readOnly}
          InputProps={{
            readOnly: readOnly
          }}
          helperText="example: limitations, color card, # of colors..."
          value={supplierState.howToColor}
          onChange={handleSupplierChange}
        />
        <br />
        <br />
      </div>
    </div>
  );
};

MaterialEditUI.propTypes = {
  supplierState: PropTypes.object,
  handleSupplierChange: PropTypes.func
};

export default MaterialEditUI;
