import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch'
    }
  }
}));

const SupplierTypeR = ({ supplierState, handleSupplierChange }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TextField
        label="Sample Minimum Order/Color"
        variant="outlined"
        type="text"
        name="sampleMinimum"
        id="sampleMinimum"
        required
        value={supplierState.sampleMinimum}
        onChange={handleSupplierChange}
      />
      <br />
      <TextField
        label="Sample Lead Time (Days)"
        variant="outlined"
        type="text"
        name="sampleLead"
        id="sampleLead"
        required
        value={supplierState.sampleLead}
        onChange={handleSupplierChange}
      />
      <br />
      <TextField
        label="Bulk Order Minimum"
        variant="outlined"
        type="text"
        name="bulkMinimum"
        id="bulkMinimum"
        required
        value={supplierState.bulkMinimum}
        onChange={handleSupplierChange}
      />
      <br />
      <TextField
        label="Bulk Color Minimum"
        variant="outlined"
        type="text"
        name="bulkColor"
        id="bulkColor"
        required
        value={supplierState.bulkColor}
        onChange={handleSupplierChange}
      />
      <br />
      <TextField
        label="Bulk Lead Time (Days)"
        variant="outlined"
        type="text"
        name="bulkLead"
        id="bulkLead"
        required
        value={supplierState.bulkLead}
        onChange={handleSupplierChange}
      />
      <br />
    </div>
  );
};

SupplierTypeR.propTypes = {
  supplierState: PropTypes.object,
  handleSupplierChange: PropTypes.func
};

export default SupplierTypeR;
