import {
  selectIsAuthenticated,
  selectUserName,
  selectUserId,
  selectError,
  selectAccessToken,
  selectIsRestoreSessionFailed
} from 'reducers/auth';

export const selectAuth = state => state.auth;
export const selectAuthIsAuthenticated = state =>
  selectIsAuthenticated(selectAuth(state));
export const selectAuthUserId = state => selectUserId(selectAuth(state));
export const selectAuthUserName = state => selectUserName(selectAuth(state));
export const selectAuthAccessToken = state =>
  selectAccessToken(selectAuth(state));
export const selectAuthError = state => selectError(selectAuth(state));
export const selectAuthIsRestoreSessionFailed = state =>
  selectIsRestoreSessionFailed(selectAuth(state));
