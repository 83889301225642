import {
  skuTooltipValueGetter,
  skuCellClassRules,
  skuNumericRenderer
} from './utils';

export function skuMarkupPercentage(ctx) {
  return {
    headerName: 'Markup (%)',
    field: 'markupPercentage.value',
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    editable: false,
    tooltipComponent: 'tooltip',
    tooltipValueGetter: skuTooltipValueGetter('markupPercentage', ctx),
    cellClassRules: skuCellClassRules('markupPercentage', ctx),
    cellRenderer: skuNumericRenderer('Percent')
  };
}
