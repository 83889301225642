import { Grid } from '@material-ui/core';
import React from 'react';
import 'stylesheets/CostingInformation.scss';
import TextEntry from './TextComponents/TextEntry';
import TextDropdown from './TextComponents/TextDropdown';

const OtherCost = props => {
  const currencyDD = {
    usd: 'USD',
    eur: 'EUR'
  };
  const rpText = props.rp ? '-RP' : '-NonRP';
  const type = props.type;

  const costFields = [
    {
      label: 'Currency Denomination',
      name: 'currency' + rpText, //TODO check how this will work for knit/woven
      valName:
        props.type === 'Knit'
          ? 'knitCurrency'
          : props.type === 'Woven'
          ? 'wovenCurrency'
          : props.type === 'Sweater'
          ? 'sweaterCurrency'
          : 'simplifiedCurrencyDD',
      required: true,
      type: 'dropdown',
      disabled: false,
      lov: currencyDD
    }
  ];

  if (type === 'Knit' || type === 'Woven') {
    const fabric = {
      label: 'UOM Fabric, Price and Yield',
      name: 'fabricPriceYieldUOMDD' + rpText, //TODO check how this will work for knit/woven
      valName:
        props.type === 'Knit'
          ? 'knitFabricPriceYieldUOMDD'
          : 'wovenFabricPriceYieldUOMDD', //check this
      required: true,
      type: 'dropdown',
      disabled: false,
      lov: props.fabricPriceYieldUOMDD
    };
    costFields.push(fabric);
  }

  if (type === 'Sweater') {
    const yarn = {
      label: 'Garment Construction',
      name: 'sweaterGarmentConsDD' + rpText,
      valName: 'sweaterGarmentConsDD',
      required: false,
      type: 'dropdown',
      disabled: false,
      lov: props.GarmentConstructionLOV
    };
    costFields.push(yarn);
  }

  return (
    <Grid>
      <div>
        <div key={`ci`} className="column">
          {costFields.map(field =>
            field.type === 'text' ? (
              <TextEntry
                field={field}
                props={props}
                val={props.funlist[props.idx]}
              />
            ) : (
              <TextDropdown field={field} props={props} />
            )
          )}
        </div>
      </div>
    </Grid>
  );
};

export default OtherCost;
