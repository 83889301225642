import React, { Component } from 'react';

class SessionExpired extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <div>Session Expired Placeholder. This should be a modal.</div>;
  }
}

export default SessionExpired;
