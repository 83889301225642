import { skuValidationRules } from '../../validationRules';
import { buildValidationSetter } from '../columnDefsShared';
import { skuTooltipValueGetter, skuCellClassRules } from './utils';

export function skuSize2(ctx) {
  return {
    headerName: 'Size 2',
    field: 'size2.value',
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    tooltipComponent: 'tooltip',
    tooltipValueGetter: skuTooltipValueGetter('size2', ctx),
    cellClassRules: skuCellClassRules('size2', ctx),
    valueSetter: buildValidationSetter(ctx, {
      validationRules: skuValidationRules
    })
  };
}
