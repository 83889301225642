import {
  FETCH_SUMMARY_CONTENT,
  FETCH_SUMMARY_DEPARTMENTS,
  FETCH_SUMMARY_SUPPLIERS,
  SUMMARY_SAVE_CONTENT,
  RESET_SUMMARY_VIEW
} from 'actiontypes';
import Fetcher from 'services/Fetcher';

export const fetchSummaryContent = ({ brandCode, supplier }) => {
  return {
    type: FETCH_SUMMARY_CONTENT,
    async payload() {
      try {
        const url = '/api/choices-view/choice';
        let payload = brandCode ? { brandCode } : { supplier };
        const data = await Fetcher.post(url, payload);

        // Adding mock fields for each editable resource like CC and SKU
        for (let cc of data) {
          cc.totalSkus = cc.skus ? cc.skus.length : 0;
          // TODO: remove "Designer" mock when API has it
          cc.designer = { value: 'N', error: null };
          // TODO: remove mocked market when multi-markets will be in place
          cc.market = { value: 'USA', error: null };

          // TODO: remove mocked data
          let mockDispositions = ['abc', 'stock', 'scrap', 'no cost postings'];
          if (cc.skus) {
            for (let sku of cc.skus) {
              // Adding mock field for each sku
              let mockRandomValue =
                mockDispositions[
                  Math.floor(Math.random() * mockDispositions.length)
                ];
              sku.disposition = { value: mockRandomValue, error: null };
              sku.dropship = { value: '', error: null };
            }
          }
        }
        return data;
      } catch (e) {
        throw e;
      }
    }
  };
};

export const resetSummaryView = () => {
  return {
    type: RESET_SUMMARY_VIEW
  };
};

export const fetchSummaryDepartments = () => {
  return {
    type: FETCH_SUMMARY_DEPARTMENTS,
    async payload() {
      const url = '/api/org-data/departments';
      try {
        return await Fetcher.get(url);
      } catch (e) {
        throw e;
      }
    }
  };
};

export const fetchSummarySuppliers = () => {
  return {
    type: FETCH_SUMMARY_SUPPLIERS,
    async payload() {
      const url = '/api/vendor-data/vendors';
      try {
        const vendors = await Fetcher.get(url);
        return vendors.reduce((acc, next) => {
          acc[next.VendorNumber] = {
            vendorLabel: `${next.VendorName} (${next.VendorNumber})`,
            vendorBrands: next.VendorBrands,
            vendorName: next.VendorName
          };

          return acc;
        }, {});
      } catch (e) {
        throw e;
      }
    }
  };
};

export const saveSummaryContent = payload => {
  return {
    type: SUMMARY_SAVE_CONTENT,
    async payload() {
      const url = '/api/workitem/save';
      try {
        return await Fetcher.put(url, payload);
      } catch (e) {
        throw e;
      }
    }
  };
};
