import { numericFormatter } from '../columnDefsShared';

export function skuCellClassRules(field) {
  return {
    hardDanger: params => !!params.data[field].error
  };
}

export function skuNumericRenderer(type) {
  return params => {
    return !params.value || !isFinite(params.value)
      ? params.value
      : numericFormatter(Number(params.value), type);
  };
}

export function skuCurrencyFormatter(value) {
  return value.replace(/^\$|,/g, '');
}

export function skuTooltipValueGetter(field) {
  return params => params.data[field].error;
}
