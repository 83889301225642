import { Utils } from 'utils';

export let hideTooltip = () => {};

export function setTooltipSettings(tooltipManager) {
  tooltipManager.beanInstance.MOUSEOVER_SHOW_TOOLTIP_TIMEOUT = 750;
  tooltipManager.beanInstance.MOUSEOUT_HIDE_TOOLTIP_TIMEOUT = 0;
  tooltipManager.beanInstance.DEFAULT_HIDE_TOOLTIP_TIMEOUT = 2000;
  tooltipManager.beanInstance.processMouseOver = _tooltipMouseOver;
  tooltipManager.beanInstance.processMouseMove = _tooltipMouseMove;
}

// NOTE: following functions use ag-grid tooltip widget bean context so it shouldn't be changed
export function _tooltipMouseOver(e, targetCmp) {
  let delay = this.MOUSEOVER_SHOW_TOOLTIP_TIMEOUT;

  if (this.activeComponent) {
    // lastHoveredComponent will be the activeComponent when we are hovering
    // a component with many child elements like the grid header
    if (this.lastHoveredComponent === this.activeComponent) {
      return;
    }
  } else if (this.showTimeoutId && this.lastHoveredComponent === targetCmp) {
    return;
  }

  // Save reference to hideTooltip function from ag-grid so we can call it on scroll to hide tooltip
  hideTooltip = Utils.throttle(this.hideTooltip.bind(this), 250);

  this.clearTimers();
  this.hideTooltip();

  // lastHoveredComponent will be the targetCmp when a click hid the tooltip
  // and the lastHoveredComponent has many child elements
  if (this.lastHoveredComponent === targetCmp) {
    return;
  }

  this.lastHoveredComponent = targetCmp;
  this.lastMouseEvent = e;
  this.showTimeoutId = window.setTimeout(this.showTooltip.bind(this), delay, e);
}

export const _updateTooltipTimersThrottled = Utils.throttle(
  function _updateTooltipTimers() {
    window.clearTimeout(this.hideTimeoutId);
    this.hideTimeoutId = window.setTimeout(
      this.hideTooltip.bind(this),
      this.DEFAULT_HIDE_TOOLTIP_TIMEOUT
    );
  },
  100
);

export function _tooltipMouseMove(e) {
  // there is a delay from the time we mouseOver a component and the time the
  // tooltip is displayed, so we need to track mousemove to be able to correctly
  // position the tooltip when showTooltip is called.
  this.lastMouseEvent = e;
  if (this.activeComponent) {
    _updateTooltipTimersThrottled.call(this);
  }
}
