import { REDIRECT_URL } from 'constants/localStorage';
import SharedComponents from './sharedComponents';

export default class Utils {
  static setRedirectUrl(redirectUrl) {
    localStorage.setItem(REDIRECT_URL, JSON.stringify(redirectUrl));
  }

  static getRedirectUrl() {
    return JSON.parse(localStorage.getItem(REDIRECT_URL)) || '/home';
  }

  static removeRedirectUrl() {
    localStorage.removeItem(REDIRECT_URL);
  }

  static throttle(func, delay) {
    let timeoutId;
    let lastRan;
    return function() {
      const context = this;
      const args = arguments;
      if (!lastRan) {
        func.apply(context, args);
        lastRan = Date.now();
      } else {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(function() {
          if (Date.now() - lastRan >= delay) {
            func.apply(context, args);
            lastRan = Date.now();
          }
        }, delay - (Date.now() - lastRan));
      }
    };
  }

  static isObject(val) {
    return val && typeof val === 'object' && !Array.isArray(val);
  }

  static sortObject(obj) {
    let sort = Object.entries(obj).sort(function(a, b) {
      return a[1].localeCompare(b[1]);
    });
    sort = sort.filter(data => data[0] !== 'NULLVALUE');

    return SharedComponents.selectOptions(sort);
  }

  static getDto(originalJson, ownerState, supplierState, contentState) {
    let obj = {
      objectString: originalJson.objectString,
      smacsData: {
        materialVersion: originalJson.smacsData.materialVersion,
        materialType: originalJson.smacsData.materialType,
        materialName: originalJson.smacsData.materialName,
        dateGenerated: originalJson.smacsData.dateGenerated,
        materialId: originalJson.smacsData.materialId,
        materialSupplierId: originalJson.smacsData.materialSupplierId,
        materialEditable: originalJson.smacsData.materialEditable,
        colorVisible: originalJson.smacsData.colorVisible
      }
    };
    if (
      originalJson.smacsData.materialType !== 'Label' &&
      originalJson.smacsData.materialType !== 'Packaging'
    ) {
      obj = {
        smacsData: {
          ...obj.smacsData,
          materialFinish: {
            option: originalJson.smacsData.materialFinish.option
          },
          materialComments: supplierState.howToColor,
          materialContent: {
            materialContentDD:
              originalJson.smacsData.materialContent.materialContentDD
          },
          //originalJson.smacsData.osrCertification boolean is for safe extraction
          //to make sure old dtos work.
          osrCertification: originalJson.smacsData.osrCertification
            ? {
                option: originalJson.smacsData.osrCertification.option
              }
            : null,
          headerSection: null, //?
          supplierName: originalJson.smacsData.supplierName,
          supplierArticleName: supplierState.articleName,
          supplierInitialBulkPrice: supplierState.bulkPrice,
          supplierSamplePrice: supplierState.samplePrice,
          supplierCountryOfOriginDD: {
            option: originalJson.smacsData.supplierCountryOfOriginDD.option
          },
          supplierOriginOfSkinsDD: {
            option: originalJson.smacsData.supplierOriginOfSkinsDD.option
          },
          spinnerCountryOfOriginDD: {
            option: originalJson.smacsData.spinnerCountryOfOriginDD.option
          },
          millCountryOfOriginDD: {
            option: originalJson.smacsData.millCountryOfOriginDD.option
          },
          supplierMaterialSuppComments: supplierState.supplierComments,
          supplierCareInstructions: supplierState.recommendedCare,
          supplierBulkLeadTime: supplierState.bulkLead,
          cuttableWidthSize: supplierState.width,
          supplierBulkOrderMin: supplierState.bulkMinimum,
          supplierBulkColorMin: supplierState.bulkColor,
          supplierSampleMinOrderColor: supplierState.sampleMinimum,
          supplierSampleLeadTime: supplierState.sampleLead,
          supplierSampleUOMDD: {
            option: originalJson.smacsData.supplierSampleUOMDD.option
          },
          supplierSampleCurrency: supplierState.sampleCurrency,
          supplierBulkUOMDD: {
            option: originalJson.smacsData.supplierBulkUOMDD.option
          },
          supplierBulkCurrency: supplierState.bulkCurrency,
          supplierMill: supplierState.mill,
          supplierSpinner: supplierState.spinner,
          supplierLowestBulkMOQMOC: supplierState.lowestAcceptable,
          fiberCountryOfOriginInformation:
            supplierState.fiberCountryOfOriginInformation
        }
      };

      this.getMaterialType(obj, originalJson, ownerState);
      this.getSupplierType(obj, originalJson, supplierState);

      //originalJson.smacsData.osrCertification is for safe extraction
      //to make sure old dtos work.
      if (
        originalJson.smacsData.osrCertification &&
        supplierState.osrCertification
      ) {
        const osrCertification = [];

        for (let i = 0; i < supplierState.osrCertification.length; i++) {
          osrCertification[i] = {
            [supplierState.osrCertification[i]]:
              originalJson.smacsData.osrCertification.option[
                supplierState.osrCertification[i]
              ]
          };
        }
        obj.smacsData.osrCertification.valueList = osrCertification;
      }

      if (ownerState.finish) {
        const finish = [];

        for (let i = 0; i < ownerState.finish.length; i++) {
          finish[i] = {
            [ownerState.finish[i]]:
              originalJson.smacsData.materialFinish.option[ownerState.finish[i]]
          };
        }
        obj.smacsData.materialFinish.valueList = finish;
      }

      if (supplierState.bulkUom) {
        obj.smacsData.supplierBulkUOMDD.value = {
          [supplierState.bulkUom]:
            originalJson.smacsData.supplierBulkUOMDD.option[
              supplierState.bulkUom
            ]
        };
      }

      if (supplierState.sampleUom) {
        obj.smacsData.supplierSampleUOMDD.value = {
          [supplierState.sampleUom]:
            originalJson.smacsData.supplierSampleUOMDD.option[
              supplierState.sampleUom
            ]
        };
      }

      if (supplierState.country) {
        obj.smacsData.supplierCountryOfOriginDD.value = {
          [supplierState.country]:
            originalJson.smacsData.supplierCountryOfOriginDD.option[
              supplierState.country
            ]
        };
      }

      if (supplierState.skins) {
        obj.smacsData.supplierOriginOfSkinsDD.value = {
          [supplierState.skins]:
            originalJson.smacsData.supplierOriginOfSkinsDD.option[
              supplierState.skins
            ]
        };
      }

      if (supplierState.millCountry) {
        obj.smacsData.millCountryOfOriginDD.value = {
          [supplierState.millCountry]:
            originalJson.smacsData.millCountryOfOriginDD.option[
              supplierState.millCountry
            ]
        };
      }

      if (supplierState.spinnerCountry) {
        obj.smacsData.spinnerCountryOfOriginDD.value = {
          [supplierState.spinnerCountry]:
            originalJson.smacsData.spinnerCountryOfOriginDD.option[
              supplierState.spinnerCountry
            ]
        };
      }

      const content = [];

      for (let i = 0; i < contentState.length; i++) {
        if (contentState[i].content) {
          content[i] = {
            content: {
              [contentState[i].content]:
                originalJson.smacsData.materialContent.materialContentDD[
                  contentState[i].content
                ]
            },
            contentPercentage: contentState[i].percentage
          };
        } else {
          content[i] = {
            contentPercentage: contentState[i].percentage
          };
        }
      }

      obj.smacsData.materialContent.materialContent = content;

      for (let i = 0; i < supplierState.npsTest.length; i++) {
        let idx = 'npsTest' + (i + 1);
        obj.smacsData[idx] = {
          number: supplierState.npsTest[i].number,
          isNoBrieflyExplain: supplierState.npsTest[i].isNoBrieflyExplain,
          doesMaterialPass: {
            option: originalJson.smacsData[idx].doesMaterialPass.option
          }
        };
        if (supplierState.npsTest[i].doesMaterialPass) {
          obj.smacsData[idx].doesMaterialPass.value = {
            [supplierState.npsTest[i].doesMaterialPass]:
              originalJson.smacsData[idx].doesMaterialPass.option[
                supplierState.npsTest[i].doesMaterialPass
              ]
          };
        }
      }
    } else if (
      originalJson.smacsData.materialType === 'Packaging' ||
      originalJson.smacsData.materialType === 'Label'
    ) {
      obj.smacsData.materialComments = null;
      this.getMaterialType(obj, originalJson, ownerState);
      this.getSupplierType(obj, originalJson, supplierState);
    }

    return obj;
  }

  static getSupplierType(obj, originalJson, supplierState) {
    if (
      originalJson.smacsData.materialType === 'Knit' ||
      originalJson.smacsData.materialType === 'Yarn' ||
      originalJson.smacsData.materialType === 'Woven' ||
      originalJson.smacsData.materialType === 'Leather' ||
      originalJson.smacsData.materialType === 'Other'
    ) {
      const bulkValues = [
        {
          bulkValueInitialPrice: supplierState.initialPrice1,
          bulkValueOrderMinimum: supplierState.orderMinimum1,
          bulkValueColorMinimum: supplierState.colorMinimum1,
          bulkValueLeadTime: supplierState.leadTime1
        },
        {
          bulkValueInitialPrice: supplierState.initialPrice2,
          bulkValueOrderMinimum: supplierState.orderMinimum2,
          bulkValueColorMinimum: supplierState.colorMinimum2,
          bulkValueLeadTime: supplierState.leadTime2
        },
        {
          bulkValueInitialPrice: supplierState.initialPrice3,
          bulkValueOrderMinimum: supplierState.orderMinimum3,
          bulkValueColorMinimum: supplierState.colorMinimum3,
          bulkValueLeadTime: supplierState.leadTime3
        }
      ];
      obj.smacsData.bulkValues = bulkValues;
      obj.smacsData.bulkValuesDD = originalJson.smacsData.bulkValuesDD;

      if (supplierState.bulkValue1) {
        obj.smacsData.bulkValues[0].bulkValue = {
          [supplierState.bulkValue1]:
            originalJson.smacsData.bulkValuesDD[supplierState.bulkValue1]
        };
      }
      if (supplierState.bulkValue2) {
        obj.smacsData.bulkValues[1].bulkValue = {
          [supplierState.bulkValue2]:
            originalJson.smacsData.bulkValuesDD[supplierState.bulkValue2]
        };
      }
      if (supplierState.bulkValue3) {
        obj.smacsData.bulkValues[2].bulkValue = {
          [supplierState.bulkValue3]:
            originalJson.smacsData.bulkValuesDD[supplierState.bulkValue3]
        };
      }
    } else if (
      originalJson.smacsData.materialType === 'Label' ||
      originalJson.smacsData.materialType === 'Packaging'
    ) {
      obj.smacsData.lblPkgUnitPrice = supplierState.supplierUnitPrice;
      obj.smacsData.lblPkgCostPerThousand =
        supplierState.supplierCostPerThousand;
      obj.smacsData.lblPkgMinOrderQty = supplierState.supplierMinOrderSkuQty;
      obj.smacsData.lblPkgSupplier = supplierState.supplierName;
      obj.smacsData.lblPkgSupplierComments = supplierState.supplierComments;
      obj.smacsData.lblPkgSupplierRefNum = supplierState.supplierReference;
      obj.smacsData.lblPkgLeadTime = supplierState.supplierLeadTimeInDays;
      obj.smacsData.lblPkgYesTieredPricing =
        supplierState.supplierYesTieredPricing;
      obj.smacsData.lblPkgBulkOrderSetup =
        supplierState.supplierInventoryPickPackLeadTime;
      obj.smacsData.lblPkgYesBulkOrderSetup =
        supplierState.supplierInventoryPackIncrements;

      obj.smacsData.lblPkgTieredPricing = {
        option: originalJson.smacsData.lblPkgTieredPricing.option
      };
      if (supplierState.supplierTieredPricing) {
        obj.smacsData.lblPkgTieredPricing.value = {
          [supplierState.supplierTieredPricing]:
            originalJson.smacsData.lblPkgTieredPricing.option[
              supplierState.supplierTieredPricing
            ]
        };
      }
      // obj.smacsData.supplierTieredPricingDD = {
      //   option: supplierState.supplierTieredPricingDD
      // };
      // if (supplierState.supplierTieredPricing) {
      //   obj.smacsData.supplierTieredPricingDD.value = {
      //     [supplierState.supplierTieredPricing]:
      //       supplierState.supplierTieredPricingDD[
      //         supplierState.supplierTieredPricing
      //       ]
      //   };
      // }
    }
  }

  static getMaterialType(obj, originalJson, ownerState) {
    if (originalJson.smacsData.materialType === 'Knit') {
      obj.smacsData['type'] = 'knit';
      obj.smacsData.knitTypeDD = {
        option: originalJson.smacsData.knitTypeDD.option
      };
      obj.smacsData.knitStitchCount = ownerState.count;
      obj.smacsData.knitStitchCountUOMDD = {
        option: originalJson.smacsData.knitStitchCountUOMDD.option
      };
      obj.smacsData.knitPrintProcessDD = {
        option: originalJson.smacsData.knitPrintProcessDD.option
      };
      obj.smacsData.knitPerformanceDD = {
        option: originalJson.smacsData.knitPerformanceDD.option
      };
      obj.smacsData.knitWeight = ownerState.weight;
      obj.smacsData.knitWeightUOMDD = {
        option: originalJson.smacsData.knitWeightUOMDD.option
      };
      obj.smacsData.knitGaugeDD = {
        option: originalJson.smacsData.knitGaugeDD.option
      };
      obj.smacsData.knitYarnSizePly = ownerState.yarn;
      obj.smacsData.knitStructureDD = {
        option: originalJson.smacsData.knitStructureDD.option
      };
      obj.smacsData.knitDyeApplDD = {
        option: originalJson.smacsData.knitDyeApplDD.option
      };
      obj.smacsData.knitDyeTypeDD = {
        option: originalJson.smacsData.knitDyeTypeDD.option
      };

      if (ownerState.type) {
        obj.smacsData.knitTypeDD.value = {
          [ownerState.type]:
            originalJson.smacsData.knitTypeDD.option[ownerState.type]
        };
      }

      if (ownerState.structure) {
        obj.smacsData.knitStructureDD.value = {
          [ownerState.structure]:
            originalJson.smacsData.knitStructureDD.option[ownerState.structure]
        };
      }

      if (ownerState.weightUom) {
        obj.smacsData.knitWeightUOMDD.value = {
          [ownerState.weightUom]:
            originalJson.smacsData.knitWeightUOMDD.option[ownerState.weightUom]
        };
      }

      if (ownerState.gauge) {
        obj.smacsData.knitGaugeDD.value = {
          [ownerState.gauge]:
            originalJson.smacsData.knitGaugeDD.option[ownerState.gauge]
        };
      }

      if (ownerState.countUom) {
        obj.smacsData.knitStitchCountUOMDD.value = {
          [ownerState.countUom]:
            originalJson.smacsData.knitStitchCountUOMDD.option[
              ownerState.countUom
            ]
        };
      }

      if (ownerState.printProcess) {
        obj.smacsData.knitPrintProcessDD.value = {
          [ownerState.printProcess]:
            originalJson.smacsData.knitPrintProcessDD.option[
              ownerState.printProcess
            ]
        };
      }

      if (ownerState.dyeApplication) {
        obj.smacsData.knitDyeApplDD.value = {
          [ownerState.dyeApplication]:
            originalJson.smacsData.knitDyeApplDD.option[
              ownerState.dyeApplication
            ]
        };
      }

      if (ownerState.performance) {
        obj.smacsData.knitPerformanceDD.value = {
          [ownerState.performance]:
            originalJson.smacsData.knitPerformanceDD.option[
              ownerState.performance
            ]
        };
      }

      // multilist
      if (ownerState.dyeType) {
        const dyeTypes = [];

        for (let i = 0; i < ownerState.dyeType.length; i++) {
          dyeTypes[i] = {
            [ownerState.dyeType[i]]:
              originalJson.smacsData.knitDyeTypeDD.option[ownerState.dyeType[i]]
          };
        }
        obj.smacsData.knitDyeTypeDD.valueList = dyeTypes;
      }
    } else if (originalJson.smacsData.materialType === 'Yarn') {
      obj.smacsData['type'] = 'yarn';
      obj.smacsData.yarnPlySizeDD = ownerState.yarn;
      obj.smacsData.yarnGauge = ownerState.gauge;

      obj.smacsData.yarnTypeDD = {
        option: originalJson.smacsData.yarnTypeDD.option
      };
      obj.smacsData.yarnDyeApplDD = {
        option: originalJson.smacsData.yarnDyeApplDD.option
      };
      obj.smacsData.yarnDyeTypeDD = {
        option: originalJson.smacsData.yarnDyeTypeDD.option
      };

      if (ownerState.type) {
        obj.smacsData.yarnTypeDD.value = {
          [ownerState.type]:
            originalJson.smacsData.yarnTypeDD.option[ownerState.type]
        };
      }

      if (ownerState.dyeApplication) {
        obj.smacsData.yarnDyeApplDD.value = {
          [ownerState.dyeApplication]:
            originalJson.smacsData.yarnDyeApplDD.option[
              ownerState.dyeApplication
            ]
        };
      }

      // multilist
      if (ownerState.dyeType) {
        const dyeTypes = [];

        for (let i = 0; i < ownerState.dyeType.length; i++) {
          dyeTypes[i] = {
            [ownerState.dyeType[i]]:
              originalJson.smacsData.yarnDyeTypeDD.option[ownerState.dyeType[i]]
          };
        }
        obj.smacsData.yarnDyeTypeDD.valueList = dyeTypes;
      }
    } else if (originalJson.smacsData.materialType === 'Woven') {
      obj.smacsData['type'] = 'woven';
      obj.smacsData.wovenWeaveTypeDD = {
        option: originalJson.smacsData.wovenWeaveTypeDD.option
      };
      obj.smacsData.wovenThreadCount = ownerState.count;
      obj.smacsData.wovenThreadCountUOMDD = {
        option: originalJson.smacsData.wovenThreadCountUOMDD.option
      };
      obj.smacsData.wovenPrintProcessDD = {
        option: originalJson.smacsData.wovenPrintProcessDD.option
      };
      obj.smacsData.wovenPerformanceDD = {
        option: originalJson.smacsData.wovenPerformanceDD.option
      };
      obj.smacsData.wovenWeight = ownerState.weight;
      obj.smacsData.wovenWeightUOMDD = {
        option: originalJson.smacsData.wovenWeightUOMDD.option
      };
      obj.smacsData.wovenFabricWorstedDD = {
        option: originalJson.smacsData.wovenFabricWorstedDD.option
      };
      obj.smacsData.wovenYarnSize = ownerState.yarn;
      obj.smacsData.wovenFiberDiameter = ownerState.diameter;
      obj.smacsData.wovenDyeApplDD = {
        option: originalJson.smacsData.wovenDyeApplDD.option
      };
      obj.smacsData.wovenDyeTypeDD = {
        option: originalJson.smacsData.wovenDyeTypeDD.option
      };

      if (ownerState.type) {
        obj.smacsData.wovenWeaveTypeDD.value = {
          [ownerState.type]:
            originalJson.smacsData.wovenWeaveTypeDD.option[ownerState.type]
        };
      }

      if (ownerState.weightUom) {
        obj.smacsData.wovenWeightUOMDD.value = {
          [ownerState.weightUom]:
            originalJson.smacsData.wovenWeightUOMDD.option[ownerState.weightUom]
        };
      }

      if (ownerState.worsted) {
        obj.smacsData.wovenFabricWorstedDD.value = {
          [ownerState.worsted]:
            originalJson.smacsData.wovenFabricWorstedDD.option[
              ownerState.worsted
            ]
        };
      }

      if (ownerState.countUom) {
        obj.smacsData.wovenThreadCountUOMDD.value = {
          [ownerState.countUom]:
            originalJson.smacsData.wovenThreadCountUOMDD.option[
              ownerState.countUom
            ]
        };
      }

      if (ownerState.printProcess) {
        obj.smacsData.wovenPrintProcessDD.value = {
          [ownerState.printProcess]:
            originalJson.smacsData.wovenPrintProcessDD.option[
              ownerState.printProcess
            ]
        };
      }

      if (ownerState.dyeApplication) {
        obj.smacsData.wovenDyeApplDD.value = {
          [ownerState.dyeApplication]:
            originalJson.smacsData.wovenDyeApplDD.option[
              ownerState.dyeApplication
            ]
        };
      }

      if (ownerState.performance) {
        obj.smacsData.wovenPerformanceDD.value = {
          [ownerState.performance]:
            originalJson.smacsData.wovenPerformanceDD.option[
              ownerState.performance
            ]
        };
      }

      // multilist
      if (ownerState.dyeType) {
        const dyeTypes = [];

        for (let i = 0; i < ownerState.dyeType.length; i++) {
          dyeTypes[i] = {
            [ownerState.dyeType[i]]:
              originalJson.smacsData.wovenDyeTypeDD.option[
                ownerState.dyeType[i]
              ]
          };
        }
        obj.smacsData.wovenDyeTypeDD.valueList = dyeTypes;
      }
    } else if (originalJson.smacsData.materialType === 'Leather') {
      obj.smacsData['type'] = 'leather';

      obj.smacsData.leatherTypeDD = {
        option: originalJson.smacsData.leatherTypeDD.option
      };
      obj.smacsData.leatherDyeApplDD = {
        option: originalJson.smacsData.leatherDyeApplDD.option
      };
      obj.smacsData.leatherDyeTypeDD = {
        option: originalJson.smacsData.leatherDyeTypeDD.option
      };
      obj.smacsData.leatherThicknessDD = {
        option: originalJson.smacsData.leatherThicknessDD.option
      };
      obj.smacsData.leatherPrintProcessDD = {
        option: originalJson.smacsData.leatherPrintProcessDD.option
      };
      obj.smacsData.leatherGradeList = {
        option: originalJson.smacsData.leatherGradeList.option
      };

      if (ownerState.type) {
        obj.smacsData.leatherTypeDD.value = {
          [ownerState.type]:
            originalJson.smacsData.leatherTypeDD.option[ownerState.type]
        };
      }

      if (ownerState.dyeApplication) {
        obj.smacsData.leatherDyeApplDD.value = {
          [ownerState.dyeApplication]:
            originalJson.smacsData.leatherDyeApplDD.option[
              ownerState.dyeApplication
            ]
        };
      }

      if (ownerState.thickness) {
        obj.smacsData.leatherThicknessDD.value = {
          [ownerState.thickness]:
            originalJson.smacsData.leatherThicknessDD.option[
              ownerState.thickness
            ]
        };
      }

      if (ownerState.printProcess) {
        obj.smacsData.leatherPrintProcessDD.value = {
          [ownerState.printProcess]:
            originalJson.smacsData.leatherPrintProcessDD.option[
              ownerState.printProcess
            ]
        };
      }

      // multilist
      if (ownerState.dyeType) {
        const dyeTypes = [];

        for (let i = 0; i < ownerState.dyeType.length; i++) {
          dyeTypes[i] = {
            [ownerState.dyeType[i]]:
              originalJson.smacsData.leatherDyeTypeDD.option[
                ownerState.dyeType[i]
              ]
          };
        }
        obj.smacsData.leatherDyeTypeDD.valueList = dyeTypes;
      }

      if (ownerState.gradeList) {
        const gradeList = [];

        for (let i = 0; i < ownerState.gradeList.length; i++) {
          gradeList[i] = {
            [ownerState.gradeList[i]]:
              originalJson.smacsData.leatherGradeList.option[
                ownerState.gradeList[i]
              ]
          };
        }
        obj.smacsData.leatherGradeList.valueList = gradeList;
      }
    } else if (originalJson.smacsData.materialType === 'Other') {
      obj.smacsData['type'] = 'other';
      obj.smacsData.otherTypeDD = ownerState.type;
      obj.smacsData.otherPrintProcessDD = {
        option: originalJson.smacsData.otherPrintProcessDD.option
      };
      obj.smacsData.otherWeight = ownerState.weight;
      obj.smacsData.otherWeightUOMDD = {
        option: originalJson.smacsData.otherWeightUOMDD.option
      };
      obj.smacsData.otherThicknessDD = ownerState.thickness;
      obj.smacsData.otherDyeApplDD = {
        option: originalJson.smacsData.otherDyeApplDD.option
      };
      obj.smacsData.otherDyeTypeDD = {
        option: originalJson.smacsData.otherDyeTypeDD.option
      };

      if (ownerState.weightUom) {
        obj.smacsData.otherWeightUOMDD.value = {
          [ownerState.weightUom]:
            originalJson.smacsData.otherWeightUOMDD.option[ownerState.weightUom]
        };
      }

      if (ownerState.printProcess) {
        obj.smacsData.otherPrintProcessDD.value = {
          [ownerState.printProcess]:
            originalJson.smacsData.otherPrintProcessDD.option[
              ownerState.printProcess
            ]
        };
      }

      if (ownerState.dyeApplication) {
        obj.smacsData.otherDyeApplDD.value = {
          [ownerState.dyeApplication]:
            originalJson.smacsData.otherDyeApplDD.option[
              ownerState.dyeApplication
            ]
        };
      }

      // multilist
      if (ownerState.dyeType) {
        const dyeTypes = [];

        for (let i = 0; i < ownerState.dyeType.length; i++) {
          dyeTypes[i] = {
            [ownerState.dyeType[i]]:
              originalJson.smacsData.otherDyeTypeDD.option[
                ownerState.dyeType[i]
              ]
          };
        }
        obj.smacsData.otherDyeTypeDD.valueList = dyeTypes;
      }
    } else if (originalJson.smacsData.materialType === 'Label') {
      obj.smacsData['type'] = 'label';

      obj.smacsData.labelArticleNum = originalJson.smacsData.labelArticleNum;

      obj.smacsData.labelClassificationDD = {
        option: originalJson.smacsData.labelClassificationDD.option
      };
      if (ownerState.labelClassification) {
        obj.smacsData.labelClassificationDD.value = {
          [ownerState.labelClassification]:
            originalJson.smacsData.labelClassificationDD.option[
              ownerState.labelClassification
            ]
        };
      }

      if (
        originalJson.smacsData.materialVersion &&
        originalJson.smacsData.materialVersion !== 'V1'
      ) {
        obj.smacsData.labelConstructionDD = {
          option: originalJson.smacsData.labelConstructionDD.option
        };
        if (ownerState.labelConstruction) {
          obj.smacsData.labelConstructionDD.value = {
            [ownerState.labelConstruction]:
              originalJson.smacsData.labelConstructionDD.option[
                ownerState.labelConstruction
              ]
          };
        }
      } else {
        obj.smacsData.labelConstruction =
          originalJson.smacsData.labelConstruction;
      }

      obj.smacsData.labelDenierDD = {
        option: originalJson.smacsData.labelDenierDD.option
      };
      if (ownerState.labelDenier) {
        obj.smacsData.labelDenierDD.value = {
          [ownerState.labelDenier]:
            originalJson.smacsData.labelDenierDD.option[ownerState.labelDenier]
        };
      }

      // TODO: labelDepartment is a deprecated field. Remove when no longer used.
      obj.smacsData.labelDepartment = originalJson.smacsData.labelDepartment;

      obj.smacsData.labelEndUse = ownerState.labelEndUse;

      obj.smacsData.labelFinishDD = {
        option: originalJson.smacsData.labelFinishDD.option
      };
      if (ownerState.labelFinish) {
        obj.smacsData.labelFinishDD.value = {
          [ownerState.labelFinish]:
            originalJson.smacsData.labelFinishDD.option[ownerState.labelFinish]
        };
      }

      obj.smacsData.labelLabelBrand = originalJson.smacsData.labelLabelBrand;

      obj.smacsData.labelLabelType = originalJson.smacsData.labelLabelType;

      if (
        originalJson.smacsData.materialVersion &&
        originalJson.smacsData.materialVersion !== 'V1'
      ) {
        obj.smacsData.labelDivision = originalJson.smacsData.labelDivision;

        obj.smacsData.labelProductCategory =
          originalJson.smacsData.labelProductCategory;
      }

      obj.smacsData.labelDivision = originalJson.smacsData.labelDivision;

      obj.smacsData.labelProductCategory =
        originalJson.smacsData.labelProductCategory;

      obj.smacsData.materialComments = ownerState.materialComments;

      obj.smacsData.labelMaterialStatus =
        originalJson.smacsData.labelMaterialStatus;

      obj.smacsData.labelSustainableComments =
        ownerState.labelSustainableComments;

      obj.smacsData.labelEndUse = ownerState.labelEndUse;

      obj.smacsData.labelWeaveDD = {
        option: originalJson.smacsData.labelWeaveDD.option
      };
      if (ownerState.labelWeave) {
        obj.smacsData.labelWeaveDD.value = {
          [ownerState.labelWeave]:
            originalJson.smacsData.labelWeaveDD.option[ownerState.labelWeave]
        };
      }

      obj.smacsData.labelExtrasMisc = {
        option: originalJson.smacsData.labelExtrasMisc.option
      };
      if (ownerState.labelExtrasMisc) {
        const extraMiscValues = [];

        for (let i = 0; i < ownerState.labelExtrasMisc.length; i++) {
          extraMiscValues[i] = {
            [ownerState.labelExtrasMisc[i]]:
              originalJson.smacsData.labelExtrasMisc.option[
                ownerState.labelExtrasMisc[i]
              ]
          };
        }
        obj.smacsData.labelExtrasMisc.valueList = extraMiscValues;
      }

      obj.smacsData.labelMaterial = {
        option: originalJson.smacsData.labelMaterial.option
      };
      if (ownerState.labelMaterials) {
        const materialsValues = [];

        for (let i = 0; i < ownerState.labelMaterials.length; i++) {
          materialsValues[i] = {
            [ownerState.labelMaterials[i]]:
              originalJson.smacsData.labelMaterial.option[
                ownerState.labelMaterials[i]
              ]
          };
        }
        obj.smacsData.labelMaterial.valueList = materialsValues;
      }

      obj.smacsData.labelSustainable = {
        option: originalJson.smacsData.labelSustainable.option
      };
      if (ownerState.labelSustainable) {
        const sustainableValues = [];

        for (let i = 0; i < ownerState.labelSustainable.length; i++) {
          sustainableValues[i] = {
            [ownerState.labelSustainable[i]]:
              originalJson.smacsData.labelSustainable.option[
                ownerState.labelSustainable[i]
              ]
          };
        }
        obj.smacsData.labelSustainable.valueList = sustainableValues;
      }
    } else if (originalJson.smacsData.materialType === 'Packaging') {
      obj.smacsData.type = 'packaging';

      obj.smacsData.pkgArticleNum = originalJson.smacsData.pkgArticleNum;

      obj.smacsData.pkgClassificationDD = {
        option: originalJson.smacsData.pkgClassificationDD.option
      };
      if (ownerState.pkgClassification) {
        obj.smacsData.pkgClassificationDD.value = {
          [ownerState.pkgClassification]:
            originalJson.smacsData.pkgClassificationDD.option[
              ownerState.pkgClassification
            ]
        };
      }

      // TODO: pkgConstruction & pkgDepartment are deprecated fields.
      // Remove them when no longer in use.
      obj.smacsData.pkgConstruction = originalJson.smacsData.pkgConstruction;
      obj.smacsData.pkgDepartment = originalJson.smacsData.pkgDepartment;

      if (
        originalJson.smacsData.materialVersion &&
        originalJson.smacsData.materialVersion !== 'V1'
      ) {
        obj.smacsData.pkgConstructionDD = {
          option: originalJson.smacsData.pkgConstructionDD.option
        };
        if (ownerState.pkgConstruction) {
          obj.smacsData.pkgConstructionDD.value = {
            [ownerState.pkgConstruction]:
              originalJson.smacsData.pkgConstructionDD.option[
                ownerState.pkgConstruction
              ]
          };
        }
      }

      obj.smacsData.pkgEndUse = ownerState.pkgEndUse;
      obj.smacsData.pkgLabelBrand = originalJson.smacsData.pkgLabelBrand;
      obj.smacsData.materialComments = ownerState.materialComments;
      obj.smacsData.pkgMaterialStatus =
        originalJson.smacsData.pkgMaterialStatus;
      obj.smacsData.pkgSustainableComments = ownerState.pkgSustainableComments;
      obj.smacsData.pkgPackagingType = originalJson.smacsData.pkgPackagingType;

      if (
        originalJson.smacsData.materialVersion &&
        originalJson.smacsData.materialVersion !== 'V1'
      ) {
        obj.smacsData.pkgDivision = originalJson.smacsData.pkgDivision;
        obj.smacsData.pkgProductCategory =
          originalJson.smacsData.pkgProductCategory;
      }

      obj.smacsData.pkgPrintMethodDD = {
        option: originalJson.smacsData.pkgPrintMethodDD.option
      };
      if (ownerState.pkgPrintMethod) {
        obj.smacsData.pkgPrintMethodDD.value = {
          [ownerState.pkgPrintMethod]:
            originalJson.smacsData.pkgPrintMethodDD.option[
              ownerState.pkgPrintMethod
            ]
        };
      }

      obj.smacsData.pkgWeight = ownerState.pkgWeight;
      obj.smacsData.pkgThickness = ownerState.pkgThickness;

      obj.smacsData.pkgExtrasMisc = {
        option: originalJson.smacsData.pkgExtrasMisc.option
      };
      if (ownerState.pkgExtrasMisc) {
        const extrasMiscValues = [];

        for (let i = 0; i < ownerState.pkgExtrasMisc.length; i++) {
          extrasMiscValues[i] = {
            [ownerState.pkgExtrasMisc[i]]:
              originalJson.smacsData.pkgExtrasMisc.option[
                ownerState.pkgExtrasMisc[i]
              ]
          };
        }
        obj.smacsData.pkgExtrasMisc.valueList = extrasMiscValues;
      }

      obj.smacsData.pkgFinish = {
        option: originalJson.smacsData.pkgFinish.option
      };
      if (ownerState.pkgFinish) {
        const finishValues = [];

        for (let i = 0; i < ownerState.pkgFinish.length; i++) {
          finishValues[i] = {
            [ownerState.pkgFinish[i]]:
              originalJson.smacsData.pkgFinish.option[ownerState.pkgFinish[i]]
          };
        }
        obj.smacsData.pkgFinish.valueList = finishValues;
      }

      obj.smacsData.pkgMaterial = {
        option: originalJson.smacsData.pkgMaterial.option
      };
      if (ownerState.pkgMaterial) {
        const materialValues = [];

        for (let i = 0; i < ownerState.pkgMaterial.length; i++) {
          materialValues[i] = {
            [ownerState.pkgMaterial[i]]:
              originalJson.smacsData.pkgMaterial.option[
                ownerState.pkgMaterial[i]
              ]
          };
        }
        obj.smacsData.pkgMaterial.valueList = materialValues;
      }

      obj.smacsData.pkgSustainable = {
        option: originalJson.smacsData.pkgSustainable.option
      };
      if (ownerState.pkgSustainable) {
        const sustainableValues = [];

        for (let i = 0; i < ownerState.pkgSustainable.length; i++) {
          sustainableValues[i] = {
            [ownerState.pkgSustainable[i]]:
              originalJson.smacsData.pkgSustainable.option[
                ownerState.pkgSustainable[i]
              ]
          };
        }
        obj.smacsData.pkgSustainable.valueList = sustainableValues;
      }
    }
  }

  static getCostDto(
    originalJson,
    giState,
    miState,
    fabricState,
    contentState,
    garmentState,
    ciState,
    knitState,
    wovenState,
    scState,
    headerReport,
    headerForm,
    enableState,
    yarnState,
    sweaterState,
    costSheetVersion
  ) {
    const commentsList = this.getSpecialComments(scState);
    let obj = {
      smacsData: {
        type: 'costsheet',
        costSheetVersion: costSheetVersion,
        costSheetSubForm: [
          {
            costSheetSubFormColumn: [
              {
                comments: commentsList[0],
                simplified: ciState[0],
                fabric: fabricState[0],
                garmentContent: garmentState[0],
                knit: knitState[0],
                woven: wovenState[0],
                generalInformation: giState[0],
                enabled: enableState[0],
                yarn: yarnState[0],
                sweater: sweaterState[0],
                materialInformation: miState[0]
              },
              {
                comments: commentsList[1],
                simplified: ciState[1],
                fabric: fabricState[1],
                garmentContent: garmentState[1],
                knit: knitState[1],
                woven: wovenState[1],
                generalInformation: giState[1],
                enabled: enableState[1],
                yarn: yarnState[1],
                sweater: sweaterState[1],
                materialInformation: miState[1]
              },
              {
                comments: commentsList[2],
                simplified: ciState[2],
                fabric: fabricState[2],
                garmentContent: garmentState[2],
                knit: knitState[2],
                woven: wovenState[2],
                generalInformation: giState[2],
                enabled: enableState[2],
                yarn: yarnState[2],
                sweater: sweaterState[2],
                materialInformation: miState[2]
              }
            ]
          }
        ],
        header: [
          {
            headerForm: [{ ...headerForm }],
            headerReport: [{ ...headerReport }]
          }
        ],
        hiddenFields: originalJson.smacsData.hiddenFields,
        lovs: originalJson.smacsData.lovs
      }
    };

    return obj;
  }

  static getSpecialComments(scState) {
    return scState.map(comment => ({
      wovenSpecialComments: comment['wovenSpecialComments']
    }));
  }
}
