import { skuValidationRules } from '../../validationRules';
import { buildValidationSetter } from '../columnDefsShared';
import { skuTooltipValueGetter, skuCellClassRules } from './utils';

export function skuNdSize1(ctx) {
  return {
    headerName: 'N.com Size 1',
    field: 'ndSize1.value',
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    tooltipComponent: 'tooltip',
    tooltipValueGetter: skuTooltipValueGetter('ndSize1', ctx),
    cellClassRules: skuCellClassRules('ndSize1', ctx),
    valueSetter: buildValidationSetter(ctx, {
      validationRules: skuValidationRules
    })
  };
}
