import React, { Component } from 'react';

import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Warning from '@material-ui/icons/Warning';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';

const styles = {
  icon: {
    marginRight: '10px',
    height: '21px',
    width: '21px'
  },
  error: {
    color: '#c5211e'
  },
  warning: {
    color: '#4a4a4a'
  },
  info: {
    display: 'inline-block',
    backgroundColor: '#f4f4f4',
    borderRadius: '50%'
  }
};

export class Tooltip extends Component {
  getReactContainerClasses() {
    return ['tooltip'];
  }

  render() {
    const { classes, value } = this.props;

    if (!value) {
      return null;
    }

    return (
      <div className="tooltip__content">
        {value.state.toLowerCase() === 'error' ? (
          <Warning className={`${classes.icon} ${classes.error}`} />
        ) : null}
        {value.state.toLowerCase() === 'warning' && (
          <ErrorOutlineOutlinedIcon
            className={`${classes.icon} ${classes.warning}`}
          />
        )}
        {value.state.toLowerCase() === 'info' && (
          <span className={`${classes.icon} ${classes.info}`} />
        )}
        <Typography variant="subtitle1">{value.reason}</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(Tooltip);
