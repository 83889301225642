import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'stylesheets/CostingInformation.scss';
import { Utils } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch'
    },
    '& .MuiInputBase-multiline': {
      minHeight: '8ch',
      width: '40ch',
      height: 'auto'
    }
  },
  disabled: {
    background: '#A8A8A8',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch'
    },
    '& .MuiInputBase-multiline': {
      minHeight: '8ch',
      width: '40ch',
      height: 'auto'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}));

const CostingInformation = props => {
  const classes = useStyles();

  const simplifiedList = props.simplifiedList;
  const currencyDD = {
    usd: 'USD',
    eur: 'EUR'
  };
  const handleCIChange = props.handleCIChange;

  const enableState = props.enableState;

  const giState = props.giState;

  const costSheetVersion = props.costSheetVersion;

  return (
    <Grid>
      <div className={classes.root}>
        <hr />
        {simplifiedList.map((val, idx) => (
          <div key={`ci-${idx}`} className="column">
            <div className="ci-header">Costing Information</div>
            <hr />
            <TextField
              label="Currency Denomination"
              variant="outlined"
              select
              required={enableState[idx]}
              name={`currency-denomination-${idx}`}
              data-idx={idx}
              id={`currency-denomination-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['simplifiedCurrencyDD']}
              disabled={!enableState[idx]}
              onChange={e => handleCIChange(e, idx, 'simplifiedCurrencyDD')}
            >
              {Utils.sortObject(currencyDD)}
            </TextField>
            <TextField
              label="US FOB Cost"
              variant="outlined"
              type="text"
              name={`us-fob-cost-${idx}`}
              data-idx={idx}
              id={`us-fob-cost-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['simplifiedFOBUS']}
              disabled={!enableState[idx] || giState[idx].giLDPProgram === '1'}
              onBlur={e => handleCIChange(e, idx, 'simplifiedFOBUS')}
              onChange={e => handleCIChange(e, idx, 'simplifiedFOBUS')}
            />
            <TextField
              label="US LDP Cost"
              variant="outlined"
              type="text"
              name={`us-ldp-cost-${idx}`}
              data-idx={idx}
              id={`us-ldp-cost-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['simplifiedLDPUS']}
              disabled={!enableState[idx]}
              onBlur={e => handleCIChange(e, idx, 'simplifiedLDPUS')}
              onChange={e => handleCIChange(e, idx, 'simplifiedLDPUS')}
            />
            {costSheetVersion === 'V3' && (
              <TextField
                label="CA FOB Cost"
                variant="outlined"
                type="text"
                name={`ca-fob-cost-${idx}`}
                data-idx={idx}
                id={`ca-fob-cost-${idx}`}
                className={!enableState[idx] && classes.disabled}
                value={val['simplifiedFOBCA']}
                disabled={
                  !enableState[idx] || giState[idx].giLDPProgramCA === '1'
                }
                onBlur={e => handleCIChange(e, idx, 'simplifiedFOBCA')}
                onChange={e => handleCIChange(e, idx, 'simplifiedFOBCA')}
              />
            )}
            {costSheetVersion === 'V3' && (
              <TextField
                label="CA LDP Cost"
                variant="outlined"
                type="text"
                name={`ca-ldp-cost-${idx}`}
                data-idx={idx}
                id={`ca-ldp-cost-${idx}`}
                className={!enableState[idx] && classes.disabled}
                value={val['simplifiedLDPCA']}
                disabled={!enableState[idx]}
                onBlur={e => handleCIChange(e, idx, 'simplifiedLDPCA')}
                onChange={e => handleCIChange(e, idx, 'simplifiedLDPCA')}
              />
            )}
          </div>
        ))}
      </div>
    </Grid>
  );
};

export default CostingInformation;
