import { Grid } from '@material-ui/core';
import React from 'react';
import 'stylesheets/CostingInformation.scss';
import TextEntry from './TextComponents/TextEntry';
import TextDropdown from './TextComponents/TextDropdown';

const FirstCost = props => {
  const rpText = props.props.rp ? '-RP' : '-NonRP';
  const ccText = props.field.cc ? 'US' : 'CA';

  const firstCostFields = [];

  if (props.field.fob) {
    const fob = {
      label: ccText + ' FOB COST',
      name: 'freeOnBoard' + rpText,
      valName: 'freeOnBoard',
      required: false,
      type: 'text',
      disabled: props.field.disabledFob,
      val: props.field.val
    };
    firstCostFields.push(fob);
  }

  if (props.field.ldp) {
    const ldp = {
      label: ccText + ' LDP COST',
      name: 'landedDutyPaid' + rpText,
      valName: 'landedDutyPaid',
      required: false,
      type: 'text',
      disabled: props.field.disabledLdp,
      val: props.field.val
    };
    firstCostFields.push(ldp);
  }

  return (
    <Grid>
      <div>
        {firstCostFields.map(field =>
          field.type === 'text' ? (
            <TextEntry
              cc={props.field.cc}
              field={field}
              props={props.props}
              val={field.val}
            />
          ) : (
            //in case future fields include drop down
            <TextDropdown field={field} props={props.props} val={field.val} />
          )
        )}
      </div>
    </Grid>
  );
};

export default FirstCost;
