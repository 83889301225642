import React, { useCallback, useEffect, useState } from 'react';

import OrionAppNav from '../OrionApplicationNav';
import CostHeader from './CostHeader';
import CostingInformation from './CostingInformation';
import CostingInformationCost from './CostingInformationCost';
import GeneralInformation from './GeneralInformation';
import SpecialComments from './SpecialComments';
import FormInstructions from './FormInstructions';
import MaterialInformation from './MaterialInformation';
import uuid from 'uuid';
import { Utils } from 'utils';
import {
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core';
import HttpError from 'utils/errors/HttpError';
import FabricCost from './FabricCost';
import KnitCost from './KnitCost';
import WovenCost from './WovenCost';
import SweaterCost from './SweaterCost';
import FabricInformation from './FabricInformation';
import YarnInformation from './YarnInformation';
import YarnCost from './YarnCost';

import { BrowserView } from 'react-device-detect';

import 'stylesheets/Print.scss';
import { makeStyles } from '@material-ui/core/styles';
import OtherCost from './OtherCost';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch'
    },
    '& .MuiInputBase-multiline': {
      minHeight: '8ch',
      width: '40ch',
      height: 'auto'
    }
  },
  root2: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}));

const CostView = props => {
  const classes = useStyles();

  const locationProp = props.location.state;
  // commented out to remove nag message, left in in case updating this state is desired in the future
  // const [originalJson, setOriginalJson] = useState(locationProp.originalJson);

  //set as a variable so it can be changed for testing with knitTest.json
  var originalJson = locationProp.originalJson;

  const testJson = require('./knitTest.json');

  //set true for testing -- this will use the knitTest.json to load the form
  if (false) {
    originalJson = testJson;
  }

  const {
    smacsData: {
      header: [
        {
          headerReport: [headerReport],
          headerForm: [headerForm]
        }
      ],
      costSheetSubForm: [{ costSheetSubFormColumn }],
      lovs: {
        giGarmentCODD,
        fabricCODD,
        fabricArticleNoDD,
        knitFabricPriceYieldUOMDD,
        wovenFabricPriceYieldUOMDD,
        yarnCODD,
        yarnArticleNoDD,
        sweaterGarmentConsDD,
        giProductCategoryDD,
        giMaterialTypeDD,
        miUomFabricPriceYieldDD
      },
      costSheetVersion
    }
  } = originalJson;

  const [isReloading, setIsReloading] = useState(false);

  //for explanation of code below see the MaterialView component
  useEffect(() => {
    window.onload = e => {
      let { type } = performance.getEntriesByType('navigation')[0];
      if (type === 'reload' || type === 'back_forward') {
        setIsReloading(true);
      }
    };
  });

  useEffect(() => {
    if (isReloading) {
      const authArray = locationProp.auth.split('.');

      let tokenPath = [authArray[0], locationProp.token, authArray[1]].join(
        '.'
      );
      let { protocol, hostname, port } = window.location;
      if (Number(port) === 0) {
        port = '';
      }
      window.location.replace(
        `${protocol}//${hostname}:${port}/co/${tokenPath}`
      );
    }
  }, [isReloading, locationProp.auth, locationProp.token]);

  useEffect(() => {
    window.onbeforeunload = e => {
      let dialogText =
        'Are you sure you want to leave? Form data may not be saved!';
      e.returnValue = dialogText;
      return dialogText;
    };
  });

  //Enabled
  const enabled = costSheetSubFormColumn.map(column => column['enabled']);

  const [enableState, setEnableState] = useState(enabled);

  const clearData = (fields, idx, handleFunction, ignoreFields) => {
    var event = {
      type: 'change',
      target: {
        value: ''
      }
    };

    for (var i = 0; i < fields.length; i++) {
      if (!ignoreFields.includes(fields[i])) {
        handleFunction(event, idx, fields[i]);
      }
    }
  };

  const duplicateData = (
    fields,
    idx,
    handleFunction,
    currentValues,
    ignoreFields
  ) => {
    var event = {};

    for (var i = 0; i < fields.length; i++) {
      if (!ignoreFields.includes(fields[i])) {
        event = {
          type: 'change',
          target: {
            value: currentValues[0][fields[i]]
          }
        };
        handleFunction(event, idx, fields[i]);
      }
    }
  };

  const duplicateCostData = (
    fields,
    idx,
    handleFunction,
    currentValues,
    type,
    cc
  ) => {
    var event = {};

    for (var i = 0; i < fields.length; i++) {
      event = {
        type: 'change',
        target: {
          value: currentValues[fields[i]]
        }
      };
      handleFunction(event, idx, fields[i] + '-' + type, cc);
    }
  };

  const clearContentData = (fields, idx, idx2, handleFunction) => {
    var event = {
      type: 'change',
      target: {
        value: ''
      }
    };

    for (var i = 0; i < fields.length; i++) {
      handleFunction(event, idx, idx2, fields[i]);
    }
  };

  const handleEnableChange = (e, idx) => {
    if (
      costSheetVersion === 'V2' ||
      costSheetVersion === 'V3' ||
      costSheetVersion === 'V4' ||
      costSheetVersion === 'V5' ||
      costSheetVersion === 'V6'
    ) {
      var isEnableChange = true;
      if (!e.target.checked) {
        isEnableChange = window.confirm(
          'Are you sure you want to disable this column? This action will clear out all data in this column.'
        );
      }

      const ignoreFields = [
        'giSentDate',
        'replenishmentFabricCost',
        'nonReplenishmentFabricCost',
        'replenishmentYarnCost',
        'nonReplenishmentYarnCost'
      ];

      if (!e.target.checked && isEnableChange && idx !== 0) {
        clearData(Object.keys(giState[idx]), idx, handleGIChange, ignoreFields);
        clearCostData('rp', idx);
        clearCostData('nonrp', idx);
        clearData(Object.keys(scState[idx]), idx, handleSCChange, ignoreFields);

        switch (headerForm.headerFormType) {
          case 'Knit':
            clearData(
              Object.keys(fabricState[idx]),
              idx,
              handleFabricChange,
              ignoreFields
            );
            clearData(
              Object.keys(garmentState[idx]),
              idx,
              handleGarmentChange,
              ignoreFields
            );
            for (let i = 0; i < contentState[idx].length; i++) {
              clearContentData(
                Object.keys(contentState[idx][i]),
                idx,
                i,
                handleContentChange
              );
            }

            clearData(
              Object.keys(knitState[idx]),
              idx,
              handleKnitChange,
              ignoreFields
            );
            break;
          case 'Woven':
            clearData(
              Object.keys(fabricState[idx]),
              idx,
              handleFabricChange,
              ignoreFields
            );
            clearData(
              Object.keys(garmentState[idx]),
              idx,
              handleGarmentChange,
              ignoreFields
            );
            for (let i = 0; i < contentState[idx].length; i++) {
              clearContentData(
                Object.keys(contentState[idx][i]),
                idx,
                i,
                handleContentChange
              );
            }
            clearData(
              Object.keys(wovenState[idx]),
              idx,
              handleWovenChange,
              ignoreFields
            );
            break;
          case 'Sweater':
            clearData(
              Object.keys(yarnState[idx]),
              idx,
              handleYarnChange,
              ignoreFields
            );
            clearData(
              Object.keys(garmentState[idx]),
              idx,
              handleGarmentChange,
              ignoreFields
            );
            for (let i = 0; i < contentState[idx].length; i++) {
              clearContentData(
                Object.keys(contentState[idx][i]),
                idx,
                i,
                handleContentChange
              );
            }
            clearData(
              Object.keys(sweaterState[idx]),
              idx,
              handleSweaterChange,
              ignoreFields
            );
            break;
          case 'Simplified':
            break;
          default:
            break;
        }

        const updatedEnableState = [...enableState];
        updatedEnableState[idx] = e.target.checked;
        setEnableState(updatedEnableState);
      } else if (e.target.checked && idx !== 0) {
        const updatedEnableState = [...enableState];
        updatedEnableState[idx] = e.target.checked;
        setEnableState(updatedEnableState);
        let event = {
          type: 'change',
          target: {
            value: 'both'
          }
        };
        handleRPChange(event, idx);

        const fieldsToDuplicate = [
          'giQuoteSubmittedBy',
          'giManufacturer',
          'giFactory',
          'giGarmentCODD'
        ];

        duplicateData(
          fieldsToDuplicate,
          idx,
          handleGIChange,
          giState,
          ignoreFields
        );
      }
    } else if (idx !== 0) {
      const updatedEnableState = [...enableState];
      updatedEnableState[idx] = e.target.checked;
      setEnableState(updatedEnableState);
    }
  };

  //Replenishment
  var replenishment = [false, false, false];
  var nonReplenishment = [false, false, false];

  if (
    costSheetVersion === 'V2' ||
    costSheetVersion === 'V3' ||
    costSheetVersion === 'V4' ||
    costSheetVersion === 'V5' ||
    costSheetVersion === 'V6'
  ) {
    if (['Knit', 'Woven'].includes(headerForm.headerFormType)) {
      replenishment = costSheetSubFormColumn.map(
        column =>
          column[headerForm.headerFormType.toLowerCase()][
            'replenishmentFabricCost'
          ]['included']
      );
      nonReplenishment = costSheetSubFormColumn.map(
        column =>
          column[headerForm.headerFormType.toLowerCase()][
            'nonReplenishmentFabricCost'
          ]['included']
      );
    } else if (['Sweater'].includes(headerForm.headerFormType)) {
      replenishment = costSheetSubFormColumn.map(
        column =>
          column[headerForm.headerFormType.toLowerCase()][
            'replenishmentYarnCost'
          ]['included']
      );
      nonReplenishment = costSheetSubFormColumn.map(
        column =>
          column[headerForm.headerFormType.toLowerCase()][
            'nonReplenishmentYarnCost'
          ]['included']
      );
    } else if (['Simplified'].includes(headerForm.headerFormType)) {
      replenishment = costSheetSubFormColumn.map(
        column => column[headerForm.headerFormType.toLowerCase()]['rpincluded']
      );
      nonReplenishment = costSheetSubFormColumn.map(
        column =>
          column[headerForm.headerFormType.toLowerCase()]['nonRPIncluded']
      );
    }
  }

  const handleUpdateRPState = (updatedState, type, includedName, idx, e) => {
    updatedState[idx][includedName] = e;
    return updatedState;
  };

  const [replenishmentState, setReplenishmentState] = useState(replenishment);

  const handleReplenishmentChange = (e, idx) => {
    const updatedReplenishmentState = [...replenishmentState];
    updatedReplenishmentState[idx] = e;
    setReplenishmentState(updatedReplenishmentState);

    if (headerForm.headerFormType === 'Simplified') {
      setCiState(handleUpdateRPState([...ciState], null, 'rpincluded', idx, e));
    }
    if (headerForm.headerFormType === 'Knit') {
      setCostRPListState(
        handleUpdateRPState(
          [...costRPListState],
          'replenishmentFabricCost',
          'included',
          idx,
          e
        )
      );
      const updatedKnitState = [...knitState];
      updatedKnitState[idx].replenishmentFabricCost = costRPListState[idx];
      setKnitState(updatedKnitState);
    }
    if (headerForm.headerFormType === 'Woven') {
      setCostRPListState(
        handleUpdateRPState(
          [...costRPListState],
          'replenishmentFabricCost',
          'included',
          idx,
          e
        )
      );
      const updatedWovenState = [...wovenState];
      updatedWovenState[idx].replenishmentFabricCost = costRPListState[idx];
      setWovenState(updatedWovenState);
    }
    if (headerForm.headerFormType === 'Sweater') {
      setCostRPListState(
        handleUpdateRPState(
          [...costRPListState],
          'replenishmentYarnCost',
          'included',
          idx,
          e
        )
      );
      const updatedSweaterState = [...sweaterState];
      updatedSweaterState[idx].replenishmentYarnCost = costRPListState[idx];
      setSweaterState(updatedSweaterState);
    }
  };

  const [nonReplenishmentState, setNonReplenishmentState] = useState(
    nonReplenishment
  );

  const handleNonReplenishmentChange = (e, idx) => {
    const updatedNonReplenishmentState = [...nonReplenishmentState];
    updatedNonReplenishmentState[idx] = e;
    setNonReplenishmentState(updatedNonReplenishmentState);

    if (headerForm.headerFormType === 'Simplified') {
      setCiState(
        handleUpdateRPState([...ciState], null, 'nonRPIncluded', idx, e)
      );
    }
    if (headerForm.headerFormType === 'Knit') {
      setCostNonRPListState(
        handleUpdateRPState(
          [...costNonRPListState],
          'nonReplenishmentFabricCost',
          'included',
          idx,
          e
        )
      );
      const updatedKnitState = [...knitState];
      updatedKnitState[idx].nonReplenishmentFabricCost =
        costNonRPListState[idx];
      setKnitState(updatedKnitState);
    }
    if (headerForm.headerFormType === 'Woven') {
      setCostNonRPListState(
        handleUpdateRPState(
          [...costNonRPListState],
          'nonReplenishmentFabricCost',
          'included',
          idx,
          e
        )
      );
      const updatedWovenState = [...wovenState];
      updatedWovenState[idx].nonReplenishmentFabricCost =
        costNonRPListState[idx];
      setWovenState(updatedWovenState);
    }
    if (headerForm.headerFormType === 'Sweater') {
      setCostNonRPListState(
        handleUpdateRPState(
          [...costNonRPListState],
          'nonReplenishmentYarnCost',
          'included',
          idx,
          e
        )
      );
      const updatedSweaterState = [...sweaterState];
      updatedSweaterState[idx].nonReplenishmentYarnCost =
        costNonRPListState[idx];
      setSweaterState(updatedSweaterState);
    }
  };

  var radio = ['', '', ''];
  for (let i = 0; i < 3; i++) {
    if (replenishmentState[i] && nonReplenishmentState[i]) {
      radio[i] = 'both';
    } else if (replenishmentState[i]) {
      radio[i] = 'rp';
    } else if (nonReplenishmentState[i]) {
      radio[i] = 'nonrp';
    }
  }

  const clearCostData = (type, idx) => {
    var fields = {};

    var eventForNotIncluded = {
      type: 'change',
      target: {
        value: ''
      }
    };

    var eventForIncluded = {
      type: 'change',
      target: {
        value: false
      }
    };

    if (type === 'nonrp') {
      if (headerForm.headerFormType !== 'Simplified') {
        fields = Object.keys(costRPListState[idx]);

        for (var i = 0; i < fields.length; i++) {
          if (!fields[i].includes('firstCost')) {
            if (
              headerForm.headerFormType === 'Knit' ||
              headerForm.headerFormType === 'Woven'
            ) {
              fields[i] === 'included'
                ? handleFabricCostChange(
                    eventForIncluded,
                    idx,
                    fields[i] + '-RP'
                  )
                : handleFabricCostChange(
                    eventForNotIncluded,
                    idx,
                    fields[i] + '-RP'
                  );
            }
            if (headerForm.headerFormType === 'Sweater') {
              fields[i] === 'included'
                ? handleYarnCostChange(eventForIncluded, idx, fields[i] + '-RP')
                : handleYarnCostChange(
                    eventForNotIncluded,
                    idx,
                    fields[i] + '-RP'
                  );
            }
          }
        }
      } else if (headerForm.headerFormType === 'Simplified') {
        fields = Object.keys(firstCostRPListUSState[idx]);

        for (let i = 0; i < fields.length; i++) {
          fields[i] === 'rpincluded'
            ? handleFirstCostChange(eventForIncluded, idx, fields[i] + '-RP')
            : handleFirstCostChange(
                eventForNotIncluded,
                idx,
                fields[i] + '-RP',
                'US'
              );
          fields[i] === 'rpincluded'
            ? handleFirstCostChange(eventForIncluded, idx, fields[i] + '-RP')
            : handleFirstCostChange(
                eventForNotIncluded,
                idx,
                fields[i] + '-RP',
                ''
              );
        }
      }
    }

    if (type === 'rp') {
      if (headerForm.headerFormType !== 'Simplified') {
        fields = Object.keys(costNonRPListState[idx]);

        for (let i = 0; i < fields.length; i++) {
          if (!fields[i].includes('firstCost')) {
            if (
              headerForm.headerFormType === 'Knit' ||
              headerForm.headerFormType === 'Woven'
            ) {
              fields[i] === 'included'
                ? handleFabricCostChange(
                    eventForIncluded,
                    idx,
                    fields[i] + '-NonRP'
                  )
                : handleFabricCostChange(
                    eventForNotIncluded,
                    idx,
                    fields[i] + '-NonRP'
                  );
            }
            if (headerForm.headerFormType === 'Sweater') {
              fields[i] === 'included'
                ? handleYarnCostChange(
                    eventForIncluded,
                    idx,
                    fields[i] + '-NonRP'
                  )
                : handleYarnCostChange(
                    eventForNotIncluded,
                    idx,
                    fields[i] + '-NonRP'
                  );
            }
          }
        }
      } else if (headerForm.headerFormType === 'Simplified') {
        fields = Object.keys(firstCostNonRPListUSState[idx]);

        for (let i = 0; i < fields.length; i++) {
          fields[i] === 'nonRPIncluded'
            ? handleFirstCostChange(eventForIncluded, idx, fields[i] + '-NonRP')
            : handleFirstCostChange(
                eventForNotIncluded,
                idx,
                fields[i] + '-NonRP',
                'US'
              );
          fields[i] === 'nonRPIncluded'
            ? handleFirstCostChange(eventForIncluded, idx, fields[i] + '-NonRP')
            : handleFirstCostChange(
                eventForNotIncluded,
                idx,
                fields[i] + '-NonRP',
                ''
              );
        }
      }
    }
  };

  const [radioState, setRadioState] = useState(radio);

  const handleRPChange = (e, idx) => {
    var isRPChange = true;
    if (e.target.value !== 'both') {
      isRPChange = window.confirm(
        'Are you sure you want to change the Fulfillment Type? This action will clear out nonrelevant data fields.'
      );
    }

    if (isRPChange) {
      const updatedRadioState = [...radioState];
      updatedRadioState[idx] = e.target.value;
      setRadioState(updatedRadioState);

      if (e.target.value === 'both') {
        handleNonReplenishmentChange(true, idx);
        handleReplenishmentChange(true, idx);
      } else if (e.target.value === 'nonrp') {
        handleNonReplenishmentChange(true, idx);
        handleReplenishmentChange(false, idx);
      } else {
        handleNonReplenishmentChange(false, idx);
        handleReplenishmentChange(true, idx);
      }
    }

    clearCostData(e.target.value, idx);
  };

  //General Information/Simplified
  const giList = costSheetSubFormColumn.map(
    column => column['generalInformation']
  );
  const simplifiedList = costSheetSubFormColumn.map(
    column => column['simplified']
  );
  const specialCommentsList = costSheetSubFormColumn.map(
    column => column['comments']
  );
  const miList = costSheetSubFormColumn.map(
    column => column['materialInformation']
  );

  const [giState, setGiState] = useState(giList);
  const [ciState, setCiState] = useState(simplifiedList);
  const [scState, setScState] = useState(specialCommentsList);
  const [fetchStatus, setFetchStatus] = useState({ POST: 0, PUT: 0 });
  const [miState, setMiState] = useState(miList);

  const handleFetch = (type, status) => {
    const updatedStatus = fetchStatus;
    updatedStatus[type] = status;
    setFetchStatus(updatedStatus);
  };

  const handleNumberFormatting = (value, className) => {
    const calculated = new Set([
      'knitFabricFinPct',
      'knitFabricCost',
      'knitTotalFabricCost',
      'knitTotalTrimCost',
      'knitFirstCost',
      'knitFirstCostLDP',
      'knitFirstCostLDPCA',
      'wovenFabricFinPct',
      'wovenMainFabricCost',
      'wovenTotalFabricCost',
      'wovenTotalTrimCMWetProcCost',
      'wovenFirstCost',
      'wovenFirstCostLDP',
      'wovenFirstCostLDPCA',
      'sweaterFabricFinChargePct',
      'sweaterMainYarnWtGarment',
      'sweaterTotalYarnCostDz',
      'sweaterTotalYarnCostGmt',
      'sweaterTotalTrimLaborCost',
      'sweaterFirstCostFOB',
      'sweaterFirstCostLDP',
      'sweaterFirstCostLDPCA',
      'garmentContentTotalPct',
      'garmentContentLbs',
      'garmentOrganicCottonLbs',
      // Knit and Woven FabricCost fields
      'mainFabricCost',
      'fabricFinPct',
      'totalFabricCost',
      'firstCostUSFOB',
      'firstCostUSLDP',
      'firstCostCALDP',
      'importCostLDP',
      'importCostLDPCA',
      // Sweater YarnCost fields
      'fabricFinPct',
      'mainYarnWeightLbsGmt',
      'totalYarnCostDz',
      'totalYarnCostGmt',
      'totalTrimCost',
      'firstCostUSFOB',
      'firstCostUSLDP',
      'firstCostCALDP',
      'miMainYarnWeightLbsGmt'
    ]);
    if (calculated.has(className) && value !== '') {
      return value.toFixed(2);
    }
    return value;
  };

  const handleNumbers = (e, idx, className, updatedState) => {
    const regexInteger = /^([1-9][0-9]*|[0])?$/;
    const regexFloat = /^([1-9][0-9]*|[0])?(\.\d{0,2})?$/;
    const intLimit = 2147483647;
    const floatLimit = 1e100;

    const integers = new Set([
      'giProdCutMinOrder',
      'giProdCutMinColor',
      'yarnMinOrder',
      'yarnMinColor',
      'fabricMinOrder',
      'fabricMinColor',
      'fabricCuttableWidth'
    ]);
    const floats = new Set([
      'knitFabricWt',
      'knitMainFabricPrice',
      'knitFabricFinChg',
      'knitFabricGmtYield',
      'knitAdditionalFabricCost',
      'knitTrimsAndEmb',
      'knitCutandMakeLabor',
      'knitWetProcCost',
      'knitImportCostForLDP',
      'knitImportCostForLDPCA',
      'wovenFabricWt',
      'wovenMainFabricPrice',
      'wovenFabricFinChg',
      'wovenMainFabricYield',
      'wovenAddFabricCost',
      'wovenTotalTrims',
      'wovenCutMakeLabor',
      'wovenWetProcFinish',
      'wovenImportCostForLDP',
      'wovenImportCostForLDPCA',
      'sweaterMainYarnCost',
      'sweaterMainFinCharge',
      'sweaterMainYarnWt',
      'sweaterAddnlYarnCostDz',
      'sweaterTotalTrim',
      'sweaterKnitting',
      'sweaterWetProc',
      'sweaterImportCost',
      'sweaterImportCostCA',
      'garmentOrganicCottonPCT',
      'garmentContentTotalLbs',
      'simplifiedFOBUS',
      'simplifiedLDPUS',
      'simplifiedFOBCA',
      'simplifiedLDPCA',
      // Knit and Woven FabricCost fields
      'fabricFinChg',
      'fabricWeight',
      'mainFabricPrice',
      'mainFabricGmtYield',
      'additionalFabricCost',
      'trimsAndTesting',
      'cutAndMakeLabor',
      'wetProcCost',
      'importCostForLDP',
      'importCostForLDPCA',
      // Sweater YarnCost fields
      'mainYarnCost',
      'fabricFinChg',
      'totalTrimCost',
      'knittingLinkingLabor',
      'wetProcCost',
      'importCostForLDP',
      'importCostForLDPCA',
      'mainYarnWeightLbsDz',
      'additionalYarnCost',
      // Simplified FirstCost fields
      'landedDutyPaid',
      'freeOnBoard',
      'giDutyPct',
      'giAdditionalTariffPct',
      //Material Infromation Fields
      'miFabricCuttableWidthInches',
      'miMainFabricGarmentYieldUOM',
      'miMainYarnWeightLbsDz'
    ]);

    if (e.type === 'change' || e.type === 'click') {
      if (integers.has(className)) {
        if (regexInteger.test(e.target.value)) {
          if (e.target.value <= intLimit) {
            updatedState[idx][className] = e.target.value;
            return updatedState;
          }
        }
      } else if (floats.has(className)) {
        if (regexFloat.test(e.target.value)) {
          if (e.target.value <= floatLimit) {
            updatedState[idx][className] = e.target.value;
            return updatedState;
          }
        }
      } else {
        updatedState[idx][className] = e.target.value;
        return updatedState;
      }
    } else if (e.type === 'blur') {
      if (floats.has(className)) {
        if (e.target.value.endsWith('.')) {
          updatedState[idx][className] = e.target.value.replace('.', '');
          return updatedState;
        }
      }
    }
    return updatedState;
  };

  const handleContentNumbers = (e, idx, idx2, className, updatedState) => {
    const regexFloat = /^([1-9][0-9]*|[0])?(\.\d{0,2})?$/;
    const floatLimit = 1e100;

    const floats = new Set(['garmentContentPCT']);

    if (e.type === 'change') {
      if (floats.has(className)) {
        if (regexFloat.test(e.target.value)) {
          if (e.target.value <= floatLimit) {
            updatedState[idx][idx2][className] = e.target.value;
            return updatedState;
          }
        }
      } else {
        updatedState[idx][idx2][className] = e.target.value;
        return updatedState;
      }
    } else if (e.type === 'blur') {
      if (floats.has(className)) {
        if (e.target.value.endsWith('.')) {
          updatedState[idx][idx][className] = e.target.value.replace('.', '');
          return updatedState;
        }
      }
    }
    return updatedState;
  };

  const handleMIChange = (e, idx, className) => {
    var updatedMIState = [...miState];
    updatedMIState = handleNumbers(e, idx, className, updatedMIState);
    if (className === 'miMainYarnWeightLbsDz') {
      updatedMIState = miWeightPerGarment(updatedMIState, idx);
    }
    setMiState(updatedMIState);
  };

  const handleGIChange = (e, idx, className) => {
    var updatedGIState = [...giState];
    updatedGIState = handleNumbers(e, idx, className, updatedGIState);
    setGiState(updatedGIState);
    var updatedMIState = [...miState];
    if (className === 'giMaterialTypeDD') {
      if (
        updatedGIState[idx].giMaterialTypeDD === 'yarn' ||
        updatedGIState[idx].giMaterialTypeDD === ''
      ) {
        updatedMIState[idx].miFabricCuttableWidthInches = '';
        updatedMIState[idx].miUomFabricPriceYieldDD = '';
        updatedMIState[idx].miMainFabricGarmentYieldUOM = '';
      } else if (
        updatedGIState[idx].giMaterialTypeDD === 'knit' ||
        updatedGIState[idx].giMaterialTypeDD === 'woven' ||
        updatedGIState[idx].giMaterialTypeDD === ''
      ) {
        updatedMIState[idx].miMainYarnWeightLbsDz = '';
        updatedMIState[idx].miMainYarnWeightLbsGmt = '';
      }
      setMiState(updatedMIState);
    }
    if (className === 'giProductCategoryDD') {
      if (updatedGIState[idx].giProductCategoryDD === 'nonApparel') {
        updatedGIState[idx].giMaterialTypeDD = '';
        updatedMIState[idx].miFabricCuttableWidthInches = '';
        updatedMIState[idx].miUomFabricPriceYieldDD = '';
        updatedMIState[idx].miMainFabricGarmentYieldUOM = '';
        updatedMIState[idx].miMainYarnWeightLbsDz = '';
        updatedMIState[idx].miMainYarnWeightLbsGmt = '';
      }
      setMiState(updatedMIState);
    }
  };

  const handleGICheckBoxChange = (e, idx, className) => {
    const updatedGIState = [...giState];
    updatedGIState[idx][className] = e.target.checked ? '1' : '0';
    setGiState(updatedGIState);

    if (headerForm.headerFormType === 'Simplified') {
      if (e.target.checked) {
        var event = {
          type: 'change',
          target: {
            value: ''
          }
        };
        const cc = className === 'giLDPProgram' ? 'US' : '';
        handleFirstCostChange(event, idx, 'freeOnBoard-NonRP', cc);
        handleFirstCostChange(event, idx, 'freeOnBoard-RP', cc);
      }
    }
    if (
      (costSheetVersion === 'V5' || costSheetVersion === 'V6') &&
      e.target.checked
    ) {
      var event2 = {
        type: 'change',
        target: {
          value: '0.00'
        }
      };
      handleGIChange(event2, idx, 'giDutyPct');
      handleGIChange(event2, idx, 'giAdditionalTariffPct');
    }
  };

  const handleCIChange = (e, idx, className) => {
    var updatedCIState = [...ciState];
    updatedCIState = handleNumbers(e, idx, className, updatedCIState);
    setCiState(updatedCIState);
  };

  const handleSCChange = (e, idx, className) => {
    const updatedSCState = [...scState];
    updatedSCState[idx][className] = e.target.value;
    setScState(updatedSCState);
  };

  //Garment and Fabric, not type specific, need to add to useEffect conditional other form types
  const [formReady, setReady] = useState(false);

  useEffect(() => {
    if (
      headerForm.headerFormType === 'Knit' ||
      headerForm.headerFormType === 'Woven' ||
      headerForm.headerFormType === 'Sweater'
    ) {
      const garmentContentList = costSheetSubFormColumn.map(
        column => column['garmentContent']
      );
      const garmentContentRowList = garmentContentList.map(
        column => column['garmentContentRow']
      );

      setGarmentState(garmentContentList);
      setContentState(garmentContentRowList);

      setReady(true);
    }
  }, [headerForm, costSheetSubFormColumn]);

  const fabricList = costSheetSubFormColumn.map(column => column['fabric']);

  const [garmentState, setGarmentState] = useState([]);
  const [contentState, setContentState] = useState([]);
  const [fabricState, setFabricState] = useState(fabricList);

  const handleGarmentTotal = idx => {
    const updatedGarment = [...garmentState];

    var total = 0;
    for (let i = 0; i < contentState[idx].length; i++) {
      if (
        contentState[idx][i].garmentContentPCT != null &&
        contentState[idx][i].garmentContentPCT !== ''
      )
        total = total + parseFloat(contentState[idx][i].garmentContentPCT);
    }
    if (
      garmentState[idx].garmentOrganicCottonPCT != null &&
      garmentState[idx].garmentOrganicCottonPCT !== ''
    )
      total = total + parseFloat(garmentState[idx].garmentOrganicCottonPCT);

    updatedGarment[idx].garmentContentTotalPct = handleNumberFormatting(
      total,
      'garmentContentTotalPct'
    );
    setGarmentState(updatedGarment);
  };

  const handleGarmentLbs = (idx, idx2) => {
    const updatedContent = [...contentState];

    var totalLbs = 0;
    var percent = 0;

    if (
      garmentState[idx].garmentContentTotalLbs != null &&
      garmentState[idx].garmentContentTotalLbs !== ''
    )
      totalLbs = parseFloat(garmentState[idx].garmentContentTotalLbs);

    if (
      contentState[idx][idx2].garmentContentPCT != null &&
      contentState[idx][idx2].garmentContentPCT !== ''
    )
      percent = parseFloat(contentState[idx][idx2].garmentContentPCT);

    var lbs = (parseFloat(totalLbs) * parseFloat(percent)) / 100;

    updatedContent[idx][idx2].garmentContentLbs = handleNumberFormatting(
      lbs,
      'garmentContentLbs'
    );
    setContentState(updatedContent);
  };

  const handleGarmentLbsCotton = idx => {
    const updatedGarment = [...garmentState];

    var totalLbs = 0;
    var percent = 0;

    if (
      garmentState[idx].garmentContentTotalLbs != null &&
      garmentState[idx].garmentContentTotalLbs !== ''
    )
      totalLbs = parseFloat(garmentState[idx].garmentContentTotalLbs);

    if (
      garmentState[idx].garmentOrganicCottonPCT != null &&
      garmentState[idx].garmentOrganicCottonPCT !== ''
    )
      percent = parseFloat(garmentState[idx].garmentOrganicCottonPCT);

    var lbs = (parseFloat(totalLbs) * parseFloat(percent)) / 100;

    updatedGarment[idx].garmentOrganicCottonLbs = handleNumberFormatting(
      lbs,
      'garmentOrganicCottonLbs'
    );
    setGarmentState(updatedGarment);
  };

  const handleGarmentLbsAll = idx => {
    for (let i = 0; i < contentState[idx].length; i++) {
      handleGarmentLbs(idx, i);
    }
    handleGarmentLbsCotton(idx);
  };

  const addContent = (idx, i) => {
    if (contentState[idx].length < 6) {
      const updatedContent = [...contentState];
      updatedContent[idx][i] = {
        garmentContentDD: '',
        garmentContentPCT: '',
        garmentContentLbs: ''
      };
      setContentState(updatedContent);
    } else
      alert('You have reached the maximum number of Content items allowed.');
  };

  const handleContentChange = (e, idx, idx2, className) => {
    var updatedContent = [...contentState];

    const updatedContentColTotal = updatedContent[idx].map(
      idx => idx.garmentContentPCT
    );

    updatedContent = handleContentNumbers(
      e,
      idx,
      idx2,
      className,
      updatedContent
    );

    const changedContentColTotal = updatedContent[idx].map(
      idx => idx.garmentContentPCT
    );
    const newVal = Number(changedContentColTotal[idx2]);
    const oldVal = updatedContentColTotal[idx2];
    const oldValAsNumber = Number(updatedContentColTotal[idx2]);

    const updatedGarment = [...garmentState];

    const totalPct =
      parseFloat(updatedGarment[idx].garmentContentTotalPct) - oldVal;

    let increasing = oldValAsNumber < newVal;

    let isLessThanOrEqualTo100 = !parseFloat(newVal).isNaN
      ? parseFloat(newVal) + totalPct <= 100.0
      : totalPct <= 100.0;

    updatedGarment[idx]['garmentContentRow'] = contentState[idx];
    setGarmentState(updatedGarment);

    if (className === 'garmentContentPCT') {
      handleGarmentTotal(idx);
      handleGarmentLbs(idx, idx2);

      if (isLessThanOrEqualTo100) {
        setContentState(updatedContent);
      } else if (!increasing && !isLessThanOrEqualTo100) {
        setContentState(updatedContent);
      } else {
        updatedContent[idx][idx2][className] = oldVal;
        setContentState(updatedContent);
        alert('Content totals must add up to 100%');
      }
    }
  };

  const handleGarmentChange = (e, idx, className) => {
    var updatedGarment = [...garmentState];
    updatedGarment = handleNumbers(e, idx, className, updatedGarment);
    setGarmentState(updatedGarment);

    if (className === 'garmentOrganicCottonPCT') {
      handleGarmentTotal(idx);
    }
    if (className === 'garmentContentTotalLbs') {
      handleGarmentLbsAll(idx);
    }
    if (className === 'garmentOrganicCottonPCT') {
      handleGarmentLbsCotton(idx);
    }
  };

  const handleFabricChange = (e, idx, className) => {
    let updatedFabricState = [...fabricState];
    updatedFabricState = handleNumbers(e, idx, className, updatedFabricState);
    setFabricState(updatedFabricState);
  };

  //Fabric Cost RP
  var costRPList = ['', '', ''];
  var firstCostRPListUS = ['', '', ''];
  var firstCostRPListCA = ['', '', ''];
  if (
    costSheetVersion === 'V2' ||
    costSheetVersion === 'V3' ||
    costSheetVersion === 'V4' ||
    costSheetVersion === 'V5' ||
    costSheetVersion === 'V6'
  ) {
    if (['Knit', 'Woven'].includes(headerForm.headerFormType)) {
      costRPList = costSheetSubFormColumn.map(
        column =>
          column[headerForm.headerFormType.toLowerCase()]
            .replenishmentFabricCost
      );
    } else if (['Sweater'].includes(headerForm.headerFormType)) {
      costRPList = costSheetSubFormColumn.map(
        column =>
          column[headerForm.headerFormType.toLowerCase()].replenishmentYarnCost
      );
    } else if (['Simplified'].includes(headerForm.headerFormType)) {
      firstCostRPListUS = costSheetSubFormColumn.map(
        column =>
          column[headerForm.headerFormType.toLowerCase()]
            .replenishmentFirstCostUS
      );
      firstCostRPListCA = costSheetSubFormColumn.map(
        column =>
          column[headerForm.headerFormType.toLowerCase()]
            .replenishmentFirstCostCA
      );
    }
  }

  const [costRPListState, setCostRPListState] = useState(costRPList);
  const [firstCostRPListUSState, setFirstCostRPListUSState] = useState(
    firstCostRPListUS
  );
  const [firstCostRPListCAState, setFirstCostRPListCAState] = useState(
    firstCostRPListCA
  );
  var firstCostRPList = {
    firstCostRPListUSState: firstCostRPListUSState,
    firstCostRPListCAState: firstCostRPListCAState
  };
  const firstCostRPListState = firstCostRPList;

  //Fabric Cost Non-RP
  var costNonRPList = ['', '', ''];
  var firstCostNonRPListUS = ['', '', ''];
  var firstCostNonRPListCA = ['', '', ''];
  if (
    costSheetVersion === 'V2' ||
    costSheetVersion === 'V3' ||
    costSheetVersion === 'V4' ||
    costSheetVersion === 'V5' ||
    costSheetVersion === 'V6'
  ) {
    if (['Knit', 'Woven'].includes(headerForm.headerFormType)) {
      costNonRPList = costSheetSubFormColumn.map(
        column =>
          column[headerForm.headerFormType.toLowerCase()]
            .nonReplenishmentFabricCost
      );
    } else if (['Sweater'].includes(headerForm.headerFormType)) {
      costNonRPList = costSheetSubFormColumn.map(
        column =>
          column[headerForm.headerFormType.toLowerCase()]
            .nonReplenishmentYarnCost
      );
    } else if (['Simplified'].includes(headerForm.headerFormType)) {
      firstCostNonRPListUS = costSheetSubFormColumn.map(
        column =>
          column[headerForm.headerFormType.toLowerCase()]
            .nonReplenishmentFirstCostUS
      );
      firstCostNonRPListCA = costSheetSubFormColumn.map(
        column =>
          column[headerForm.headerFormType.toLowerCase()]
            .nonReplenishmentFirstCostCA
      );
    }
  }
  const [costNonRPListState, setCostNonRPListState] = useState(costNonRPList);
  const [firstCostNonRPListUSState, setFirstCostNonRPListUSState] = useState(
    firstCostNonRPListUS
  );
  const [firstCostNonRPListCAState, setFirstCostNonRPListCAState] = useState(
    firstCostNonRPListCA
  );

  var firstCostNonRPList = {
    firstCostNonRPListUSState: firstCostNonRPListUSState,
    firstCostNonRPListCAState: firstCostNonRPListCAState
  };
  const firstCostNonRPListState = firstCostNonRPList;

  const isCopy = useCallback(
    (idx, type) => {
      if (radioState[idx] !== 'both') {
        return true;
      }
      const requiredFields = [
        'mainFabricPrice',
        'fabricFinChg',
        'mainFabricGmtYield',
        'trimsAndTesting',
        'cutAndMakeLabor',
        'mainYarnCost',
        'mainYarnWeightLbsDz',
        'knittingLinkingLabor'
      ];
      const fields =
        type === 'nonrp' ? costNonRPListState[idx] : costRPListState[idx];
      for (var i = 0; i < requiredFields.length; i++) {
        if (typeof fields[requiredFields[i]] === 'undefined') continue;
        if (fields[requiredFields[i]] === '') return true;
      }
      return false;
    },
    [costNonRPListState, costRPListState, radioState]
  );

  useEffect(() => {
    const copyCostNonRP = [
      isCopy(0, 'nonrp'),
      isCopy(1, 'nonrp'),
      isCopy(2, 'nonrp')
    ];
    const copyCostRP = [isCopy(0, 'rp'), isCopy(1, 'rp'), isCopy(2, 'rp')];
    setCopyStateNonRP(copyCostNonRP);
    setCopyStateRP(copyCostRP);
  }, [isCopy, radioState]);

  const copyCostNonRP = [
    isCopy(0, 'nonrp'),
    isCopy(1, 'nonrp'),
    isCopy(2, 'nonrp')
  ];
  const copyCostRP = [isCopy(0, 'rp'), isCopy(1, 'rp'), isCopy(2, 'rp')];
  const [copyStateNonRP, setCopyStateNonRP] = useState(copyCostNonRP);
  const [copyStateRP, setCopyStateRP] = useState(copyCostRP);

  //Knit
  const knitList = costSheetSubFormColumn.map(column => column['knit']);
  const [knitState, setKnitState] = useState(knitList);

  const knitFinancePct = (updatedKnitState, idx) => {
    var mainPrice = parseFloat(updatedKnitState[idx].knitMainFabricPrice);
    if (isNaN(mainPrice)) mainPrice = 0;

    var financeCharge = parseFloat(updatedKnitState[idx].knitFabricFinChg);
    if (isNaN(financeCharge)) financeCharge = 0;

    var financePercent =
      (parseFloat(financeCharge) /
        (parseFloat(financeCharge) + parseFloat(mainPrice))) *
      100;
    if (isNaN(financePercent)) financePercent = '';

    updatedKnitState[idx].knitFabricFinPct = handleNumberFormatting(
      financePercent,
      'knitFabricFinPct'
    );

    return updatedKnitState;
  };

  const fabricCostFinancePct = (newState, idx) => {
    var mainPrice = parseFloat(newState[idx].mainFabricPrice);
    if (isNaN(mainPrice)) mainPrice = 0;

    var financeCharge = parseFloat(newState[idx].fabricFinChg);
    if (isNaN(financeCharge)) financeCharge = 0;

    var financePercent =
      (parseFloat(financeCharge) /
        (parseFloat(financeCharge) + parseFloat(mainPrice))) *
      100;
    if (isNaN(financePercent)) financePercent = '';

    newState[idx].fabricFinPct = handleNumberFormatting(
      financePercent,
      'fabricFinPct'
    );

    return newState;
  };

  const fabricCostMainCost = (newState, idx) => {
    var mainPrice = parseFloat(newState[idx].mainFabricPrice);
    if (isNaN(mainPrice)) mainPrice = 0;

    var financeCharge = parseFloat(newState[idx].fabricFinChg);
    if (isNaN(financeCharge)) financeCharge = 0;

    var fabricYield = parseFloat(newState[idx].mainFabricGmtYield);
    if (isNaN(fabricYield)) fabricYield = 0;

    newState[idx].mainFabricCost = handleNumberFormatting(
      (parseFloat(financeCharge) + parseFloat(mainPrice)) *
        parseFloat(fabricYield),
      'mainFabricCost'
    );

    newState = fabricCostTotalCost(newState, idx);

    return newState;
  };

  const knitMainCost = (updatedKnitState, idx) => {
    var mainPrice = parseFloat(updatedKnitState[idx].knitMainFabricPrice);
    if (isNaN(mainPrice)) mainPrice = 0;

    var financeCharge = parseFloat(updatedKnitState[idx].knitFabricFinChg);
    if (isNaN(financeCharge)) financeCharge = 0;

    var fabricYield = parseFloat(updatedKnitState[idx].knitFabricGmtYield);
    if (isNaN(fabricYield)) fabricYield = 0;

    updatedKnitState[idx].knitFabricCost = handleNumberFormatting(
      (parseFloat(financeCharge) + parseFloat(mainPrice)) *
        parseFloat(fabricYield),
      'knitFabricCost'
    );

    updatedKnitState = knitTotalCost(updatedKnitState, idx);

    return updatedKnitState;
  };

  const knitTotalCost = (updatedKnitState, idx) => {
    var mainCost = parseFloat(updatedKnitState[idx].knitFabricCost);
    if (isNaN(mainCost)) mainCost = 0;

    var addCost = parseFloat(updatedKnitState[idx].knitAdditionalFabricCost);
    if (isNaN(addCost)) addCost = 0;

    updatedKnitState[idx].knitTotalFabricCost = handleNumberFormatting(
      mainCost + addCost,
      'knitTotalFabricCost'
    );

    updatedKnitState = knitFirstCostFOB(updatedKnitState, idx);

    return updatedKnitState;
  };

  const fabricCostTotalCost = (newState, idx) => {
    var mainCost = parseFloat(newState[idx].mainFabricCost);
    if (isNaN(mainCost)) mainCost = 0;

    var addCost = parseFloat(newState[idx].additionalFabricCost);
    if (isNaN(addCost)) addCost = 0;

    newState[idx].totalFabricCost = handleNumberFormatting(
      mainCost + addCost,
      'totalFabricCost'
    );

    newState = fabricCostFirstCostFOB(newState, idx);

    return newState;
  };

  const knitFirstCostFOB = (updatedKnitState, idx) => {
    var totalCost = parseFloat(updatedKnitState[idx].knitTotalFabricCost);
    if (isNaN(totalCost)) totalCost = 0;

    var totalTrim = parseFloat(updatedKnitState[idx].knitTotalTrimCost);
    if (isNaN(totalTrim)) totalTrim = 0;

    updatedKnitState[idx].knitFirstCost = handleNumberFormatting(
      totalCost + totalTrim,
      'knitFirstCost'
    );

    updatedKnitState = knitFirstCostLDP(updatedKnitState, idx);
    if (costSheetVersion === 'V3') {
      updatedKnitState = knitFirstCostLDPCA(updatedKnitState, idx);
    }

    return updatedKnitState;
  };

  const fabricCostFirstCostFOB = (newState, idx) => {
    var totalCost = parseFloat(newState[idx].totalFabricCost);
    if (isNaN(totalCost)) totalCost = 0;

    var totalTrim = parseFloat(newState[idx].totalTrimCost);
    if (isNaN(totalTrim)) totalTrim = 0;

    newState[idx].firstCostUS.freeOnBoard = handleNumberFormatting(
      totalCost + totalTrim,
      'firstCostUSFOB'
    );

    newState = fabricCostFirstCostLDP(newState, idx);
    if (costSheetVersion === 'V3') {
      newState = fabricCostFirstCostLDPCA(newState, idx);
    }

    return newState;
  };

  const knitFirstCostLDP = (updatedKnitState, idx) => {
    var firstCost = parseFloat(updatedKnitState[idx].knitFirstCost);
    if (isNaN(firstCost)) firstCost = 0;

    var importCost = parseFloat(updatedKnitState[idx].knitImportCostForLDP);
    if (isNaN(importCost)) importCost = 0;

    updatedKnitState[idx].knitFirstCostLDP = handleNumberFormatting(
      firstCost + importCost,
      'knitFirstCostLDP'
    );

    return updatedKnitState;
  };

  const fabricCostFirstCostLDP = (newState, idx) => {
    var firstCost = parseFloat(newState[idx].firstCostUS.freeOnBoard);
    if (isNaN(firstCost)) firstCost = 0;

    var importCost = parseFloat(newState[idx].importCostForLDP);
    if (isNaN(importCost)) importCost = 0;

    newState[idx].firstCostUS.landedDutyPaid = handleNumberFormatting(
      firstCost + importCost,
      'firstCostUSLDP'
    );

    return newState;
  };

  const knitFirstCostLDPCA = (updatedKnitState, idx) => {
    var firstCost = parseFloat(updatedKnitState[idx].knitFirstCost);
    if (isNaN(firstCost)) firstCost = 0;

    var importCost = parseFloat(updatedKnitState[idx].knitImportCostForLDPCA);
    if (isNaN(importCost)) importCost = 0;

    updatedKnitState[idx].knitFirstCostLDPCA = handleNumberFormatting(
      firstCost + importCost,
      'knitFirstCostLDPCA'
    );

    return updatedKnitState;
  };

  const fabricCostFirstCostLDPCA = (newState, idx) => {
    var firstCost = parseFloat(newState[idx].firstCostUS.freeOnBoard);
    if (isNaN(firstCost)) firstCost = 0;

    var importCost = parseFloat(newState[idx].importCostForLDPCA);
    if (isNaN(importCost)) importCost = 0;

    newState[idx].firstCostCA.landedDutyPaid = handleNumberFormatting(
      firstCost + importCost,
      'firstCostCALDP'
    );

    return newState;
  };

  const handleUnspecificRPFields = (e, idx, className, updatedState) => {
    updatedState[idx][className] = e.target.value;
    return updatedState;
  };

  // Handles for Knit for now.
  const handleFabricCostChange = (e, idx, className) => {
    const rpType =
      className.slice(-6) === '-NonRP'
        ? 'nonReplenishmentFabricCost'
        : 'replenishmentFabricCost';
    const isRp = rpType === 'replenishmentFabricCost';
    var newState = isRp ? [...costRPListState] : [...costNonRPListState];
    const cleanClassName = isRp
      ? className.slice(0, -3)
      : className.slice(0, -6);
    if (!['currency', 'fabricPriceYieldUOMDD'].includes(cleanClassName)) {
      newState = handleNumbers(e, idx, cleanClassName, newState);
    }

    if (cleanClassName === 'fabricPriceYieldUOMDD') {
      if (headerForm.headerFormType === 'Knit') {
        newState[idx]['mainFabricPriceUOM'] =
          knitFabricPriceYieldUOMDD[e.target.value]; //TODO: handle UOM dropdowns for woven and knit --> now ensure it goes into nonRP and RP obj
        newState[idx]['mainFabricGmtYieldUOM'] =
          knitFabricPriceYieldUOMDD[e.target.value];

        const updatedFabricState = [...fabricState];
        updatedFabricState[idx]['fabricMinOrdUOM'] =
          knitFabricPriceYieldUOMDD[e.target.value];
        updatedFabricState[idx]['fabricMinColUOM'] =
          knitFabricPriceYieldUOMDD[e.target.value];
        setFabricState(updatedFabricState);
      } else if (headerForm.headerFormType === 'Woven') {
        newState[idx]['mainFabricPriceUOM'] =
          wovenFabricPriceYieldUOMDD[e.target.value]; //TODO: handle UOM dropdowns for woven and knit
        newState[idx]['mainFabricGmtYieldUOM'] =
          wovenFabricPriceYieldUOMDD[e.target.value];

        const updatedFabricState = [...fabricState];
        updatedFabricState[idx]['fabricMinOrdUOM'] =
          wovenFabricPriceYieldUOMDD[e.target.value];
        updatedFabricState[idx]['fabricMinColUOM'] =
          wovenFabricPriceYieldUOMDD[e.target.value];
        setFabricState(updatedFabricState);
      }
    }

    if (
      cleanClassName === 'mainFabricPrice' ||
      cleanClassName === 'fabricFinChg'
    ) {
      newState = fabricCostFinancePct(newState, idx);
      newState = fabricCostMainCost(newState, idx);
    }

    if (cleanClassName === 'mainFabricGmtYield') {
      newState = fabricCostMainCost(newState, idx);
    }

    if (cleanClassName === 'additionalFabricCost') {
      newState = fabricCostTotalCost(newState, idx);
    }

    if (
      cleanClassName === 'trimsAndTesting' ||
      cleanClassName === 'cutAndMakeLabor' ||
      cleanClassName === 'wetProcCost'
    ) {
      var trims = parseFloat(newState[idx].trimsAndTesting);
      if (isNaN(trims)) trims = 0;

      var labor = parseFloat(newState[idx].cutAndMakeLabor);
      if (isNaN(labor)) labor = 0;

      var wetProc = parseFloat(newState[idx].wetProcCost);
      if (isNaN(wetProc)) wetProc = 0;

      newState[idx].totalTrimCost = handleNumberFormatting(
        trims + labor + wetProc,
        'totalTrimCost'
      );

      newState = fabricCostFirstCostFOB(newState, idx);
    }

    if (cleanClassName === 'importCostForLDP') {
      newState = fabricCostFirstCostLDP(newState, idx);
    }

    if (cleanClassName === 'importCostForLDPCA') {
      if (costSheetVersion === 'V3') {
        newState = fabricCostFirstCostLDPCA(newState, idx);
      }
    }

    var updatedKnitState = [...knitState];
    var updatedWovenState = [...wovenState];

    if (headerForm.headerFormType === 'Knit') {
      if (cleanClassName === 'fabricPriceYieldUOMDD') {
        updatedKnitState = handleUnspecificRPFields(
          e,
          idx,
          'knitFabricPriceYieldUOMDD',
          updatedKnitState
        );
      }
      if (cleanClassName === 'currency') {
        updatedKnitState = handleUnspecificRPFields(
          e,
          idx,
          'knitCurrency',
          updatedKnitState
        );
      }
    }

    if (headerForm.headerFormType === 'Woven') {
      if (cleanClassName === 'fabricPriceYieldUOMDD') {
        updatedWovenState = handleUnspecificRPFields(
          e,
          idx,
          'wovenFabricPriceYieldUOMDD',
          updatedWovenState
        );
      }
      if (cleanClassName === 'currency') {
        updatedWovenState = handleUnspecificRPFields(
          e,
          idx,
          'wovenCurrency',
          updatedWovenState
        );
      }
    }

    if (isRp) {
      setCostRPListState(newState);
      if (headerForm.headerFormType === 'Knit') {
        updatedKnitState[idx].replenishmentFabricCost = costRPListState[idx];
        setKnitState(updatedKnitState);
      } else if (headerForm.headerFormType === 'Woven') {
        updatedWovenState[idx].replenishmentFabricCost = costRPListState[idx];
        setWovenState(updatedWovenState);
      }
    } else {
      setCostNonRPListState(newState);
      if (headerForm.headerFormType === 'Knit') {
        updatedKnitState[idx].nonReplenishmentFabricCost =
          costNonRPListState[idx];
        setKnitState(updatedKnitState);
      } else if (headerForm.headerFormType === 'Woven') {
        updatedWovenState[idx].nonReplenishmentFabricCost =
          costNonRPListState[idx];
        setWovenState(updatedWovenState);
      }
    }

    const copyCostNonRP = [
      isCopy(0, 'nonrp'),
      isCopy(1, 'nonrp'),
      isCopy(2, 'nonrp')
    ];
    const copyCostRP = [isCopy(0, 'rp'), isCopy(1, 'rp'), isCopy(2, 'rp')];
    setCopyStateNonRP(copyCostNonRP);
    setCopyStateRP(copyCostRP);
  };

  const handleFirstCostChange = (e, idx, className, cc) => {
    const rpType =
      className.slice(-6) === '-NonRP'
        ? 'nonReplenishmentFabricCost'
        : 'replenishmentFabricCost';
    const isRp = rpType === 'replenishmentFabricCost';

    var newState = isRp
      ? cc
        ? [...firstCostRPListUSState]
        : [...firstCostRPListCAState]
      : cc
      ? [...firstCostNonRPListUSState]
      : [...firstCostNonRPListCAState];

    const cleanClassName = isRp
      ? className.slice(0, -3)
      : className.slice(0, -6);

    if (!['currency'].includes(cleanClassName)) {
      newState = handleNumbers(e, idx, cleanClassName, newState);
    }

    if (isRp) {
      if (cc) {
        setFirstCostRPListUSState(newState); //added for use for pipe - update var use in validation
        firstCostRPListState.firstCostRPListUSState = firstCostRPListUSState;
      } else {
        setFirstCostRPListCAState(newState); //added for use for pipe - update var use in validation
        firstCostRPListState.firstCostRPListCAState = firstCostRPListCAState;
      }
    } else {
      if (cc) {
        setFirstCostNonRPListUSState(newState); //added for use for pipe - update var use in validation
        firstCostNonRPListState.firstCostNonRPListUSState = firstCostNonRPListUSState;
      } else {
        setFirstCostNonRPListCAState(newState); //added for use for pipe - update var use in validation
        firstCostNonRPListState.firstCostNonRPListCAState = firstCostNonRPListCAState;
      }
    }

    var updatedCIState = [...ciState];

    if (headerForm.headerFormType === 'Simplified') {
      if (cleanClassName === 'currency') {
        updatedCIState = handleUnspecificRPFields(
          e,
          idx,
          'simplifiedCurrencyDD',
          updatedCIState
        );
      }
      setCiState(updatedCIState);
    }
  };

  const handleKnitChange = (e, idx, className) => {
    var updatedKnitState = [...knitState];
    updatedKnitState = handleNumbers(e, idx, className, updatedKnitState);

    if (className === 'knitFabricPriceYieldUOMDD') {
      updatedKnitState[idx]['knitMainFabricPriceUOM'] =
        knitFabricPriceYieldUOMDD[e.target.value];
      updatedKnitState[idx]['knitFabricGmtYieldUOM'] =
        knitFabricPriceYieldUOMDD[e.target.value];

      const updatedFabricState = [...fabricState];
      updatedFabricState[idx]['fabricMinOrdUOM'] =
        knitFabricPriceYieldUOMDD[e.target.value];
      updatedFabricState[idx]['fabricMinColUOM'] =
        knitFabricPriceYieldUOMDD[e.target.value];
      setFabricState(updatedFabricState);
    }

    if (
      className === 'knitMainFabricPrice' ||
      className === 'knitFabricFinChg'
    ) {
      updatedKnitState = knitFinancePct(updatedKnitState, idx);
      updatedKnitState = knitMainCost(updatedKnitState, idx);
    }

    if (className === 'knitFabricGmtYield') {
      updatedKnitState = knitMainCost(updatedKnitState, idx);
    }

    if (className === 'knitAdditionalFabricCost') {
      updatedKnitState = knitTotalCost(updatedKnitState, idx);
    }

    if (
      className === 'knitTrimsAndEmb' ||
      className === 'knitCutandMakeLabor' ||
      className === 'knitWetProcCost'
    ) {
      var trims = parseFloat(updatedKnitState[idx].knitTrimsAndEmb);
      if (isNaN(trims)) trims = 0;

      var labor = parseFloat(updatedKnitState[idx].knitCutandMakeLabor);
      if (isNaN(labor)) labor = 0;

      var wetProc = parseFloat(updatedKnitState[idx].knitWetProcCost);
      if (isNaN(wetProc)) wetProc = 0;

      updatedKnitState[idx].knitTotalTrimCost = handleNumberFormatting(
        trims + labor + wetProc,
        'knitTotalTrimCost'
      );

      updatedKnitState = knitFirstCostFOB(updatedKnitState, idx);
    }

    if (className === 'knitImportCostForLDP') {
      updatedKnitState = knitFirstCostLDP(updatedKnitState, idx);
    }

    if (className === 'knitImportCostForLDPCA') {
      if (costSheetVersion === 'V3') {
        updatedKnitState = knitFirstCostLDPCA(updatedKnitState, idx);
      }
    }

    setKnitState(updatedKnitState);
  };

  //woven
  const wovenList = costSheetSubFormColumn.map(column => column['woven']);
  const [wovenState, setWovenState] = useState(wovenList);

  const wovenFinancePct = (updatedWovenState, idx) => {
    var mainPrice = parseFloat(updatedWovenState[idx].wovenMainFabricPrice);
    if (isNaN(mainPrice)) mainPrice = 0;

    var financeCharge = parseFloat(updatedWovenState[idx].wovenFabricFinChg);
    if (isNaN(financeCharge)) financeCharge = 0;

    var financePercent =
      (parseFloat(financeCharge) /
        (parseFloat(financeCharge) + parseFloat(mainPrice))) *
      100;
    if (isNaN(financePercent)) financePercent = '';

    updatedWovenState[idx].wovenFabricFinPct = handleNumberFormatting(
      financePercent,
      'wovenFabricFinPct'
    );

    return updatedWovenState;
  };

  const wovenMainCost = (updatedWovenState, idx) => {
    var mainPrice = parseFloat(updatedWovenState[idx].wovenMainFabricPrice);
    if (isNaN(mainPrice)) mainPrice = 0;

    var financeCharge = parseFloat(updatedWovenState[idx].wovenFabricFinChg);
    if (isNaN(financeCharge)) financeCharge = 0;

    var fabricYield = parseFloat(updatedWovenState[idx].wovenMainFabricYield);
    if (isNaN(fabricYield)) fabricYield = 0;

    updatedWovenState[idx].wovenMainFabricCost = handleNumberFormatting(
      (parseFloat(financeCharge) + parseFloat(mainPrice)) *
        parseFloat(fabricYield),
      'wovenMainFabricCost'
    );

    updatedWovenState = wovenTotalCost(updatedWovenState, idx);

    return updatedWovenState;
  };

  const wovenTotalCost = (updatedWovenState, idx) => {
    var mainCost = parseFloat(updatedWovenState[idx].wovenMainFabricCost);
    if (isNaN(mainCost)) mainCost = 0;

    var addCost = parseFloat(updatedWovenState[idx].wovenAddFabricCost);
    if (isNaN(addCost)) addCost = 0;

    updatedWovenState[idx].wovenTotalFabricCost = handleNumberFormatting(
      mainCost + addCost,
      'wovenTotalFabricCost'
    );

    updatedWovenState = wovenFirstCostFOB(updatedWovenState, idx);

    return updatedWovenState;
  };

  const wovenFirstCostFOB = (updatedWovenState, idx) => {
    var totalCost = parseFloat(updatedWovenState[idx].wovenTotalFabricCost);
    if (isNaN(totalCost)) totalCost = 0;

    var totalTrim = parseFloat(
      updatedWovenState[idx].wovenTotalTrimCMWetProcCost
    );
    if (isNaN(totalTrim)) totalTrim = 0;

    updatedWovenState[idx].wovenFirstCost = handleNumberFormatting(
      totalCost + totalTrim,
      'wovenFirstCost'
    );

    updatedWovenState = wovenFirstCostLDP(updatedWovenState, idx);
    if (costSheetVersion === 'V3') {
      updatedWovenState = wovenFirstCostLDPCA(updatedWovenState, idx);
    }

    return updatedWovenState;
  };

  const wovenFirstCostLDP = (updatedWovenState, idx) => {
    var firstCost = parseFloat(updatedWovenState[idx].wovenFirstCost);
    if (isNaN(firstCost)) firstCost = 0;

    var importCost = parseFloat(updatedWovenState[idx].wovenImportCostForLDP);
    if (isNaN(importCost)) importCost = 0;

    updatedWovenState[idx].wovenFirstCostLDP = handleNumberFormatting(
      firstCost + importCost,
      'wovenFirstCostLDP'
    );

    return updatedWovenState;
  };

  const wovenFirstCostLDPCA = (updatedWovenState, idx) => {
    var firstCost = parseFloat(updatedWovenState[idx].wovenFirstCost);
    if (isNaN(firstCost)) firstCost = 0;

    var importCost = parseFloat(updatedWovenState[idx].wovenImportCostForLDPCA);
    if (isNaN(importCost)) importCost = 0;

    updatedWovenState[idx].wovenFirstCostLDPCA = handleNumberFormatting(
      firstCost + importCost,
      'wovenFirstCostLDPCA'
    );

    return updatedWovenState;
  };

  const handleWovenChange = (e, idx, className) => {
    var updatedWovenState = [...wovenState];
    updatedWovenState = handleNumbers(e, idx, className, updatedWovenState);

    if (className === 'wovenFabricPriceYieldUOMDD') {
      updatedWovenState[idx]['wovenMainFabricPriceUOM'] =
        wovenFabricPriceYieldUOMDD[e.target.value];
      updatedWovenState[idx]['wovenMainFabricYieldUOM'] =
        wovenFabricPriceYieldUOMDD[e.target.value];

      const updatedFabricState = [...fabricState];
      updatedFabricState[idx]['fabricMinOrdUOM'] =
        wovenFabricPriceYieldUOMDD[e.target.value];
      updatedFabricState[idx]['fabricMinColUOM'] =
        wovenFabricPriceYieldUOMDD[e.target.value];
      setFabricState(updatedFabricState);
    }

    if (
      className === 'wovenMainFabricPrice' ||
      className === 'wovenFabricFinChg'
    ) {
      updatedWovenState = wovenFinancePct(updatedWovenState, idx);
      updatedWovenState = wovenMainCost(updatedWovenState, idx);
    }

    if (className === 'wovenMainFabricYield') {
      updatedWovenState = wovenMainCost(updatedWovenState, idx);
    }

    if (className === 'wovenAddFabricCost') {
      updatedWovenState = wovenTotalCost(updatedWovenState, idx);
    }

    if (
      className === 'wovenTotalTrims' ||
      className === 'wovenCutMakeLabor' ||
      className === 'wovenWetProcFinish'
    ) {
      var trims = parseFloat(updatedWovenState[idx].wovenTotalTrims);
      if (isNaN(trims)) trims = 0;

      var labor = parseFloat(updatedWovenState[idx].wovenCutMakeLabor);
      if (isNaN(labor)) labor = 0;

      var wetProc = parseFloat(updatedWovenState[idx].wovenWetProcFinish);
      if (isNaN(wetProc)) wetProc = 0;

      updatedWovenState[
        idx
      ].wovenTotalTrimCMWetProcCost = handleNumberFormatting(
        trims + labor + wetProc,
        'wovenTotalTrimCMWetProcCost'
      );

      updatedWovenState = wovenFirstCostFOB(updatedWovenState, idx);
    }

    if (className === 'wovenImportCostForLDP') {
      updatedWovenState = wovenFirstCostFOB(updatedWovenState, idx);
    }

    if (className === 'wovenImportCostForLDPCA') {
      if (costSheetVersion === 'V3') {
        updatedWovenState = wovenFirstCostLDPCA(updatedWovenState, idx);
      }
    }

    setWovenState(updatedWovenState);
  };

  //Sweater & Yarn
  const sweaterList = costSheetSubFormColumn.map(column => column['sweater']);
  const [sweaterState, setSweaterState] = useState(sweaterList);

  const yarnList = costSheetSubFormColumn.map(column => column['yarn']);
  const [yarnState, setYarnState] = useState(yarnList);

  //Yarn update functions
  const handleYarnChange = (e, idx, className) => {
    let updatedYarnState = [...yarnState];

    updatedYarnState = handleNumbers(e, idx, className, updatedYarnState);
    setYarnState(updatedYarnState);
  };

  //Sweater update functions
  const sweaterFinancePct = (updatedSweaterState, idx) => {
    var mainPrice = parseFloat(updatedSweaterState[idx].sweaterMainYarnCost);
    if (isNaN(mainPrice)) mainPrice = 0;

    var financeCharge = parseFloat(
      updatedSweaterState[idx].sweaterMainFinCharge
    );
    if (isNaN(financeCharge)) financeCharge = 0;

    var financePercent =
      (parseFloat(financeCharge) /
        (parseFloat(financeCharge) + parseFloat(mainPrice))) *
      100;
    if (isNaN(financePercent)) financePercent = '';

    updatedSweaterState[idx].sweaterFabricFinChargePct = handleNumberFormatting(
      financePercent,
      'sweaterFabricFinChargePct'
    );

    return updatedSweaterState;
  };

  //Sweater update functions
  const yarnCostFinancePct = (newState, idx) => {
    var mainPrice = parseFloat(newState[idx].mainYarnCost);
    if (isNaN(mainPrice)) mainPrice = 0;

    var financeCharge = parseFloat(newState[idx].fabricFinChg);
    if (isNaN(financeCharge)) financeCharge = 0;

    var financePercent =
      (parseFloat(financeCharge) /
        (parseFloat(financeCharge) + parseFloat(mainPrice))) *
      100;
    if (isNaN(financePercent)) financePercent = '';

    newState[idx].fabricFinPct = handleNumberFormatting(
      financePercent,
      'fabricFinPct'
    );

    return newState;
  };

  const miWeightPerGarment = (updatedMIState, idx) => {
    let mainWeight = parseFloat(updatedMIState[idx].miMainYarnWeightLbsDz);
    if (isNaN(mainWeight)) mainWeight = 0;

    let mainWeightPerGarment = parseFloat(
      updatedMIState[idx].miMainYarnWeightLbsGmt
    );
    if (isNaN(mainWeightPerGarment)) mainWeightPerGarment = 0;

    updatedMIState[idx].miMainYarnWeightLbsGmt = handleNumberFormatting(
      parseFloat(mainWeight / 12.0),
      'miMainYarnWeightLbsGmt'
    );
    return updatedMIState;
  };

  const sweaterWeightPerGarment = (updatedSweaterState, idx) => {
    let mainWeight = parseFloat(updatedSweaterState[idx].sweaterMainYarnWt);
    if (isNaN(mainWeight)) mainWeight = 0;

    let mainWeightPerGarment = parseFloat(
      updatedSweaterState[idx].sweaterMainYarnWtGarment
    );
    if (isNaN(mainWeightPerGarment)) mainWeightPerGarment = 0;

    updatedSweaterState[idx].sweaterMainYarnWtGarment = handleNumberFormatting(
      parseFloat(mainWeight / 12.0),
      'sweaterMainYarnWtGarment'
    );
    return updatedSweaterState;
  };

  const yarnCostWeightPerGarment = (newState, idx) => {
    let mainWeight = parseFloat(newState[idx].mainYarnWeightLbsDz);
    if (isNaN(mainWeight)) mainWeight = 0;

    let mainWeightPerGarment = parseFloat(newState[idx].mainYarnWeightLbsGmt);
    if (isNaN(mainWeightPerGarment)) mainWeightPerGarment = 0;

    newState[idx].mainYarnWeightLbsGmt = handleNumberFormatting(
      parseFloat(mainWeight / 12.0),
      'mainYarnWeightLbsGmt'
    );
    return newState;
  };

  const sweaterTotalYarnCostPerDozen = (updatedSweaterState, idx) => {
    let sweaterMainYarnCost = parseFloat(
      updatedSweaterState[idx].sweaterMainYarnCost
    );
    if (isNaN(sweaterMainYarnCost)) sweaterMainYarnCost = 0;

    let sweaterMainFinCharge = parseFloat(
      updatedSweaterState[idx].sweaterMainFinCharge
    );
    if (isNaN(sweaterMainFinCharge)) sweaterMainFinCharge = 0;

    let sweaterMainYarnWt = parseFloat(
      updatedSweaterState[idx].sweaterMainYarnWt
    );
    if (isNaN(sweaterMainYarnWt)) sweaterMainYarnWt = 0;

    updatedSweaterState[idx].sweaterTotalYarnCostDz = handleNumberFormatting(
      parseFloat(
        sweaterMainYarnWt * (sweaterMainYarnCost + sweaterMainFinCharge)
      ),
      'sweaterTotalYarnCostDz'
    );

    return updatedSweaterState;
  };

  const yarnCostTotalYarnCostPerDozen = (newState, idx) => {
    let sweaterMainYarnCost = parseFloat(newState[idx].mainYarnCost);
    if (isNaN(sweaterMainYarnCost)) sweaterMainYarnCost = 0;

    let sweaterMainFinCharge = parseFloat(newState[idx].fabricFinChg);
    if (isNaN(sweaterMainFinCharge)) sweaterMainFinCharge = 0;

    let sweaterMainYarnWt = parseFloat(newState[idx].mainYarnWeightLbsDz);
    if (isNaN(sweaterMainYarnWt)) sweaterMainYarnWt = 0;

    newState[idx].totalYarnCostDz = handleNumberFormatting(
      parseFloat(
        sweaterMainYarnWt * (sweaterMainYarnCost + sweaterMainFinCharge)
      ),
      'totalYarnCostDz'
    );

    return newState;
  };

  const sweaterTotalYarnCostPerGarment = (updatedSweaterState, idx) => {
    let sweaterTotalYarnCostPerDozen = parseFloat(
      updatedSweaterState[idx].sweaterTotalYarnCostDz
    );
    if (isNaN(sweaterTotalYarnCostPerDozen)) sweaterTotalYarnCostPerDozen = 0;

    let sweaterAdditionalCostPerDozen = parseFloat(
      updatedSweaterState[idx].sweaterAddnlYarnCostDz
    );
    if (isNaN(sweaterAdditionalCostPerDozen)) sweaterAdditionalCostPerDozen = 0;

    updatedSweaterState[idx].sweaterTotalYarnCostGmt = handleNumberFormatting(
      parseFloat(
        (sweaterTotalYarnCostPerDozen + sweaterAdditionalCostPerDozen) / 12.0
      ),
      'sweaterTotalYarnCostGmt'
    );

    return updatedSweaterState;
  };

  const yarnCostTotalYarnCostPerGarment = (newState, idx) => {
    let sweaterTotalYarnCostPerDozen = parseFloat(
      newState[idx].totalYarnCostDz
    );
    if (isNaN(sweaterTotalYarnCostPerDozen)) sweaterTotalYarnCostPerDozen = 0;

    let sweaterAdditionalCostPerDozen = parseFloat(
      newState[idx].additionalYarnCost
    );
    if (isNaN(sweaterAdditionalCostPerDozen)) sweaterAdditionalCostPerDozen = 0;

    newState[idx].totalYarnCostGmt = handleNumberFormatting(
      parseFloat(
        (sweaterTotalYarnCostPerDozen + sweaterAdditionalCostPerDozen) / 12.0
      ),
      'totalYarnCostGmt'
    );

    return newState;
  };

  const sweaterTotalTrimLaborWetProcCost = (updatedSweaterState, idx) => {
    let sweaterTotalTrimCost = parseFloat(
      updatedSweaterState[idx].sweaterTotalTrim
    );
    if (isNaN(sweaterTotalTrimCost)) sweaterTotalTrimCost = 0;

    let sweaterKnittingCost = parseFloat(
      updatedSweaterState[idx].sweaterKnitting
    );
    if (isNaN(sweaterKnittingCost)) sweaterKnittingCost = 0;

    let sweaterWetProcessingFinishingCost = parseFloat(
      updatedSweaterState[idx].sweaterWetProc
    );
    if (isNaN(sweaterWetProcessingFinishingCost))
      sweaterWetProcessingFinishingCost = 0;

    updatedSweaterState[idx].sweaterTotalTrimLaborCost = handleNumberFormatting(
      parseFloat(
        sweaterTotalTrimCost +
          sweaterKnittingCost +
          sweaterWetProcessingFinishingCost
      ),
      'sweaterTotalTrimLaborCost'
    );
    return updatedSweaterState;
  };

  const yarnCostTotalTrimLaborWetProcCost = (newState, idx) => {
    let sweaterTotalTrimCost = parseFloat(newState[idx].trimsAndTesting);
    if (isNaN(sweaterTotalTrimCost)) sweaterTotalTrimCost = 0;

    let sweaterKnittingCost = parseFloat(newState[idx].knittingLinkingLabor);
    if (isNaN(sweaterKnittingCost)) sweaterKnittingCost = 0;

    let sweaterWetProcessingFinishingCost = parseFloat(
      newState[idx].wetProcCost
    );
    if (isNaN(sweaterWetProcessingFinishingCost))
      sweaterWetProcessingFinishingCost = 0;

    newState[idx].totalTrimCost = handleNumberFormatting(
      parseFloat(
        sweaterTotalTrimCost +
          sweaterKnittingCost +
          sweaterWetProcessingFinishingCost
      ),
      'totalTrimCost'
    );
    return newState;
  };

  const sweaterFirstCostFobPackageCost = (updatedSweaterState, idx) => {
    let sweaterTotalYarnCostPerGarment = parseFloat(
      updatedSweaterState[idx].sweaterTotalYarnCostGmt
    );
    if (isNaN(sweaterTotalYarnCostPerGarment))
      sweaterTotalYarnCostPerGarment = 0;

    let sweaterTotalTrimAndLaborCost = parseFloat(
      updatedSweaterState[idx].sweaterTotalTrimLaborCost
    );
    if (isNaN(sweaterTotalTrimAndLaborCost)) sweaterTotalTrimAndLaborCost = 0;

    updatedSweaterState[idx].sweaterFirstCostFOB = handleNumberFormatting(
      parseFloat(sweaterTotalYarnCostPerGarment + sweaterTotalTrimAndLaborCost),
      'sweaterFirstCostFOB'
    );

    return updatedSweaterState;
  };

  const yarnCostFirstCostFobPackageCost = (newState, idx) => {
    let sweaterTotalYarnCostPerGarment = parseFloat(
      newState[idx].totalYarnCostGmt
    );
    if (isNaN(sweaterTotalYarnCostPerGarment))
      sweaterTotalYarnCostPerGarment = 0;

    let sweaterTotalTrimAndLaborCost = parseFloat(newState[idx].totalTrimCost);
    if (isNaN(sweaterTotalTrimAndLaborCost)) sweaterTotalTrimAndLaborCost = 0;

    newState[idx].firstCostUS.freeOnBoard = handleNumberFormatting(
      parseFloat(sweaterTotalYarnCostPerGarment + sweaterTotalTrimAndLaborCost),
      'firstCostUSFOB'
    );

    return newState;
  };

  const sweaterFirstCostLdpCost = (updatedSweaterState, idx) => {
    let sweaterFirstCostFOB = parseFloat(
      updatedSweaterState[idx].sweaterFirstCostFOB
    );
    if (isNaN(sweaterFirstCostFOB)) sweaterFirstCostFOB = 0;

    let sweaterImportCost = parseFloat(
      updatedSweaterState[idx].sweaterImportCost
    );
    if (isNaN(sweaterImportCost)) sweaterImportCost = 0;

    updatedSweaterState[idx].sweaterFirstCostLDP = handleNumberFormatting(
      parseFloat(sweaterFirstCostFOB + sweaterImportCost),
      'sweaterFirstCostLDP'
    );

    return updatedSweaterState;
  };

  const yarnCostFirstCostLdpCost = (newState, idx) => {
    let sweaterFirstCostFOB = parseFloat(newState[idx].firstCostUS.freeOnBoard);
    if (isNaN(sweaterFirstCostFOB)) sweaterFirstCostFOB = 0;

    let sweaterImportCost = parseFloat(newState[idx].importCostForLDP);
    if (isNaN(sweaterImportCost)) sweaterImportCost = 0;

    newState[idx].firstCostUS.landedDutyPaid = handleNumberFormatting(
      parseFloat(sweaterFirstCostFOB + sweaterImportCost),
      'firstCostUSLDP'
    );

    return newState;
  };

  const sweaterFirstCostLdpCaCost = (updatedSweaterState, idx) => {
    let sweaterFirstCostFOB = parseFloat(
      updatedSweaterState[idx].sweaterFirstCostFOB
    );
    if (isNaN(sweaterFirstCostFOB)) sweaterFirstCostFOB = 0;

    let sweaterImportCostCA = parseFloat(
      updatedSweaterState[idx].sweaterImportCostCA
    );
    if (isNaN(sweaterImportCostCA)) sweaterImportCostCA = 0;

    updatedSweaterState[idx].sweaterFirstCostLDPCA = handleNumberFormatting(
      parseFloat(sweaterFirstCostFOB + sweaterImportCostCA),
      'sweaterFirstCostLDPCA'
    );

    return updatedSweaterState;
  };

  const yarnCostFirstCostLdpCaCost = (newState, idx) => {
    let sweaterFirstCostFOB = parseFloat(newState[idx].firstCostUS.freeOnBoard);
    if (isNaN(sweaterFirstCostFOB)) sweaterFirstCostFOB = 0;

    let sweaterImportCostCA = parseFloat(newState[idx].importCostForLDPCA);
    if (isNaN(sweaterImportCostCA)) sweaterImportCostCA = 0;

    newState[idx].firstCostCA.landedDutyPaid = handleNumberFormatting(
      parseFloat(sweaterFirstCostFOB + sweaterImportCostCA),
      'firstCostCALDP'
    );

    return newState;
  };

  //TODO reevaluate formulas, likely the conditionals are not needed (currently aren't working, always true)
  const handleYarnCostChange = (e, idx, className) => {
    const rpType =
      className.slice(-6) === '-NonRP'
        ? 'nonReplenishmentYarnCost'
        : 'replenishmentYarnCost';
    const isRp = rpType === 'replenishmentYarnCost';
    var newState = isRp ? [...costRPListState] : [...costNonRPListState];
    const cleanClassName = isRp
      ? className.slice(0, -3)
      : className.slice(0, -6);
    if (!['currency', 'sweaterGarmentConsDD'].includes(cleanClassName)) {
      newState = handleNumbers(e, idx, cleanClassName, newState);
    }

    if (cleanClassName === 'mainYarnCost' || 'fabricFinChg') {
      newState = yarnCostFinancePct(newState, idx);
    }

    if (cleanClassName === 'mainYarnWeightLbsDz' || 'additionalYarnCost') {
      newState = yarnCostWeightPerGarment(newState, idx);
      newState = yarnCostTotalYarnCostPerDozen(newState, idx);
      newState = yarnCostTotalYarnCostPerGarment(newState, idx);
    }

    if (
      cleanClassName === 'trimsAndTesting' ||
      'knittingLinkingLabor' ||
      'wetProcCost'
    ) {
      newState = yarnCostTotalTrimLaborWetProcCost(newState, idx);
    }

    if (
      cleanClassName === 'mainYarnWeightLbsDz' ||
      'trimsAndTesting' ||
      'knittingLinkingLabor' ||
      'wetProcCost'
    ) {
      newState = yarnCostFirstCostFobPackageCost(newState, idx);
    }

    if (
      cleanClassName === 'importCostForLDP' ||
      'trimsAndTesting' ||
      'knittingLinkingLabor' ||
      'wetProcCost'
    ) {
      newState = yarnCostFirstCostLdpCost(newState, idx);
    }

    if (
      cleanClassName === 'importCostForLDPCA' ||
      'trimsAndTesting' ||
      'knittingLinkingLabor' ||
      'wetProcCost'
    ) {
      if (costSheetVersion === 'V3') {
        newState = yarnCostFirstCostLdpCaCost(newState, idx);
      }
    }

    var updatedSweaterCost = [...sweaterState];

    if (headerForm.headerFormType === 'Sweater') {
      if (cleanClassName === 'sweaterGarmentConsDD') {
        updatedSweaterCost = handleUnspecificRPFields(
          e,
          idx,
          'sweaterGarmentConsDD',
          updatedSweaterCost
        );
      }
      if (cleanClassName === 'currency') {
        updatedSweaterCost = handleUnspecificRPFields(
          e,
          idx,
          'sweaterCurrency',
          updatedSweaterCost
        );
      }
    }

    if (isRp) {
      setCostRPListState(newState);
      if (headerForm.headerFormType === 'Sweater') {
        updatedSweaterCost[idx].replenishmentYarnCost = costRPListState[idx];
        setSweaterState(updatedSweaterCost);
      }
    } else {
      setCostNonRPListState(newState);
      if (headerForm.headerFormType === 'Sweater') {
        updatedSweaterCost[idx].nonReplenishmentYarnCost =
          costNonRPListState[idx];
        setSweaterState(updatedSweaterCost);
      }
    }

    const copyCostNonRP = [
      isCopy(0, 'nonrp'),
      isCopy(1, 'nonrp'),
      isCopy(2, 'nonrp')
    ];
    const copyCostRP = [isCopy(0, 'rp'), isCopy(1, 'rp'), isCopy(2, 'rp')];
    setCopyStateNonRP(copyCostNonRP);
    setCopyStateRP(copyCostRP);
  };

  const handleSweaterChange = (e, idx, className) => {
    let updatedSweaterState = [...sweaterState];

    updatedSweaterState = handleNumbers(e, idx, className, updatedSweaterState);
    if (className === 'sweaterMainYarnCost' || 'sweaterMainFinCharge') {
      updatedSweaterState = sweaterFinancePct(updatedSweaterState, idx);
    }
    if (className === 'sweaterMainYarnWt' || 'sweaterAddnlYarnCostDz') {
      updatedSweaterState = sweaterWeightPerGarment(updatedSweaterState, idx);
      updatedSweaterState = sweaterTotalYarnCostPerDozen(
        updatedSweaterState,
        idx
      );
      updatedSweaterState = sweaterTotalYarnCostPerGarment(
        updatedSweaterState,
        idx
      );
    }
    if (
      className === 'sweaterTotalTrim' ||
      'sweaterKnitting' ||
      'sweaterWetProc'
    ) {
      updatedSweaterState = sweaterTotalTrimLaborWetProcCost(
        updatedSweaterState,
        idx
      );
    }
    if (
      className === 'sweaterMainYarnWt' ||
      'sweaterTotalTrim' ||
      'sweaterKnitting' ||
      'sweaterWetProc'
    ) {
      updatedSweaterState = sweaterFirstCostFobPackageCost(
        updatedSweaterState,
        idx
      );
    }
    if (
      className === 'sweaterImportCost' ||
      'sweaterTotalTrim' ||
      'sweaterKnitting' ||
      'sweaterWetProc'
    ) {
      updatedSweaterState = sweaterFirstCostLdpCost(updatedSweaterState, idx);
    }
    if (
      className === 'sweaterImportCostCA' ||
      'sweaterTotalTrim' ||
      'sweaterKnitting' ||
      'sweaterWetProc'
    ) {
      if (costSheetVersion === 'V3') {
        updatedSweaterState = sweaterFirstCostLdpCaCost(
          updatedSweaterState,
          idx
        );
      }
    }
    setSweaterState(updatedSweaterState);
  };

  const copyCostData = (type, idx) => {
    const source = type === 'nonrp' ? 'NonRP' : 'RP';
    const target = type === 'nonrp' ? 'RP' : 'NonRP';
    const confirmCopy = window.confirm(
      'Are you sure you want to copy over the ' +
        source +
        ' values to ' +
        target +
        '? This action will clear out any existing ' +
        target +
        ' fields with entered ' +
        source +
        ' data.'
    );
    if (confirmCopy) {
      const fields =
        headerForm.headerFormType === 'Simplified'
          ? Object.keys(firstCostRPListUSState[idx])
          : Object.keys(costRPListState[idx]);
      const handleFunction =
        headerForm.headerFormType === 'Simplified'
          ? handleFirstCostChange
          : headerForm.headerFormType === 'Sweater'
          ? handleYarnCostChange
          : handleFabricCostChange;
      var sourceFields = {};
      if (type === 'nonrp') {
        sourceFields =
          headerForm.headerFormType === 'Simplified'
            ? firstCostNonRPListUSState[idx]
            : costNonRPListState[idx];
      } else if (type === 'rp') {
        sourceFields =
          headerForm.headerFormType === 'Simplified'
            ? firstCostRPListUSState[idx]
            : costRPListState[idx];
      }

      duplicateCostData(
        fields,
        idx,
        handleFunction,
        sourceFields,
        target,
        'US'
      );
      if (headerForm.headerFormType === 'Simplified') {
        sourceFields =
          type === 'nonrp'
            ? firstCostNonRPListCAState[idx]
            : firstCostRPListCAState[idx];
        duplicateCostData(
          fields,
          idx,
          handleFunction,
          sourceFields,
          target,
          ''
        );
      }
    }
  };

  const [disableSaveSend, setDisableSaveSend] = useState(false);
  //Save/Submit
  const handleSubmit = e => {
    const body = Utils.getCostDto(
      originalJson,
      giState,
      miState,
      fabricState,
      contentState,
      garmentState,
      ciState,
      knitState,
      wovenState,
      scState,
      headerReport,
      headerForm,
      enableState,
      yarnState,
      sweaterState,
      costSheetVersion
    );

    let garmentContentValid = true;
    if (originalJson.smacsData.hiddenFields[0].formType !== 'Simplified') {
      let enabledCols = body.smacsData.costSheetSubForm[0].costSheetSubFormColumn.map(
        idx => idx.enabled
      );

      let cols100Content = body.smacsData.costSheetSubForm[0].costSheetSubFormColumn
        .map(idx => idx.garmentContent.garmentContentTotalPct)
        .map(Number)
        .map(idx => idx === 100);

      for (let i = 0; i < enabledCols.length; i++) {
        if (enabledCols[i] && !cols100Content[i]) {
          garmentContentValid = false;
        }
      }
    }

    const isConfirmed = window.confirm(
      "Are you sure you're ready to submit? This action cannot be undone"
    );
    if (garmentContentValid && isConfirmed) {
      callRestApi(
        locationProp.token,
        locationProp.auth,
        locationProp.synthetic,
        body,
        'POST',
        'send'
      );
      e.preventDefault();
    } else if (isConfirmed && !garmentContentValid) {
      alert(
        'Please check all garment content fields. All enabled columns must sum to 100% total garment content.'
      );
      e.preventDefault();
    } else if (!isConfirmed) {
      e.preventDefault();
    }
  };

  const handleSave = e => {
    const body = Utils.getCostDto(
      originalJson,
      giState,
      miState,
      fabricState,
      contentState,
      garmentState,
      ciState,
      knitState,
      wovenState,
      scState,
      headerReport,
      headerForm,
      enableState,
      yarnState,
      sweaterState,
      costSheetVersion
    );

    callRestApi(
      locationProp.token,
      locationProp.auth,
      locationProp.synthetic,
      body,
      'PUT',
      ''
    );
  };

  const callRestApi = (
    costId,
    signature,
    synthetic,
    body,
    request,
    endpoint
  ) => {
    const payload = {
      method: `${request}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Nord-Client-Id': 'APP03154',
        'Nord-Synthetic': synthetic,
        'Nord-Request-Id': uuid(),
        AUTH: `${signature}`
      },
      body: JSON.stringify(body)
    };

    const url = `/smacsRest/cost/${costId}/${endpoint}`;

    return fetch(url, payload)
      .then(response => {
        if (!response.ok) {
          throw new HttpError(response.status, 'Error fetching data');
        } else {
          return response;
        }
      })
      .then(responseJson => {
        handleFetch(request, 200);
        if (request === 'PUT') alert('Successfully Saved.');
        if (request === 'POST') {
          alert('Successfully Sent to FlexPLM.');
          setDisableSaveSend(true);
        }
      })
      .catch(error => {
        if (error instanceof HttpError) {
          handleFetch(request, error.statusCode);
          alert(
            'Sorry, there was an error attempting to save your form. Please try again.'
          );
        } else {
          handleFetch(request, error.statusCode);
          alert(
            'Sorry, there was an error attempting to save your form. Please try again.'
          );
        }
      });
  };

  const fabric = () => {
    if (
      headerForm.headerFormType === 'Knit' ||
      headerForm.headerFormType === 'Woven'
    )
      return (
        <>
          {formReady && (
            <FabricInformation
              fabricState={fabricState}
              handleFabricChange={handleFabricChange}
              type={headerForm.headerFormType}
              fabricCODD={fabricCODD}
              fabricArticleNoDD={fabricArticleNoDD}
              garmentState={garmentState}
              handleGarmentChange={handleGarmentChange}
              contentState={contentState}
              addContent={addContent}
              handleContentChange={handleContentChange}
              enableState={enableState}
            />
          )}
        </>
      );
    else if (headerForm.headerFormType === 'Sweater')
      return (
        <>
          {formReady && (
            <YarnInformation
              garmentState={garmentState}
              handleGarmentChange={handleGarmentChange}
              contentState={contentState}
              addContent={addContent}
              handleContentChange={handleContentChange}
              type={headerForm.headerFormType}
              yarnState={yarnState}
              enableState={enableState}
              yarnCODD={yarnCODD}
              yarnArticleNoDD={yarnArticleNoDD}
              handleYarnChange={handleYarnChange}
            />
          )}
        </>
      );
  };

  const material = () => {
    if (costSheetVersion === 'V6' && headerForm.headerFormType === 'Simplified')
      return (
        <>
          {
            <MaterialInformation
              giList={giList}
              miState={miState}
              miUomFabricPriceYieldDD={miUomFabricPriceYieldDD}
              handleMIChange={handleMIChange}
              enableState={enableState}
            />
          }
        </>
      );
  };
  const components = {
    Simplified: {
      comp: CostingInformation,
      props: {
        simplifiedList,
        handleCIChange,
        enableState,
        giState,
        costSheetVersion
      }
    },
    SimplifiedCostAccounting: {
      comp: CostingInformationCost,
      props: {
        list: simplifiedList,
        rpList: firstCostRPListState, //will remove once dynamic
        nonRpList: firstCostNonRPListState, //will remove once dynamic
        type: headerForm.headerFormType,
        handleCIChange,
        handleChange: handleFirstCostChange,
        enableState,
        replenishmentState,
        nonReplenishmentState,
        giState,
        costSheetVersion
      }
    },
    Knit: {
      comp: KnitCost,
      props: {
        knitList,
        handleKnitChange,
        type: headerForm.headerFormType,
        knitFabricPriceYieldUOMDD,
        enableState,
        costSheetVersion
      }
    },
    KnitCostAccounting: {
      comp: FabricCost,
      props: {
        list: knitList,
        rpList: costRPListState,
        nonRpList: costNonRPListState,
        handleChange: handleFabricCostChange,
        type: headerForm.headerFormType,
        fabricPriceYieldUOMDD: knitFabricPriceYieldUOMDD,
        enableState,
        replenishmentState,
        nonReplenishmentState,
        costSheetVersion
      }
    },
    Woven: {
      comp: WovenCost,
      props: {
        wovenList,
        handleWovenChange,
        type: headerForm.headerFormType,
        wovenFabricPriceYieldUOMDD,
        enableState,
        costSheetVersion
      }
    },
    WovenCostAccounting: {
      comp: FabricCost,
      props: {
        list: wovenList,
        rpList: costRPListState,
        nonRpList: costNonRPListState,
        handleChange: handleFabricCostChange,
        type: headerForm.headerFormType,
        fabricPriceYieldUOMDD: wovenFabricPriceYieldUOMDD,
        enableState,
        replenishmentState,
        nonReplenishmentState,
        costSheetVersion
      }
    },
    Sweater: {
      comp: SweaterCost,
      props: {
        sweaterList,
        handleSweaterChange,
        enableState,
        GarmentConstructionLOV: sweaterGarmentConsDD,
        costSheetVersion
      }
    },
    SweaterCostAccounting: {
      comp: YarnCost,
      props: {
        list: sweaterList,
        rpList: costRPListState,
        nonRpList: costNonRPListState,
        handleSweaterChange,
        handleChange: handleYarnCostChange,
        type: headerForm.headerFormType,
        enableState,
        GarmentConstructionLOV: sweaterGarmentConsDD,
        replenishmentState,
        nonReplenishmentState,
        costSheetVersion
      }
    }
  };

  const costingInformation = (costType, idx) => {
    if (
      costSheetVersion === 'V2' ||
      costSheetVersion === 'V3' ||
      costSheetVersion === 'V4' ||
      costSheetVersion === 'V5' ||
      costSheetVersion === 'V6'
    ) {
      //eventually this can be removed
      const ComponentName =
        components[headerForm.headerFormType + 'CostAccounting'].comp;
      if (['Simplified'].includes(headerForm.headerFormType)) {
        return (
          <ComponentName
            {...components[headerForm.headerFormType + 'CostAccounting'].props}
            idx={idx}
            rp={costType === 'RP'}
            funlist={
              costType === 'RP' ? firstCostRPListState : firstCostNonRPListState
            }
          />
        );
      } else {
        return (
          <ComponentName
            {...components[headerForm.headerFormType + 'CostAccounting'].props}
            idx={idx}
            rp={costType === 'RP'}
            funlist={costType === 'RP' ? costRPListState : costNonRPListState}
          />
        );
      }
    } else {
      //supports V1 for now
      const ComponentName = components[headerForm.headerFormType].comp;
      return <ComponentName {...components[headerForm.headerFormType].props} />;
    }
  };

  const print = () => {
    window.print();
  };

  return (
    <>
      <OrionAppNav
        path={window.location.pathname}
        expiryDate={props.location.state.auth.match('\\d+')}
        statusCode={props.location.state.fetchStatus}
      />
      <form onSubmit={handleSubmit} id="costForm">
        <br />
        <CostHeader headerReport={headerReport} headerForm={headerForm} />
        <Grid container spacing={1}>
          <Grid item>
            <FormInstructions
              formType="cost"
              costSheetVersion={costSheetVersion}
            />
            <br className="print-hide" />
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  id="save-button"
                  onClick={handleSave}
                  disabled={disableSaveSend}
                >
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="outlined"
                  id="send-button"
                  size="large"
                  disabled={disableSaveSend}
                >
                  Send
                </Button>
              </Grid>
              <Grid item>
                <BrowserView>
                  <Button
                    variant="outlined"
                    size="large"
                    id="print-button"
                    onClick={print}
                  >
                    Save to PDF
                  </Button>
                </BrowserView>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br className="print-hide" />
        <Grid>
          {giList.map((val, idx) => (
            <div key={`enable-${idx}`} className="column">
              {idx !== 0 && (
                <FormControlLabel
                  id={`enable-${idx}`}
                  control={
                    <Checkbox
                      checked={enableState[idx]}
                      onChange={e => {
                        handleEnableChange(e, idx);
                      }}
                      name="enabled"
                      color="primary"
                    />
                  }
                  label="Enable Column"
                />
              )}
              {idx === 0 && (
                <TextField
                  id={`enable-${idx}`}
                  disabled
                  InputProps={{
                    disableUnderline: true
                  }}
                />
              )}
            </div>
          ))}
        </Grid>
        <Grid container direction="column" spacing={0} justify="center">
          <Grid item>
            <GeneralInformation
              giList={giList}
              giGarmentCODD={giGarmentCODD}
              giProductCategoryDD={giProductCategoryDD}
              giMaterialTypeDD={giMaterialTypeDD}
              handleGIChange={handleGIChange}
              handleGICheckBoxChange={handleGICheckBoxChange}
              enableState={enableState}
              costSheetVersion={costSheetVersion}
              formType={headerForm.headerFormType}
            />
          </Grid>
          <Grid item>
            <SpecialComments
              specialCommentsList={specialCommentsList}
              handleSCChange={handleSCChange}
              enableState={enableState}
            />
          </Grid>
          <Grid item>{fabric()}</Grid>
          <Grid>
            {giList.map((val, idx) => (
              <div key={`enable-${idx}`} className="column">
                <Collapse
                  in={
                    costSheetVersion === 'V3' ||
                    costSheetVersion === 'V4' ||
                    costSheetVersion === 'V5' ||
                    costSheetVersion === 'V6'
                  }
                >
                  <RadioGroup
                    row
                    value={radioState[idx]}
                    onChange={e => handleRPChange(e, idx)}
                  >
                    <FormControlLabel
                      id={`non-rp-${idx}`}
                      control={
                        <Radio value="nonrp" name="enabled" color="primary" />
                      }
                      label="Non-RP"
                      disabled={!enableState[idx]}
                    />
                    <FormControlLabel
                      id={`rp-${idx}`}
                      control={
                        <Radio value="rp" name="enabled" color="primary" />
                      }
                      label="RP"
                      disabled={!enableState[idx]}
                    />
                    <FormControlLabel
                      id={`both-${idx}`}
                      control={
                        <Radio value="both" name="enabled" color="primary" />
                      }
                      label="RP / Non-RP"
                      disabled={!enableState[idx]}
                    />
                  </RadioGroup>
                </Collapse>
              </div>
            ))}
          </Grid>
          <div className={classes.root}>
            {costSheetVersion === 'V2' ||
            costSheetVersion === 'V3' ||
            costSheetVersion === 'V4' ||
            costSheetVersion === 'V5' ||
            costSheetVersion === 'V6' ? (
              <>
                {enabled.map((val, idx) => (
                  <div key={`ci-${idx}`} className="column">
                    <Grid item>
                      <OtherCost
                        {...components[
                          headerForm.headerFormType + 'CostAccounting'
                        ].props}
                        idx={idx}
                        funlist={costNonRPListState}
                      />
                    </Grid>
                  </div>
                ))}
                {costNonRPList.map((val, idx) => (
                  <div key={`ci-${idx}`} className="column">
                    <hr />
                    <div className="ci-header">
                      Non-RP {headerForm.headerFormType} Costing Information
                    </div>
                    <hr />
                    <Button
                      variant="outlined"
                      size="small"
                      id="copy-rp"
                      onClick={e => copyCostData('rp', idx)}
                      disabled={
                        copyStateRP[idx] ||
                        (headerForm.headerFormType === 'Simplified' &&
                          !enableState[idx])
                      }
                    >
                      Copy RP Values
                    </Button>
                    <Grid item>{costingInformation('', idx)}</Grid>
                  </div>
                ))}
              </>
            ) : (
              <Grid item>{costingInformation()}</Grid>
            )}

            {costSheetVersion === 'V3' ||
            costSheetVersion === 'V4' ||
            costSheetVersion === 'V5' ||
            costSheetVersion === 'V6' ? (
              <>
                {costRPList.map((val, idx) => (
                  <div key={`ci-${idx}`} className="column">
                    <hr />
                    <div className="ci-header">
                      RP {headerForm.headerFormType} Costing Information
                    </div>
                    <hr />
                    <Button
                      variant="outlined"
                      size="small"
                      id="copy-nonrp"
                      onClick={e => copyCostData('nonrp', idx)}
                      disabled={
                        copyStateNonRP[idx] ||
                        (headerForm.headerFormType === 'Simplified' &&
                          !enableState[idx])
                      }
                    >
                      Copy Non-RP Values
                    </Button>
                    <Grid item>{costingInformation('RP', idx)}</Grid>
                  </div>
                ))}
              </>
            ) : null}
          </div>
          <Grid item>{material()}</Grid>
        </Grid>
      </form>
    </>
  );
};

export default CostView;
