export default class Settings {
  static getTitleString(path, name) {
    path = path.replace('/', '').toLowerCase();
    //  When SPA is ponted to '/home' Validate Styles' will display
    const contextNavHeading = {
      home: 'Nordstrom Brands',
      summary: name
    };
    return contextNavHeading[path];
  }

  static getSecondaryTitleString() {
    return null; //TODO: Account for sub-views here
  }
}
