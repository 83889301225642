import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const MaterialFormInstructions = () => {
  return (
    <div>
      <Typography variant="h6">Instructions:</Typography>
      <Typography variant="subtitle2">
        1. Please fill out the Raw Material Request Form
        <br />
        2. An asterisk (*) designates a required field
        <br />
        3. For NPS Test Number specified, please refer to NPG supplier website
        for test method
        <br />
        4. The Raw Material Request Form can only be submitted once. For any
        changes, please contact your Raw Material Team Member to re-send the
        form.
        <br />
        5. We understand that some information may not be available or final
        during development
        <br />
        6. If you need more information on the requested fields, please contact
        your Raw Material Team Member
        <br />
        7. Click SAVE to save your data and return to the form at a later time
        <br />
        8. Click SEND when the form is complete to submit back to NPG
      </Typography>
    </div>
  );
};

const CostFormInstructions = props => {
  const costSheetVersion = props.costSheetVersion;
  return (
    <div>
      <Typography variant="h6">Instructions:</Typography>
      {((costSheetVersion === 'V5' || costSheetVersion === 'V6') && (
        <Typography variant="subtitle2">
          1. Please fill out the Product Cost Request Form
          <br />
          2. An asterisk (*) designates a required field
          <br />
          3. To submit additional costing options, select the Enable Column
          button at the top of the form and multiple cost sheets will be created
          in our system
          <br />
          4. The Cost Flex Form can only be submitted once. For any changes,
          please contact your NPG Team Member to send a new form.
          <br />
          5. We understand that some information may not be available or final
          for Pre Cost Form submissions. Information is expected to be final and
          accurate when submitting Final Cost.
          <br />
          6. Utilize the optional Duty % and Additional Tariff % fields to
          provide this information to the best of your knowledge, when
          applicable. Note: these fields are not editable if the LDP Program
          checkbox is selected.
          <br />
          7. If you need more information on dropdown definitions, please
          contact your NPG Team Member
          <br />
          8. Click SAVE to save your data and return to the form at a later time
          <br />
          9. Click SEND when the form is complete to submit back to NPG
        </Typography>
      )) || (
        <Typography variant="subtitle2">
          1. Please fill out the Product Cost Request Form
          <br />
          2. An asterisk (*) designates a required field
          <br />
          3. To complete additional columns, select the Enable Column button at
          the top of the form
          <br />
          4. The Cost Flex Form can only be submitted once. For any changes,
          please contact your Product Developer Team Member to re-send the form.
          <br />
          5. We understand that some information may not be available or final
          for Pre Cost Form submissions. Information is expected to be final and
          accurate when submitting Final Cost.
          <br />
          6. If you need more information on dropdown definitions, please
          contact your Product Developer Team Member
          <br />
          7. Click SAVE to save your data and return to the form at a later time
          <br />
          8. Click SEND when the form is complete to submit back to NPG
        </Typography>
      )}
    </div>
  );
};

const LabelPackagingFormInstructions = () => {
  return (
    <div>
      <Typography variant="h6">Instructions:</Typography>
      <Typography variant="subtitle2">
        1. Please fill out the Packaging Material Request Form
        <br />
        2. An asterisk (*) designates a required field
        <br />
        3. The Packaging Material Request Form can only be submitted once. For
        any changes, please contact the NPG Packaging Team to re-send the form.
        <br />
        4. We understand that some information may not be available or final
        during development. An updated request form will be emailed to you again
        when information is finalized.
        <br />
        5. If you need more information on dropdown definitions, please contact
        the NPG Packaging Team
        <br />
        6. Click SAVE to save your data and return to the form at a later time
        <br />
        7. Click SEND when the form is complete to submit back to the NPG
        Packaging Team
      </Typography>
    </div>
  );
};

const FormInstructions = props => {
  switch (props.formType) {
    case 'material':
      return <MaterialFormInstructions />;
    case 'cost':
      return <CostFormInstructions costSheetVersion={props.costSheetVersion} />;
    case 'labelPackaging':
      return <LabelPackagingFormInstructions />;
    default:
      return null;
  }
};

FormInstructions.propTypes = {
  formType: PropTypes.string
};

export default FormInstructions;
