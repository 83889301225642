import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';

// Style
import { ReactComponent as Logo } from '../images/nordLogo.svg';

const OrionAppNav = props => {
  const [headerName, setHeaderName] = useState('');
  const [expiryDate, setExpiryDate] = useState(null);
  const [expirationStyle, setExpirationStyle] = useState({
    color: 'rgba(255, 255, 255, 0.6)',
    flexGrow: 1,
    margin: '2px 10px 0 0'
  });

  useEffect(() => {
    if (
      (props.path.includes('cost') || props.path.includes('/co/')) &&
      props.statusCode === 200
    ) {
      setHeaderName('Product Cost Request Form');
    } else if (
      (props.path.includes('label') || props.path.includes('packaging')) &&
      props.statusCode === 200
    ) {
      setHeaderName('Product Request Form');
    } else if (props.path.includes('error') || props.statusCode !== 200) {
      setExpiryDate(null);
      setHeaderName('Product Request Form');
    } else if (
      props.materialType.includes('Label') ||
      props.materialType.includes('Packaging')
    ) {
      setHeaderName('Material Request Form - ' + props.materialType);
    } else {
      setHeaderName('Raw Material Request Form - ' + props.materialType);
    }
  }, [props.path, props.materialType, props.statusCode]);

  useEffect(() => {
    let expirationTime = new Date(props.expiryDate * 1000);
    let currentTime = Date.now();

    let timeDisplayOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    };
    let expirationTimeAsString = expirationTime.toLocaleDateString(
      'en-US',
      timeDisplayOptions
    );
    if (expirationTime > currentTime && props.statusCode === 200) {
      setExpiryDate('Form will expire on ' + expirationTimeAsString);
      if (Math.floor((expirationTime - currentTime) / 1000 / 86400) <= 5) {
        setExpirationStyle({
          color: 'rgba(255, 0, 0, 0.6)',
          flexGrow: 1,
          margin: '2px 10px 0 0'
        });
      }
    }
  }, [props.expiryDate, props.statusCode]);

  return (
    <>
      <AppBar>
        <Toolbar variant="dense">
          <Grid
            container
            alignItems="center"
            justify="space-between"
            direction="row"
          >
            <Grid item>
              <Grid
                container
                alignItems="center"
                justify="center"
                direction="row"
              >
                <Grid item>
                  <Logo
                    fill="white"
                    alt=""
                    style={{
                      height: '1.1rem',
                      marginTop: '5px',
                      fill: '#FFFFFF'
                    }}
                  />
                </Grid>
                <Grid item>
                  <Typography
                    style={{
                      color: 'rgba(255, 255, 255, 0.6)'
                    }}
                    variant="h6"
                    nowrap="true"
                  >
                    <span
                      style={{
                        color: 'rgba(255, 255, 255, 0.6)',
                        flexGrow: 1
                      }}
                    >
                      |
                    </span>
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    style={{
                      color: 'rgba(255, 255, 255, 0.6)',
                      flexGrow: 1,
                      margin: '2px 10px 0 0'
                    }}
                    variant="h6"
                    nowrap="true"
                  >
                    NPG {headerName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography style={expirationStyle} variant="h6" nowrap="true">
                {expiryDate}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default withRouter(OrionAppNav);
