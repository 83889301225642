import { Grid } from '@material-ui/core';
import React from 'react';
import 'stylesheets/CostHeader.scss';

const CostHeader = props => {
  const headerReport = props.headerReport;
  const headerForm = props.headerForm;

  return (
    <Grid>
      <div className="row">
        <div className="column">
          Cost Sheet For: {headerForm.headerStyleSeason}
        </div>
        <div className="column">{headerForm.headerFormType}</div>
      </div>
      <div className="row">
        <div className="column">Size Class: {headerReport.headerSizeClass}</div>
        <div className="column">
          Style Description: {headerReport.headerStyleDesc}
        </div>
        <div className="column">
          Target 1st Cost: {headerReport.headerTarget1StCost}
        </div>
      </div>
      <div className="row">
        <div className="column">Size Range: {headerReport.headerSizeRange}</div>
        <div className="column">Sent To: {headerReport.headerSentTo}</div>
      </div>
    </Grid>
  );
};
export default CostHeader;
