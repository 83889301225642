import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Utils } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  }
}));

const WovenTypeL = ({ ownerState, handleOwnerChange }) => {
  const classes = useStyles();

  var readOnly = false;
  if (ownerState.materialEditable === 'false') {
    readOnly = true;
  }

  const type = ownerState.typeDD;

  const weightUom = ownerState.weightUomDD;

  const worsted = ownerState.worstedDD;

  return (
    <div className={classes.root}>
      {/* left side of the un-editable portion of the form */}
      <TextField
        label="Weave Type"
        variant="outlined"
        select
        name="type"
        id="type"
        disabled={readOnly}
        required
        InputProps={{
          readOnly: readOnly
        }}
        value={ownerState.type}
        onChange={handleOwnerChange}
      >
        {Utils.sortObject(type)}
      </TextField>
      <br />
      <TextField
        label="Weight"
        variant="outlined"
        type="text"
        name="weight"
        id="weight"
        inputProps={{ maxLength: 15 }}
        disabled={readOnly}
        required
        InputProps={{
          readOnly: readOnly
        }}
        value={ownerState.weight}
        onChange={handleOwnerChange}
      />
      <TextField
        label="Weight UOM"
        variant="outlined"
        select
        name="weightUom"
        id="weightUom"
        disabled={readOnly}
        required
        InputProps={{
          readOnly: readOnly
        }}
        value={ownerState.weightUom}
        onChange={handleOwnerChange}
      >
        {Utils.sortObject(weightUom)}
      </TextField>
      <br />
      <TextField
        label="Fiber Diameter"
        variant="outlined"
        type="text"
        name="diameter"
        id="diameter"
        inputProps={{ maxLength: 40 }}
        disabled={readOnly}
        InputProps={{
          readOnly: readOnly
        }}
        value={ownerState.diameter}
        onChange={handleOwnerChange}
      />
      <br />
      <TextField
        label="Is Fiber Worsted?"
        variant="outlined"
        select
        name="worsted"
        id="worsted"
        disabled={readOnly}
        InputProps={{
          readOnly: readOnly
        }}
        value={ownerState.worsted}
        onChange={handleOwnerChange}
      >
        {Utils.sortObject(worsted)}
      </TextField>
      <br />
      <TextField
        label="Yarn Size (Warp x Weft)"
        variant="outlined"
        type="text"
        name="yarn"
        id="yarn"
        inputProps={{ maxLength: 50 }}
        disabled={readOnly}
        InputProps={{
          readOnly: readOnly
        }}
        value={ownerState.yarn}
        onChange={handleOwnerChange}
      />
    </div>
  );
};

WovenTypeL.propTypes = {
  ownerState: PropTypes.object,
  handleOwnerChange: PropTypes.func
};

export default WovenTypeL;
