/**
 * Validation rules
 * @typedef {{ state: 'error'|'warning'|'info', reason: string }|null|undefined} ValidatorFnResult
 * @typedef {() => ValidatorFnResult} ValidatorFn
 * @typedef {{ attribute: string, validators: (ValidatorFn|Array<ValidatorFn>) }} Rule
 * @typedef {{ [key: string]: Rule }} Rules
 * @typedef {{ details: { [key: string]: boolean }, issuesCount: number }} Result
 * @typedef {{ details: Array<{ [key: string]: boolean }>, issuesCount: number }} Results
 */

/**
 * Validator - service designed to validate data based on provided rules
 */
export default class Validator {
  /**
   * @description check - runs data validation based on provided rules
   * @param {Rules} rules - set of rules to validate provided data
   * @param {Object} data - data to be validated
   * @param {Object} meta - any additional data that will be passed to each validation fn
   * @returns {Result} validation results
   *
   * @example
   * // returns { details: { size: { state: 'error', reason: 'Required' } }, issuesCount: 1 }
   * Validator.check(
   *   { size: {
   *     attribute: 'size.value',
   *     validators: [ val => !!val ? null : { state: 'error', reason: 'Required' } ]
   *   }},
   *   { size: { value: '' } }
   * )
   *
   */
  static check(rules, data, meta) {
    let details = {};
    let issuesCount = 0;

    for (let key in rules) {
      if (!rules.hasOwnProperty(key) || key === '_defaults') {
        continue;
      }

      let { attribute, validators, label } = rules[key];
      let value = attribute.split('.').reduce((acc, next) => {
        if (!acc) {
          throw new Error(`No data found for attribute: ${attribute}`);
        }
        return acc[next];
      }, data);

      let errMsg;

      // Update attribute-specific validators with default ones if they provided
      if (rules._defaults && rules._defaults.validators) {
        if (Array.isArray(rules._defaults.validators)) {
          validators = rules._defaults.validators
            .concat(validators)
            .filter(Boolean);
        } else {
          validators = []
            .concat(rules._defaults.validators, validators)
            .filter(Boolean);
        }
      }

      if (!validators) {
        throw new Error(`No validators provided for attribute: ${attribute}`);
      }

      if (Array.isArray(validators)) {
        for (let validator of validators) {
          errMsg = validator(value, label, meta);
          if (errMsg) {
            // break as soon as at least one validation rule fails
            break;
          }
        }
      } else {
        errMsg = validators(value, label, meta);
      }

      if (errMsg) {
        issuesCount += 1;
      }

      details[key] = errMsg;
    }

    return { details, issuesCount };
  }

  /**
   * @description checkList - runs data list validation based on provided rules
   * @param {Rules} rules - set of rules to validate provided data
   * @param {Array<Object>} data - data to be validated
   * @param {Object} meta - any additional data that will be passed to each validation fn
   * @returns {Results} validation results
   *
   * @example
   * // returns { details: [ { size: { state: 'error', reason: 'Required' } }, { size: null } ], issuesCount: 1 }
   * Validator.checkList(
   *   { size: {
   *     attribute: 'size.value',
   *     validators: [ val => !!val ? null : { state: 'error', reason: 'Required' } ]
   *   }},
   *   [
   *     { size: { value: '' } },
   *     { size: { value: 'L' } }
   *   ]
   * )
   *
   */
  static checkList(rules, dataList, meta) {
    let totalDetails = [];
    let totalissuesCount = 0;

    for (let data of dataList) {
      const { issuesCount, details } = Validator.check(rules, data, meta);
      totalDetails.push(details);
      totalissuesCount += issuesCount;
    }

    return { details: totalDetails, issuesCount: totalissuesCount };
  }
}
