export function ccImageViewer() {
  return {
    headerName: '',
    cellRenderer: 'ImageViewer',
    editable: false,
    cellStyle: {
      textAlign: 'center',
      textOverflow: 'unset'
    },
    headerClass: 'ag-Table__header MuiTypography-body2',
    filter: false,
    columnGroupShow: 'open',
    suppressMenu: true
  };
}
