import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Utils } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  }
}));

const OtherTypeL = ({ ownerState, handleOwnerChange }) => {
  const classes = useStyles();

  var readOnly = false;
  if (ownerState.materialEditable === 'false') {
    readOnly = true;
  }

  const weightUom = ownerState.weightUomDD;

  return (
    <div className={classes.root}>
      {/* left side of the un-editable portion of the form */}
      <TextField
        label="Other Type"
        variant="outlined"
        type="text"
        name="type"
        id="type"
        inputProps={{ maxLength: 50 }}
        disabled={readOnly}
        required
        InputProps={{
          readOnly: readOnly
        }}
        value={ownerState.type}
        onChange={handleOwnerChange}
      />
      <br />
      <TextField
        label="Weight"
        variant="outlined"
        type="text"
        name="weight"
        id="weight"
        inputProps={{ maxLength: 15 }}
        disabled={readOnly}
        required
        InputProps={{
          readOnly: readOnly
        }}
        value={ownerState.weight}
        onChange={handleOwnerChange}
      />
      <TextField
        label="Weight UOM"
        variant="outlined"
        select
        name="weightUom"
        id="weightUom"
        disabled={readOnly}
        required
        InputProps={{
          readOnly: readOnly
        }}
        value={ownerState.weightUom}
        onChange={handleOwnerChange}
      >
        {Utils.sortObject(weightUom)}
      </TextField>
      <br />
      <TextField
        label="Thickness"
        variant="outlined"
        type="text"
        name="thickness"
        id="thickness"
        inputProps={{ maxLength: 4000 }}
        disabled={readOnly}
        InputProps={{
          readOnly: readOnly
        }}
        value={ownerState.thickness}
        onChange={handleOwnerChange}
      />
    </div>
  );
};

OtherTypeL.propTypes = {
  ownerState: PropTypes.object,
  handleOwnerChange: PropTypes.func
};

export default OtherTypeL;
