import { skuValidationRules } from '../../validationRules';
import { buildValidationSetter } from '../columnDefsShared';
import { COUNTRIES_LIST } from '../columnDefsShared';
import { skuTooltipValueGetter, skuCellClassRules } from './utils';

export function skuCountryOfMaterialsOrigin(ctx) {
  return {
    headerName: 'Country Of Materials Origin',
    field: 'countryOfMaterialsOrigin.value',
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    editable: true,
    tooltipComponent: 'tooltip',
    tooltipValueGetter: skuTooltipValueGetter('countryOfMaterialsOrigin', ctx),
    cellClassRules: skuCellClassRules('countryOfMaterialsOrigin', ctx),
    cellEditor: 'agRichSelectCellEditor',
    cellEditorParams: {
      values: Object.keys(COUNTRIES_LIST),
      useFormatter: true
    },
    valueFormatter: params => COUNTRIES_LIST[params.value],
    valueSetter: buildValidationSetter(ctx, {
      validationRules: skuValidationRules,
      aggrField: 'countryOfMaterialsOrigin'
    })
  };
}
