// https://material-ui.com/components/app-bar/

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { ContextualNav } from 'nordstrom-ui-pattern-library';

const OrionContextualNav = props => {
  // Moved useStyles unto pure function to dynamically load headingWapper specifics
  const useStyles = makeStyles({
    appBar: {
      backgroundColor: 'inherit',
      boxShadow: 'none',
      marginBottom: '100px'
    },
    toolbar: {
      padding: 0
    },
    headingWrapper: {
      display: 'flex'
    },
    controls: {
      display: 'flex',
      flexGrow: 1,
      alignItems: 'center'
    },
    navWrapper: {
      margin: '10px 0'
    },
    title: {
      marginRight: 14
    }
  });

  const classes = useStyles();

  return (
    <div className={classes.navWrapper}>
      <ContextualNav>
        <div className={classes.headingWrapper}>
          <Typography variant="h5" color="primary" className={classes.title}>
            {props.title}
          </Typography>
          {props.secondaryTitle && (
            <Typography variant="h6" color="textPrimary">
              {props.secondaryTitle}
            </Typography>
          )}
        </div>
        {props.children && (
          <div className={classes.controls}>{props.children}</div>
        )}
      </ContextualNav>
    </div>
  );
};

export default OrionContextualNav;
