import { MenuItem } from '@material-ui/core';
import React from 'react';

export default class SharedComponents {
  static selectOptions(obj) {
    var result = Object.keys(obj).map(option => (
      <MenuItem key={obj[option][0]} value={obj[option][0]}>
        {obj[option][1]}
      </MenuItem>
    ));

    result.unshift(
      <MenuItem key="" value="" style={{ color: 'grey' }}>
        <em>None</em>
      </MenuItem>
    );
    return result;
  }
}
