import {
  buildCcAggregatedCellClassRules,
  buildAggrValidationSetter,
  ccNumericGetter,
  ccBeforeNumericSet,
  ccCurrencyFormatter,
  ccRollupFromSkuTooltipValueGetter
} from './utils';

export function ccSpecialPricePerUnit(ctx) {
  return {
    headerName: 'Special Price Per Unit',
    colId: 'specialPricePerUnit',
    filter: 'agTextColumnFilter',
    headerClass: 'ag-Table__header MuiTypography-body2',
    columnGroupShow: 'open',
    cellClassRules: buildCcAggregatedCellClassRules('specialPricePerUnit', ctx),
    valueGetter: ccNumericGetter('specialPricePerUnit', 'USD'),
    valueSetter: buildAggrValidationSetter({
      relatedField: 'specialPricePerUnit.value',
      beforeSet: ccBeforeNumericSet,
      valueFormatter: ccCurrencyFormatter
    }),
    tooltipComponent: 'tooltip',
    tooltipValueGetter: ccRollupFromSkuTooltipValueGetter(
      'specialPricePerUnit',
      ctx
    ),
    suppressMenu: true
  };
}
