import React, { Component } from 'react';
import PropTypes from 'prop-types';

// AG Grid
import { AgGridReact } from '@ag-grid-community/react';

// import ImportModal from 'components/shared/ImportModal';
import { buildGridOptions } from './gridOptions';

export class Grid extends Component {
  static propTypes = {
    columnDefs: PropTypes.arrayOf(PropTypes.object).isRequired,
    rowData: PropTypes.arrayOf(PropTypes.object).isRequired
  };

  // state = {
  //   showImportModal: false
  // };

  render() {
    const { className, ...restProps } = this.props;

    return (
      <div className="ag-Table__container">
        {/* <ImportModal showImportModal={this.state.showImportModal} /> */}
        <div className={`ag-theme-material ${className}`}>
          <AgGridReact {...buildGridOptions(restProps)} />
        </div>
      </div>
    );
  }
}

export default Grid;
