import {
  buildCcAggregatedCellClassRules,
  buildAggrValidationSetter,
  ccRollupFromSkuTooltipValueGetter,
  ccNumericGetter,
  ccBeforeNumericSet,
  ccCurrencyFormatter
} from './utils';

export function ccMfgCost(ctx) {
  return {
    headerName: 'Mfg Cost',
    colId: 'mfgCost',
    filter: 'agTextColumnFilter',
    headerClass: 'ag-Table__header MuiTypography-body2',
    columnGroupShow: 'open',
    suppressMenu: true,
    tooltipComponent: 'tooltip',
    valueGetter: ccNumericGetter('mfgCost', 'USD'),
    valueSetter: buildAggrValidationSetter({
      relatedField: 'mfgCost.value',
      beforeSet: ccBeforeNumericSet,
      valueFormatter: ccCurrencyFormatter
    }),
    tooltipValueGetter: ccRollupFromSkuTooltipValueGetter('mfgCost', ctx),
    cellClassRules: buildCcAggregatedCellClassRules('mfgCost', ctx)
  };
}
