import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Utils } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  }
}));

const KnitTypeL = ({ ownerState, handleOwnerChange }) => {
  const classes = useStyles();

  var readOnly = false;
  if (ownerState.materialEditable === 'false') {
    readOnly = true;
  }

  const type = ownerState.typeDD;

  return (
    <div className={classes.root}>
      {/* left side of the un-editable portion of the form */}
      <TextField
        label="Yarn Type"
        variant="outlined"
        select
        name="type"
        id="type"
        disabled={readOnly}
        required
        InputProps={{
          readOnly: readOnly
        }}
        value={ownerState.type}
        onChange={handleOwnerChange}
      >
        {Utils.sortObject(type)}
      </TextField>
      <TextField
        label="Gauge"
        variant="outlined"
        type="text"
        name="gauge"
        id="gauge"
        inputProps={{ maxLength: 4000 }}
        disabled={readOnly}
        InputProps={{
          readOnly: readOnly
        }}
        value={ownerState.gauge}
        onChange={handleOwnerChange}
      />
      <br />
      <TextField
        label="Yarn Size/Ply"
        variant="outlined"
        type="text"
        name="yarn"
        id="yarn"
        inputProps={{ maxLength: 2000 }}
        disabled={readOnly}
        required
        InputProps={{
          readOnly: readOnly
        }}
        value={ownerState.yarn}
        onChange={handleOwnerChange}
      />
    </div>
  );
};

KnitTypeL.propTypes = {
  ownerState: PropTypes.object,
  handleOwnerChange: PropTypes.func
};

export default KnitTypeL;
