import {
  CHANNELS_LIST,
  getChannelValue,
  setChannelValue
} from '../columnDefsShared';
import {
  buildCcAggregatedCellClassRules,
  ccRollupFromSkuTooltipValueGetter
} from './utils';

export function ccChannels(ctx) {
  return {
    headerName: 'Channels',
    colId: 'channels',
    filter: 'agTextColumnFilter',
    headerClass: 'ag-Table__header MuiTypography-body2',
    columnGroupShow: 'open',
    suppressMenu: true,
    valueGetter: params => {
      let skus = params.data.skus;
      let nDirect = skus
        .map(sku => sku.nDirect.value.toLowerCase())
        .includes('y');
      let fls = skus.map(sku => sku.fls.value.toLowerCase()).includes('y');
      return getChannelValue(nDirect, fls);
    },
    // TODO: add rules service validation support
    valueSetter: params => {
      if (params.oldValue === params.newValue) {
        return;
      }
      params.data.skus.forEach(sku => {
        setChannelValue(sku, params.newValue);
      });
      let { detailNode } = params.node;
      if (detailNode && detailNode.detailGridInfo) {
        detailNode.detailGridInfo.api.refreshCells({
          columns: [`channels`],
          force: true
        });
      }
    },
    cellEditor: 'agRichSelectCellEditor',
    cellEditorParams: { values: CHANNELS_LIST },
    tooltipComponent: 'tooltip',
    tooltipValueGetter: ccRollupFromSkuTooltipValueGetter('nDirect', ctx),
    cellClassRules: buildCcAggregatedCellClassRules('nDirect', ctx)
  };
}
