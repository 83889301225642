import { makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import 'stylesheets/CostingInformation.scss';

//styling for background shading/italicizing for making disabled fields more clear
//copy this over for TextDropdown as well
const useStyles = makeStyles(theme => ({
  root: {
    background: '#A8A8A8'
    // borderColor: "green"
  },
  input: {
    // color: "green",
    fontStyle: 'italic'
    // borderColor: "green"
  },
  label: {
    // textTransform: "uppercase",
    // color: "red",
    fontStyle: 'italic'
    // borderColor: "green"
  }
}));

const TextEntry = props => {
  const classes = useStyles();

  const label = props.field.label;

  const name = props.field.name;

  const required = props.field.required;

  const disabled = props.field.disabled;

  const idx = props.props.idx;

  const enableState = props.props.enableState;

  const handleChange = props.props.handleChange;

  var rpState =
    props.props.rp === true
      ? props.props.replenishmentState
      : props.props.nonReplenishmentState;

  var val = '';

  if (props.props.type === 'Simplified') {
    if (props.cc === 'US') {
      val = props.props.rp
        ? props.props.funlist.firstCostRPListUSState[idx]
        : props.props.funlist.firstCostNonRPListUSState[idx];
    } else {
      val = props.props.rp
        ? props.props.funlist.firstCostRPListCAState[idx]
        : props.props.funlist.firstCostNonRPListCAState[idx];
    }
  } else {
    val = props.val;
  }

  const valName = props.field.valName;

  return (
    <TextField
      label={label}
      variant="outlined"
      type="text"
      name={`${name}-${idx}`}
      data-idx={idx}
      id={`${name}-${idx}`}
      value={val[valName] || ''}
      className={(!enableState[idx] || !rpState[idx]) && classes.root}
      InputProps={
        (!enableState[idx] || !rpState[idx]) && { className: classes.input }
      }
      InputLabelProps={
        (!enableState[idx] || !rpState[idx]) && { className: classes.label }
      }
      disabled={!enableState[idx] || !rpState[idx] || disabled}
      required={enableState[idx] && rpState[idx] && required}
      onBlur={e => handleChange(e, idx, name, props.cc)}
      onChange={e => handleChange(e, idx, name, props.cc)}
    />
  );
};

export default TextEntry;
