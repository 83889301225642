// TODO: extract API calls to /api
// TODO: check if log in process can be grouped in single action
import * as queryString from 'query-string';
import * as types from 'actiontypes/auth';
import { SSO_TOKEN } from 'constants/localStorage';
import { loadEnvironments } from 'providers/configurationProvider';
import { Utils } from 'utils';
import Notifier from 'services/Notifier';
import uuid from 'uuid';

const config = loadEnvironments();

export const restoreSession = () => {
  return {
    type: types.RESTORE_SESSION,
    payload() {
      const localStorageAuth = JSON.parse(localStorage.getItem(SSO_TOKEN));

      // If user already authenticated and authentication not expired - return existing
      if (localStorageAuth && localStorageAuth.expiresAt > Date.now()) {
        return localStorageAuth;
      }

      localStorage.removeItem(SSO_TOKEN);
      return null;
    }
  };
};

export const redirectToLogin = () => {
  return {
    type: types.REDIRECT_TO_LOGIN,
    async payload() {
      const url =
        `/nauth/v1/default/authorize` +
        `?client_id=${config.NauthClientId}` +
        `&redirect_uri=${window.location.origin}/${config.NauthRedirectUrl}` +
        `&response_type=code&scope=${config.NauthClaim}` +
        '&state=' +
        uuid();
      window.location.assign(url);
    }
  };
};

const getAuthorizationParams = authGroups => ({
  isAuthorized: true,
  hasAdminRights: true
});

export const login = () => {
  return {
    type: types.LOGIN,
    async payload() {
      let params = queryString.parse(window.location.search);
      let url = '/nauth/v1/default/token';
      const redirectUri = `${window.location.origin}/${config.NauthRedirectUrl}`;
      const encodedString = new Buffer.from(
        `${config.NauthClientId}:${process.env.REACT_APP_CLIENT_SECRET}`
      ).toString('base64');
      const bodyString = JSON.stringify({
        grant_type: `authorization_code`,
        redirect_uri: redirectUri,
        code: params.code
      });

      let payload = {
        method: 'POST',
        body: bodyString,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Basic ${encodedString}`
        }
      };

      let res = null;
      try {
        res = await fetch(url, payload);
        if (!res.ok) {
          throw await res.json();
        }

        // Store the token payload
        res = await res.json();
        localStorage.setItem('token-object', JSON.stringify(res));
      } catch (e) {
        throw e;
      }

      // Parse OAuth Response from URL param
      const accessToken = res.access_token;
      const idToken = res.id_token;
      const expiresAt = Date.now() + res.expires_in * 1000;
      if(accessToken === undefined){
        const warnMsg = 'undefined token';
        Notifier.info(warnMsg);
      }
      if (!accessToken && accessToken !== undefined) {
        const errMsg = 'Not authenticated';
        Notifier.error(errMsg);
        throw new Error(errMsg);
      }

      url =
        `/nauth/v1/default/userinfo` +
        `?env=${config.NauthEnv}` +
        `&client_id=${config.NauthClientId}`;

      const init = {
        headers: {
          Authorization: 'Bearer ' + accessToken
        }
      };

      res = null;

      try {
        res = await fetch(url, init);
        if (!res.ok) {
          throw await res.json();
        }
        res = await res.text();
      } catch (e) {
        throw e;
      }

      const sessionData = {
        accessToken: accessToken,
        idToken: idToken,
        firstName: res.first_name,
        lastName: res.last_name,
        groups: res.groups_whitelist,
        expiresAt: expiresAt
      };

      localStorage.setItem(SSO_TOKEN, JSON.stringify(sessionData));
      return {
        ...sessionData,
        ...getAuthorizationParams(res.groups_whitelist)
      };
    }
  };
};

export const setSessionTimeout = () => {
  return {
    type: types.SET_SESSION_TIMEOUT,
    payload() {
      const expireSession = () => {
        localStorage.removeItem(SSO_TOKEN);
        // TODO: This should be a modal. Routing to page as a functional placeholder.
        window.location.assign('/sessionexpired');
      };

      const { expiresAt } = JSON.parse(localStorage.getItem(SSO_TOKEN));

      setTimeout(expireSession, expiresAt - Date.now());
    }
  };
};

//TODO: Add unit tests
export const logout = () => {
  return {
    type: types.LOGOUT,
    async payload() {
      const url = config.NauthLogoutUrl;
      const { accessToken } = JSON.parse(localStorage.getItem(SSO_TOKEN));
      const bodyString = JSON.stringify({
        client_id: config.NauthClientId,
        token: accessToken,
        env: config.NauthEnv
      });

      const payload = {
        method: 'POST',
        body: bodyString
      };

      try {
        const res = await fetch(url, payload);
        if (!res.ok) {
          throw await res.json();
        }
        localStorage.removeItem(SSO_TOKEN);

        // Store current URL to return there after authentication process
        const { pathname, search, hash } = window.location;
        Utils.setRedirectUrl(`${pathname}${search}${hash}`);
      } catch (e) {
        throw e;
      }
    }
  };
};
