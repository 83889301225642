import { Button, Grid, TextField } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'stylesheets/CostingInformation.scss';
import GarmentContent from './GarmentContent';
import { Utils } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch'
    },
    '& .MuiInputBase-multiline': {
      minHeight: '8ch',
      width: '40ch',
      height: 'auto'
    }
  },
  root2: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '10ch'
    },
    '& .MuiButton-root': {
      margin: theme.spacing(1),
      width: '12.5ch'
    },
    '& .MuiInputBase-multiline': {
      width: '40ch',
      height: 'auto'
    }
  },
  root3: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },
  disabled: {
    background: '#A8A8A8',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch'
    },
    '& .MuiInputBase-multiline': {
      minHeight: '8ch',
      width: '40ch',
      height: 'auto'
    }
  },
  disabled2: {
    background: '#A8A8A8',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '10ch'
    },
    '& .MuiButton-root': {
      margin: theme.spacing(1),
      width: '12.5ch'
    },
    '& .MuiInputBase-multiline': {
      width: '40ch',
      height: 'auto'
    }
  },
  disabled3: {
    background: '#A8A8A8',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },
  input: {
    fontStyle: 'italic'
  },
  label: {
    fontStyle: 'italic'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}));

const FabricInformation = props => {
  const classes = useStyles();

  const fabricState = props.fabricState;
  const fabricCODD = props.fabricCODD;

  const fabricArticleNoOptions = {
    NULLVALUE: ' ',
    Other: 'Other'
  };

  const handleFabricChange = props.handleFabricChange;

  const contentState = props.contentState;
  const handleContentChange = props.handleContentChange;
  const garmentState = props.garmentState;
  const handleGarmentChange = props.handleGarmentChange;
  const addContent = props.addContent;

  const enableState = props.enableState;

  const organicLabel = 'Organic\nCotton';

  const garmentTotal = garmentState.map(
    column => column['garmentContentTotalPct']
  );
  var garmentTotalPct = 0;

  const garment = idx => {
    var current;
    if (contentState[idx] != null) {
      current = contentState[idx].length;
    } else {
      current = 0;
      contentState[idx] = [
        {
          garmentContentDD: '',
          garmentContentPCT: '',
          garmentContentLbs: ''
        }
      ];
    }

    for (let i = current; i < 3; i++) {
      addContent(idx, i);
    }
  };

  const garmentTotalCheck = idx => {
    if (garmentTotal[idx] === null || garmentTotal[idx] === '') {
      garmentTotalPct = 0;
    } else if (garmentTotal[idx].garmentContentTotalPct === undefined) {
      garmentTotalPct = garmentTotal[idx];
    } else garmentTotalPct = garmentTotal[idx].garmentContentTotalPct;
  };

  return (
    <Grid>
      <hr />
      {fabricState.map((val, idx) => (
        <div key={`ci-${idx}`} className="column">
          <div className="ci-header">Fabric Information</div>
          <hr />
          <div className={classes.root}>
            <TextField
              label="Fabric Mill"
              variant="outlined"
              type="text"
              inputProps={{ maxLength: 2000 }}
              name={`fabric-mill-${idx}`}
              data-idx={idx}
              id={`fabric-mill-${idx}`}
              className={!enableState[idx] && classes.disabled}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['fabricMill']}
              disabled={!enableState[idx]}
              onChange={e => handleFabricChange(e, idx, 'fabricMill')}
            />
            <TextField
              label="Fabric C/O"
              variant="outlined"
              select
              name={`fabric-co-${idx}`}
              data-idx={idx}
              id={`fabric-co-${idx}`}
              className={!enableState[idx] && classes.disabled}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['fabricCODD']}
              disabled={!enableState[idx]}
              onChange={e => handleFabricChange(e, idx, 'fabricCODD')}
            >
              {Utils.sortObject(fabricCODD)}
            </TextField>
            <div className="page-break" />
            <TextField
              label="Fabric Article #"
              variant="outlined"
              select
              name={`fabric-article-${idx}`}
              data-idx={idx}
              id={`fabric-article-${idx}`}
              className={!enableState[idx] && classes.disabled}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['fabricArticleNoDD']}
              disabled={!enableState[idx]}
              onChange={e => handleFabricChange(e, idx, 'fabricArticleNoDD')}
            >
              {Utils.sortObject(fabricArticleNoOptions)}
            </TextField>
            <br />
            {val['fabricArticleNoDD'] === 'Other' ? (
              <TextField
                label="Fabric Article # Other"
                variant="outlined"
                inputProps={{ maxLength: 2000 }}
                name={`fabric-article-other-${idx}`}
                id={`fabric-article-other-${idx}`}
                className={!enableState[idx] && classes.disabled}
                InputProps={!enableState[idx] && { className: classes.input }}
                InputLabelProps={
                  !enableState[idx] && { className: classes.label }
                }
                value={val['fabricArticleNoOther']}
                disabled={!enableState[idx]}
                onChange={e =>
                  handleFabricChange(e, idx, 'fabricArticleNoOther')
                }
              />
            ) : null}
          </div>
          <hr />
          {garment(idx)}
          <div className="ci-header">Garment Content</div>
          <br className="print-hide" />
          <div className={classes.root2}>
            <TextField
              value={organicLabel}
              multiline={true}
              InputProps={{
                disableUnderline: true,
                readOnly: true
              }}
            />
            <TextField
              label="%"
              variant="outlined"
              type="text"
              name={`cotton-percent-${idx}`}
              data-idx={idx}
              id={`cotton-percent-${idx}`}
              className={!enableState[idx] && classes.disabled2}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={garmentState[idx]['garmentOrganicCottonPCT']}
              disabled={!enableState[idx]}
              onBlur={e =>
                handleGarmentChange(e, idx, 'garmentOrganicCottonPCT')
              }
              onChange={e =>
                handleGarmentChange(e, idx, 'garmentOrganicCottonPCT')
              }
            />
            <TextField
              label="lbs"
              variant="outlined"
              type="text"
              name={`cotton-lbs-${idx}`}
              data-idx={idx}
              id={`cotton-lbs-${idx}`}
              className={!enableState[idx] && classes.disabled2}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={garmentState[idx]['garmentOrganicCottonLbs'] || ''}
              disabled={true}
            />
            <div />
            {contentState[idx].map((val2, idx2) => (
              <div key={`cat-div-${idx}-${idx2}`}>
                <GarmentContent
                  key={`cat-${idx}-${idx2}`}
                  idx={idx}
                  idx2={idx2}
                  contentState={contentState}
                  handleContentChange={handleContentChange}
                  enableState={enableState}
                  className={!enableState[idx] && classes.disabled2}
                  InputProps={!enableState[idx] && { className: classes.input }}
                  InputLabelProps={
                    !enableState[idx] && { className: classes.label }
                  }
                />
              </div>
            ))}
            <Button size="small" id="placeholder-button" disabled={true} />
            <Button
              variant="outlined"
              size="small"
              id={`add-button-${idx}`}
              onClick={e => addContent(idx, contentState[idx].length)}
              disabled={!enableState[idx]}
            >
              Add
            </Button>
            {garmentTotalCheck(idx)}
            <TextField
              label="%"
              variant="outlined"
              type="text"
              name={`total-percent-${idx}`}
              data-idx={idx}
              id={`total-percent-${idx}`}
              className={!enableState[idx] && classes.disabled2}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={garmentTotalPct || ''}
              error={enableState[idx] && garmentTotal[idx] < 100}
            />
            <TextField
              label="lbs"
              variant="outlined"
              type="text"
              name={`total-lbs-${idx}`}
              data-idx={idx}
              id={`total-lbs-${idx}`}
              className={!enableState[idx] && classes.disabled2}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={garmentState[idx]['garmentContentTotalLbs']}
              disabled={!enableState[idx]}
              onBlur={e =>
                handleGarmentChange(e, idx, 'garmentContentTotalLbs')
              }
              onChange={e =>
                handleGarmentChange(e, idx, 'garmentContentTotalLbs')
              }
            />
          </div>
          <hr />
          <div className="page-break" />
          <div className={classes.root3}>
            <TextField
              label="UOM"
              variant="outlined"
              type="text"
              name={`fabric-min-ord-uom-${idx}`}
              data-idx={idx}
              id={`fabric-min-ord-uom-${idx}`}
              className={!enableState[idx] && classes.disabled3}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['fabricMinOrdUOM'] || ''}
              disabled={true}
            />
            <TextField
              label="Fabric Minimum Order/"
              variant="outlined"
              type="text"
              required={enableState[idx]}
              name={`fabric-minimum-order-${idx}`}
              data-idx={idx}
              id={`fabric-minimum-order-${idx}`}
              className={!enableState[idx] && classes.disabled3}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['fabricMinOrder']}
              disabled={!enableState[idx]}
              onChange={e => handleFabricChange(e, idx, 'fabricMinOrder')}
            />
            <TextField
              label="UOM"
              variant="outlined"
              type="text"
              name={`fabric-min-col-uom-${idx}`}
              data-idx={idx}
              id={`fabric-min-col-uom-${idx}`}
              className={!enableState[idx] && classes.disabled}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['fabricMinColUOM'] || ''}
              disabled={true}
            />
            <TextField
              label="Fabric Minimum Color"
              variant="outlined"
              type="text"
              required={enableState[idx]}
              name={`fabric-minimum-color-${idx}`}
              data-idx={idx}
              id={`fabric-minimum-color-${idx}`}
              className={!enableState[idx] && classes.disabled3}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['fabricMinColor']}
              disabled={!enableState[idx]}
              onChange={e => handleFabricChange(e, idx, 'fabricMinColor')}
            />
          </div>
          <div className={classes.root}>
            <TextField
              label="Fabric Cuttable Width Inches"
              variant="outlined"
              type="text"
              name={`fabric-cuttable-${idx}`}
              data-idx={idx}
              id={`fabric-cuttable-${idx}`}
              className={!enableState[idx] && classes.disabled}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['fabricCuttableWidth']}
              disabled={!enableState[idx]}
              onChange={e => handleFabricChange(e, idx, 'fabricCuttableWidth')}
            />
          </div>
          <br />
        </div>
      ))}
    </Grid>
  );
};

export default FabricInformation;
