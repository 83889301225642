import uuid from 'uuid';
import { logout } from 'actions/auth';
import { loadEnvironments } from 'providers/configurationProvider';

const config = loadEnvironments();

export default class Fetcher {
  static _dispatch = null;
  static _accessToken = null;

  static init(dispatch, accessToken) {
    Fetcher._dispatch = dispatch;
    Fetcher._accessToken = accessToken;
  }

  static async _request(url, init) {
    if (!Fetcher._dispatch || !Fetcher._accessToken) {
      throw new Error('Service not initialized');
    }

    init = {
      headers: {
        'Content-Type': 'application/json',
        'Nord-Request-Id': uuid.v4(),
        'Nord-Client-Id': uuid.v4(),
        ApplicationId: config.ApplicationID,
        Authorization: `Bearer ${Fetcher._accessToken}`
      },
      ...init
    };

    const res = await fetch(url, init);

    if (res.status === 401) {
      Fetcher._dispatch(logout());
    }

    // TODO: Handle behavior for 403. Most likely user is not authorized. Dispatch notification / Redirect?

    if (res.status < 200 || res.status >= 400) {
      throw await res.json();
    }

    if (res.status !== 204) {
      const jsonRes = await res.json();
      return typeof jsonRes === 'object' ? jsonRes : JSON.parse(jsonRes);
    }

    return undefined;
  }

  static get(url, init = {}) {
    init = {
      method: 'GET',
      ...init
    };
    return Fetcher._request(url, init);
  }

  static post(url, payload, init = {}) {
    init = {
      method: 'POST',
      body: JSON.stringify(payload),
      ...init
    };
    return Fetcher._request(url, init);
  }

  static patch(url, payload, init = {}) {
    init = {
      method: 'PATCH',
      body: JSON.stringify(payload),
      ...init
    };
    return Fetcher._request(url, init);
  }

  static put(url, payload, init = {}) {
    init = {
      method: 'PUT',
      body: JSON.stringify(payload),
      ...init
    };
    return Fetcher._request(url, init);
  }

  static delete(url, init = {}) {
    init = {
      method: 'DELETE',
      ...init
    };
    return Fetcher._request(url, init);
  }
}
