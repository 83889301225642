import React from 'react';

import LabelDetails from './Label/LabelDetails';
import PackagingDetails from './Packaging/PackagingDetails';


const DetailsLP = ({ownerState, handleOwnerChange}) => {

    if(ownerState.materialType === 'Label'){
        return(
            <>
                <LabelDetails ownerState={ownerState} handleOwnerChange={handleOwnerChange} />
            </>
        );
    } else if (ownerState.materialType === 'Packaging'){
        return(
            <>
                <PackagingDetails ownerState={ownerState} handleOwnerChange={handleOwnerChange} />
            </>
        );
    }
};

export default DetailsLP;