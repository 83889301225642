export const loadEnvironments = () => {
  let config = null;
  try {
    if (process.env.REACT_APP_ENV === 'prod') {
      config = require('../settings/prod.json');
    } else {
      config = require('../settings/non-prod.json');
    }
  } catch (error) {
    console.log('Failed to load environment', {
      error
    });
  }
  return config;
};
