import { skuValidationRules } from '../../validationRules';
import { buildValidationSetter } from '../columnDefsShared';
import { skuTooltipValueGetter, skuCellClassRules } from './utils';

export function skuDisposition(ctx) {
  return {
    headerName: 'Disposition',
    field: 'disposition.value',
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    tooltipComponent: 'tooltip',
    tooltipValueGetter: skuTooltipValueGetter('disposition', ctx),
    cellClassRules: skuCellClassRules('disposition', ctx),
    valueSetter: buildValidationSetter(ctx, {
      validationRules: skuValidationRules,
      aggrField: 'disposition'
    })
  };
}
