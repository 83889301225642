import { ccValidationRules } from '../../validationRules';
import { buildValidationSetter } from '../columnDefsShared';
import { ccCellClassRules, ccTooltipValueGetter } from './utils';

export function ccProductLabel(ctx) {
  return {
    headerName: 'Product Label',
    field: 'productLabel.value',
    filter: 'agTextColumnFilter',
    headerClass: 'ag-Table__header MuiTypography-body2',
    columnGroupShow: 'open',
    suppressMenu: true,
    tooltipComponent: 'tooltip',
    tooltipValueGetter: ccTooltipValueGetter('productLabel', ctx),
    cellEditor: 'agRichSelectCellEditor',
    cellEditorParams: function(params) {
      // Get required details from /choice call
      let supplierId;
      let brandCode;
      let values = [];

      // Check for Supplier and Brand details, return early if data is missing.
      if (params.data.supplierId && params.data.supplierId.value) {
        supplierId = params.data.supplierId.value;

        if (params.data.brandCode && params.data.brandCode.value) {
          brandCode = params.data.brandCode.value;
        }
      } else {
        return { values };
      }

      // In /vendor response, find Product Labels matching SupplierId/BrandCode obtained from above /choice call
      // vendorNumber <--> supplierId
      // brandCode <--> brandCode
      const ctxSupplier = ctx.props.suppliers[supplierId];
      const ctxBrand = ctxSupplier ? ctxSupplier.vendorBrands[brandCode] : null;

      values = ctx.props.suppliersIsLoading
        ? ['Retrieving values...']
        : ctxBrand
        ? Object.values(ctxBrand.VendorLabels)
        : [];

      return { values };
    },
    cellClassRules: ccCellClassRules('productLabel', ctx),
    valueSetter: buildValidationSetter(ctx, {
      validationRules: ccValidationRules
    })
  };
}
