import {
  buildCcAggregatedCellClassRules,
  buildAggrValidationSetter,
  ccRollupFromSkuTooltipValueGetter,
  ccListSkusRenderer,
  ccBeforeTextSet
} from './utils';

export function ccDisposition(ctx) {
  return {
    headerName: 'Disposition',
    colId: 'disposition',
    filter: 'agTextColumnFilter',
    headerClass: 'ag-Table__header MuiTypography-body2',
    columnGroupShow: 'open',
    suppressMenu: true,
    cellRenderer: ccListSkusRenderer('disposition'),
    valueSetter: buildAggrValidationSetter({
      relatedField: 'disposition.value',
      beforeSet: ccBeforeTextSet
    }),
    tooltipComponent: 'tooltip',
    tooltipValueGetter: ccRollupFromSkuTooltipValueGetter('disposition', ctx),
    cellClassRules: buildCcAggregatedCellClassRules('disposition', ctx)
  };
}
