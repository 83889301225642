import { ccValidationRules } from '../../validationRules';
import { buildValidationSetter } from '../columnDefsShared';
import { ccCellClassRules, ccTooltipValueGetter } from './utils';

export function ccStyleGroup(ctx) {
  return {
    headerName: 'Style Group #',
    field: 'styleGroup.value',
    filter: 'agTextColumnFilter',
    headerClass: 'ag-Table__header MuiTypography-body2',
    columnGroupShow: 'open',
    suppressMenu: true,
    tooltipComponent: 'tooltip',
    tooltipValueGetter: ccTooltipValueGetter('styleGroup', ctx),
    cellClassRules: ccCellClassRules('styleGroup', ctx),
    valueSetter: buildValidationSetter(ctx, {
      validationRules: ccValidationRules
    })
  };
}
