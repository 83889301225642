import * as R from 'ramda';
import { AllModules } from '@ag-grid-enterprise/all-modules';
import ImageViewer from 'components/shared/ImageViewer';
import Tooltip from 'components/shared/Tooltip';
import { hideTooltip, setTooltipSettings } from './tooltipUtils';

export function buildGridOptions(props) {
  const {
    onGridReady: _onGridReady,
    onCellValueChanged: _onCellValueChanged,
    onBodyScroll: _onBodyScroll,
    detailCellRendererParams: _detailCellRendererParams,
    ...restProps
  } = props;

  const defaultGridOptions = {
    modules: AllModules,
    reactNext: true,
    masterDetail: true,
    rowSelection: 'single',
    floatingFilter: true,
    stopEditingWhenGridLosesFocus: true,
    // suppressColumnVirtualisation - will force table to render all
    // items even if they are not in the view. Allows to operate with
    // all table columns immediately on initialization but potentially
    // can affect performance.
    suppressColumnVirtualisation: true,
    defaultColDef: {
      editable: true,
      minWidth: 100,
      maxWidth: 400,
      resizable: true,
      filter: true,
      sortable: true,
      enableCellChangeFlash: true
    },
    frameworkComponents: {
      tooltip: Tooltip,
      ImageViewer: ImageViewer
    },
    statusBar: {
      statusPanels: [
        {
          statusPanel: 'agTotalRowCountComponent',
          align: 'left'
        },
        { statusPanel: 'agFilteredRowCountComponent' },
        { statusPanel: 'agSelectedRowCountComponent' },
        { statusPanel: 'agAggregationComponent' }
      ]
    },
    getRowHeight: _getRowHeight,
    overlayLoadingTemplate:
      '<div class="ag-overlay-loading-inner-wrapper"><span class="ag-overlay-loading-center">Please wait while data is loading</span></div>',
    overlayNoRowsTemplate:
      '<span style="padding: 10px; border: 2px solid #444; background: lightgoldenrodyellow;">No products found</span>',
    onGridReady: _buildGridReadyHandler(props),
    onCellValueChanged: _buildCellValueChangedHandler(props),
    onBodyScroll: _buildBodyScrollHandler(props),
    detailCellRendererParams: _buildDetailCellRendererParams(props)
  };

  return R.mergeDeepRight(defaultGridOptions, restProps);
}

export function _getRowHeight(params) {
  if (params.node && params.node.detail) {
    let headerHeight = 56;
    let offset = 80;
    let skusCount = params.data.skus.length || 1; // Keep one row for "no data" msg in case when no skus
    let allDetailRowHeight = skusCount * 48;
    return headerHeight + allDetailRowHeight + offset;
  } else {
    return 48;
  }
}

export function _buildGridReadyHandler(props) {
  return params => {
    setTooltipSettings(params.api.context.beanWrappers.tooltipManager);
    if (props.onGridReady) {
      props.onGridReady(params);
    }
  };
}

export function _buildCellValueChangedHandler(props) {
  return params => {
    if (params.oldValue === params.newValue) {
      return;
    }
    if (params.api) {
      const rowNode = params.api.getDisplayedRowAtIndex(params.rowIndex);
      const column = params.column.colId;
      params.api.flashCells({
        rowNodes: [rowNode],
        columns: [column]
      });
    }
    if (props.onCellValueChanged) {
      props.onCellValueChanged(params);
    }
  };
}

export function _buildBodyScrollHandler(props) {
  return params => {
    hideTooltip();
    _hidePopup(params);
    if (props.onBodyScroll) {
      props.onBodyScroll(params);
    }
  };
}

function _hidePopup(params) {
  const instances = params.api.getCellEditorInstances();
  if (Array.isArray(instances)) {
    instances.forEach(instance => instance.params.stopEditing());
  }
}

const _buildDetailCellRendererParams = props => {
  const { detailCellRendererParams } = props;

  if (!detailCellRendererParams) {
    return undefined;
  }

  const { detailGridOptions, getDetailRowData } = detailCellRendererParams;

  const {
    onGridReady: _onGridReady,
    onCellValueChanged: _onCellValueChanged,
    onBodyScroll: _onBodyScroll,
    ...restDetailGridOptions
  } = detailGridOptions;

  const defaultDetailGridOptions = {
    stopEditingWhenGridLosesFocus: true,
    suppressColumnVirtualisation: true,
    defaultColDef: {
      minWidth: 100,
      maxWidth: 400,
      editable: true,
      resizable: true,
      enableCellChangeFlash: true
    },
    frameworkComponents: {
      tooltip: Tooltip
    },
    onGridReady: _buildDetailGridReadyHandler(props),
    onCellValueChanged: _buildDetailCellValueChangedHandler(props),
    onBodyScroll: _buildDetailBodyScrollHandler(props)
  };

  return {
    detailGridOptions: R.mergeDeepRight(
      defaultDetailGridOptions,
      restDetailGridOptions
    ),
    getDetailRowData: getDetailRowData
  };
};

export function _buildDetailGridReadyHandler(props) {
  return params => {
    params.api.setDomLayout('autoHeight');
    setTooltipSettings(params.api.context.beanWrappers.tooltipManager);
    const { detailGridOptions } = props.detailCellRendererParams;
    if (detailGridOptions && detailGridOptions.onGridReady) {
      detailGridOptions.onGridReady(params);
    }
  };
}

export function _buildDetailCellValueChangedHandler(props) {
  return params => {
    if (params.oldValue === params.newValue) {
      return;
    }
    const rowNode = params.api.getDisplayedRowAtIndex(params.rowIndex);
    const column = params.column.colId;
    params.api.flashCells({
      rowNodes: [rowNode],
      columns: [column]
    });
    const { detailGridOptions } = props.detailCellRendererParams;
    if (detailGridOptions && detailGridOptions.onCellValueChanged) {
      detailGridOptions.onCellValueChanged(params);
    }
  };
}

export function _buildDetailBodyScrollHandler(props) {
  return params => {
    hideTooltip();
    _hidePopup(params);
    const { detailGridOptions } = props.detailCellRendererParams;
    if (detailGridOptions && detailGridOptions.onBodyScroll) {
      detailGridOptions.onBodyScroll(params);
    }
  };
}
