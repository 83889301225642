import { addNotification } from 'actions/notifications';

export default class Notifier {
  static _dispatch = null;

  static init(dispatch) {
    Notifier._dispatch = dispatch;
  }

  static _checkInit() {
    if (!Notifier._dispatch) {
      throw new Error('Notifier service was not initialized');
    }
  }

  static info(msg) {
    Notifier._checkInit();
    Notifier._dispatch(addNotification(msg, 'info'));
  }

  static error(msg) {
    Notifier._checkInit();
    Notifier._dispatch(addNotification(msg, 'error'));
  }
}
