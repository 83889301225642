import { COUNTRIES_LIST } from '../columnDefsShared';
import {
  buildCcAggregatedCellClassRules,
  buildAggrValidationSetter,
  ccRollupFromSkuTooltipValueGetter,
  ccListSkusRenderer,
  ccBeforeTextSet
} from './utils';

export function ccCountryOfMaterialsOrigin(ctx) {
  return {
    headerName: 'Country of Materials Origin',
    colId: 'countryOfMaterialsOrigin',
    filter: 'agTextColumnFilter',
    headerClass: 'ag-Table__header MuiTypography-body2',
    columnGroupShow: 'open',
    suppressMenu: true,
    // Changing from cellRenderer to valueGetter to enable copy/paste across multiple CCs
    valueGetter: ccListSkusRenderer('countryOfMaterialsOrigin'),
    valueSetter: buildAggrValidationSetter({
      relatedField: 'countryOfMaterialsOrigin.value',
      beforeSet: ccBeforeTextSet
    }),
    tooltipComponent: 'tooltip',
    tooltipValueGetter: ccRollupFromSkuTooltipValueGetter(
      'countryOfMaterialsOrigin',
      ctx
    ),
    cellEditor: 'agRichSelectCellEditor',
    cellEditorParams: {
      values: Object.keys(COUNTRIES_LIST),
      useFormatter: true
    },
    valueFormatter: params => COUNTRIES_LIST[params.value],
    cellClassRules: buildCcAggregatedCellClassRules(
      'countryOfMaterialsOrigin',
      ctx
    )
  };
}
