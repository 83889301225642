import {
  selectTableContent,
  selectDepartments,
  selectSuppliers,
  selectContentIsFetching,
  selectSuppliersIsFetching,
  selectDepartmentsIsFetching,
  selectcontentFetchError
} from 'reducers/summary';

export const selectSummaryTableContent = state =>
  selectTableContent(state.summary);
export const selectSummaryDepartments = state =>
  selectDepartments(state.summary);
export const selectSummarySuppliers = state => selectSuppliers(state.summary);
export const selectSummaryContentIsFetching = state =>
  selectContentIsFetching(state.summary);
export const selectSummarySuppliersIsFetching = state =>
  selectSuppliersIsFetching(state.summary);
export const selectSummaryDepartmentsIsFetching = state =>
  selectDepartmentsIsFetching(state.summary);
export const selectSummaryContentFetchError = state =>
  selectcontentFetchError(state.summary);
