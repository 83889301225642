import React from 'react';
import PropTypes from 'prop-types';
import { Chip, MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Utils } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch'
    },
    '& .MuiInputBase-multiline': {
      minHeight: '8ch',
      width: '40ch',
      height: 'auto'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}));

const OtherTypeR = ({ ownerState, handleOwnerChange }) => {
  const classes = useStyles();

  var readOnly = false;
  if (ownerState.materialEditable === 'false') {
    readOnly = true;
  }

  const print = ownerState.printProcessDD;

  const dyeApp = ownerState.dyeApplicationDD;

  const dyeType = ownerState.dyeTypeDD;
  const dyeTypeKeys = Object.keys(dyeType);

  return (
    <div className={classes.root}>
      {/* right side of the uneditable side of the form */}
      <TextField
        label="Print Process"
        variant="outlined"
        select
        name="printProcess"
        id="printProcess"
        disabled={readOnly}
        InputProps={{
          readOnly: readOnly
        }}
        value={ownerState.printProcess}
        onChange={handleOwnerChange}
      >
        {Utils.sortObject(print)}
      </TextField>
      <br />
      <TextField
        label="Dye Application"
        variant="outlined"
        select
        name="dyeApplication"
        id="dyeApplication"
        disabled={readOnly}
        InputProps={{
          readOnly: readOnly
        }}
        value={ownerState.dyeApplication}
        onChange={handleOwnerChange}
      >
        {Utils.sortObject(dyeApp)}
      </TextField>
      <br />
      <TextField
        label="Dye Type"
        variant="outlined"
        select
        name="dyeType"
        id="dyeType"
        SelectProps={{
          multiple: true,
          renderValue: selected => (
            <div className={classes.chips}>
              {selected.map(value => (
                <Chip
                  key={value}
                  label={dyeType[value]}
                  className={classes.chip}
                />
              ))}
            </div>
          )
        }}
        multiline={true}
        disabled={readOnly}
        InputProps={{
          readOnly: readOnly
        }}
        value={ownerState.dyeType}
        onChange={handleOwnerChange}
      >
        {dyeTypeKeys.map(option => (
          <MenuItem key={option} value={option}>
            {dyeType[option]}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

OtherTypeR.propTypes = {
  ownerState: PropTypes.object,
  handleOwnerChange: PropTypes.func
};

export default OtherTypeR;
