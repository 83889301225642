import { COUNTRIES_LIST } from '../columnDefsShared';
import {
  buildCcAggregatedCellClassRules,
  buildAggrValidationSetter,
  ccRollupFromSkuTooltipValueGetter,
  ccListSkusRenderer,
  ccBeforeTextSet
} from './utils';

export function ccCountryOfOrigin(ctx) {
  return {
    headerName: 'Country of Origin',
    colId: 'countryOfOrigin',
    filter: 'agTextColumnFilter',
    headerClass: 'ag-Table__header MuiTypography-body2',
    suppressMenu: true,
    pivot: true,
    enablePivot: true,
    // Changing from cellRenderer to valueGetter to enable copy/paste across multiple CCs
    valueGetter: ccListSkusRenderer('countryOfOrigin'),
    valueSetter: buildAggrValidationSetter({
      relatedField: 'countryOfOrigin.value',
      beforeSet: ccBeforeTextSet
    }),
    tooltipComponent: 'tooltip',
    tooltipValueGetter: ccRollupFromSkuTooltipValueGetter(
      'countryOfOrigin',
      ctx
    ),
    cellEditor: 'agRichSelectCellEditor',
    cellEditorParams: {
      values: Object.keys(COUNTRIES_LIST),
      useFormatter: true
    },
    valueFormatter: params => COUNTRIES_LIST[params.value],
    cellClassRules: buildCcAggregatedCellClassRules('countryOfOrigin', ctx)
  };
}
