import { FETCH_SUPPLIERS_CONTENT } from 'actiontypes';
import { pending, fulfilled, rejected } from './utils';

var initialState = {
  tableContent: [],
  actionBtnContent: [],
  isContentFetching: false,
  contentFetchError: null
};

// Creates new object, avoiding mutation of original.
const assign = (state, newVars) => {
  return Object.assign({}, state, newVars);
};

// action.* values are instantiated in action creator
// Reducer takes initial state + action & creates a new state
// Must be pure functions and not mutate state
export default function suppliers(state = initialState, action) {
  switch (action.type) {
    case pending(FETCH_SUPPLIERS_CONTENT):
      return assign(state, {
        contentFetchError: null,
        isContentFetching: true
      });

    case fulfilled(FETCH_SUPPLIERS_CONTENT):
      return assign(state, {
        tableContent: action.payload.grid,
        actionBtnContent: action.payload.actionBtn,
        isContentFetching: false
      });

    case rejected(FETCH_SUPPLIERS_CONTENT):
      return assign(state, {
        contentFetchError: action.payload,
        isContentFetching: false
      });

    default:
      return state;
  }
}

/** PRIVATE SELECTORS */
export const selectTableContent = state => state.tableContent;
export const selectActionBtnContent = state => state.actionBtnContent;
export const selectIsContentFetching = state => state.isContentFetching;
export const selectContentFetchError = state => state.contentFetchError;
