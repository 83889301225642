import { AgGridUtils } from 'utils';
import { ccValidationRules } from '../../validationRules';
import { buildValidationSetter } from '../columnDefsShared';
import { ccCellClassRules, ccTooltipValueGetter } from './utils';

export function ccPoDue(ctx) {
  return {
    // TODO: Edit cell should be date picker
    headerName: 'PO Due',
    field: 'poDue.value',
    cellRenderer: data => {
      return data.value ? new Date(data.value).toLocaleDateString() : '';
    },
    filter: 'agDateColumnFilter',
    headerClass: 'ag-Table__header MuiTypography-body2',
    suppressMenu: true,
    columnGroupShow: 'open',
    filterParams: {
      comparator: AgGridUtils.comparatorHelper,
      browserDatePicker: true
    },
    cellClassRules: ccCellClassRules('poDue', ctx),
    tooltipComponent: 'tooltip',
    tooltipValueGetter: ccTooltipValueGetter('poDue', ctx),
    valueSetter: buildValidationSetter(ctx, {
      validationRules: ccValidationRules
    })
  };
}
