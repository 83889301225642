import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Utils } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  }
}));

const LeatherTypeL = ({ ownerState, handleOwnerChange }) => {
  const classes = useStyles();

  var readOnly = false;
  if (ownerState.materialEditable === 'false') {
    readOnly = true;
  }

  const type = ownerState.typeDD;

  const thickness = ownerState.thicknessDD;

  return (
    <div className={classes.root}>
      {/* left side of the un-editable portion of the form */}
      <TextField
        label="Leather Type"
        variant="outlined"
        select
        name="type"
        id="type"
        disabled={readOnly}
        required
        InputProps={{
          readOnly: readOnly
        }}
        value={ownerState.type}
        onChange={handleOwnerChange}
      >
        {Utils.sortObject(type)}
      </TextField>
      <TextField
        label="Thickness"
        variant="outlined"
        select
        name="thickness"
        id="thickness"
        disabled={readOnly}
        required
        InputProps={{
          readOnly: readOnly
        }}
        value={ownerState.thickness}
        onChange={handleOwnerChange}
      >
        {Utils.sortObject(thickness)}
      </TextField>
    </div>
  );
};

LeatherTypeL.propTypes = {
  ownerState: PropTypes.object,
  handleOwnerChange: PropTypes.func
};

export default LeatherTypeL;
