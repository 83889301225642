import React from 'react';

// Material-UI
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import nordLogoBlack from '../../images/nordLogoBlack.svg';

//Components
import OrionAppNav from '../OrionApplicationNav';

class ErrorBoundary extends React.Component{
    constructor(props){
        super(props);
        this.state ={
            hasError:false
        };
    }

    static getDerivedStateFromError(error) {
        return {hasError:true};
    }

    componentDidCatch(error, errorInfo){
        //Logging can be implemented here if desired
    }

    render(){
        if(this.state.hasError){
            return(
            <>
                <OrionAppNav 
                    path={window.location.pathname} 
                    expiryDate={null} // null b/c error doesn't expire
                    statusCode={null} // null b/c based on JS errors, not HTTP errors
                />
                <CssBaseline />
                    <Grid
                        container
                        spacing={5}
                        direction="column"
                        alignItems='center'
                        justify='center'
                        style={{minHeight:'100vh'}}
                    >
                        <Grid item>
                            <Typography 
                                variant='h1'
                                style={{textAlign:'center'}}
                            > 
                                Whoops! 
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography 
                                variant='h3'
                                style={{textAlign:'center'}}
                            > 
                                Something went wrong  
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='h4'>
                            If the issue persists contact support team.
                            </Typography>
                        </Grid>
                        <Grid item>
                            <img 
                                src={nordLogoBlack} 
                                alt='Nordstrom Logo' 
                                style={{width:'200px'}}
                            />
                        </Grid>
                    </Grid>
            </>
            );
        }
        return this.props.children;
        }
}

export default ErrorBoundary;