import { skuNumberOfIssues } from './skuNumberOfIssues';
import { skuStatus } from './skuStatus';
import { skuPoDue } from './skuPoDue';
import { skuSupplierSize } from './skuSupplierSize';
import { skuSize1 } from './skuSize1';
import { skuSize2 } from './skuSize2';
import { skuUpc } from './skuUpc';
import { skuCostPerUnit } from './skuCostPerUnit';
import { skuPricePerUnit } from './skuPricePerUnit';
import { skuMarkupPercentage } from './skuMarkupPercentage';
import { skuSpecialCostPerUnit } from './skuSpecialCostPerUnit';
import { skuSpecialPricePerUnit } from './skuSpecialPricePerUnit';
import { skuChannels } from './skuChannels';
import { skuDropship } from './skuDropship';
import { skuCountryOfOrigin } from './skuCountryOfOrigin';
import { skuCountryOfMaterialsOrigin } from './skuCountryOfMaterialsOrigin';
import { skuMfgCost } from './skuMfgCost';
import { skuSellingStatus } from './skuSellingStatus';
import { skuDisposition } from './skuDisposition';
import { skuLiveDate } from './skuLiveDate';
import { skuNdSize1 } from './skuNdSize1';
import { skuNdSize2 } from './skuNdSize2';
import { skuRmsSkuId } from './skuRmsSkuId';

export function getSkuColumnDefs(ctx) {
  return [
    skuNumberOfIssues(),
    skuStatus(),
    skuPoDue(ctx),
    skuSupplierSize(ctx),
    skuSize1(ctx),
    skuSize2(ctx),
    skuUpc(ctx),
    skuCostPerUnit(ctx),
    skuPricePerUnit(ctx),
    skuMarkupPercentage(ctx),
    skuSpecialCostPerUnit(ctx),
    skuSpecialPricePerUnit(ctx),
    skuChannels(ctx),
    skuDropship(ctx),
    skuCountryOfOrigin(ctx),
    skuCountryOfMaterialsOrigin(ctx),
    skuMfgCost(ctx),
    skuSellingStatus(ctx),
    skuDisposition(ctx),
    skuLiveDate(ctx),
    skuNdSize1(ctx),
    skuNdSize2(ctx),
    skuRmsSkuId()
  ];
}
