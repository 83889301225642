import { createStore, applyMiddleware, compose } from 'redux';
import ReduxThunk from 'redux-thunk';
import ReduxPromise from 'redux-promise-middleware';
import immutableCheckMiddleWare from 'redux-immutable-state-invariant';
import reducer from 'reducers';

let composeEnhancers = compose;
let middleware = [ReduxThunk, ReduxPromise];

if (process.env.REACT_APP_ENV !== 'prod') {
  middleware.unshift(immutableCheckMiddleWare());
  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
  }
}

const enhancer = composeEnhancers(applyMiddleware(...middleware));

export default createStore(reducer, enhancer);
