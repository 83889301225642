import { ccSkus } from './ccSkus';
import { ccNumberOfIssues } from './ccNumberOfIssues';
import { ccStatus } from './ccStatus';
import { ccPoDue } from './ccPoDue';
import { ccImageViewer } from './ccImageViewer';
import { ccStyleId } from './ccStyleId';
import { ccStyleName } from './ccStyleName';
import { ccDesigner } from './ccDesigner';
import { ccNrfColorCode } from './ccNrfColorCode';
import { ccSupplierColor } from './ccSupplierColor';
import { ccSizeRange } from './ccSizeRange';
import { ccTotalSkus } from './ccTotalSkus';
import { ccSupplierUs } from './ccSupplierUs';
import { ccUnitSize } from './ccUnitSize';
import { ccCostPerUnit } from './ccCostPerUnit';
import { ccPricePerUnit } from './ccPricePerUnit';
import { ccMarkupPercentage } from './ccMarkupPercentage';
import { ccSpecialCostPerUnit } from './ccSpecialCostPerUnit';
import { ccSpecialPricePerUnit } from './ccSpecialPricePerUnit';
import { ccDepartment } from './ccDepartment';
import { ccClass } from './ccClass';
import { ccSubclass } from './ccSubclass';
import { ccProductLabel } from './ccProductLabel';
import { ccChannels } from './ccChannels';
import { ccCountryOfOrigin } from './ccCountryOfOrigin';
import { ccCountryOfMaterialsOrigin } from './ccCountryOfMaterialsOrigin';
import { ccMfgCost } from './ccMfgCost';
import { ccFlsPackingMethod } from './ccFlsPackingMethod';
import { ccNDirectPackingMethod } from './ccNDirectPackingMethod';
import { ccTicketType } from './ccTicketType';
import { ccHangerType } from './ccHangerType';
import { ccSellingStatus } from './ccSellingStatus';
import { ccSourcingCategory } from './ccSourcingCategory';
import { ccDisposition } from './ccDisposition';
import { ccLiveDate } from './ccLiveDate';
import { ccStyleGroup } from './ccStyleGroup';
import { ccNdStyleName } from './ccNdStyleName';
import { ccGender } from './ccGender';
import { ccAge } from './ccAge';
import { ccProductType1 } from './ccProductType1';
import { ccProductType2 } from './ccProductType2';
import { ccColorNumber } from './ccColorNumber';
import { ccDisplayColorName } from './ccDisplayColorName';
import { ccColorFamily } from './ccColorFamily';
import { ccRmsStyle } from './ccRmsStyle';
import { ccRmsSizeGroup } from './ccRmsSizeGroup';
import { ccRmsSizeRange } from './ccRmsSizeRange';

export function getCcColumnDefs(ctx) {
  return [
    ccSkus(),
    ccNumberOfIssues(),
    ccStatus(),
    ccPoDue(ctx),
    ccImageViewer(),
    ccStyleId(ctx),
    ccStyleName(ctx),
    ccDesigner(ctx),
    ccNrfColorCode(ctx),
    ccSupplierColor(ctx),
    ccSizeRange(ctx),
    ccTotalSkus(),
    ccSupplierUs(ctx),
    ccUnitSize(ctx),
    ccCostPerUnit(ctx),
    ccPricePerUnit(ctx),
    ccMarkupPercentage(ctx),
    ccSpecialCostPerUnit(ctx),
    ccSpecialPricePerUnit(ctx),
    ccDepartment(ctx),
    ccClass(ctx),
    ccSubclass(ctx),
    ccProductLabel(ctx),
    ccChannels(ctx),
    ccCountryOfOrigin(ctx),
    ccCountryOfMaterialsOrigin(ctx),
    ccMfgCost(ctx),
    ccFlsPackingMethod(ctx),
    ccNDirectPackingMethod(ctx),
    ccTicketType(ctx),
    ccHangerType(ctx),
    ccSellingStatus(ctx),
    ccDisposition(ctx),
    ccSourcingCategory(ctx),
    ccLiveDate(ctx),
    ccStyleGroup(ctx),
    ccNdStyleName(ctx),
    ccGender(ctx),
    ccAge(ctx),
    ccProductType1(ctx),
    ccProductType2(ctx),
    ccColorNumber(ctx),
    ccDisplayColorName(ctx),
    ccColorFamily(ctx),
    ccRmsStyle(ctx),
    ccRmsSizeGroup(ctx),
    ccRmsSizeRange(ctx)
  ];
}
