import React, { Component } from 'react';
import { withStyles } from '@material-ui/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

//Navbar component
import OrionAppNav from '../../OrionApplicationNav';

export const useStyles = () => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  }
});

export class Loader extends Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <OrionAppNav
          path={window.location.pathname}
          expiryDate={null}
          statusCode={null}
        />
        <CircularProgress />
      </div>
    );
  }
}

export default withStyles(useStyles)(Loader);
