export function ccStatus() {
  // TODO: map number to text for searching purpose?
  return {
    headerName: 'Status',
    field: 'status.value',
    filter: 'agTextColumnFilter',
    headerClass: 'ag-Table__header MuiTypography-body2',
    suppressMenu: true,
    columnGroupShow: 'open',
    enablePivot: true,
    editable: false,
    cellClassRules: {
      softDanger: function(params) {
        return false;
      }
    },
    cellRenderer: data => {
      // TODO: This hard coded value will need to be removed. For user scenerio testing only.
      return 'Setup';
    }
  };
}
