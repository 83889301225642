import React from 'react';
import { TextField, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
      height: 'auto'
    },
    '& .MuiInputBase-multiline': {
      width: '26ch',
      height: 'auto'
    }
  },
  disabled: {
    background: '#A8A8A8',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
      height: 'auto'
    },
    '& .MuiInputBase-multiline': {
      width: '26ch',
      height: 'auto'
    }
  },
  input: {
    fontStyle: 'italic'
  },
  label: {
    fontStyle: 'italic'
  }
}));

const SpecialComments = props => {
  const classes = useStyles();

  const specialCommentsList = props.specialCommentsList;
  const handleSCChange = props.handleSCChange;
  const enableState = props.enableState;

  return (
    <Grid>
      <div className={classes.root}>
        {specialCommentsList.map((val, idx) => (
          <div key={`sc-${idx}`} className="column">
            <TextField
              variant="outlined"
              type="text"
              multiline={true}
              inputProps={{ maxLength: 2000 }}
              name="Special Comments & Trim Details"
              id={`Special Comments & Trim Details ${idx}`}
              rows={3}
              rowsMax={Infinity}
              helperText="Special Comments & Trim Details"
              value={val['wovenSpecialComments']}
              onChange={e => handleSCChange(e, idx, 'wovenSpecialComments')}
              disabled={!enableState[idx]}
              className={!enableState[idx] && classes.disabled}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
            />
            <br />
            <br />
          </div>
        ))}
      </div>
    </Grid>
  );
};

export default SpecialComments;
