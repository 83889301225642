import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'stylesheets/CostingInformation.scss';
import { Utils } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch'
    },
    '& .MuiInputBase-multiline': {
      minHeight: '8ch',
      width: '40ch',
      height: 'auto'
    }
  },
  disabled: {
    background: '#A8A8A8',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch'
    },
    '& .MuiInputBase-multiline': {
      minHeight: '8ch',
      width: '40ch',
      height: 'auto'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}));
const MaterialInformation = props => {
  const classes = useStyles();

  const miState = props.miState;
  const handleMIChange = props.handleMIChange;
  const enableState = props.enableState;
  const uomFabricPriceYieldDD = props.miUomFabricPriceYieldDD;
  const giList = props.giList;

  return (
    <Grid>
      <hr />
      {miState.map((val, idx) => (
        <div key={`ci-${idx}`} className="column">
          <div className="ci-header">Material Information</div>
          <hr />

          <div className="page-break" />
          <div className={classes.root}>
            <TextField
              label="Fabric Cuttable Width Inches"
              variant="outlined"
              type="text"
              name={`fabric-cuttable-${idx}`}
              data-idx={idx}
              id={`fabric-cuttable-width-inches${idx}`}
              className={!enableState[idx] && classes.disabled}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['miFabricCuttableWidthInches'] || ''}
              disabled={
                !enableState[idx] ||
                giList[idx].giMaterialTypeDD === 'yarn' ||
                giList[idx].giMaterialTypeDD === ''
              }
              onChange={e =>
                handleMIChange(e, idx, 'miFabricCuttableWidthInches')
              }
            />
            <br />
            <TextField
              label="UOM Fabric, Price and Yield"
              variant="outlined"
              select
              required={
                enableState[idx] &&
                (giList[idx].giMaterialTypeDD === 'knit' ||
                  giList[idx].giMaterialTypeDD === 'woven')
              }
              name={`uom-fabric-price-yield-${idx}`}
              data-idx={idx}
              id={`uom-fabric-price-yield-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['miUomFabricPriceYieldDD'] || ''}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              disabled={
                !enableState[idx] ||
                giList[idx].giMaterialTypeDD === 'yarn' ||
                giList[idx].giMaterialTypeDD === ''
              }
              onChange={e => handleMIChange(e, idx, 'miUomFabricPriceYieldDD')}
            >
              {Utils.sortObject(uomFabricPriceYieldDD)}
            </TextField>
            <TextField
              label="Main Fabric Garment Yield/UOM"
              variant="outlined"
              type="text"
              required={
                enableState[idx] &&
                (giList[idx].giMaterialTypeDD === 'knit' ||
                  giList[idx].giMaterialTypeDD === 'woven')
              }
              name={`main-fabric-garment-yield-uom${idx}`}
              data-idx={idx}
              id={`main-fabric-garment-yield-uom${idx}`}
              className={!enableState[idx] && classes.disabled}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['miMainFabricGarmentYieldUOM']}
              disabled={
                !enableState[idx] ||
                giList[idx].giMaterialTypeDD === 'yarn' ||
                giList[idx].giMaterialTypeDD === ''
              }
              onChange={e =>
                handleMIChange(e, idx, 'miMainFabricGarmentYieldUOM')
              }
            />
            <br />
            <TextField
              label="Main Yarn Weight/Garment (Lbs/Dz)"
              variant="outlined"
              type="text"
              required={
                enableState[idx] && giList[idx].giMaterialTypeDD === 'yarn'
              }
              name={`main-yarn-weight-lbs-dz${idx}`}
              data-idx={idx}
              id={`main-yarn-weight-lbs-dz-${idx}`}
              className={!enableState[idx] && classes.disabled}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['miMainYarnWeightLbsDz']}
              disabled={
                !enableState[idx] ||
                giList[idx].giMaterialTypeDD === 'knit' ||
                giList[idx].giMaterialTypeDD === 'woven' ||
                giList[idx].giMaterialTypeDD === ''
              }
              onChange={e => handleMIChange(e, idx, 'miMainYarnWeightLbsDz')}
            />
            <br />
            <TextField
              label="Main Yarn Weight/Garment (Lbs/Gmt)"
              variant="outlined"
              type="number"
              name={`main-yarn-weight-lbs-gmt-${idx}`}
              data-idx={idx}
              id={`main-yarn-weight-lbs-gmt-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['miMainYarnWeightLbsGmt']}
              disabled={true}
              InputProps={{ readOnly: true }}
            />
            <br />
          </div>
          <br />
        </div>
      ))}
    </Grid>
  );
};

export default MaterialInformation;
