import { skuValidationRules } from '../../validationRules';
import { buildValidationSetter } from '../columnDefsShared';
import { skuTooltipValueGetter, skuCellClassRules } from './utils';

export function skuLiveDate(ctx) {
  return {
    headerName: 'Live Date',
    cellRenderer: data => {
      return data.value ? new Date(data.value).toLocaleDateString() : '';
    },
    field: 'liveDate.value',
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    tooltipComponent: 'tooltip',
    tooltipValueGetter: skuTooltipValueGetter('liveDate', ctx),
    cellClassRules: skuCellClassRules('liveDate', ctx),
    valueSetter: buildValidationSetter(ctx, {
      validationRules: skuValidationRules
    })
  };
}
