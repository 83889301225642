import React, { Component } from 'react';
import ReactDOM from 'react-dom';

export class ImageViewer extends Component {
  state = {
    visible: false,
    position: {
      top: 0,
      left: 0
    }
  };

  showTimerId = null;

  componentWillUnmount() {
    if (this.showTimerId) {
      window.clearTimeout(this.showTimerId);
    }
  }

  showImageViewer = e => {
    e.persist();
    const delay = 750;

    this.showTimerId = setTimeout(() => {
      this.setState({
        visible: true,
        position: { top: e.clientY, left: e.clientX }
      });
    }, delay);
  };

  hideImageViewer = () => {
    if (this.showTimerId) {
      window.clearTimeout(this.showTimerId);
    }
    this.setState({ visible: false });
  };

  render() {
    const { visible, position } = this.state;
    const { imageIconUrl, imageThumbnailUrl } = this.props.data;

    const modal =
      visible && imageThumbnailUrl.value ? (
        <img
          className="image-viewer__thumb"
          src={imageThumbnailUrl.value}
          style={{
            top: position.top,
            left: position.left
          }}
          alt=""
        />
      ) : null;

    return (
      <div
        className="image-viewer"
        onMouseEnter={this.showImageViewer}
        onMouseLeave={this.hideImageViewer}
      >
        <img className="image-viewer__icon" src={imageIconUrl.value} alt="" />
        {ReactDOM.createPortal(modal, document.body)}
      </div>
    );
  }
}

export default ImageViewer;
