import { AgGridUtils } from 'utils';
import { skuValidationRules } from '../../validationRules';
import { buildValidationSetter } from '../columnDefsShared';

export function skuPoDue(ctx) {
  return {
    // TODO: Edit cell should be date picker
    headerName: 'PO Due',
    // TODO: no such field in data structure. Fix it, put editable to false in the meantime
    field: 'poDue.value',
    editable: false,
    cellRenderer: data => {
      return data.value ? new Date(data.value).toLocaleDateString() : '';
    },
    filter: 'agDateColumnFilter',
    suppressMenu: true,
    filterParams: {
      comparator: AgGridUtils.comparatorHelper,
      browserDatePicker: true
    },
    valueSetter: buildValidationSetter(ctx, {
      validationRules: skuValidationRules
    })
  };
}
