export function ccNumberOfIssues() {
  return {
    headerName: '# of Issues',
    field: 'numberOfIssues',
    filter: 'agNumberColumnFilter',
    headerClass: 'ag-Table__header MuiTypography-body2',
    suppressMenu: true,
    editable: false,
    aggFunc: 'sum',
    //TODO: Create means for re-use across multiple cells (constants?)
    cellClassRules: {
      hardDanger: function(params) {
        return params.value >= 1;
      }
    }
  };
}
