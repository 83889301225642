export function skuStatus() {
  return {
    headerName: 'Status',
    field: 'status.value',
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    editable: false,
    cellRenderer: data => {
      // TODO: This hard coded value will need to be removed. For user scenerio testing only.
      return 'Setup';
    }
  };
}
