import {
  CHANNELS_LIST,
  getChannelValue,
  setChannelValue
} from '../columnDefsShared';
import { skuTooltipValueGetter, skuCellClassRules } from './utils';

export function skuChannels(ctx) {
  return {
    headerName: 'Channels',
    colId: 'channels',
    filter: 'agTextColumnFilter',
    columnGroupShow: 'open',
    suppressMenu: true,
    valueGetter: params => {
      let nDirect = params.data.nDirect.value.toLowerCase() === 'y';
      let fls = params.data.fls.value.toLowerCase() === 'y';
      return getChannelValue(nDirect, fls);
    },
    // TODO: validation setter for channels
    valueSetter: params => {
      setChannelValue(params.data, params.newValue);
      ctx.gridApi.forEachNode(node => {
        let result = node.data.skus.find(
          sku => sku.pieId === params.data.pieId
        );
        if (result) {
          ctx.gridApi.refreshCells({
            rowNodes: [node],
            columns: [`channels`],
            force: true
          });
        }
      });
    },
    cellEditor: 'agRichSelectCellEditor',
    cellEditorParams: { values: CHANNELS_LIST },
    tooltipComponent: 'tooltip',
    // TODO: channels should check FLS as well as nDirect
    tooltipValueGetter: skuTooltipValueGetter('nDirect', ctx),
    cellClassRules: skuCellClassRules('nDirect', ctx)
  };
}
