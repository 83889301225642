import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Utils } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch'
    },
    '& .MuiInputBase-multiline': {
      minHeight: '8ch',
      width: '40ch',
      height: 'auto'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}));

const PackagingDetails = ({ ownerState, handleOwnerChange }) => {
  const classes = useStyles();

  var readOnly = false;
  if (ownerState.materialEditable === 'false') {
    readOnly = true;
  }

  const materialVersion = ownerState.materialVersion;

  const classification = ownerState.pkgClassificationDD.option;

  let construction;

  if (materialVersion && materialVersion !== 'V1') {
    construction = ownerState.pkgConstructionDD.option;
  }

  const printMethod = ownerState.pkgPrintMethodDD.option;

  const material = ownerState.pkgMaterialDD;
  const materialKeys = Object.keys(material);

  const sustainable = ownerState.pkgSustainableDD;
  const sustainableKeys = Object.keys(sustainable);

  const extrasMisc = ownerState.pkgExtrasMiscDD;
  const extrasMiscKeys = Object.keys(extrasMisc);

  const finish = ownerState.pkgFinishDD;
  const finishKeys = Object.keys(finish);

  return (
    <>
      <Grid container direction="row" justify="space-between" id="hide-grid">
        <Grid container justify="space-between" id="show-grid">
          {/* Details */}
          <Grid item>
            <Grid container direction="column" justify="space-between">
              <Grid item>
                <Typography variant="h6">
                  Article #: {ownerState.pkgArticleNum}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  Material Status: {ownerState.pkgMaterialStatus}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  Label Brand: {ownerState.pkgLabelBrand}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  Packaging Type: {ownerState.pkgPackagingType}
                </Typography>
              </Grid>
              <Grid item>
                {materialVersion && materialVersion !== 'V1' ? (
                  <Typography variant="h6">
                    Division: {ownerState.pkgDivision}
                  </Typography>
                ) : (
                  <Typography variant="h6">
                    Construction: {ownerState.pkgConstruction}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                {materialVersion && materialVersion !== 'V1' ? (
                  <Typography variant="h6">
                    Product Category: {ownerState.pkgProductCategory}
                  </Typography>
                ) : (
                  <Typography variant="h6">
                    Department: {ownerState.pkgDepartment}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>

          {/* single select dropdowns */}
          <Grid item>
            <Grid container direction="column" justify="space-between">
              {materialVersion && materialVersion !== 'V1' && (
                <Grid item>
                  <TextField
                    required
                    label="Construction"
                    variant="outlined"
                    select
                    name="pkgConstruction"
                    id="constructionDD"
                    disabled={readOnly}
                    InputProps={{
                      readOnly: readOnly
                    }}
                    value={ownerState.pkgConstruction}
                    onChange={handleOwnerChange}
                    style={{ width: 250, paddingBottom: 10 }}
                  >
                    {Utils.sortObject(construction)}
                  </TextField>
                </Grid>
              )}
              <Grid item>
                <TextField
                  required
                  label="Classification"
                  variant="outlined"
                  select
                  name="pkgClassification"
                  id="classificationDD"
                  disabled={readOnly}
                  InputProps={{
                    readOnly: readOnly
                  }}
                  value={ownerState.pkgClassification}
                  onChange={handleOwnerChange}
                  style={{ width: 250 }}
                >
                  {Utils.sortObject(classification)}
                </TextField>
              </Grid>
              <Grid item>
                <TextField
                  required
                  label="Weight (gsm)"
                  variant="outlined"
                  name="pkgWeight"
                  disabled={readOnly}
                  inputProps={{ maxLength: 50 }}
                  InputProps={{ readOnly: readOnly }}
                  value={ownerState.pkgWeight}
                  onChange={handleOwnerChange}
                  style={{ width: 250, marginTop: 8 }}
                />
              </Grid>
              <Grid item>
                <TextField
                  required
                  label="Thickness (pts)"
                  variant="outlined"
                  name="pkgThickness"
                  disabled={readOnly}
                  inputProps={{ maxLength: 50 }}
                  InputProps={{ readOnly: readOnly }}
                  value={ownerState.pkgThickness}
                  onChange={handleOwnerChange}
                  style={{ width: 250, marginTop: 8 }}
                />
              </Grid>
              <Grid item>
                <TextField
                  required
                  label="Print Method"
                  variant="outlined"
                  select
                  name="pkgPrintMethod"
                  id="printMethod"
                  disabled={readOnly}
                  InputProps={{
                    readOnly: readOnly
                  }}
                  value={ownerState.pkgPrintMethod}
                  onChange={handleOwnerChange}
                  style={{ width: 250, marginTop: 8 }}
                >
                  {Utils.sortObject(printMethod)}
                </TextField>
              </Grid>
              <Grid item>
                <TextField
                  label="End Use"
                  variant="outlined"
                  name="pkgEndUse"
                  disabled={readOnly}
                  inputProps={{ maxLength: 40 }}
                  InputProps={{ readOnly: readOnly }}
                  value={ownerState.pkgEndUse}
                  onChange={handleOwnerChange}
                  style={{ width: 250, marginTop: 8 }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div className="page-break" />

        <Grid container justify="space-between" id="show-grid">
          {/* material dropdowns and comments */}
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <TextField
                  required
                  label="Material"
                  variant="outlined"
                  select
                  name="pkgMaterial"
                  id="pkgMaterialDD"
                  style={{ width: 400, marginTop: 8 }}
                  SelectProps={{
                    multiple: true,
                    renderValue: selected => (
                      <div className={classes.chips}>
                        {selected.map(value => (
                          <Chip
                            key={value}
                            label={material[value]}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )
                  }}
                  multiline={true}
                  disabled={readOnly}
                  InputProps={{
                    readOnly: readOnly
                  }}
                  value={ownerState.pkgMaterial}
                  onChange={handleOwnerChange}
                >
                  {materialKeys.map(option => (
                    <MenuItem key={option} value={option}>
                      {material[option]}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item>
                <TextField
                  label="Material Comments"
                  variant="outlined"
                  name="materialComments"
                  disabled={readOnly}
                  inputProps={{ maxLength: 4000 }}
                  InputProps={{ readOnly: readOnly }}
                  value={ownerState.materialComments}
                  multiline={true}
                  onChange={handleOwnerChange}
                  style={{ width: 400, marginTop: 8 }}
                  rows={10}
                  rowsMax={Infinity}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* sustainable dropdowns and comments */}
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <TextField
                  required
                  label="Sustainable"
                  variant="outlined"
                  select
                  name="pkgSustainable"
                  id="labelMaterialDD"
                  style={{ width: 400, marginTop: 8 }}
                  SelectProps={{
                    multiple: true,
                    renderValue: selected => (
                      <div className={classes.chips}>
                        {selected.map(value => (
                          <Chip
                            key={value}
                            label={sustainable[value]}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )
                  }}
                  multiline={true}
                  disabled={readOnly}
                  InputProps={{
                    readOnly: readOnly
                  }}
                  value={ownerState.pkgSustainable}
                  onChange={handleOwnerChange}
                >
                  {sustainableKeys.map(option => (
                    <MenuItem key={option} value={option}>
                      {sustainable[option]}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item>
                <TextField
                  label="Sustainable Comments"
                  variant="outlined"
                  name="pkgSustainableComments"
                  disabled={readOnly}
                  inputProps={{ maxLength: 100 }}
                  InputProps={{ readOnly: readOnly }}
                  value={ownerState.pkgSustainableComments}
                  multiline={true}
                  onChange={handleOwnerChange}
                  style={{ width: 400, marginTop: 8 }}
                  rows={10}
                  rowsMax={Infinity}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <TextField
                  required
                  label="Finish"
                  variant="outlined"
                  select
                  name="pkgFinish"
                  id="pkgExtrasMisc"
                  style={{ width: 400, marginTop: 8 }}
                  SelectProps={{
                    multiple: true,
                    renderValue: selected => (
                      <div className={classes.chips}>
                        {selected.map(value => (
                          <Chip
                            key={value}
                            label={finish[value]}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )
                  }}
                  multiline={true}
                  disabled={readOnly}
                  InputProps={{
                    readOnly: readOnly
                  }}
                  value={ownerState.pkgFinish}
                  onChange={handleOwnerChange}
                >
                  {finishKeys.map(option => (
                    <MenuItem key={option} value={option}>
                      {finish[option]}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item>
                <TextField
                  required
                  label="Extras/Misc."
                  variant="outlined"
                  select
                  name="pkgExtrasMisc"
                  id="pkgExtrasMisc"
                  style={{ width: 400, marginTop: 8 }}
                  SelectProps={{
                    multiple: true,
                    renderValue: selected => (
                      <div className={classes.chips}>
                        {selected.map(value => (
                          <Chip
                            key={value}
                            label={extrasMisc[value]}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )
                  }}
                  multiline={true}
                  disabled={readOnly}
                  InputProps={{
                    readOnly: readOnly
                  }}
                  value={ownerState.pkgExtrasMisc}
                  onChange={handleOwnerChange}
                >
                  {extrasMiscKeys.map(option => (
                    <MenuItem key={option} value={option}>
                      {extrasMisc[option]}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className="page-break" />
    </>
  );
};

export default PackagingDetails;
