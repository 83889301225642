import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, TextField } from '@material-ui/core';
import { Utils } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },
  file: {
    display: 'none'
  }
}));

const ContentFields = ({
  idx,
  ownerState,
  contentState,
  handleContentChange,
  contentError
}) => {
  const classes = useStyles();

  var readOnly = false;
  if (ownerState.materialEditable === 'false') {
    readOnly = true;
  }

  const contentN = `content-${idx}`;
  const percentageN = `percentage-${idx}`;

  const content = ownerState.contentDD;

  var required = false;
  if (idx === 0) required = true;

  var error = false;

  return (
    <div key={`cat-${idx}`} className={classes.root}>
      <TextField
        label="Content"
        variant="outlined"
        select
        required={required}
        name={contentN}
        data-idx={idx}
        id={contentN}
        className="content"
        disabled={readOnly}
        InputProps={{
          readOnly: readOnly
        }}
        value={contentState[idx].content}
        onChange={e => handleContentChange(e, idx, 'content')}
      >
        {Utils.sortObject(content)}
      </TextField>
      <TextField
        error={error || contentError}
        label="Percentage"
        variant="outlined"
        type="text"
        name={percentageN}
        data-idx={idx}
        data-cy={percentageN}
        id={percentageN}
        className="percentage"
        disabled={readOnly}
        InputProps={{
          readOnly: readOnly
        }}
        value={contentState[idx].percentage}
        onBlur={e => handleContentChange(e, idx, 'percentage')}
        onChange={e => handleContentChange(e, idx, 'percentage')}
      />
      <br />
    </div>
  );
};

ContentFields.propTypes = {
  idx: PropTypes.number,
  ownerState: PropTypes.object,
  contentState: PropTypes.array,
  handleContentChange: PropTypes.func
};

export default ContentFields;
