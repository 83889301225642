import {
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Checkbox
} from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'stylesheets/GeneralInformation.scss';
import { Utils } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch'
    },
    '& .MuiFormGroup-root': {
      margin: theme.spacing(1),
      width: '30ch'
    },
    '& .MuiInputBase-multiline': {
      minHeight: '8ch',
      width: '40ch',
      height: 'auto'
    }
  },
  disabled: {
    background: '#A8A8A8',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch'
    },
    '& .MuiFormGroup-root': {
      margin: theme.spacing(1),
      width: '30ch'
    },
    '& .MuiInputBase-multiline': {
      minHeight: '8ch',
      width: '40ch',
      height: 'auto'
    }
  },
  input: {
    fontStyle: 'italic'
  },
  label: {
    fontStyle: 'italic'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}));

const GeneralInformation = props => {
  const classes = useStyles();

  const giList = props.giList;
  const garmentCODD = props.giGarmentCODD;
  const productCategoryDD = props.giProductCategoryDD;
  const materialTypeDD = props.giMaterialTypeDD;
  const formType = props.formType;
  const handleGIChange = props.handleGIChange;
  const handleGICheckBoxChange = props.handleGICheckBoxChange;

  const enableState = props.enableState;

  const costSheetVersion = props.costSheetVersion;

  return (
    <Grid>
      <div className={classes.root}>
        {giList.map((val, idx) => (
          <div key={`gi-${idx}`} className="column">
            <hr />
            <div className="gi-header">General Information</div>
            <hr />
            <TextField
              label="Quote Comment"
              variant="outlined"
              type="text"
              inputProps={{ maxLength: 2000 }}
              name={`quoteComment-${idx}`}
              data-idx={idx}
              id={`quoteComment-${idx}`}
              className={!enableState[idx] && classes.disabled}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['giQuoteComment'] || ''}
              disabled={!enableState[idx]}
              onChange={e => handleGIChange(e, idx, 'giQuoteComment')}
            />
            <TextField
              label="Quote Submitted By"
              variant="outlined"
              type="text"
              inputProps={{ maxLength: 2000 }}
              name={`quoteSubmittedBy-${idx}`}
              data-idx={idx}
              id={`quoteSubmittedBy-${idx}`}
              className={!enableState[idx] && classes.disabled}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['giQuoteSubmittedBy'] || ''}
              disabled={!enableState[idx]}
              onChange={e => handleGIChange(e, idx, 'giQuoteSubmittedBy')}
            />
            <TextField
              label="Manufacturer"
              variant="outlined"
              type="text"
              inputProps={{ maxLength: 40 }}
              name={`manufacturer-${idx}`}
              data-idx={idx}
              id={`manufacturer-${idx}`}
              className={!enableState[idx] && classes.disabled}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['giManufacturer'] || ''}
              disabled={!enableState[idx]}
              onChange={e => handleGIChange(e, idx, 'giManufacturer')}
            />
            <TextField
              label="Factory"
              variant="outlined"
              type="text"
              inputProps={{ maxLength: 40 }}
              required={enableState[idx]}
              name={`factory-${idx}`}
              data-idx={idx}
              id={`factory-${idx}`}
              className={!enableState[idx] && classes.disabled}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['giFactory'] || ''}
              disabled={!enableState[idx]}
              onChange={e => handleGIChange(e, idx, 'giFactory')}
            />
            <div className="page-break" />
            <TextField
              label="Garment C/O"
              variant="outlined"
              select
              required={enableState[idx]}
              name={`garment-co-${idx}`}
              data-idx={idx}
              id={`garment-co-${idx}`}
              className={!enableState[idx] && classes.disabled}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['giGarmentCODD'] || ''}
              disabled={!enableState[idx]}
              onChange={e => handleGIChange(e, idx, 'giGarmentCODD')}
            >
              {Utils.sortObject(garmentCODD)}
            </TextField>
            <FormGroup>
              <div>LDP Program</div>
              <div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={val.giLDPProgram === '1'}
                      onChange={e => {
                        handleGICheckBoxChange(e, idx, 'giLDPProgram');
                      }}
                      name="checkedUS"
                      color="primary"
                      disabled={!enableState[idx]}
                    />
                  }
                  label="US"
                />
              </div>
              {costSheetVersion === 'V3' && (
                <div>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={val.giLDPProgramCA === '1'}
                        onChange={e => {
                          handleGICheckBoxChange(e, idx, 'giLDPProgramCA');
                        }}
                        name="checkedCA"
                        color="primary"
                        disabled={!enableState[idx]}
                      />
                    }
                    label="CA"
                  />
                </div>
              )}
            </FormGroup>
            <TextField
              label="Production Cut Min Order"
              variant="outlined"
              type="text"
              required={enableState[idx]}
              name={`production-cut-min-order-${idx}`}
              data-idx={idx}
              id={`production-cut-min-order-${idx}`}
              className={!enableState[idx] && classes.disabled}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['giProdCutMinOrder'] || ''}
              disabled={!enableState[idx]}
              onChange={e => handleGIChange(e, idx, 'giProdCutMinOrder')}
            />
            <TextField
              label="Production Cut Min Color"
              variant="outlined"
              type="text"
              required={enableState[idx]}
              name={`production-cut-min-color-${idx}`}
              data-idx={idx}
              id={`production-cut-min-color-${idx}`}
              className={!enableState[idx] && classes.disabled}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['giProdCutMinColor'] || ''}
              disabled={!enableState[idx]}
              onChange={e => handleGIChange(e, idx, 'giProdCutMinColor')}
            />
            <TextField
              label="Production Cut Min Size"
              variant="outlined"
              type="text"
              name={`production-cut-min-size-${idx}`}
              data-idx={idx}
              id={`production-cut-min-size-${idx}`}
              className={!enableState[idx] && classes.disabled}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['giProdCutMinSize'] || ''}
              disabled={!enableState[idx]}
              onChange={e => handleGIChange(e, idx, 'giProdCutMinSize')}
            />
            {costSheetVersion === 'V1' && (
              <TextField
                label="Units based on this Quote"
                variant="outlined"
                type="text"
                inputProps={{ maxLength: 2000 }}
                name={`units-based-on-this-quote-${idx}`}
                data-idx={idx}
                id={`units-based-on-this-quote-${idx}`}
                className={!enableState[idx] && classes.disabled}
                InputProps={!enableState[idx] && { className: classes.input }}
                InputLabelProps={
                  !enableState[idx] && { className: classes.label }
                }
                value={val['giUnits']}
                disabled={!enableState[idx]}
                onChange={e => handleGIChange(e, idx, 'giUnits')}
              />
            )}
            {(costSheetVersion === 'V3' ||
              costSheetVersion === 'V2' ||
              costSheetVersion === 'V4' ||
              costSheetVersion === 'V5' ||
              costSheetVersion === 'V6') && (
              <TextField
                label="Non-RP Units based on this Quote"
                variant="outlined"
                type="text"
                inputProps={{ maxLength: 2000 }}
                name={`non-rp-units-based-on-this-quote-${idx}`}
                data-idx={idx}
                id={`non-rp-units-based-on-this-quote-${idx}`}
                className={!enableState[idx] && classes.disabled}
                InputProps={!enableState[idx] && { className: classes.input }}
                InputLabelProps={
                  !enableState[idx] && { className: classes.label }
                }
                value={val['giUnits'] || ''}
                disabled={!enableState[idx]}
                onChange={e => handleGIChange(e, idx, 'giUnits')}
              />
            )}
            {(costSheetVersion === 'V3' ||
              costSheetVersion === 'V4' ||
              costSheetVersion === 'V5' ||
              costSheetVersion === 'V6') && (
              <TextField
                label="RP Units based on this Quote"
                variant="outlined"
                type="text"
                inputProps={{ maxLength: 2000 }}
                name={`rp-units-based-on-this-quote-${idx}`}
                data-idx={idx}
                id={`rp-units-based-on-this-quote-${idx}`}
                className={!enableState[idx] && classes.disabled}
                InputProps={!enableState[idx] && { className: classes.input }}
                InputLabelProps={
                  !enableState[idx] && { className: classes.label }
                }
                value={val['giReplenishmentUnits'] || ''}
                disabled={!enableState[idx]}
                onChange={e => handleGIChange(e, idx, 'giReplenishmentUnits')}
              />
            )}
            {(costSheetVersion === 'V5' || costSheetVersion === 'V6') && (
              <TextField
                label="Duty %"
                variant="outlined"
                type="text"
                inputProps={{ maxLength: 2000 }}
                name={`rp-units-based-on-this-quote-${idx}`}
                data-idx={idx}
                id={`rp-units-based-on-this-quote-${idx}`}
                className={!enableState[idx] && classes.disabled}
                InputProps={!enableState[idx] && { className: classes.input }}
                InputLabelProps={
                  !enableState[idx] && { className: classes.label }
                }
                value={val['giDutyPct'] || ''}
                disabled={!enableState[idx] || val.giLDPProgram === '1'}
                onChange={e => handleGIChange(e, idx, 'giDutyPct')}
              />
            )}
            {(costSheetVersion === 'V5' || costSheetVersion === 'V6') && (
              <TextField
                label="Additional Tariff %"
                variant="outlined"
                type="text"
                inputProps={{ maxLength: 2000 }}
                name={`rp-units-based-on-this-quote-${idx}`}
                data-idx={idx}
                id={`rp-units-based-on-this-quote-${idx}`}
                className={!enableState[idx] && classes.disabled}
                InputProps={!enableState[idx] && { className: classes.input }}
                InputLabelProps={
                  !enableState[idx] && { className: classes.label }
                }
                value={val['giAdditionalTariffPct'] || ''}
                disabled={!enableState[idx] || val.giLDPProgram === '1'}
                onChange={e => handleGIChange(e, idx, 'giAdditionalTariffPct')}
              />
            )}
            {costSheetVersion === 'V6' && formType === 'Simplified' && (
              <TextField
                label="Product Category"
                variant="outlined"
                select
                required={enableState[idx]}
                name={`product-category-${idx}`}
                data-idx={idx}
                id={`product-category-${idx}`}
                className={!enableState[idx] && classes.disabled}
                InputProps={!enableState[idx] && { className: classes.input }}
                InputLabelProps={
                  !enableState[idx] && { className: classes.label }
                }
                value={val['giProductCategoryDD'] || ''}
                disabled={!enableState[idx]}
                onChange={e => handleGIChange(e, idx, 'giProductCategoryDD')}
              >
                {Utils.sortObject(productCategoryDD)}
              </TextField>
            )}
            {costSheetVersion === 'V6' && formType === 'Simplified' && (
              <TextField
                label="Material Type"
                variant="outlined"
                select
                required={
                  enableState[idx] && val.giProductCategoryDD === 'apparel'
                }
                name={`material-type-${idx}`}
                data-idx={idx}
                id={`material-type-${idx}`}
                className={!enableState[idx] && classes.disabled}
                InputProps={!enableState[idx] && { className: classes.input }}
                InputLabelProps={
                  !enableState[idx] && { className: classes.label }
                }
                value={val['giMaterialTypeDD'] || ''}
                disabled={
                  !enableState[idx] || val.giProductCategoryDD === 'nonApparel'
                }
                onChange={e => handleGIChange(e, idx, 'giMaterialTypeDD')}
              >
                {Utils.sortObject(materialTypeDD)}
              </TextField>
            )}
          </div>
        ))}
      </div>
    </Grid>
  );
};

export default GeneralInformation;
