import {
  buildCcAggregatedCellClassRules,
  ccNumericGetter,
  ccRollupFromSkuTooltipValueGetter
} from './utils';

export function ccMarkupPercentage(ctx) {
  return {
    headerName: 'Markup (%)',
    colId: 'markupPercentage',
    filter: 'agTextColumnFilter',
    headerClass: 'ag-Table__header MuiTypography-body2',
    columnGroupShow: 'open',
    cellClassRules: buildCcAggregatedCellClassRules('markupPercentage', ctx),
    valueGetter: ccNumericGetter('markupPercentage', 'Percent'),
    tooltipComponent: 'tooltip',
    tooltipValueGetter: ccRollupFromSkuTooltipValueGetter(
      'markupPercentage',
      ctx
    ),
    editable: false,
    suppressMenu: true
  };
}
