export function ccTotalSkus() {
  return {
    headerName: '# of SKUs',
    field: 'totalSkus',
    filter: 'agTextColumnFilter',
    headerClass: 'ag-Table__header MuiTypography-body2',
    columnGroupShow: 'open',
    suppressMenu: true,
    editable: false
  };
}
