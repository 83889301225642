import { ccValidationRules } from '../../validationRules';
import { buildValidationSetter } from '../columnDefsShared';
import { ccCellClassRules, ccTooltipValueGetter } from './utils';

export function ccDepartment(ctx) {
  return {
    headerName: 'Department',
    field: 'department.value',
    filter: 'agTextColumnFilter',
    headerClass: 'ag-Table__header MuiTypography-body2',
    suppressMenu: true,
    cellEditor: 'agRichSelectCellEditor',
    // AG Bug Fix: Wrapping key/value pair within function to deal with AG defect
    // which unsorts our column order when colId is assigned to any given attribute.
    cellEditorParams: function() {
      const values = ctx.props.departmentsIsLoading
        ? ['Retrieving values...']
        : ctx.props.departments
            .map(d => d.departmentId)
            .sort(function(a, b) {
              return a - b;
            });
      return { values: values };
    },
    tooltipComponent: 'tooltip',
    tooltipValueGetter: ccTooltipValueGetter('department', ctx),
    cellClassRules: ccCellClassRules('department', ctx),
    valueSetter: buildValidationSetter(ctx, {
      validationRules: ccValidationRules
    })
  };
}
