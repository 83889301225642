import React from 'react';

import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Utils } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch'
    },
    '& .MuiInputBase-multiline': {
      minHeight: '8ch',
      width: '40ch',
      height: 'auto'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}));

const LabelDetails = ({ ownerState, handleOwnerChange }) => {
  const classes = useStyles();

  const materialObj = ownerState;

  var readOnly = false;
  if (ownerState.materialEditable === 'false') {
    readOnly = true;
  }

  const materialVersion = materialObj.materialVersion;

  const classification = materialObj.labelClassificationDD;
  const construction = materialObj.labelConstructionDD;

  const denier = materialObj.labelDenierDD;

  const weave = materialObj.labelWeaveDD;

  const finish = materialObj.labelFinishDD;

  const material = materialObj.labelMaterialDD;
  const materialKeys = Object.keys(material);

  const sustainable = materialObj.labelSustainableDD;
  const sustainableKeys = Object.keys(sustainable);

  const extrasMisc = materialObj.labelExtrasMiscDD;
  const extrasMiscKeys = Object.keys(extrasMisc);

  return (
    <>
      <Grid container direction="row" justify="space-between" id="hide-grid">
        <Grid container justify="space-between" id="show-grid">
          {/* Left side of details */}
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <Typography variant="h6">
                  Article #: {materialObj.labelArticleNum}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  Material Status: {materialObj.labelMaterialStatus}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  Label Brand: {materialObj.labelLabelBrand}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h6">
                  Label Type: {materialObj.labelLabelType}
                </Typography>
              </Grid>
              <Grid item>
                {materialVersion && materialVersion !== 'V1' ? (
                  <Typography variant="h6">
                    Division: {ownerState.labelDivision}
                  </Typography>
                ) : (
                  <Typography variant="h6">
                    Construction: {materialObj.labelConstruction}
                  </Typography>
                )}
              </Grid>
              <Grid item>
                {materialVersion && materialVersion !== 'V1' ? (
                  <Typography variant="h6">
                    Product Category: {ownerState.labelProductCategory}
                  </Typography>
                ) : (
                  <Typography variant="h6">
                    Department: {materialObj.labelDepartment}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>

          {/* Right side of details */}
          <Grid item>
            <Grid container direction="column" spacing={1}>
              {materialVersion && materialVersion !== 'V1' && (
                <Grid item>
                  <TextField
                    required
                    label="Construction"
                    variant="outlined"
                    select
                    name="labelConstruction"
                    id="constructionDD"
                    disabled={readOnly}
                    InputProps={{
                      readOnly: readOnly
                    }}
                    value={ownerState.labelConstruction}
                    onChange={handleOwnerChange}
                    style={{ width: 250 }}
                  >
                    {Utils.sortObject(construction)}
                  </TextField>
                </Grid>
              )}
              <Grid item>
                <TextField
                  required
                  label="Classification"
                  variant="outlined"
                  select
                  name="labelClassification"
                  id="classificationDD"
                  disabled={readOnly}
                  InputProps={{
                    readOnly: readOnly
                  }}
                  value={ownerState.labelClassification}
                  onChange={handleOwnerChange}
                  style={{ width: 250 }}
                >
                  {Utils.sortObject(classification)}
                </TextField>
              </Grid>
              <Grid item>
                <TextField
                  required
                  label="Denier"
                  variant="outlined"
                  select
                  name="labelDenier"
                  id="classificationDD"
                  disabled={readOnly}
                  InputProps={{
                    readOnly: readOnly
                  }}
                  value={ownerState.labelDenier}
                  onChange={handleOwnerChange}
                  style={{ width: 250 }}
                >
                  {Utils.sortObject(denier)}
                </TextField>
              </Grid>
              <Grid item>
                <TextField
                  required
                  label="Weave"
                  variant="outlined"
                  select
                  name="labelWeave"
                  id="classificationDD"
                  disabled={readOnly}
                  InputProps={{
                    readOnly: readOnly
                  }}
                  value={ownerState.labelWeave}
                  onChange={handleOwnerChange}
                  style={{ width: 250 }}
                >
                  {Utils.sortObject(weave)}
                </TextField>
              </Grid>
              <Grid item>
                <TextField
                  required
                  label="Finish"
                  variant="outlined"
                  select
                  name="labelFinish"
                  id="classificationDD"
                  disabled={readOnly}
                  InputProps={{
                    readOnly: readOnly
                  }}
                  value={ownerState.labelFinish}
                  onChange={handleOwnerChange}
                  style={{ width: 250 }}
                >
                  {Utils.sortObject(finish)}
                </TextField>
              </Grid>
              <Grid item>
                <TextField
                  label="End Use"
                  variant="outlined"
                  name="labelEndUse"
                  id="labelEndUse"
                  disabled={readOnly}
                  inputProps={{ maxLength: 40 }}
                  InputProps={{ readOnly: readOnly }}
                  value={ownerState.labelEndUse}
                  onChange={handleOwnerChange}
                  style={{ width: 250 }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <div className="page-break" />

        <Grid container justify="space-between" id="show-grid">
          {/* Material multiselect and comments */}
          <Grid item>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <TextField
                  required
                  label="Material"
                  variant="outlined"
                  select
                  name="labelMaterials"
                  id="labelMaterialDD"
                  style={{ width: 400, marginTop: 8 }}
                  SelectProps={{
                    multiple: true,
                    renderValue: selected => (
                      <div className={classes.chips}>
                        {selected.map(value => (
                          <Chip
                            key={value}
                            label={material[value]}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )
                  }}
                  multiline={true}
                  disabled={readOnly}
                  InputProps={{
                    readOnly: readOnly
                  }}
                  value={ownerState.labelMaterials}
                  onChange={handleOwnerChange}
                >
                  {materialKeys.map(option => (
                    <MenuItem key={option} value={option}>
                      {material[option]}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item>
                <TextField
                  label="Material Comments"
                  variant="outlined"
                  name="materialComments"
                  disabled={readOnly}
                  inputProps={{ maxLength: 4000 }}
                  InputProps={{ readOnly: readOnly }}
                  value={ownerState.materialComments}
                  multiline={true}
                  onChange={handleOwnerChange}
                  style={{ width: 400, marginTop: 8 }}
                  rows={6}
                  rowsMax={Infinity}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* sustainable info */}
          <Grid item>
            <Grid container direction="column">
              <Grid item>
                <TextField
                  required
                  label="Sustainable"
                  variant="outlined"
                  select
                  name="labelSustainable"
                  id="labelMaterialDD"
                  style={{ width: 400, marginTop: 8 }}
                  SelectProps={{
                    multiple: true,
                    renderValue: selected => (
                      <div className={classes.chips}>
                        {selected.map(value => (
                          <Chip
                            key={value}
                            label={sustainable[value]}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )
                  }}
                  multiline={true}
                  disabled={readOnly}
                  InputProps={{
                    readOnly: readOnly
                  }}
                  value={ownerState.labelSustainable}
                  onChange={handleOwnerChange}
                >
                  {sustainableKeys.map(option => (
                    <MenuItem key={option} value={option}>
                      {sustainable[option]}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item>
                <TextField
                  label="Sustainable Comments"
                  variant="outlined"
                  name="labelSustainableComments"
                  disabled={readOnly}
                  inputProps={{ maxLength: 100 }}
                  InputProps={{ readOnly: readOnly }}
                  value={ownerState.labelSustainableComments}
                  multiline={true}
                  onChange={handleOwnerChange}
                  style={{ width: 400, marginTop: 8 }}
                  rows={6}
                  rowsMax={Infinity}
                />
              </Grid>
            </Grid>
          </Grid>

          {/* extras/misc */}
          <Grid item>
            <Grid container>
              <Grid item>
                <TextField
                  required
                  label="Extras/Misc."
                  variant="outlined"
                  select
                  name="labelExtrasMisc"
                  id="labelMaterialDD"
                  style={{ width: 400, marginTop: 8 }}
                  SelectProps={{
                    multiple: true,
                    renderValue: selected => (
                      <div className={classes.chips}>
                        {selected.map(value => (
                          <Chip
                            key={value}
                            label={extrasMisc[value]}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )
                  }}
                  multiline={true}
                  disabled={readOnly}
                  InputProps={{
                    readOnly: readOnly
                  }}
                  value={ownerState.labelExtrasMisc}
                  onChange={handleOwnerChange}
                >
                  {extrasMiscKeys.map(option => (
                    <MenuItem key={option} value={option}>
                      {extrasMisc[option]}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default LabelDetails;
