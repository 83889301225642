import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Utils } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '31ch',
      height: 'auto'
    },
    '& .MuiInputBase-multiline': {
      width: '45ch',
      height: 'auto'
    },
    '& .MuiFormHelperText-root': {
      width: '300px'
    }
  }
}));

const SupplierTypeL = ({ supplierState, handleSupplierChange }) => {
  const classes = useStyles();

  const sampleUom = supplierState.sampleUomDD;

  const bulkUom = supplierState.bulkUomDD;

  var cutWidth = 'Cuttable Width';

  if (supplierState.materialType === 'Leather') {
    cutWidth = 'Cuttable Width - Average Skin Size';
  }

  var millLabel = 'Mill';
  if (
    supplierState.materialType === 'Yarn' ||
    supplierState.materialType === 'Woven' ||
    supplierState.materialType === 'Knit'
  ) {
    millLabel = 'Mill (as known on business license)';
  }
  var millName = 'mill';
  var millValue = supplierState.mill;
  var millLength = 50;

  var spinnerLabel = 'Spinner';
  if (
    supplierState.materialType === 'Yarn' ||
    supplierState.materialType === 'Woven' ||
    supplierState.materialType === 'Knit'
  ) {
    spinnerLabel = 'Spinner (as known on business license)';
  }
  var spinnerName = 'spinner';
  var spinnerValue = supplierState.spinner;
  var spinnerLength = 40;

  // Countries
  var countries = supplierState.countryDD;
  var countriesLabel = 'Country of Origin';
  var countriesName = 'country';
  var countriesValue = supplierState.country;

  if (supplierState.materialType === 'Leather') {
    countries = supplierState.skinsDD;
    countriesLabel = 'Country of Origin of Skins';
    countriesName = 'skins';
    countriesValue = supplierState.skins;
  }

  var millCountries = supplierState.millCountryDD;
  var millCountriesLabel = 'Mill Country of Origin';
  var millCountriesName = 'millCountry';
  var millCountriesValue = supplierState.millCountry;

  var spinnerCountries = supplierState.spinnerCountryDD;
  var spinnerCountriesLabel = 'Spinner Country of Origin';
  var spinnerCountriesName = 'spinnerCountry';
  var spinnerCountriesValue = supplierState.spinnerCountry;

  return (
    <div>
      <div className={classes.root}>
        <TextField
          label="Article Name / #"
          variant="outlined"
          type="text"
          inputProps={{ maxLength: 50 }}
          name="articleName"
          id="articleName"
          required
          value={supplierState.articleName}
          onChange={handleSupplierChange}
        />
        <br />
        <TextField
          label={millLabel}
          variant="outlined"
          type="text"
          inputProps={{ maxLength: millLength }}
          name={millName}
          id={millName}
          required={supplierState.materialType !== 'Yarn'}
          value={millValue}
          onChange={handleSupplierChange}
        />
        {(supplierState.materialType === 'Yarn' ||
          supplierState.materialType === 'Woven' ||
          supplierState.materialType === 'Knit') && (
          <TextField
            label={spinnerLabel}
            variant="outlined"
            type="text"
            inputProps={{ maxLength: spinnerLength }}
            name={spinnerName}
            id={spinnerName}
            required={supplierState.materialType === 'Yarn'}
            value={spinnerValue}
            onChange={handleSupplierChange}
          />
        )}
        {supplierState.materialType === 'Yarn' ||
        supplierState.materialType === 'Woven' ||
        supplierState.materialType === 'Knit' ? (
          <div>
            <TextField
              label={millCountriesLabel}
              variant="outlined"
              select
              name={millCountriesName}
              id={millCountriesName}
              required={supplierState.materialType !== 'Yarn'}
              value={millCountriesValue}
              onChange={handleSupplierChange}
            >
              {Utils.sortObject(millCountries)}
            </TextField>

            <TextField
              label={spinnerCountriesLabel}
              variant="outlined"
              select
              name={spinnerCountriesName}
              id={spinnerCountriesName}
              required={supplierState.materialType === 'Yarn'}
              value={spinnerCountriesValue}
              onChange={handleSupplierChange}
            >
              {Utils.sortObject(spinnerCountries)}
            </TextField>
          </div>
        ) : (
          <TextField
            label={countriesLabel}
            variant="outlined"
            select
            name={countriesName}
            id={countriesName}
            required
            value={countriesValue}
            onChange={handleSupplierChange}
          >
            {Utils.sortObject(countries)}
          </TextField>
        )}
        <div className="page-break" />
        {supplierState.materialType === 'Yarn' ||
        supplierState.materialType === 'Woven' ||
        supplierState.materialType === 'Knit' ? (
          <>
            <TextField
              label="Fiber Country of Origin Information"
              variant="outlined"
              type="text"
              multiline
              helperText="Required for any cotton fiber content"
              inputProps={{ maxLength: 4000 }}
              name="fiberCountryOfOriginInformation"
              id="fiberCountryOfOriginInformation"
              rows={10}
              rowsMax={Infinity}
              className={classes.root}
              value={supplierState.fiberCountryOfOriginInformation}
              onChange={handleSupplierChange}
            />
            <br />
          </>
        ) : (
          <br />
        )}
        <TextField
          label="Sample Price"
          variant="outlined"
          type="text"
          name="samplePrice"
          id="samplePrice"
          required
          onBlur={handleSupplierChange}
          value={supplierState.samplePrice}
          onChange={handleSupplierChange}
        />
        <TextField
          label="Sample UOM"
          variant="outlined"
          select
          name="sampleUom"
          id="sampleUom"
          required
          value={supplierState.sampleUom}
          onChange={handleSupplierChange}
        >
          {Utils.sortObject(sampleUom)}
        </TextField>
        <br />
        <TextField
          label="Sample Currency"
          variant="outlined"
          type="text"
          inputProps={{ maxLength: 20 }}
          name="sampleCurrency"
          id="sampleCurrency"
          required
          value={supplierState.sampleCurrency}
          onChange={handleSupplierChange}
        />
        <br />
        <TextField
          label="Initial Bulk Price"
          variant="outlined"
          type="text"
          name="bulkPrice"
          id="bulkPrice"
          required
          onBlur={handleSupplierChange}
          value={supplierState.bulkPrice}
          onChange={handleSupplierChange}
        />
        <TextField
          label="Bulk UOM"
          variant="outlined"
          select
          name="bulkUom"
          id="bulkUom"
          required
          value={supplierState.bulkUom}
          onChange={handleSupplierChange}
        >
          {Utils.sortObject(bulkUom)}
        </TextField>
        <br />
        <TextField
          label="Bulk Currency"
          variant="outlined"
          type="text"
          inputProps={{ maxLength: 20 }}
          name="bulkCurrency"
          id="bulkCurrency"
          required
          value={supplierState.bulkCurrency}
          onChange={handleSupplierChange}
        />
        <br />
        {supplierState.materialType !== 'Yarn' && (
          <div>
            <TextField
              label={cutWidth}
              variant="outlined"
              type="text"
              name="width"
              id="width"
              inputProps={{ maxLength: 2000 }}
              required
              value={supplierState.width}
              onChange={handleSupplierChange}
            />
            <br />
          </div>
        )}
        <div className="page-break" />
        <TextField
          label="Lowest Acceptable Bulk"
          variant="outlined"
          type="text"
          multiline
          inputProps={{ maxLength: 4000 }}
          name="lowestAcceptable"
          id="lowestAcceptable"
          rows={10}
          rowsMax={Infinity}
          className={classes.root}
          value={supplierState.lowestAcceptable}
          onChange={handleSupplierChange}
        />
      </div>
    </div>
  );
};

SupplierTypeL.propTypes = {
  supplierState: PropTypes.object,
  handleSupplierChange: PropTypes.func
};

export default SupplierTypeL;
