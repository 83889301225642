import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import { Provider } from 'react-redux';

import 'typeface-open-sans';
import 'index.scss';
import 'stylesheets/Globals.scss';
import 'stylesheets/Grid.scss';

import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';

import App from './App';
import ErrorBoundary from './components/shared/ErrorBoundary';
import * as serviceWorker from './serviceWorker';
import createStore from './createReduxStore';

import { LicenseManager } from '@ag-grid-enterprise/core';

LicenseManager.setLicenseKey(process.env.LICENSE_KEY);

const store = createStore;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#000000'
    },
    secondary: {
      main: '#5D1049'
    },
    shadows: 'none'
  },
  typography: {
    fontFamily: ['Open Sans', 'sans-serif'].join(',')
  },
  dense: {
    minHeight: 48
  }
});

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Router>
        <ErrorBoundary>
          <App />
        </ErrorBoundary>
      </Router>
    </Provider>
  </ThemeProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
