import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Utils } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
      height: 'auto'
    }
  },
  multi: {
    '& .MuiInputBase-multiline': {
      margin: theme.spacing(1),
      width: '45ch',
      height: 'auto'
    }
  }
}));

const SupplierLP = ({ supplierState, handleSupplierChange }) => {
  const classes = useStyles();

  const tieredPricingDD = supplierState.supplierTieredPricingDD;

  return (
    <>
      <Grid container justify="space-between" id="hide-grid">
        <Grid container justify="space-between" id="show-grid">
          <Grid item xs="auto">
            <div className={classes.root}>
              <TextField
                label="Supplier Reference #"
                variant="outlined"
                type="text"
                name="supplierReference"
                id="supplierReference"
                required
                inputProps={{ maxLength: 50 }}
                value={supplierState.supplierReference}
                onChange={handleSupplierChange}
              />
            </div>
            <div className={classes.multi}>
              <TextField
                label="Supplier Comments"
                variant="outlined"
                type="text"
                multiline={true}
                inputProps={{ maxLength: 500 }}
                name="supplierComments"
                id="supplierComments"
                rows={10}
                rowsMax={Infinity}
                helperText="Enter any comments for this material
              that are not specified otherwise on this form"
                value={supplierState.supplierComments}
                onChange={handleSupplierChange}
              />
            </div>
          </Grid>
          <Grid item xs="auto">
            <div className={classes.root}>
              <TextField
                label="Unit Price"
                variant="outlined"
                type="text"
                name="supplierUnitPrice"
                id="supplierUnitPrice"
                required
                value={supplierState.supplierUnitPrice}
                onBlur={handleSupplierChange}
                onChange={handleSupplierChange}
              />
              <br />
              <TextField
                label="Cost per Thousand"
                variant="outlined"
                type="text"
                name="supplierCostPerThousand"
                id="supplierCostPerThousand"
                required
                value={supplierState.supplierCostPerThousand}
                onBlur={handleSupplierChange}
                onChange={handleSupplierChange}
              />
              <br />
              <TextField
                label="Minimum Order/ SKU Quantity"
                variant="outlined"
                type="text"
                name="supplierMinOrderSkuQty"
                id="supplierMinOrderSkuQty"
                required
                inputProps={{ maxLength: 25 }}
                value={supplierState.supplierMinOrderSkuQty}
                onChange={handleSupplierChange}
              />
              <br />
              <TextField
                label="Lead Time in Days (Based on 7-Day Week)"
                variant="outlined"
                type="text"
                name="supplierLeadTimeInDays"
                id="supplierLeadTimeInDays"
                required
                value={supplierState.supplierLeadTimeInDays}
                onChange={handleSupplierChange}
              />
            </div>
          </Grid>
        </Grid>
        <div className="page-break" />
        <br className="print-show" />
        <Grid container justify="space-between" id="show-grid">
          <Grid item xs="auto">
            <div className={classes.root}>
              <TextField
                label="Tiered Pricing"
                variant="outlined"
                select
                name="supplierTieredPricing"
                id="supplierTieredPricing"
                required
                value={supplierState.supplierTieredPricing}
                onChange={handleSupplierChange}
              >
                {Utils.sortObject(tieredPricingDD)}
              </TextField>
            </div>
            <div className={classes.multi}>
              <TextField
                label="Yes - Tiered Pricing"
                variant="outlined"
                type="text"
                multiline={true}
                inputProps={{ maxLength: 200 }}
                name="supplierYesTieredPricing"
                id="supplierYesTieredPricing"
                rows={10}
                rowsMax={Infinity}
                value={supplierState.supplierYesTieredPricing}
                onChange={handleSupplierChange}
              />
            </div>
          </Grid>
          <Grid item xs="auto">
            <div className={classes.multi}>
              <TextField
                label="Inventory Pick and Pack Lead Time (7-Day Week)"
                variant="outlined"
                type="text"
                multiline={true}
                inputProps={{ maxLength: 4000 }}
                required
                name="supplierInventoryPickPackLeadTime"
                id="supplierInventoryPickPackLeadTime"
                rows={10}
                rowsMax={Infinity}
                value={supplierState.supplierInventoryPickPackLeadTime}
                onChange={handleSupplierChange}
              />
              <br />
              <TextField
                label="Inventory Pack Increments"
                variant="outlined"
                type="text"
                multiline={true}
                inputProps={{ maxLength: 200 }}
                name="supplierInventoryPackIncrements"
                id="supplierInventoryPackIncrements"
                rows={10}
                rowsMax={Infinity}
                value={supplierState.supplierInventoryPackIncrements}
                onChange={handleSupplierChange}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

SupplierLP.propTypes = {
  supplierState: PropTypes.object,
  handleSupplierChange: PropTypes.func
};

export default SupplierLP;
