import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

// Commented out to remove nag message, left in in case implementation is desired at a later date
// when re-implementing, import makeStyles from @material-ui/core
// const useStyles = makeStyles((theme) => ({
//     root: {
//         "& .MuiTextField-root": {
//             margin: theme.spacing(1),
//             width: "15ch",
//         },
//     },
//     file: {
//         display: "none",
//     },
// }));

const GarmentContent = ({
  idx,
  idx2,
  contentState,
  handleContentChange,
  enableState,
  className,
  InputProps,
  InputLabelProps
}) => {
  const ind = '' + idx + idx2;
  const index = parseInt(ind);

  const contentN = `content-${index}`;
  const percentageN = `percentage-${index}`;
  const poundsN = `pounds-${index}`;

  var required = false;

  var error = false;

  //   if (contentState[idx].content && errorState.hasSubmit)
  //     error = errorState.error;

  return (
    <div key={`cat-${idx}-${idx2}`}>
      <TextField
        label="Content"
        variant="outlined"
        type="text"
        inputProps={{ maxLength: 2000 }}
        required={required}
        name={contentN}
        data-idx={index}
        id={contentN}
        className={className}
        InputProps={InputProps}
        InputLabelProps={InputLabelProps}
        disabled={!enableState[idx]}
        value={contentState[idx][idx2].garmentContentDD}
        onChange={e => handleContentChange(e, idx, idx2, 'garmentContentDD')}
      />
      <TextField
        error={error}
        label="%"
        variant="outlined"
        type="text"
        name={percentageN}
        data-idx={index}
        id={percentageN}
        className={className}
        InputProps={InputProps}
        InputLabelProps={InputLabelProps}
        disabled={!enableState[idx]}
        value={contentState[idx][idx2].garmentContentPCT}
        onBlur={e => handleContentChange(e, idx, idx2, 'garmentContentPCT')}
        onChange={e => handleContentChange(e, idx, idx2, 'garmentContentPCT')}
      />
      <TextField
        error={error}
        label="lbs"
        variant="outlined"
        type="text"
        name={poundsN}
        data-idx={index}
        id={poundsN}
        className={className}
        InputProps={InputProps}
        InputLabelProps={InputLabelProps}
        disabled={true}
        value={contentState[idx][idx2].garmentContentLbs || ''}
      />
      <br />
    </div>
  );
};

GarmentContent.propTypes = {
  idx: PropTypes.number,
  idx2: PropTypes.number,
  contentState: PropTypes.array,
  handleContentChange: PropTypes.func
};

export default GarmentContent;
