import * as types from 'actiontypes/auth';
import { pending, fulfilled, rejected } from './utils';

export const initialState = {
  firstName: '',
  lastName: '',
  xid: '',
  accessToken: '',
  groups: [],
  expiresAt: null,
  error: null,
  isAuthenticated: false,
  isLoading: false,
  isRestoreSessionFailed: false
};

// action.* values are instantiated in action creator
// Reducer takes initial state + action & creates a new state
// Must be pure functions and not mutate state
export default function Auth(state = initialState, action) {
  switch (action.type) {
    case types.RESTORE_SESSION:
      if (!action.payload) {
        return {
          ...state,
          isRestoreSessionFailed: true
        };
      }
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true
      };

    case pending(types.REDIRECT_TO_LOGIN):
      return {
        ...state,
        error: null,
        isLoading: true
      }; // may not use isLoading for any of these cases

    case fulfilled(types.REDIRECT_TO_LOGIN):
      return {
        ...state,
        isLoading: false
      };

    case rejected(types.REDIRECT_TO_LOGIN):
      return {
        ...state,
        error: action.payload,
        isLoading: false
      }; // Still need to build out failure paths

    case pending(types.LOGIN):
      return {
        ...state,
        isLoading: true,
        error: null
      };

    case fulfilled(types.LOGIN):
      return {
        ...state,
        ...action.payload,
        isAuthenticated: true,
        isLoading: false
      };

    case rejected(types.LOGIN):
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };

    case pending(types.LOGOUT):
      return {
        ...state,
        error: null,
        isLoading: true
      };

    case rejected(types.LOGOUT):
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };

    default:
      return state;
  }
}

/** PRIVATE SELECTORS */
export const selectIsAuthenticated = state => state.isAuthenticated;
export const selectUserId = state => state.xid;
export const selectUserName = state => `${state.firstName} ${state.lastName}`;
export const selectAccessToken = state => state.accessToken;
export const selectError = state => state.error;
export const selectIsRestoreSessionFailed = state =>
  state.isRestoreSessionFailed;
