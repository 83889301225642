import React from 'react';

import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';

import 'stylesheets/CostingInformation.scss';
import { Utils } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch'
    },
    '& .MuiInputBase-multiline': {
      minHeight: '8ch',
      width: '40ch',
      height: 'auto'
    }
  },
  root2: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },
  disabled: {
    background: '#A8A8A8',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch'
    },
    '& .MuiInputBase-multiline': {
      minHeight: '8ch',
      width: '40ch',
      height: 'auto'
    }
  },
  disabled2: {
    background: '#A8A8A8',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}));

const SweaterCost = props => {
  const classes = useStyles();

  const currencyDD = {
    usd: 'USD',
    eur: 'EUR'
  };

  const handleSweaterChange = props.handleSweaterChange;

  const sweaterList = props.sweaterList;

  const enableState = props.enableState;

  const GarmentConstructionLOV = props.GarmentConstructionLOV;

  const costSheetVersion = props.costSheetVersion;

  return (
    <div className={classes.root}>
      <hr />
      {sweaterList.map((val, idx) => (
        <div key={`ci-${idx}`} className="column">
          <div className="ci-header">Sweater Costing Information</div>
          <hr />

          <TextField
            label="Currency Denomination"
            variant="outlined"
            select
            required={enableState[idx]}
            name={`currency-denomination-${idx}`}
            data-idx={idx}
            id={`currency-denomination-${idx}`}
            className={!enableState[idx] && classes.disabled}
            value={val['sweaterCurrency']}
            disabled={!enableState[idx]}
            onChange={e => handleSweaterChange(e, idx, 'sweaterCurrency')}
          >
            {Utils.sortObject(currencyDD)}
          </TextField>

          <br />

          <TextField
            label="Main Yarn Cost/Lb"
            variant="outlined"
            type="text"
            required={enableState[idx]}
            name={`main-yarn-price-${idx}`}
            data-idx={idx}
            id={`main-yarn-price-${idx}`}
            className={!enableState[idx] && classes.disabled}
            value={val['sweaterMainYarnCost']}
            disabled={!enableState[idx]}
            onBlur={e => handleSweaterChange(e, idx, 'sweaterMainYarnCost')}
            onChange={e => handleSweaterChange(e, idx, 'sweaterMainYarnCost')}
          />

          <br />

          <TextField
            label="Main Yarn Finance Charge"
            variant="outlined"
            type="text"
            required={enableState[idx]}
            name={`yarn-finance-${idx}`}
            data-idx={idx}
            id={`yarn-finance-${idx}`}
            className={!enableState[idx] && classes.disabled}
            value={val['sweaterMainFinCharge']}
            disabled={!enableState[idx]}
            onBlur={e => handleSweaterChange(e, idx, 'sweaterMainFinCharge')}
            onChange={e => handleSweaterChange(e, idx, 'sweaterMainFinCharge')}
          />

          <br />

          <TextField
            label="Fabric Finance Charge %"
            variant="outlined"
            type="number"
            name={`fabric-finance-pct-${idx}`}
            data-idx={idx}
            id={`fabric-finance-pct-${idx}`}
            className={!enableState[idx] && classes.disabled}
            value={val['sweaterFabricFinChargePct'] || ''}
            disabled={true}
            InputProps={{
              readOnly: true
            }}
          />

          <br />

          <TextField
            label="Main Yarn Weight/Gmt. (Lbs/Dz)"
            variant="outlined"
            type="text"
            name={`yarn-gmt-${idx}`}
            data-idx={idx}
            id={`yarn-gmt-${idx}`}
            className={!enableState[idx] && classes.disabled}
            value={val['sweaterMainYarnWt']}
            disabled={!enableState[idx]}
            required={enableState[idx]}
            onBlur={e => handleSweaterChange(e, idx, 'sweaterMainYarnWt')}
            onChange={e => handleSweaterChange(e, idx, 'sweaterMainYarnWt')}
          />

          <br />

          <TextField
            label="Main Yarn Weight/Gmt. (Lbs/Gmt)"
            variant="outlined"
            type="number"
            name={`yarn-gmt-wt-${idx}`}
            data-idx={idx}
            id={`yarn-gmt-wt-${idx}`}
            className={!enableState[idx] && classes.disabled}
            value={val['sweaterMainYarnWtGarment']}
            disabled={true}
            InputProps={{ readOnly: true }}
          />

          <br />

          <TextField
            label="Total Yarn Cost/DZ"
            variant="outlined"
            type="number"
            name={`yarn-total-cost-${idx}`}
            data-idx={idx}
            id={`yarn-total-cost-${idx}`}
            className={!enableState[idx] && classes.disabled}
            value={val['sweaterTotalYarnCostDz']}
            disabled={true}
            InputProps={{ readOnly: true }}
          />

          <br />

          <TextField
            label="Additional Yarn Total Cost/DZ"
            variant="outlined"
            type="text"
            name={`add-yarn-cost-${idx}`}
            data-idx={idx}
            id={`add-yarn-cost-${idx}`}
            className={!enableState[idx] && classes.disabled}
            value={val['sweaterAddnlYarnCostDz']}
            disabled={!enableState[idx]}
            onBlur={e => handleSweaterChange(e, idx, 'sweaterAddnlYarnCostDz')}
            onChange={e =>
              handleSweaterChange(e, idx, 'sweaterAddnlYarnCostDz')
            }
          />

          <br />

          <TextField
            label="Total Yarn Cost/Gmt."
            variant="outlined"
            type="number"
            name={`totoal-yarn-cost-${idx}`}
            data-idx={idx}
            id={`total-yarn-cost-${idx}`}
            className={!enableState[idx] && classes.disabled}
            value={val['sweaterTotalYarnCostGmt'] || ''}
            disabled={true}
            InputProps={{ readOnly: true }}
          />

          <br />

          <TextField
            label="Total Trims (Labels, Closures, Lining and Embellishments) and Testing"
            variant="outlined"
            type="text"
            required={enableState[idx]}
            name={`trims-emb-${idx}`}
            data-idx={idx}
            id={`trims-emb-${idx}`}
            className={!enableState[idx] && classes.disabled}
            value={val['sweaterTotalTrim']}
            disabled={!enableState[idx]}
            onBlur={e => handleSweaterChange(e, idx, 'sweaterTotalTrim')}
            onChange={e => handleSweaterChange(e, idx, 'sweaterTotalTrim')}
          />

          <br />

          <TextField
            label="Knitting/Linking (labor incl. O/H)"
            variant="outlined"
            type="text"
            required={enableState[idx]}
            name={`knit-labor-${idx}`}
            data-idx={idx}
            id={`knit-labor-${idx}`}
            className={!enableState[idx] && classes.disabled}
            value={val['sweaterKnitting']}
            disabled={!enableState[idx]}
            onBlur={e => handleSweaterChange(e, idx, 'sweaterKnitting')}
            onChange={e => handleSweaterChange(e, idx, 'sweaterKnitting')}
          />

          <br />

          <TextField
            label="Garment Construction"
            variant="outlined"
            select
            name={`gtm-const-${idx}`}
            data-idx={idx}
            id={`gmt-const-${idx}`}
            disabled={!enableState[idx]}
            value={val['sweaterGarmentConsDD']}
            className={!enableState[idx] && classes.disabled}
            onChange={e => handleSweaterChange(e, idx, 'sweaterGarmentConsDD')}
          >
            {Utils.sortObject(GarmentConstructionLOV)}
          </TextField>

          <br />

          <TextField
            label="Wet Processing/Finishing Cost"
            variant="outlined"
            type="text"
            name={`wet-proc-cost-${idx}`}
            data-idx={idx}
            id={`wet-proc-cost-${idx}`}
            className={!enableState[idx] && classes.disabled}
            value={val['sweaterWetProc']}
            disabled={!enableState[idx]}
            onBlur={e => handleSweaterChange(e, idx, 'sweaterWetProc')}
            onChange={e => handleSweaterChange(e, idx, 'sweaterWetProc')}
          />

          <br />

          <TextField
            label="Total Trim/Labor & Wet Proc Cost"
            name={`total-cost-${idx}`}
            data-idx={idx}
            className={!enableState[idx] && classes.disabled}
            value={val['sweaterTotalTrimLaborCost']}
            variant="outlined"
            disabled={true}
            InputProps={{ readOnly: true }}
          />

          <br />

          <TextField
            label="1st Cost (FOB/Package Cost)"
            variant="outlined"
            type="number"
            name={`first-cost-fob-${idx}`}
            data-idx={idx}
            id={`first-cost-fob-${idx}`}
            className={!enableState[idx] && classes.disabled}
            value={val['sweaterFirstCostFOB'] || ''}
            disabled={true}
            InputProps={{
              readOnly: true
            }}
          />

          <br />

          <TextField
            label="Import Cost for LDP Program"
            variant="outlined"
            type="text"
            name={`import-cost-${idx}`}
            data-idx={idx}
            id={`import-cost-${idx}`}
            className={!enableState[idx] && classes.disabled}
            value={val['sweaterImportCost']}
            disabled={!enableState[idx]}
            onBlur={e => handleSweaterChange(e, idx, 'sweaterImportCost')}
            onChange={e => handleSweaterChange(e, idx, 'sweaterImportCost')}
          />

          <br />

          <TextField
            label="1st Cost (LDP Cost)"
            variant="outlined"
            type="number"
            name={`first-cost-${idx}`}
            data-idx={idx}
            id={`first-cost-${idx}`}
            className={!enableState[idx] && classes.disabled}
            value={val['sweaterFirstCostLDP'] || ''}
            disabled={true}
            InputProps={{
              readOnly: true
            }}
          />

          <br />

          {costSheetVersion === 'V3' && (
            <TextField
              label="CA Import Cost for LDP Program"
              variant="outlined"
              type="text"
              name={`import-cost-ca-${idx}`}
              data-idx={idx}
              id={`import-cost-ca-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['sweaterImportCostCA']}
              disabled={!enableState[idx]}
              onBlur={e => handleSweaterChange(e, idx, 'sweaterImportCostCA')}
              onChange={e => handleSweaterChange(e, idx, 'sweaterImportCostCA')}
            />
          )}
          <br />
          {costSheetVersion === 'V3' && (
            <TextField
              label="CA 1st Cost (LDP Cost)"
              variant="outlined"
              type="number"
              name={`first-cost-ca-${idx}`}
              data-idx={idx}
              id={`first-cost-ca-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['sweaterFirstCostLDPCA'] || ''}
              disabled={true}
              InputProps={{
                readOnly: true
              }}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default SweaterCost;
