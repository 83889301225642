import { Grid } from '@material-ui/core';
import React from 'react';
import 'stylesheets/CostingInformation.scss';
import TextEntry from './TextComponents/TextEntry';
import TextDropdown from './TextComponents/TextDropdown';
import FirstCost from './FirstCost';

const YarnCost = props => {
  //attempting to change the way we create the fields to modularize and streamline the components
  //Set the relevant information for each field, reference the DTO and the existing fields for info re: required, text or dropdown, etc

  const rpText = props.rp ? '-RP' : '-NonRP';
  const costSheetVersion = props.costSheetVersion;
  //Passes all of this inforation through to create a TextField
  const yarnCostFields = [
    {
      label: 'Main Yarn Cost/Lb',
      name: 'mainYarnCost' + rpText,
      valName: 'mainYarnCost',
      required: true,
      type: 'text',
      disabled: false
    },
    {
      label: 'Main Yarn Finance Charge',
      name: 'fabricFinChg' + rpText,
      valName: 'fabricFinChg',
      required: true,
      type: 'text',
      disabled: false
    },
    {
      label: 'Fabric Finance Charge %',
      name: 'fabricFinPct' + rpText,
      valName: 'fabricFinPct',
      required: false,
      type: 'text',
      disabled: true
    },
    {
      label: 'Main Yarn Weight/Gmt. (Lbs/Dz)',
      name: 'mainYarnWeightLbsDz' + rpText,
      valName: 'mainYarnWeightLbsDz',
      required: true,
      type: 'text',
      disabled: false
    },
    {
      label: 'Main Yarn Weight/Gmt. (Lbs/Gmt)',
      name: 'mainYarnWeightLbsGmt' + rpText,
      valName: 'mainYarnWeightLbsGmt',
      required: false,
      type: 'text',
      disabled: true
    },
    {
      label: 'Total Yarn Cost/DZ',
      name: 'totalYarnCostDz' + rpText,
      valName: 'totalYarnCostDz',
      required: false,
      type: 'text',
      disabled: true
    },
    {
      label: 'Additional Yarn Total Cost/DZ',
      name: 'additionalYarnCost' + rpText,
      valName: 'additionalYarnCost',
      required: false,
      type: 'text',
      disabled: false
    },
    {
      label: 'Total Yarn Cost/Gmt.',
      name: 'totalYarnCostGmt' + rpText,
      valName: 'totalYarnCostGmt',
      required: false,
      type: 'text',
      disabled: true
    },
    {
      label:
        'Total Trims (Labels, Closures, Lining and Embellishments) and Testing',
      name: 'trimsAndTesting' + rpText,
      valName: 'trimsAndTesting',
      required: true,
      type: 'text',
      disabled: false
    },
    {
      label: 'Knitting/Linking (labor incl. O/H)',
      name: 'knittingLinkingLabor' + rpText,
      valName: 'knittingLinkingLabor',
      required: true,
      type: 'text',
      disabled: false
    },
    {
      label: 'Wet Processing/Finishing Cost',
      name: 'wetProcCost' + rpText,
      valName: 'wetProcCost',
      required: false,
      type: 'text',
      disabled: false
    },
    {
      label: 'Total Trim/Labor & Wet Proc Cost',
      name: 'totalTrimCost' + rpText,
      valName: 'totalTrimCost',
      required: false,
      type: 'text',
      disabled: true
    }
  ];

  const importCostUS = {
    label: 'Import Cost for LDP Program',
    name: 'importCostForLDP' + rpText,
    valName: 'importCostForLDP',
    required: false,
    type: 'text',
    disabled: false
  };

  const importCostCA =
    costSheetVersion === 'V3'
      ? {
          label: 'CA Import Cost for LDP Program',
          name: 'importCostForLDPCA' + rpText,
          valName: 'importCostForLDPCA',
          required: false,
          type: 'text',
          disabled: false
        }
      : {};

  const firstCostFob = [
    {
      label: 'First Cost',
      name: 'freeOnBoard',
      cc: 'US',
      required: false,
      type: 'text',
      disabledFob: true,
      fob: true,
      ldp: false,
      val: props.funlist[props.idx].firstCostUS
    }
  ];

  const firstCostLdpUS = [
    {
      label: 'First Cost',
      name: 'landedDutyPaid',
      cc: 'US',
      required: false,
      type: 'text',
      disabledLdp: true,
      fob: false,
      ldp: true,
      val: props.funlist[props.idx].firstCostUS
    }
  ];

  const firstCostLdpCA =
    costSheetVersion === 'V3'
      ? [
          {
            label: 'First Cost CA',
            name: 'landedDutyPaid',
            cc: '',
            required: false,
            type: 'text',
            disabledLdp: true,
            fob: false,
            ldp: true,
            val: props.funlist[props.idx].firstCostCA
          }
        ]
      : [];

  return (
    <Grid>
      <div>
        <div key={`ci`} className="column">
          {yarnCostFields.map(field =>
            field.type === 'text' ? (
              <TextEntry
                field={field}
                props={props}
                val={props.funlist[props.idx]}
              />
            ) : (
              <TextDropdown field={field} props={props} />
            )
          )}
          {firstCostFob.map(field => (
            <FirstCost field={field} props={props} />
          ))}
          <TextEntry
            field={importCostUS}
            props={props}
            val={props.funlist[props.idx]}
          />
          {firstCostLdpUS.map(field => (
            <FirstCost field={field} props={props} />
          ))}
          {costSheetVersion === 'V3' && (
            <TextEntry
              field={importCostCA}
              props={props}
              val={props.funlist[props.idx]}
            />
          )}
          {firstCostLdpCA.map(field => (
            <FirstCost field={field} props={props} />
          ))}
        </div>
      </div>
    </Grid>
  );
};

export default YarnCost;
