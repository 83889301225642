import uuid from 'uuid';
import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION
} from 'actiontypes/notifications';

export const addNotification = (msg, type) => {
  return {
    type: ADD_NOTIFICATION,
    payload: {
      id: uuid.v4(),
      type: type,
      message: msg
    }
  };
};

export const removeNotification = id => {
  return {
    type: REMOVE_NOTIFICATION,
    payload: {
      id: id
    }
  };
};
