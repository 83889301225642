import { skuValidationRules } from '../../validationRules';
import { buildValidationSetter } from '../columnDefsShared';
import { skuTooltipValueGetter, skuCellClassRules } from './utils';

export function skuSupplierSize(ctx) {
  return {
    headerName: 'Supplier Size',
    field: 'supplierSize.value',
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    tooltipComponent: 'tooltip',
    tooltipValueGetter: skuTooltipValueGetter('supplierSize', ctx),
    cellClassRules: skuCellClassRules('supplierSize', ctx),
    valueSetter: buildValidationSetter(ctx, {
      validationRules: skuValidationRules,
      aggrField: 'supplierSize'
    })
  };
}
