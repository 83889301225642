import {
  ccCellClassRules,
  ccTooltipValueGetter,
  ccBeforeTextSet,
  ccListSkusRenderer,
  buildAggrValidationSetter
} from './utils';

export function ccSizeRange(ctx) {
  return {
    headerName: 'Size Range',
    colId: 'supplierSize',
    filter: 'agTextColumnFilter',
    valueGetter: ccListSkusRenderer('supplierSize'),
    editable: false,
    headerClass: 'ag-Table__header MuiTypography-body2',
    columnGroupShow: 'open',
    suppressMenu: true,
    tooltipComponent: 'tooltip',
    tooltipValueGetter: ccTooltipValueGetter('supplierSize', ctx),
    cellClassRules: ccCellClassRules('supplierSize', ctx),
    valueSetter: buildAggrValidationSetter({
      relatedField: 'supplierSize.value',
      beforeSet: ccBeforeTextSet
    })
  };
}
