import React from 'react';

import 'stylesheets/CostingInformation.scss';

import GarmentContent from './GarmentContent';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Utils } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch'
    },
    '& .MuiInputBase-multiline': {
      minHeight: '8ch',
      width: '40ch',
      height: 'auto'
    }
  },
  root2: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '10ch'
    },
    '& .MuiButton-root': {
      margin: theme.spacing(1),
      width: '12.5ch'
    },
    '& .MuiInputBase-multiline': {
      width: '40ch',
      height: 'auto'
    }
  },
  root3: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },
  disabled: {
    background: '#A8A8A8',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch'
    },
    '& .MuiInputBase-multiline': {
      minHeight: '8ch',
      width: '40ch',
      height: 'auto'
    }
  },
  disabled2: {
    background: '#A8A8A8',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '10ch'
    },
    '& .MuiButton-root': {
      margin: theme.spacing(1),
      width: '12.5ch'
    },
    '& .MuiInputBase-multiline': {
      width: '40ch',
      height: 'auto'
    }
  },
  disabled3: {
    background: '#A8A8A8',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },
  input: {
    fontStyle: 'italic'
  },
  label: {
    fontStyle: 'italic'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}));

const YarnInformation = props => {
  const classes = useStyles();

  const yarnState = props.yarnState;
  const yarnCODD = props.yarnCODD;

  const yarnArticleNoOptions = {
    NULLVALUE: ' ',
    Other: 'Other'
  };

  const handleYarnChange = props.handleYarnChange;

  //Garment Content
  const contentState = props.contentState;
  const handleContentChange = props.handleContentChange;
  const garmentState = props.garmentState;
  const handleGarmentChange = props.handleGarmentChange;
  const addContent = props.addContent;

  const enableState = props.enableState;

  const organicLabel = 'Organic\nCotton';

  const garmentTotal = garmentState.map(
    column => column['garmentContentTotalPct']
  );
  var garmentTotalPct = 0;

  const garment = idx => {
    var current;
    if (contentState[idx] != null) {
      current = contentState[idx].length;
    } else {
      current = 0;
      contentState[idx] = [
        {
          garmentContentDD: '',
          garmentContentPCT: '',
          garmentContentLbs: ''
        }
      ];
    }

    for (let i = current; i < 3; i++) {
      addContent(idx, i);
    }
  };

  const garmentTotalCheck = idx => {
    if (garmentTotal[idx] === null || garmentTotal[idx] === '') {
      garmentTotalPct = 0;
    } else if (garmentTotal[idx].garmentContentTotalPct === undefined) {
      garmentTotalPct = garmentTotal[idx];
    } else garmentTotalPct = garmentTotal[idx].garmentContentTotalPct;
  };

  return (
    <>
      <hr />
      {yarnState.map((val, idx) => (
        <div key={`ci-${idx}`} className="column">
          <div className="ci-header">Yarn Information</div>
          <hr />

          <div className={classes.root}>
            <TextField
              label="Main Yarn Supplier"
              variant="outlined"
              type="text"
              inputProps={{ maxLength: 2000 }}
              name={`main-yarn-supplier-${idx}`}
              data-idx={idx}
              id={`main-yarn-supplier-${idx}`}
              className={!enableState[idx] && classes.disabled}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['yarnSupplier']}
              disabled={!enableState[idx]}
              onChange={e => handleYarnChange(e, idx, 'yarnSupplier')}
            />
            <br />
            <TextField
              label="Main Yarn C/O"
              variant="outlined"
              select
              name={`main-yarn-co-${idx}`}
              data-idx={idx}
              id={`main-yarn-co-${idx}`}
              className={!enableState[idx] && classes.disabled}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['yarnCODD']}
              disabled={!enableState[idx]}
              onChange={e => handleYarnChange(e, idx, 'yarnCODD')}
            >
              {Utils.sortObject(yarnCODD)}
            </TextField>
            <br />
            <TextField
              label="Main Yarn Article #"
              variant="outlined"
              select
              name={`yarn-article-${idx}`}
              data-idx={idx}
              id={`yarn-article-${idx}`}
              className={!enableState[idx] && classes.disabled}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['yarnArticleNoDD']}
              disabled={!enableState[idx]}
              onChange={e => handleYarnChange(e, idx, 'yarnArticleNoDD')}
            >
              {Utils.sortObject(yarnArticleNoOptions)}
            </TextField>
            <br />
            {val['yarnArticleNoDD'] === 'Other' ? (
              <>
                <TextField
                  label="Main Yarn Article # Other"
                  variant="outlined"
                  inputProps={{ maxLength: 2000 }}
                  name={`yarn-article-other-${idx}`}
                  id={`yarn-article-other-${idx}`}
                  className={!enableState[idx] && classes.disabled}
                  InputProps={!enableState[idx] && { className: classes.input }}
                  InputLabelProps={
                    !enableState[idx] && { className: classes.label }
                  }
                  value={val['yarnArticleNoOther']}
                  disabled={!enableState[idx]}
                  onChange={e => handleYarnChange(e, idx, 'yarnArticleNoOther')}
                />
                <br />
              </>
            ) : null}
            <TextField
              label="Gauge/Stitch"
              variant="outlined"
              name={`yarn-gauge-stitch-${idx}`}
              data-idx={idx}
              id={`yarn-gauge-stitch-${idx}`}
              inputProps={{ maxLength: 2000 }}
              className={!enableState[idx] && classes.disabled}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['yarnGaugeStitch']}
              disabled={!enableState[idx]}
              onChange={e => handleYarnChange(e, idx, 'yarnGaugeStitch')}
            />
          </div>

          <hr />

          {garment(idx)}

          <div className="ci-header">Garment Content</div>
          <br />
          <div className={classes.root2}>
            <TextField
              value={organicLabel}
              multiline={true}
              InputProps={{
                disableUnderline: true,
                readOnly: true
              }}
            />
            <TextField
              label="%"
              variant="outlined"
              type="text"
              name={`cotton-percent-${idx}`}
              data-idx={idx}
              id={`cotton-percent-${idx}`}
              className={!enableState[idx] && classes.disabled2}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={garmentState[idx]['garmentOrganicCottonPCT']}
              disabled={!enableState[idx]}
              onBlur={e =>
                handleGarmentChange(e, idx, 'garmentOrganicCottonPCT')
              }
              onChange={e =>
                handleGarmentChange(e, idx, 'garmentOrganicCottonPCT')
              }
            />
            <TextField
              label="lbs"
              variant="outlined"
              type="text"
              name={`cotton-lbs-${idx}`}
              data-idx={idx}
              id={`cotton-lbs-${idx}`}
              className={!enableState[idx] && classes.disabled2}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={garmentState[idx]['garmentOrganicCottonLbs'] || ''}
              disabled={true}
            />
            <div />
            {contentState[idx].map((val2, idx2) => (
              <div>
                <GarmentContent
                  key={`cat-${idx}-${idx2}`}
                  idx={idx}
                  idx2={idx2}
                  contentState={contentState}
                  handleContentChange={handleContentChange}
                  enableState={enableState}
                  className={!enableState[idx] && classes.disabled2}
                  InputProps={!enableState[idx] && { className: classes.input }}
                  InputLabelProps={
                    !enableState[idx] && { className: classes.label }
                  }
                />
              </div>
            ))}
            <Button size="small" id="placeholder-button" disabled={true} />
            <Button
              item
              variant="outlined"
              size="small"
              id={`add-button-${idx}`}
              onClick={e => addContent(idx, contentState[idx].length)}
              disabled={!enableState[idx]}
            >
              Add
            </Button>

            {garmentTotalCheck(idx)}

            <TextField
              label="%"
              variant="outlined"
              type="text"
              name={`total-percent-${idx}`}
              data-idx={idx}
              id={`total-percent-${idx}`}
              className={!enableState[idx] && classes.disabled2}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={garmentTotalPct || ''}
              error={enableState[idx] && garmentTotal[idx] < 100}
            />
            <TextField
              label="lbs"
              variant="outlined"
              type="text"
              name={`total-lbs-${idx}`}
              data-idx={idx}
              id={`total-lbs-${idx}`}
              className={!enableState[idx] && classes.disabled2}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={garmentState[idx]['garmentContentTotalLbs']}
              disabled={!enableState[idx]}
              onBlur={e =>
                handleGarmentChange(e, idx, 'garmentContentTotalLbs')
              }
              onChange={e =>
                handleGarmentChange(e, idx, 'garmentContentTotalLbs')
              }
            />
          </div>
          <hr />
          <div className="page-break" />
          <div className={classes.root3}>
            <TextField
              label="Main Yarn Minimum Order"
              variant="outlined"
              type="text"
              required={enableState[idx]}
              name={`yarn-minimum-order-${idx}`}
              data-idx={idx}
              id={`yarn-minimum-order-${idx}`}
              className={!enableState[idx] && classes.disabled3}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['yarnMinOrder']}
              disabled={!enableState[idx]}
              onChange={e => handleYarnChange(e, idx, 'yarnMinOrder')}
            />
            <br className="print-hide" />
            <TextField
              label="Main Yarn Minimum Color"
              variant="outlined"
              type="text"
              required={enableState[idx]}
              name={`yarn-minimum-color-${idx}`}
              data-idx={idx}
              id={`yarn-minimum-color-${idx}`}
              className={!enableState[idx] && classes.disabled}
              InputProps={!enableState[idx] && { className: classes.input }}
              InputLabelProps={
                !enableState[idx] && { className: classes.label }
              }
              value={val['yarnMinColor']}
              disabled={!enableState[idx]}
              onChange={e => handleYarnChange(e, idx, 'yarnMinColor')}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default YarnInformation;
