import { skuValidationRules } from '../../validationRules';
import { buildValidationSetter } from '../columnDefsShared';
import {
  skuTooltipValueGetter,
  skuCellClassRules,
  skuNumericRenderer,
  skuCurrencyFormatter
} from './utils';

export function skuCostPerUnit(ctx) {
  return {
    headerName: 'Cost Per Unit',
    field: 'costPerUnit.value',
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    tooltipComponent: 'tooltip',
    tooltipValueGetter: skuTooltipValueGetter('costPerUnit', ctx),
    cellClassRules: skuCellClassRules('costPerUnit', ctx),
    cellRenderer: skuNumericRenderer('USD'),
    valueSetter: buildValidationSetter(ctx, {
      validationRules: skuValidationRules,
      valueFormatter: skuCurrencyFormatter,
      aggrField: 'costPerUnit'
    })
  };
}
