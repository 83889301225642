import {
  FETCH_SUMMARY_CONTENT,
  FETCH_SUMMARY_DEPARTMENTS,
  FETCH_SUMMARY_SUPPLIERS,
  RESET_SUMMARY_VIEW
} from 'actiontypes';
import { pending, fulfilled, rejected } from './utils';

var initialState = {
  tableContent: [],
  departments: [],
  suppliers: [],
  contentIsFetching: false,
  departmentsIsFetching: false,
  suppliersIsFetching: false,
  contentFetchError: null
};

// action.* values are instantiated in action creator
// Reducer takes initial state + action & creates a new state
// Must be pure functions and not mutate state
export default function summary(state = initialState, action) {
  switch (action.type) {
    case pending(FETCH_SUMMARY_CONTENT):
      return {
        ...state,
        contentFetchError: null,
        contentIsFetching: true
      };

    case fulfilled(FETCH_SUMMARY_CONTENT):
      return {
        ...state,
        tableContent: action.payload,
        contentIsFetching: false
      };

    case rejected(FETCH_SUMMARY_CONTENT):
      return {
        ...state,
        contentFetchError: action.payload,
        contentIsFetching: false
      };

    case pending(FETCH_SUMMARY_DEPARTMENTS):
      return {
        ...state,
        departmentsIsFetching: true
      };

    case fulfilled(FETCH_SUMMARY_DEPARTMENTS):
      return {
        ...state,
        departments: action.payload,
        departmentsIsFetching: false
      };

    case rejected(FETCH_SUMMARY_DEPARTMENTS):
      return {
        ...state,
        departmentsIsFetching: false
      };

    case pending(FETCH_SUMMARY_SUPPLIERS):
      return {
        ...state,
        suppliersIsFetching: true
      };

    case fulfilled(FETCH_SUMMARY_SUPPLIERS):
      return {
        ...state,
        suppliers: action.payload,
        suppliersIsFetching: false
      };

    case rejected(FETCH_SUMMARY_SUPPLIERS):
      return {
        ...state,
        suppliersIsFetching: false
      };

    case RESET_SUMMARY_VIEW:
      return initialState;

    default:
      return state;
  }
}

/** PRIVATE SELECTORS */
export const selectTableContent = state => state.tableContent;
export const selectDepartments = state => state.departments;
export const selectSuppliers = state => state.suppliers;
export const selectContentIsFetching = state => state.contentIsFetching;
export const selectcontentFetchError = state => state.contentFetchError;
export const selectSuppliersIsFetching = state => state.suppliersIsFetching;
export const selectDepartmentsIsFetching = state => state.departmentsIsFetching;
