import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION
} from 'actiontypes/notifications';

const initialState = [];

export default function notifications(state = initialState, action) {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return state.concat(action.payload);
    case REMOVE_NOTIFICATION:
      return state.filter(
        notification => notification.id !== action.payload.id
      );
    default:
      return state;
  }
}

/** PRIVATE SELECTORS */
export const selectNotifications = state => state;
