export function skuNumberOfIssues() {
  return {
    headerName: '# of Issues',
    field: 'numberOfIssues',
    filter: 'agNumberColumnFilter',
    suppressMenu: true,
    editable: false,
    cellClassRules: {
      //TODO: Eventually this will be driven by data in our response model.
      hardDanger: params => params.value >= 1
    }
  };
}
