import {
  buildCcAggregatedCellClassRules,
  buildAggrValidationSetter,
  ccNumericGetter,
  ccBeforeNumericSet,
  ccCurrencyFormatter,
  ccRollupFromSkuTooltipValueGetter
} from './utils';

export function ccPricePerUnit(ctx) {
  return {
    headerName: 'Price Per Unit',
    colId: 'pricePerUnit',
    filter: 'agTextColumnFilter',
    headerClass: 'ag-Table__header MuiTypography-body2',
    columnGroupShow: 'open',
    cellClassRules: buildCcAggregatedCellClassRules('pricePerUnit', ctx),
    valueGetter: ccNumericGetter('pricePerUnit', 'USD', ctx),
    valueSetter: buildAggrValidationSetter({
      relatedField: 'pricePerUnit.value',
      beforeSet: ccBeforeNumericSet,
      valueFormatter: ccCurrencyFormatter
    }),
    tooltipComponent: 'tooltip',
    tooltipValueGetter: ccRollupFromSkuTooltipValueGetter('pricePerUnit', ctx),
    suppressMenu: true
  };
}
