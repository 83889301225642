import { ccValidationRules } from '../../validationRules';
import { buildValidationSetter } from '../columnDefsShared';
import { ccCellClassRules, ccTooltipValueGetter } from './utils';

export function ccGender(ctx) {
  return {
    headerName: 'Gender',
    field: 'gender.value',
    filter: 'agTextColumnFilter',
    headerClass: 'ag-Table__header MuiTypography-body2',
    columnGroupShow: 'open',
    suppressMenu: true,
    tooltipComponent: 'tooltip',
    tooltipValueGetter: ccTooltipValueGetter('gender', ctx),
    cellClassRules: ccCellClassRules('gender', ctx),
    valueSetter: buildValidationSetter(ctx, {
      validationRules: ccValidationRules
    })
  };
}
