import { skuValidationRules } from '../../validationRules';
import { buildValidationSetter } from '../columnDefsShared';
import { COUNTRIES_LIST } from '../columnDefsShared';
import { skuTooltipValueGetter, skuCellClassRules } from './utils';

export function skuCountryOfOrigin(ctx) {
  return {
    headerName: 'Country Of Origin',
    field: 'countryOfOrigin.value',
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    editable: true,
    tooltipComponent: 'tooltip',
    tooltipValueGetter: skuTooltipValueGetter('countryOfOrigin', ctx),
    cellClassRules: skuCellClassRules('countryOfOrigin', ctx),
    cellEditor: 'agRichSelectCellEditor',
    cellEditorParams: {
      values: Object.keys(COUNTRIES_LIST),
      useFormatter: true
    },
    valueFormatter: params => COUNTRIES_LIST[params.value],
    valueSetter: buildValidationSetter(ctx, {
      validationRules: skuValidationRules,
      aggrField: 'countryOfOrigin'
    })
  };
}
