import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

// Nord Pattern Library
import { Canvas } from 'nordstrom-ui-pattern-library';

const useStyles = makeStyles(theme => ({
  canvas: {
    maxWidth: '100%',
    width: '100%',
    height: `calc(100% - ${theme.mixins.toolbar.minHeight}px)`,
    [theme.breakpoints.up('sm')]: {
      height: `calc(100% - ${theme.mixins.toolbar['@media (min-width:600px)'].minHeight}px)` //TODO: AutoCalc how many navigations there are
    },
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '25px',
    paddingRight: '25px'
  }
}));

export default function OrionCanvas(props) {
  const classes = useStyles();
  return <Canvas classes={classes}>{props.children}</Canvas>;
}
