import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Utils } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '77ch'
    }
  },
  root2: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '35ch',
      height: 'auto'
    },
    '& .MuiInputBase-multiline': {
      width: '35ch',
      height: 'auto'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}));

const NpsTestNumber = ({ supplierState, handleNpsChange, idx }) => {
  const classes = useStyles();

  const specs = supplierState.meetSpecsDD;

  return (
    <>
      <div className={classes.root}>
        <TextField
          label={`NPS Test Number ${idx + 1}`}
          variant="outlined"
          type="text"
          name="nps"
          id="nps"
          disabled="true"
          InputProps={{
            readOnly: true
          }}
          value={supplierState.npsTest[idx].number}
          onChange={e => handleNpsChange(e, idx, 'number')}
        />
      </div>
      <div className={classes.root2}>
        {(supplierState.npsTest[idx].number || idx === 0) && (
          <TextField
            label={`Does Material Pass NPS Test ${idx + 1}?`}
            variant="outlined"
            select
            name="meetSpecs"
            id="meetSpecs"
            required={supplierState.npsTest[idx].number}
            disabled={!supplierState.npsTest[idx].number}
            InputProps={{
              readOnly: !supplierState.npsTest[idx].number
            }}
            value={supplierState.npsTest[idx].doesMaterialPass}
            onChange={e => handleNpsChange(e, idx, 'doesMaterialPass')}
          >
            {Utils.sortObject(specs)}
          </TextField>
        )}

        {supplierState.npsTest[idx].doesMaterialPass === 'no' && (
          <TextField
            variant="outlined"
            type="text"
            multiline={true}
            inputProps={{ maxLength: 500 }}
            name="ifNo"
            id="ifNo"
            rows={3}
            rowsMax={Infinity}
            helperText="If No - Briefly Explain"
            required={supplierState.npsTest[idx].doesMaterialPass === 'no'}
            value={supplierState.npsTest[idx].isNoBrieflyExplain}
            onChange={e => handleNpsChange(e, idx, 'isNoBrieflyExplain')}
          />
        )}
      </div>
    </>
  );
};

NpsTestNumber.propTypes = {
  supplierState: PropTypes.object,
  handleNpsChange: PropTypes.func
};

export default NpsTestNumber;
