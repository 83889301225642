export function ccSkus() {
  return {
    headerName: 'SKUs',
    field: 'skus.length',
    width: 100,
    editable: false,
    pinned: 'left',
    filter: false,
    headerClass: 'ag-Table__header MuiTypography-body2',
    columnGroupShow: 'open',
    cellRenderer: 'agGroupCellRenderer',
    suppressMenu: true,
    suppressSizeToFit: true,
    cellStyle: {
      padding: 0
    }
  };
}
