import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'stylesheets/CostingInformation.scss';
import { Utils } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch'
    },
    '& .MuiInputBase-multiline': {
      minHeight: '8ch',
      width: '40ch',
      height: 'auto'
    }
  },
  root2: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },
  disabled: {
    background: '#A8A8A8',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch'
    },
    '& .MuiInputBase-multiline': {
      minHeight: '8ch',
      width: '40ch',
      height: 'auto'
    }
  },
  disabled2: {
    background: '#A8A8A8',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}));

const KnitCost = props => {
  const classes = useStyles();

  const knitList = props.knitList;
  const currencyDD = {
    usd: 'USD',
    eur: 'EUR'
  };
  const handleKnitChange = props.handleKnitChange;

  const knitFabricPriceYieldUOMDD = props.knitFabricPriceYieldUOMDD;

  const type = props.type;

  const enableState = props.enableState;

  const costSheetVersion = props.costSheetVersion;

  return (
    <Grid>
      <div className={classes.root}>
        <hr />
        {knitList.map((val, idx) => (
          <div key={`ci-${idx}`} className="column">
            <div className="ci-header">{type} Costing Information</div>
            <hr />
            <TextField
              label="Fabric Weight (Grams/Sq. Meter)"
              variant="outlined"
              type="text"
              name={`fabric-weight-${idx}`}
              data-idx={idx}
              id={`fabric-weight-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['knitFabricWt']}
              disabled={!enableState[idx]}
              onBlur={e => handleKnitChange(e, idx, 'knitFabricWt')}
              onChange={e => handleKnitChange(e, idx, 'knitFabricWt')}
            />
            <br />
            <TextField
              label="UOM Fabric, Price and Yield"
              variant="outlined"
              select
              required={enableState[idx]}
              name={`fabric-price-${idx}`}
              data-idx={idx}
              id={`fabric-price-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['knitFabricPriceYieldUOMDD']}
              disabled={!enableState[idx]}
              onChange={e =>
                handleKnitChange(e, idx, 'knitFabricPriceYieldUOMDD')
              }
            >
              {Utils.sortObject(knitFabricPriceYieldUOMDD)}
            </TextField>
            <br />
            <TextField
              label="Currency Denomination"
              variant="outlined"
              select
              required={enableState[idx]}
              name={`currency-denomination-${idx}`}
              data-idx={idx}
              id={`currency-denomination-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['knitCurrency']}
              disabled={!enableState[idx]}
              onChange={e => handleKnitChange(e, idx, 'knitCurrency')}
            >
              {Utils.sortObject(currencyDD)}
            </TextField>
            <br />
            <div className="page-break" />
            <br className="print-show" />
            <div className={classes.root2}>
              <TextField
                label="UOM"
                variant="outlined"
                type="text"
                name={`main-fabric-price-uom-${idx}`}
                data-idx={idx}
                id={`main-fabric-price-uom-${idx}`}
                className={!enableState[idx] && classes.disabled2}
                value={val['knitMainFabricPriceUOM'] || ''}
                disabled={true}
                InputProps={{
                  readOnly: true
                }}
              />
              <TextField
                label="Main Fabric Price /"
                variant="outlined"
                type="text"
                required={enableState[idx]}
                name={`main-fabric-price-${idx}`}
                data-idx={idx}
                id={`main-fabric-price-${idx}`}
                className={!enableState[idx] && classes.disabled2}
                value={val['knitMainFabricPrice']}
                disabled={!enableState[idx]}
                onBlur={e => handleKnitChange(e, idx, 'knitMainFabricPrice')}
                onChange={e => handleKnitChange(e, idx, 'knitMainFabricPrice')}
              />
            </div>
            <TextField
              label="Fabric Finance Charge"
              variant="outlined"
              type="text"
              required={enableState[idx]}
              name={`fabric-finance-${idx}`}
              data-idx={idx}
              id={`fabric-finance-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['knitFabricFinChg']}
              disabled={!enableState[idx]}
              onBlur={e => handleKnitChange(e, idx, 'knitFabricFinChg')}
              onChange={e => handleKnitChange(e, idx, 'knitFabricFinChg')}
            />
            <br />
            <TextField
              label="Fabric Finance Charge %"
              variant="outlined"
              type="number"
              name={`fabric-finance-pct-${idx}`}
              data-idx={idx}
              id={`fabric-finance-pct-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['knitFabricFinPct'] || ''}
              disabled={true}
              InputProps={{
                readOnly: true
              }}
            />
            <br />
            <div className={classes.root2}>
              <TextField
                label="UOM"
                variant="outlined"
                type="text"
                name={`fabric-gmt-uom-${idx}`}
                data-idx={idx}
                id={`fabric-gmt-uom-${idx}`}
                className={!enableState[idx] && classes.disabled2}
                value={val['knitFabricGmtYieldUOM'] || ''}
                disabled={true}
                InputProps={{
                  readOnly: true
                }}
              />
              <TextField
                label="Main Fabric Gmt Yield"
                variant="outlined"
                type="text"
                required={enableState[idx]}
                name={`fabric-gmt-${idx}`}
                data-idx={idx}
                id={`fabric-gmt-${idx}`}
                className={!enableState[idx] && classes.disabled2}
                value={val['knitFabricGmtYield']}
                disabled={!enableState[idx]}
                onBlur={e => handleKnitChange(e, idx, 'knitFabricGmtYield')}
                onChange={e => handleKnitChange(e, idx, 'knitFabricGmtYield')}
              />
            </div>
            <TextField
              label="Main Fabric Cost/Gmt."
              variant="outlined"
              type="number"
              name={`main-fabric-cost-${idx}`}
              data-idx={idx}
              id={`main-fabric-cost-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['knitFabricCost'] || ''}
              disabled={true}
              InputProps={{
                readOnly: true
              }}
            />
            <br />
            <TextField
              label="Additional Fabric Cost/Gmt."
              variant="outlined"
              type="text"
              name={`add-fabric-cost-${idx}`}
              data-idx={idx}
              id={`add-fabric-cost-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['knitAdditionalFabricCost']}
              disabled={!enableState[idx]}
              onBlur={e => handleKnitChange(e, idx, 'knitAdditionalFabricCost')}
              onChange={e =>
                handleKnitChange(e, idx, 'knitAdditionalFabricCost')
              }
            />
            <br />
            <TextField
              label="Total Fabric Cost/Gmt"
              variant="outlined"
              type="number"
              name={`total-fabric-cost-${idx}`}
              data-idx={idx}
              id={`total-fabric-cost-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['knitTotalFabricCost'] || ''}
              disabled={true}
              InputProps={{
                readOnly: true
              }}
            />
            <br />
            <TextField
              label="Total Trims (Labels, Closures, Lining and Embellishments) and Testing"
              variant="outlined"
              type="text"
              required={enableState[idx]}
              name={`trims-emb-${idx}`}
              data-idx={idx}
              id={`trims-emb-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['knitTrimsAndEmb']}
              disabled={!enableState[idx]}
              onBlur={e => handleKnitChange(e, idx, 'knitTrimsAndEmb')}
              onChange={e => handleKnitChange(e, idx, 'knitTrimsAndEmb')}
            />
            <br />
            <div className="page-break" />
            <br className="print-show" />
            <TextField
              label="Cut and Make Labor (incl. O/H)"
              variant="outlined"
              type="text"
              required={enableState[idx]}
              name={`cut-labor-${idx}`}
              data-idx={idx}
              id={`cut-labor-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['knitCutandMakeLabor']}
              disabled={!enableState[idx]}
              onBlur={e => handleKnitChange(e, idx, 'knitCutandMakeLabor')}
              onChange={e => handleKnitChange(e, idx, 'knitCutandMakeLabor')}
            />
            <br />
            <TextField
              label="Wet Processing/Finishing Cost"
              variant="outlined"
              type="text"
              name={`wet-proc-cost-${idx}`}
              data-idx={idx}
              id={`wet-proc-cost-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['knitWetProcCost']}
              disabled={!enableState[idx]}
              onBlur={e => handleKnitChange(e, idx, 'knitWetProcCost')}
              onChange={e => handleKnitChange(e, idx, 'knitWetProcCost')}
            />
            <br />
            <TextField
              label="Total Trim, C and M and Wet Proc Cost"
              variant="outlined"
              type="number"
              name={`total-cost-${idx}`}
              data-idx={idx}
              id={`total-cost-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['knitTotalTrimCost'] || ''}
              disabled={true}
              InputProps={{
                readOnly: true
              }}
            />
            <br />
            <TextField
              label="1st Cost (FOB/Package Cost)"
              variant="outlined"
              type="number"
              name={`first-cost-fob-${idx}`}
              data-idx={idx}
              id={`first-cost-fob-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['knitFirstCost'] || ''}
              disabled={true}
              InputProps={{
                readOnly: true
              }}
            />
            <br />
            <TextField
              label="Import Cost for LDP Program"
              variant="outlined"
              type="text"
              name={`import-cost-${idx}`}
              data-idx={idx}
              id={`import-cost-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['knitImportCostForLDP']}
              disabled={!enableState[idx]}
              onBlur={e => handleKnitChange(e, idx, 'knitImportCostForLDP')}
              onChange={e => handleKnitChange(e, idx, 'knitImportCostForLDP')}
            />
            <br />
            <TextField
              label="1st Cost (LDP Cost)"
              variant="outlined"
              type="number"
              name={`first-cost-${idx}`}
              data-idx={idx}
              id={`first-cost-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['knitFirstCostLDP'] || ''}
              disabled={true}
              InputProps={{
                readOnly: true
              }}
            />
            {costSheetVersion === 'V3' && (
              <TextField
                label="CA Import Cost for LDP Program"
                variant="outlined"
                type="text"
                name={`import-cost-ca-${idx}`}
                data-idx={idx}
                id={`import-cost-ca-${idx}`}
                className={!enableState[idx] && classes.disabled}
                value={val['knitImportCostForLDPCA']}
                disabled={!enableState[idx]}
                onBlur={e => handleKnitChange(e, idx, 'knitImportCostForLDPCA')}
                onChange={e =>
                  handleKnitChange(e, idx, 'knitImportCostForLDPCA')
                }
              />
            )}
            {costSheetVersion === 'V3' && (
              <TextField
                label="CA 1st Cost (LDP Cost)"
                variant="outlined"
                type="number"
                name={`first-cost-ca-${idx}`}
                data-idx={idx}
                id={`first-cost-ca-${idx}`}
                className={!enableState[idx] && classes.disabled}
                value={val['knitFirstCostLDPCA'] || ''}
                disabled={true}
                InputProps={{
                  readOnly: true
                }}
              />
            )}
          </div>
        ))}
      </div>
    </Grid>
  );
};

export default KnitCost;
