import React, { Fragment, useState, useEffect } from 'react';

import OrionAppNav from '../OrionApplicationNav';
import KnitTypeL from './KnitTypeL';
import KnitTypeR from './KnitTypeR';
import YarnTypeL from './YarnTypeL';
import YarnTypeR from './YarnTypeR';
import MaterialTypeR from './MaterialTypeR';
import SupplierTypeL from './SupplierTypeL';
import SupplierTypeR from './SupplierTypeR';
import SupplierNotes from './SupplierNotes';
import ContentFields from './ContentFields';
import FormInstructions from './FormInstructions';
import { Button, Grid, Typography } from '@material-ui/core';
import HttpError from 'utils/errors/HttpError';
import { Utils } from 'utils';
import uuid from 'uuid';
import WovenTypeL from './WovenTypeL';
import WovenTypeR from './WovenTypeR';
import LeatherTypeL from './LeatherTypeL';
import LeatherTypeR from './LeatherTypeR';
import OtherTypeL from './OtherTypeL';
import OtherTypeR from './OtherTypeR';
import SupplierLP from './SupplierLP';
import DetailsLP from './LabelAndPackagingDetails/DetailsLP';
import { BrowserView } from 'react-device-detect';

import 'stylesheets/Print.scss';
import NpsTestNumber from './NpsTestFields';

const MaterialView = props => {
  const locationProp = props.location.state;
  const [ownerState, setOwnerState] = useState(locationProp.ownerState);

  const [isReloading, setIsReloading] = useState(false);

  useEffect(() => {
    //Run every time component loads into view
    window.onload = e => {
      //Get the type of event that caused the component to load into view
      let { type } = performance.getEntriesByType('navigation')[0];
      //If the component is being loaded in via reload or reopening closed tab
      if (type === 'reload' || type === 'back_forward') {
        //set state accordingly
        setIsReloading(true);
      }
    };
  });

  useEffect(() => {
    if (isReloading) {
      //split auth (e.g. TTL.HMAC) as passed into component through props
      const authArray = locationProp.auth.split('.');
      //reinject token for URL (e.g. TTL.token.HMAC)
      let tokenPath = [authArray[0], locationProp.token, authArray[1]].join(
        '.'
      );
      //get protocol, hostname, and port from window. If port isn't specified, will return 0
      let { protocol, hostname, port } = window.location;
      //if port is 0, replace with empty string so redirect works
      if (Number(port) === 0) {
        port = '';
      }
      //force reload to base url and tokenized call
      window.location.replace(`${protocol}//${hostname}:${port}/${tokenPath}`);
    }
  }, [isReloading, locationProp.auth, locationProp.token]);

  useEffect(() => {
    window.onbeforeunload = e => {
      let dialogText =
        'Are you sure you want to leave? Form data may not be saved!';
      e.returnValue = dialogText;
      return dialogText;
    };
  });

  const handleOwnerChange = e =>
    setOwnerState({
      ...ownerState,
      [e.target.name]: e.target.value
    });

  const originalJson = locationProp.originalJson;

  const [supplierState, setSupplierState] = useState(
    locationProp.supplierState
  );

  const handleSupplierChange = e => {
    const integers = new Set([
      'sampleMinimum',
      'sampleLead',
      'bulkMinimum',
      'bulkColor',
      'bulkLead',
      'orderMinimum1',
      'colorMinimum1',
      'leadTime1',
      'orderMinimum2',
      'colorMinimum2',
      'leadTime2',
      'orderMinimum3',
      'colorMinimum3',
      'leadTime3',
      'supplierLeadTimeInDays'
    ]);
    const floats = new Set([
      'samplePrice',
      'bulkPrice',
      'initialPrice1',
      'initialPrice2',
      'initialPrice3',
      'supplierUnitPrice',
      'supplierCostPerThousand'
    ]);

    if (integers.has(e.target.name) || floats.has(e.target.name)) {
      handleSupplierNumbers(e, integers, floats);
    } else {
      setSupplierState({
        ...supplierState,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleSupplierNumbers = (e, integers, floats) => {
    const regexInteger = /^([1-9][0-9]*|[0])?$/;
    const regexFloat = /^([1-9][0-9]*|[0])?(\.\d{0,2})?$/;
    const intLimit = 2147483647;
    const floatLimit = 1e100;

    if (e.type === 'change') {
      if (integers.has(e.target.name)) {
        if (regexInteger.test(e.target.value)) {
          if (e.target.value <= intLimit) {
            setSupplierState({
              ...supplierState,
              [e.target.name]: e.target.value
            });
          }
        }
      } else if (floats.has(e.target.name)) {
        if (regexFloat.test(e.target.value)) {
          if (e.target.value <= floatLimit) {
            setSupplierState({
              ...supplierState,
              [e.target.name]: e.target.value
            });
          }
        }
      }
    } else if (e.type === 'blur') {
      if (floats.has(e.target.name)) {
        if (e.target.value.endsWith('.')) {
          setSupplierState({
            ...supplierState,
            [e.target.name]: e.target.value.replace('.', '')
          });
        }
      }
    }
  };

  const blankContent = {
    content: '',
    percentage: ''
  };
  const [contentState, setContentState] = useState(ownerState.contentState);
  const [contentError, setContentError] = useState(false);

  useEffect(() => {
    //check if content state even exists first to prevent errors
    if (ownerState.contentState)
      setContentError(
        contentState
          .map(idx => idx.percentage)
          .map(Number)
          .reduce((total, curr) => total + curr) !== 100
      );
  }, [contentState, ownerState.contentState]);

  const addContent = () => {
    if (contentState.length < 5)
      setContentState([...contentState, { ...blankContent }]);
    else alert('You have reached the maximum number of Content items allowed.');
  };
  const removeContent = i => {
    const list = [...contentState];
    if (list.length > 3) {
      list.splice(i, 1);
      setContentState(list);
    } else {
      alert('You have reached the minimum number of content types allowed');
    }
  };

  const handleContentChange = (e, idx, className) => {
    const updatedContent = [...contentState];
    if (className === 'percentage') {
      handleContentNumbers(e, idx, className, updatedContent);
    } else {
      updatedContent[idx][className] = e.target.value;
    }
    setContentState(updatedContent);
  };

  const handleContentNumbers = (e, idx, className, updatedContent) => {
    const regexFloat = /^([1-9][0-9]*|[0])?(\.\d{0,2})?$/;
    const floatLimit = 1e100;

    if (e.type === 'change') {
      if (regexFloat.test(e.target.value)) {
        if (e.target.value <= floatLimit) {
          updatedContent[idx][className] = e.target.value;
        }
      }
    } else if (e.type === 'blur') {
      if (e.target.value.endsWith('.')) {
        updatedContent[idx][className] = e.target.value.replace('.', '');
      }
    }
  };

  const handleNpsChange = (e, idx, className) => {
    const updatedNpsState = [...supplierState.npsTest];
    updatedNpsState[idx][className] = e.target.value;
    if (className === 'doesMaterialPass' && e.target.value !== 'No') {
      let x = {
        type: 'change',
        target: {
          value: ''
        }
      };
      handleNpsChange(x, idx, 'isNoBrieflyExplain');
    }
    setSupplierState({
      ...supplierState,
      npsTest: updatedNpsState
    });
  };

  const [disableSaveSend, setDisableSaveSend] = useState(false);

  const handleSubmit = e => {
    if (!contentError) {
      const body = Utils.getDto(
        originalJson,
        ownerState,
        supplierState,
        contentState
      );
      const isConfirmed = window.confirm(
        "Are you sure you're ready to submit? This action cannot be undone"
      );
      if (isConfirmed)
        callRestApi(
          locationProp.token,
          locationProp.auth,
          locationProp.synthetic,
          body,
          'POST',
          'send'
        );
    } else {
      alert(
        'Please review each material content value selected has an associated percent value entered and the total of all material content percents equals 100.'
      );
    }
    e.preventDefault();
  };

  const onSave = e => {
    const body = Utils.getDto(
      originalJson,
      ownerState,
      supplierState,
      contentState
    );
    callRestApi(
      locationProp.token,
      locationProp.auth,
      locationProp.synthetic,
      body,
      'PUT',
      ''
    );
  };

  const [fetchStatus, setFetchStatus] = useState({ POST: 0, PUT: 0 });

  const handleFetch = (type, status) => {
    const updatedStatus = fetchStatus;
    updatedStatus[type] = status;
    setFetchStatus(updatedStatus);
  };

  const callRestApi = (
    materialId,
    signature,
    synthetic,
    body,
    request,
    endpoint
  ) => {
    const payload = {
      method: `${request}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Nord-Client-Id': 'APP03154',
        'Nord-Synthetic': synthetic,
        'Nord-Request-Id': uuid(),
        AUTH: `${signature}`
      },
      body: JSON.stringify(body)
    };

    const url = `/smacsRest/material/${materialId}/${endpoint}`;

    return fetch(url, payload)
      .then(response => {
        if (!response.ok) {
          throw new HttpError(response.status, 'Error fetching data');
        } else {
          return response;
        }
      })
      .then(responseJson => {
        handleFetch(request, 200);
        if (request === 'PUT') alert('Successfully Saved.');
        if (request === 'POST') {
          alert('Successfully Sent to FlexPLM.');
          setDisableSaveSend(true);
        }
      })
      .catch(error => {
        if (error instanceof HttpError) {
          handleFetch(request, error.statusCode);
          alert(
            'Sorry, there was an error attempting to save your form. Please try again.'
          );
        } else {
          handleFetch(request, error.statusCode);
          alert(
            'Sorry, there was an error attempting to save your form. Please try again.'
          );
        }
      });
  };

  const content = () => {
    const current = contentState.length;
    for (let i = current; i < 3; i++) {
      addContent();
    }
    if (ownerState.materialEditable === 'true') {
      return (
        <Button
          item
          variant="outlined"
          size="small"
          onClick={addContent}
          id="add-content"
        >
          Press for Additional Content Fields if Needed
        </Button>
      );
    }
  };

  const contentTest = () => {
    return (
      <Grid item xs={'auto'}>
        <Grid item xs={'auto'}>
          {contentState.map((val, idx) => (
            <div key={`cat-div-${idx}`}>
              <ContentFields
                key={`cat-${idx}`}
                idx={idx}
                ownerState={ownerState}
                contentState={contentState}
                handleContentChange={handleContentChange}
                removeContent={removeContent}
                contentError={contentError}
              />
            </div>
          ))}
        </Grid>
        <Grid item xs={'12'}>
          {content()}
          <br className="print-hide" />
          <br className="print-hide" />
        </Grid>
      </Grid>
    );
  };

  const components = {
    Knit: [KnitTypeL, KnitTypeR],
    Yarn: [YarnTypeL, YarnTypeR],
    Woven: [WovenTypeL, WovenTypeR],
    Leather: [LeatherTypeL, LeatherTypeR],
    Other: [OtherTypeL, OtherTypeR]
  };

  const materialType = () => {
    const Left = components[ownerState.materialType][0];
    const Right = components[ownerState.materialType][1];
    return (
      <Grid container spacing={10}>
        <Grid item>
          <Grid item xs={'auto'}>
            <Left
              ownerState={ownerState}
              handleOwnerChange={handleOwnerChange}
            />
          </Grid>
          <br />
          {contentTest()}
        </Grid>
        <Grid item>
          <Grid item xs={'auto'}>
            <Right
              ownerState={ownerState}
              handleOwnerChange={handleOwnerChange}
            />
          </Grid>
          <Grid item xs={'auto'}>
            <MaterialTypeR
              ownerState={ownerState}
              handleOwnerChange={handleOwnerChange}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const print = () => {
    window.print();
  };

  return (
    <>
      <OrionAppNav
        path={window.location.pathname}
        expiryDate={props.location.state.auth.match('\\d+')}
        statusCode={props.location.state.fetchStatus}
        materialType={ownerState.materialType}
      />
      <form onSubmit={handleSubmit}>
        <br />
        <Grid container spacing={1}>
          <Grid item>
            <FormInstructions
              formType={
                ownerState.materialType === 'Label' ||
                ownerState.materialType === 'Packaging'
                  ? 'labelPackaging'
                  : 'material'
              }
            />
            <br className="print-hide" />
            Date Generated: {ownerState.date}
            <br className="print-hide" />
            <br />
          </Grid>
          <Grid item>
            <Grid container spacing={1}>
              <Grid item>
                <Button
                  variant="outlined"
                  size="large"
                  id="save-button"
                  onClick={onSave}
                  disabled={disableSaveSend}
                >
                  Save
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="submit"
                  variant="outlined"
                  size="large"
                  id="send-button"
                  disabled={disableSaveSend}
                >
                  Send
                </Button>
              </Grid>
              <Grid item>
                <BrowserView>
                  <Button
                    variant="outlined"
                    size="large"
                    id="print-button"
                    onClick={print}
                  >
                    Save to PDF
                  </Button>
                </BrowserView>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <hr />
        {(ownerState.materialType === 'Yarn' ||
          ownerState.materialType === 'Knit' ||
          ownerState.materialType === 'Leather' ||
          ownerState.materialType === 'Woven' ||
          ownerState.materialType === 'Other') && (
          <>
            <Typography variant="h6">
              Material Name: {ownerState.materialName}
            </Typography>
            {materialType()}
            <hr />
            <Typography variant="h6">
              Supplier: {supplierState.supplierName}
            </Typography>
            <Grid container spacing={10}>
              <Grid item xs={'auto'}>
                <SupplierTypeL
                  supplierState={supplierState}
                  handleSupplierChange={handleSupplierChange}
                />
              </Grid>
              <Grid item xs={'auto'}>
                <SupplierTypeR
                  supplierState={supplierState}
                  handleSupplierChange={handleSupplierChange}
                />
              </Grid>
              <Grid item xs={'auto'}>
                <SupplierNotes
                  supplierState={supplierState}
                  handleSupplierChange={handleSupplierChange}
                />
              </Grid>
            </Grid>
            <Grid container spacing={10}>
              {supplierState.npsTest.map((val, idx) => (
                <>
                  {(val.number !== '' || idx === 0) && (
                    <Grid item xs={'auto'}>
                      <NpsTestNumber
                        supplierState={supplierState}
                        handleNpsChange={handleNpsChange}
                        idx={idx}
                      />
                    </Grid>
                  )}
                </>
              ))}
            </Grid>
          </>
        )}
        {(ownerState.materialType === 'Label' ||
          ownerState.materialType === 'Packaging') && (
          <>
            <DetailsLP
              ownerState={ownerState}
              handleOwnerChange={handleOwnerChange}
            />
            <hr />
            <Typography variant="h6">
              Supplier: {supplierState.supplierName}
            </Typography>
            <SupplierLP
              supplierState={supplierState}
              handleSupplierChange={handleSupplierChange}
            />
          </>
        )}
        <br />
        <br />
      </form>
    </>
  );
};

export default MaterialView;
