import {
  buildCcAggregatedCellClassRules,
  buildAggrValidationSetter,
  ccNumericGetter,
  ccBeforeNumericSet,
  ccCurrencyFormatter,
  ccRollupFromSkuTooltipValueGetter
} from './utils';

export function ccCostPerUnit(ctx) {
  return {
    headerName: 'Cost Per Unit',
    colId: 'costPerUnit',
    filter: 'agTextColumnFilter',
    headerClass: 'ag-Table__header MuiTypography-body2',
    columnGroupShow: 'open',
    cellClassRules: buildCcAggregatedCellClassRules('costPerUnit', ctx),
    valueGetter: ccNumericGetter('costPerUnit', 'USD', ctx),
    valueSetter: buildAggrValidationSetter({
      relatedField: 'costPerUnit.value',
      beforeSet: ccBeforeNumericSet,
      valueFormatter: ccCurrencyFormatter
    }),
    tooltipComponent: 'tooltip',
    tooltipValueGetter: ccRollupFromSkuTooltipValueGetter('costPerUnit', ctx),
    suppressMenu: true
  };
}
