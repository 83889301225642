import { makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import 'stylesheets/CostingInformation.scss';
import { Utils } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    background: '#A8A8A8'
    // borderColor: "green"
  },
  input: {
    // color: "green",
    fontStyle: 'italic'
    // borderColor: "green"
  },
  label: {
    // textTransform: "uppercase",
    // color: "red",
    fontStyle: 'italic'
    // borderColor: "green"
  }
}));

const TextDropdown = props => {
  const classes = useStyles();

  const label = props.field.label;

  const name = props.field.name;

  const required = props.field.required;

  const disabled = props.field.disabled;

  const idx = props.props.idx;

  const enableState = props.props.enableState;

  const handleChange = props.props.handleChange;

  const lov = props.field.lov;

  var rpState =
    props.props.rp === true
      ? props.props.replenishmentState
      : props.props.nonReplenishmentState;

  var val = props.props.funlist[idx];
  const valName = props.field.valName;

  let disabledField = !enableState[idx] || !rpState[idx];

  if (
    name.substring(0, 8) === 'currency' ||
    name.substring(0, 21) === 'fabricPriceYieldUOMDD' ||
    name.substring(0, 20) === 'sweaterGarmentConsDD'
  ) {
    disabledField = !enableState[idx];
    val = props.props.list[idx];
  }

  return (
    <TextField
      label={label}
      variant="outlined"
      select
      name={`${name}-${idx}`}
      data-idx={idx}
      id={`${name}-${idx}`}
      value={val[valName]}
      className={disabledField && classes.root}
      InputProps={disabledField && { className: classes.input }}
      InputLabelProps={disabledField && { className: classes.label }}
      disabled={disabledField || disabled}
      required={enableState[idx] && rpState[idx] && required}
      onChange={e => handleChange(e, idx, name)}
    >
      {Utils.sortObject(lov)}
    </TextField>
  );
};

export default TextDropdown;
