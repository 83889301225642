import { ccValidationRules } from '../../validationRules';
import { buildValidationSetter } from '../columnDefsShared';
import { ccCellClassRules, ccTooltipValueGetter } from './utils';

export function ccDesigner(ctx) {
  return {
    headerName: 'Designer',
    field: 'designer.value',
    filter: 'agTextColumnFilter',
    headerClass: 'ag-Table__header MuiTypography-body2',
    columnGroupShow: 'open',
    suppressMenu: true,
    tooltipComponent: 'tooltip',
    tooltipValueGetter: ccTooltipValueGetter('designer', ctx),
    cellEditor: 'agRichSelectCellEditor',
    cellEditorParams: { values: ['Y', 'N'] },
    cellClassRules: ccCellClassRules('designer', ctx),
    valueSetter: buildValidationSetter(ctx, {
      validationRules: ccValidationRules
    })
  };
}
