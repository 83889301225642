import { Grid } from '@material-ui/core';
import React from 'react';
import 'stylesheets/CostingInformation.scss';
import FirstCost from './FirstCost';

const CostingInformationCost = props => {
  const giState = props.giState;

  const costSheetVersion = props.costSheetVersion;

  const costingInformationCostFields =
    costSheetVersion === 'V3'
      ? [
          {
            label: 'First Cost',
            name: 'firstCost',
            cc: 'US',
            required: false,
            type: 'text',
            disabledLdp: false,
            disabledFob: giState[props.idx].giLDPProgram === '1',
            fob: true,
            ldp: true
          },
          {
            label: 'First Cost CA',
            name: 'firstCostCA',
            cc: '',
            required: false,
            type: 'text',
            disabledLdp: false,
            disabledFob: giState[props.idx].giLDPProgramCA === '1',
            fob: true,
            ldp: true
          }
        ]
      : [
          {
            label: 'First Cost',
            name: 'firstCost',
            cc: 'US',
            required: false,
            type: 'text',
            disabledLdp: false,
            disabledFob: giState[props.idx].giLDPProgram === '1',
            fob: true,
            ldp: true
          }
        ];

  return (
    <Grid>
      <div>
        <div key={`ci`} className="column">
          {costingInformationCostFields.map(field => (
            <FirstCost field={field} props={props} />
          ))}
        </div>
      </div>
    </Grid>
  );
};

export default CostingInformationCost;
