import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withStyles } from '@material-ui/styles';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { removeNotification } from 'actions/notifications';
import { selectNotifications } from 'selectors';

const styles = theme => ({
  close: {
    padding: 0
  },
  error: {
    backgroundColor: theme.palette.error.dark
  }
});

export class Notifications extends Component {
  constructor(props) {
    super(props);
    this.closeHandler = this.closeHandler.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.closeHandler();
    }
  }

  closeHandler() {
    const notification = this.props.notifications[0];
    if (notification) {
      const { id } = notification;
      this.props.removeNotification(id);
    }
  }

  render() {
    const { classes, notifications } = this.props;

    if (!notifications.length) {
      return null;
    }

    // Always take first available notification to render in the queue,
    // see: https://material.io/components/snackbars/#behavior - Consecutive snackbars
    const { type, message } = notifications[0];

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={true}
        autoHideDuration={8000}
        onClose={this.closeHandler}
        ContentProps={{
          classes: {
            root: classes[type]
          }
        }}
        className={classes[type]}
        message={<span>{message}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={this.closeHandler}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    );
  }
}

const mapStateToProps = state => ({
  notifications: selectNotifications(state)
});

const mapDispatchToProps = {
  removeNotification: removeNotification
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(Notifications)));
