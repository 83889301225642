import { ccValidationRules } from '../../validationRules';
import { buildValidationSetter } from '../columnDefsShared';
import { ccCellClassRules, ccTooltipValueGetter } from './utils';

export function ccSupplierUs(ctx) {
  return {
    headerName: 'Supplier (US)',
    field: 'supplier.value',
    filter: 'agTextColumnFilter',
    headerClass: 'ag-Table__header MuiTypography-body2',
    suppressMenu: true,
    enablePivot: true,
    tooltipComponent: 'tooltip',
    tooltipValueGetter: ccTooltipValueGetter('supplier', ctx),
    cellClassRules: ccCellClassRules('supplier', ctx),
    cellEditor: 'agRichSelectCellEditor',
    cellEditorParams: function() {
      const values = Object.values(ctx.props.suppliers)
        .map(s => s.vendorLabel)
        .sort();
      return { values };
    },
    valueGetter: params => {
      const supplierName = params.data.supplier;
      const supplierId = params.data.supplierId;

      // If 1:1 not found, return Supplier Name
      if (!supplierId.value) {
        return supplierName.value;
      }

      // If 1:1 match found, reutrn Supplier Name & ID within cell data
      return `${supplierName.value} (${supplierId.value})`;
    },
    valueSetter: params => {
      buildValidationSetter(ctx, {
        validationRules: ccValidationRules,
        valueFormatter: val => val.substring(0, val.lastIndexOf('(') - 1)
      })(params);
      // Set supplierId value so we can use it for value getter
      params.data.supplierId.value = params.newValue.substring(
        params.newValue.lastIndexOf('(') + 1,
        params.newValue.length - 1
      );
    }
  };
}
