import { skuValidationRules } from '../../validationRules';
import { buildValidationSetter } from '../columnDefsShared';
import {
  skuTooltipValueGetter,
  skuCellClassRules,
  skuNumericRenderer,
  skuCurrencyFormatter
} from './utils';

export function skuSpecialPricePerUnit(ctx) {
  return {
    headerName: 'Special Price Per Unit',
    field: 'specialPricePerUnit.value',
    filter: 'agTextColumnFilter',
    suppressMenu: true,
    tooltipComponent: 'tooltip',
    tooltipValueGetter: skuTooltipValueGetter('specialPricePerUnit', ctx),
    cellClassRules: skuCellClassRules('specialPricePerUnit', ctx),
    cellRenderer: skuNumericRenderer('USD'),
    valueSetter: buildValidationSetter(ctx, {
      validationRules: skuValidationRules,
      valueFormatter: skuCurrencyFormatter,
      aggrField: 'specialPricePerUnit'
    })
  };
}
