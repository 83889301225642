import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import Settings from 'settings/contextNavSettings';
import { WORKSPACE_VIEW } from 'constants/routes';
import Grid from 'components/shared/Grid';
import Notifier from 'services/Notifier';
import Validator from 'services/Validator';
import {
  fetchSummaryContent,
  fetchSummaryDepartments,
  fetchSummarySuppliers,
  saveSummaryContent,
  resetSummaryView
} from 'actions/summary';
import {
  selectSummaryTableContent,
  selectSummaryDepartments,
  selectSummarySuppliers,
  selectSummaryContentIsFetching,
  selectSummaryContentFetchError,
  selectAuthUserId,
  selectAuthUserName
} from 'selectors';
import { getCcColumnDefs, getSkuColumnDefs } from './columnDefs';
import { ccValidationRules, skuValidationRules } from './validationRules';

// Nord PatternLibrary
import OrionContextualNav from 'components/OrionContextualNav';

// Redux
import { connect } from 'react-redux';

// Display
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';

import 'stylesheets/SummaryView.scss';

export class SummaryView extends Component {
  static propTypes = {
    tableContent: PropTypes.array.isRequired
  };

  state = {
    rowData: this.props.tableContent,
    columnDefs: getCcColumnDefs(this),
    detailColumnDefs: getSkuColumnDefs(this)
  };

  gridApi = null;

  componentDidMount() {
    const query = new URLSearchParams(window.location.search);
    // If no brandCode or supplier is provided - redirect to workspace view
    if (!query.get('brandCode') && !query.get('supplier')) {
      this.props.history.push(WORKSPACE_VIEW);
    }
    this.props.fetchSummaryContent({
      brandCode: query.get('brandCode'),
      supplier: query.get('supplier')
    });
    this.props.fetchSummaryDepartments();
    this.props.fetchSummarySuppliers();
  }

  componentDidUpdate(prevProps) {
    const { tableContent, departments, suppliers } = this.props;

    if (prevProps.departments !== departments) {
      this.setState({ columnDefs: getCcColumnDefs(this) });
    }

    if (prevProps.suppliers !== suppliers) {
      this.setState({ columnDefs: getCcColumnDefs(this) });
    }

    if (prevProps.tableContent !== tableContent) {
      this.initializeTableData(this.props.tableContent);
    }

    if (prevProps.isContentFetching && !this.props.isContentFetching) {
      if (this.gridApi) {
        this.gridApi.hideOverlay();
      }
    }

    if (!prevProps.contentFetchError && this.props.contentFetchError) {
      if (this.gridApi) {
        this.gridApi.showNoRowsOverlay();
      }
    }
  }

  componentWillUnmount() {
    this.props.resetSummaryView();
  }

  initializeTableData(tableData) {
    // Deep copy table data and update it. It is done due to ag-grid will mutate table data on cell update.
    let tableDataCopy = R.clone(tableData);

    this.validateTableData(tableDataCopy);

    this.setState({ rowData: tableDataCopy }, () => {
      const issuesCount = this.getTotalIssuesCount(tableDataCopy);
      if (issuesCount > 0) {
        Notifier.error(`${issuesCount} CC issues remain.`);
      }
    });
  }

  getTotalIssuesCount(tableData) {
    let issuesCount = 0;
    for (let cc of tableData) {
      issuesCount += cc.numberOfIssues;
      for (let sku of cc.skus) {
        issuesCount += sku.numberOfIssues;
      }
    }
    return issuesCount;
  }

  validateTableData = tableData => {
    const { departments } = this.props;

    for (let cc of tableData) {
      let {
        details: ccDetails,
        issuesCount: ccIssuesCount
      } = Validator.check(ccValidationRules, cc, { departments });
      cc.numberOfIssues = ccIssuesCount;
      for (let key in ccDetails) {
        if (ccDetails.hasOwnProperty(key) && ccDetails[key]) {
          cc[key].error = ccDetails[key];
        }
      }

      for (let sku of cc.skus) {
        let {
          details: skuDetails,
          issuesCount: skuIssuesCount
        } = Validator.check(skuValidationRules, sku);
        sku.numberOfIssues = skuIssuesCount;
        for (let key in skuDetails) {
          if (skuDetails.hasOwnProperty(key) && skuDetails[key]) {
            sku[key].error = skuDetails[key];
          }
        }
      }
    }
  };

  handleGridReady = params => {
    this.gridApi = params.api;
    if (this.props.isContentFetching) {
      params.api.showLoadingOverlay();
    }
    params.api.closeToolPanel();
  };

  handleFirstDataRendered = params => {
    const colIds = params.columnApi.getAllColumns().map(col => col.colId);
    params.columnApi.autoSizeColumns(colIds);
  };

  handleSaveChanges = () => {
    const { userId, userName } = this.props;

    function getRowUpdatedAttrs(row) {
      let attrs = [];
      for (let key of Object.keys(row)) {
        let data = row[key]; // note: data can be object or primitive
        if (data && data.changed) {
          attrs.push({
            AttributeName: key,
            AttributeValue: data.value,
            AttributeState: data.state,
            AttributeReason: data.error ? data.error.reason : null
          });
        }
      }
      return attrs;
    }

    let payload = [];

    let row = {
      AuthorId: userId,
      AuthorName: userName,
      AuthorDomain: 'Orion',
      Timestamp: Date.now()
    };

    for (let cc of this.state.rowData) {
      let ccRow = {
        ...row,
        PieId: cc.pieId,
        PieType: 'cc',
        Attributes: getRowUpdatedAttrs(cc)
      };
      if (ccRow.Attributes.length) {
        payload.push(ccRow);
      }

      for (let sku of cc.skus) {
        let skuRow = {
          ...row,
          PieId: sku.pieId,
          PieType: 'sku',
          Attributes: getRowUpdatedAttrs(sku)
        };
        if (skuRow.Attributes.length) {
          payload.push(skuRow);
        }
      }
    }

    if (!payload.length) {
      return Notifier.info('Currently there are no changes to save.');
    }

    // TODO: "changed" flags should be removed from saved fields.
    // That can be done only after write-back service will return response with feedback on fields that were saved
    // TODO: Notification should be displayed depending on success/error action
    return this.props
      .saveSummaryContent(payload)
      .then(() => Notifier.info('Styles have been updated.'))
      .catch(() =>
        Notifier.error('Error occured while trying to update styles.')
      );
  };

  render() {
    const {
      location,
      tableContent: [tableContent]
    } = this.props;

    const supplierName = tableContent ? tableContent.supplier.value : null;
    const brandName = tableContent ? tableContent.brandName.value : null;
    const name = supplierName ? supplierName : brandName;

    return (
      <>
        <OrionContextualNav
          title={Settings.getTitleString(location.pathname, name)}
          secondaryTitle={Settings.getSecondaryTitleString()}
        >
          <Button
            variant="contained"
            color="secondary"
            classes={{ root: 'flexRight' }}
            onClick={this.handleSaveChanges}
          >
            Save
          </Button>
        </OrionContextualNav>
        <CssBaseline />
        <Grid
          className="summaryView"
          columnDefs={this.state.columnDefs}
          sideBar="columns"
          rowData={this.state.rowData}
          enableRangeSelection={true}
          suppressCopyRowsToClipboard={true}
          onGridReady={this.handleGridReady}
          onFirstDataRendered={this.handleFirstDataRendered}
          detailCellRendererParams={{
            detailGridOptions: {
              columnDefs: this.state.detailColumnDefs,
              enableRangeSelection: true
            },
            getDetailRowData: params => {
              params.successCallback(params.data['skus']);
            }
          }}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    userId: selectAuthUserId(state),
    userName: selectAuthUserName(state),
    tableContent: selectSummaryTableContent(state),
    departments: selectSummaryDepartments(state),
    suppliers: selectSummarySuppliers(state),
    isContentFetching: selectSummaryContentIsFetching(state),
    contentFetchError: selectSummaryContentFetchError(state)
  };
};

const mapDispatchToProps = {
  fetchSummaryContent,
  fetchSummaryDepartments,
  fetchSummarySuppliers,
  saveSummaryContent,
  resetSummaryView
};

export default connect(mapStateToProps, mapDispatchToProps)(SummaryView);
