import React, { Component } from 'react';
import { STATUS_CODE_MESSAGES } from '../../constants/statusCodeConstants';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import OrionAppNav from '../OrionApplicationNav';

import 'stylesheets/FetchFailed.scss';

import nordLogoBlack from '../../images/nordLogoBlack.svg';
import { CssBaseline } from '@material-ui/core';

class FetchFailed extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const statusCode = this.props.location.state.code;
    return (
      <>
        <OrionAppNav
          path={window.location.pathname}
          expiryDate={null} //pass as null b/c error page has no TTL needed
          statusCode={null}
        />
        <CssBaseline />
        <Grid
          container
          spacing={5}
          direction="column"
          alignItems="center"
          justify="center"
          style={{ minHeight: '100vh' }}
        >
          {statusCode === 200 ? (
            <>
              <Grid item>
                <Typography variant="h3" align="center">
                  This Flex Form cannot be submitted due to a recent system
                  update.
                </Typography>
                <Typography variant="h4" align="center">
                  Please work with your NPG contact to generate a new Flex Form.
                  Thank you for your support!
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item>
                <Typography
                  variant="h1"
                  align="center"
                  style={{ fontWeight: 'bold' }}
                >
                  {statusCode}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h3" align="center">
                  {statusCode in STATUS_CODE_MESSAGES
                    ? STATUS_CODE_MESSAGES[statusCode]
                    : STATUS_CODE_MESSAGES['default']}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="h4" align="center">
                  If the issue persists contact support team.
                </Typography>
              </Grid>
            </>
          )}

          <Grid item>
            <img
              src={nordLogoBlack}
              alt="Nordstrom Logo"
              style={{ width: '200px' }}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

export default FetchFailed;
