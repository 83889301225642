/* eslint-disable */
//React
import React, { Component } from 'react';
import { Route, Switch, Redirect, Router } from 'react-router-dom';
import PropTypes from 'prop-types';

//Routes
import {
  SUMMARY_VIEW,
  FETCH_FAILED,
  SESSION_EXPIRED,
  WORKSPACE_VIEW,
  MATERIAL_VIEW,
  COST_VIEW
} from 'constants/routes';

//App
import Initializer from 'components/shared/Initializer';
import SummaryView from 'components/views/SummaryView';
import Loader from 'components/shared/Loader';
import MaterialView from 'components/views/MaterialView';
import CostView from 'components/views/CostView';
import FetchFailed from 'components/views/FetchFailed';
import SessionExpired from 'components/views/SessionExpired';
import OrionCanvas from 'components/OrionCanvas';
import Notifications from 'components/Notifications';
import ErrorBoundary from 'components/shared/ErrorBoundary';

// Material-UI
import CssBaseline from '@material-ui/core/CssBaseline';
import { withStyles } from '@material-ui/styles';

import uuid from 'uuid';
import HttpError from 'utils/errors/HttpError';

const SYNTHETIC_URL = 'sy/';
const COST_URL = 'co/';
const MATERIAL_URL = 'ma/';

const styles = {
  wrapper: {
    flexGrow: 1
  }
};

const currentCostSheetVersion = 'V6';

export class App extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired
  };

  state = {
    fetchStatus: 0,
    fetchJson: {},
    token: '',
    auth: '',
    synthetic: false,
    smacsType: ''
  };

  getMaterialType = (obj, fetchObject) => {
    if (obj.materialType === 'Knit') {
      //Drop down options
      obj.typeDD = fetchObject.smacsData.knitTypeDD.option;
      obj.structureDD = fetchObject.smacsData.knitStructureDD.option;
      obj.weightUomDD = fetchObject.smacsData.knitWeightUOMDD.option;
      obj.gaugeDD = fetchObject.smacsData.knitGaugeDD.option;
      obj.countUomDD = fetchObject.smacsData.knitStitchCountUOMDD.option;
      obj.printProcessDD = fetchObject.smacsData.knitPrintProcessDD.option;
      obj.dyeTypeDD = fetchObject.smacsData.knitDyeTypeDD.option; //select can select an array
      obj.dyeApplicationDD = fetchObject.smacsData.knitDyeApplDD.option;
      obj.performanceDD = fetchObject.smacsData.knitPerformanceDD.option;

      //Knit.java all Knit fields that aren't drop downs
      obj.weight = fetchObject.smacsData.knitWeight;
      obj.yarn = fetchObject.smacsData.knitYarnSizePly;
      obj.count = fetchObject.smacsData.knitStitchCount;

      if (fetchObject.smacsData.knitTypeDD.value) {
        obj.type = Object.keys(fetchObject.smacsData.knitTypeDD.value)[0];
      }

      if (fetchObject.smacsData.knitStructureDD.value) {
        obj.structure = Object.keys(
          fetchObject.smacsData.knitStructureDD.value
        )[0].toString();
      }

      if (fetchObject.smacsData.knitWeightUOMDD.value) {
        obj.weightUom = Object.keys(
          fetchObject.smacsData.knitWeightUOMDD.value
        )[0].toString();
      }

      if (fetchObject.smacsData.knitGaugeDD.value) {
        obj.gauge = Object.keys(
          fetchObject.smacsData.knitGaugeDD.value
        )[0].toString();
      }

      if (fetchObject.smacsData.knitStitchCountUOMDD.value) {
        obj.countUom = Object.keys(
          fetchObject.smacsData.knitStitchCountUOMDD.value
        )[0].toString();
      }

      if (fetchObject.smacsData.knitPrintProcessDD.value) {
        obj.printProcess = Object.keys(
          fetchObject.smacsData.knitPrintProcessDD.value
        )[0].toString();
      }

      //multivaluelist
      if (fetchObject.smacsData.knitDyeTypeDD.valueList) {
        const dyeTypes = [];

        for (
          let i = 0;
          i < fetchObject.smacsData.knitDyeTypeDD.valueList.length;
          i++
        ) {
          dyeTypes[i] = Object.keys(
            fetchObject.smacsData.knitDyeTypeDD.valueList[i]
          )[0].toString();
        }

        obj.dyeType = dyeTypes;
      } else obj.dyeType = [];

      if (fetchObject.smacsData.knitDyeApplDD.value) {
        obj.dyeApplication = Object.keys(
          fetchObject.smacsData.knitDyeApplDD.value
        )[0].toString();
      }

      if (fetchObject.smacsData.knitPerformanceDD.value) {
        obj.performance = Object.keys(
          fetchObject.smacsData.knitPerformanceDD.value
        )[0].toString();
      }
    } else if (obj.materialType === 'Woven') {
      //Drop down options
      obj.typeDD = fetchObject.smacsData.wovenWeaveTypeDD.option;
      obj.weightUomDD = fetchObject.smacsData.wovenWeightUOMDD.option;
      obj.worstedDD = fetchObject.smacsData.wovenFabricWorstedDD.option;
      obj.countUomDD = fetchObject.smacsData.wovenThreadCountUOMDD.option;
      obj.printProcessDD = fetchObject.smacsData.wovenPrintProcessDD.option;
      obj.dyeTypeDD = fetchObject.smacsData.wovenDyeTypeDD.option; //select can select an array
      obj.dyeApplicationDD = fetchObject.smacsData.wovenDyeApplDD.option;
      obj.performanceDD = fetchObject.smacsData.wovenPerformanceDD.option;

      //Woven.java all Woven fields that aren't drop downs
      obj.weight = fetchObject.smacsData.wovenWeight;
      obj.yarn = fetchObject.smacsData.wovenYarnSize;
      obj.count = fetchObject.smacsData.wovenThreadCount;
      obj.diameter = fetchObject.smacsData.wovenFiberDiameter;

      if (fetchObject.smacsData.wovenWeaveTypeDD.value) {
        obj.type = Object.keys(fetchObject.smacsData.wovenWeaveTypeDD.value)[0];
      }

      if (fetchObject.smacsData.wovenWeightUOMDD.value) {
        obj.weightUom = Object.keys(
          fetchObject.smacsData.wovenWeightUOMDD.value
        )[0].toString();
      }

      if (fetchObject.smacsData.wovenFabricWorstedDD.value) {
        obj.worsted = Object.keys(
          fetchObject.smacsData.wovenFabricWorstedDD.value
        )[0].toString();
      }

      if (fetchObject.smacsData.wovenThreadCountUOMDD.value) {
        obj.countUom = Object.keys(
          fetchObject.smacsData.wovenThreadCountUOMDD.value
        )[0].toString();
      }

      if (fetchObject.smacsData.wovenPrintProcessDD.value) {
        obj.printProcess = Object.keys(
          fetchObject.smacsData.wovenPrintProcessDD.value
        )[0].toString();
      }

      //multivaluelist
      if (fetchObject.smacsData.wovenDyeTypeDD.valueList) {
        const dyeTypes = [];

        for (
          let i = 0;
          i < fetchObject.smacsData.wovenDyeTypeDD.valueList.length;
          i++
        ) {
          dyeTypes[i] = Object.keys(
            fetchObject.smacsData.wovenDyeTypeDD.valueList[i]
          )[0].toString();
        }

        obj.dyeType = dyeTypes;
      } else obj.dyeType = [];

      if (fetchObject.smacsData.wovenDyeApplDD.value) {
        obj.dyeApplication = Object.keys(
          fetchObject.smacsData.wovenDyeApplDD.value
        )[0].toString();
      }

      if (fetchObject.smacsData.wovenPerformanceDD.value) {
        obj.performance = Object.keys(
          fetchObject.smacsData.wovenPerformanceDD.value
        )[0].toString();
      }
    } else if (obj.materialType === 'Yarn') {
      // Drop downs
      obj.typeDD = fetchObject.smacsData.yarnTypeDD.option;
      obj.dyeTypeDD = fetchObject.smacsData.yarnDyeTypeDD.option; //select can select an array
      obj.dyeApplicationDD = fetchObject.smacsData.yarnDyeApplDD.option;

      //Yarn.java all Yarn fields that aren't drop downs
      obj.gauge = fetchObject.smacsData.yarnGauge;
      obj.yarn = fetchObject.smacsData.yarnPlySizeDD;

      if (fetchObject.smacsData.yarnTypeDD.value) {
        obj.type = Object.keys(fetchObject.smacsData.yarnTypeDD.value)[0];
      }

      //multivaluelist
      if (fetchObject.smacsData.yarnDyeTypeDD.valueList) {
        const dyeTypes = [];

        for (
          let i = 0;
          i < fetchObject.smacsData.yarnDyeTypeDD.valueList.length;
          i++
        ) {
          dyeTypes[i] = Object.keys(
            fetchObject.smacsData.yarnDyeTypeDD.valueList[i]
          )[0].toString();
        }

        obj.dyeType = dyeTypes;
      } else obj.dyeType = [];

      if (fetchObject.smacsData.yarnDyeApplDD.value) {
        obj.dyeApplication = Object.keys(
          fetchObject.smacsData.yarnDyeApplDD.value
        )[0].toString();
      }
    } else if (obj.materialType === 'Leather') {
      // Drop downs
      obj.typeDD = fetchObject.smacsData.leatherTypeDD.option;
      obj.dyeTypeDD = fetchObject.smacsData.leatherDyeTypeDD.option; //select can select an array
      obj.dyeApplicationDD = fetchObject.smacsData.leatherDyeApplDD.option;
      obj.thicknessDD = fetchObject.smacsData.leatherThicknessDD.option;
      obj.printProcessDD = fetchObject.smacsData.leatherPrintProcessDD.option;
      obj.gradeListDD = fetchObject.smacsData.leatherGradeList.option;

      if (fetchObject.smacsData.leatherTypeDD.value) {
        obj.type = Object.keys(fetchObject.smacsData.leatherTypeDD.value)[0];
      }

      if (fetchObject.smacsData.leatherThicknessDD.value) {
        obj.thickness = Object.keys(
          fetchObject.smacsData.leatherThicknessDD.value
        )[0];
      }

      if (fetchObject.smacsData.leatherPrintProcessDD.value) {
        obj.printProcess = Object.keys(
          fetchObject.smacsData.leatherPrintProcessDD.value
        )[0];
      }

      //multivaluelist
      if (fetchObject.smacsData.leatherDyeTypeDD.valueList) {
        const dyeTypes = [];

        for (
          let i = 0;
          i < fetchObject.smacsData.leatherDyeTypeDD.valueList.length;
          i++
        ) {
          dyeTypes[i] = Object.keys(
            fetchObject.smacsData.leatherDyeTypeDD.valueList[i]
          )[0].toString();
        }

        obj.dyeType = dyeTypes;
      } else obj.dyeType = [];

      if (fetchObject.smacsData.leatherGradeList.valueList) {
        const gradeList = [];

        for (
          let i = 0;
          i < fetchObject.smacsData.leatherGradeList.valueList.length;
          i++
        ) {
          gradeList[i] = Object.keys(
            fetchObject.smacsData.leatherGradeList.valueList[i]
          )[0].toString();
        }

        obj.gradeList = gradeList;
      } else obj.gradeList = [];

      if (fetchObject.smacsData.leatherDyeApplDD.value) {
        obj.dyeApplication = Object.keys(
          fetchObject.smacsData.leatherDyeApplDD.value
        )[0].toString();
      }
    } else if (obj.materialType === 'Other') {
      //Drop down options
      obj.weightUomDD = fetchObject.smacsData.otherWeightUOMDD.option;
      obj.printProcessDD = fetchObject.smacsData.otherPrintProcessDD.option;
      obj.dyeTypeDD = fetchObject.smacsData.otherDyeTypeDD.option; //select can select an array
      obj.dyeApplicationDD = fetchObject.smacsData.otherDyeApplDD.option;

      //Woven.java all Woven fields that aren't drop downs
      obj.weight = fetchObject.smacsData.otherWeight;
      obj.type = fetchObject.smacsData.otherTypeDD;
      obj.thickness = fetchObject.smacsData.otherThicknessDD;

      if (fetchObject.smacsData.otherWeightUOMDD.value) {
        obj.weightUom = Object.keys(
          fetchObject.smacsData.otherWeightUOMDD.value
        )[0].toString();
      }

      if (fetchObject.smacsData.otherPrintProcessDD.value) {
        obj.printProcess = Object.keys(
          fetchObject.smacsData.otherPrintProcessDD.value
        )[0].toString();
      }

      //multivaluelist
      if (fetchObject.smacsData.otherDyeTypeDD.valueList) {
        const dyeTypes = [];

        for (
          let i = 0;
          i < fetchObject.smacsData.otherDyeTypeDD.valueList.length;
          i++
        ) {
          dyeTypes[i] = Object.keys(
            fetchObject.smacsData.otherDyeTypeDD.valueList[i]
          )[0].toString();
        }

        obj.dyeType = dyeTypes;
      } else obj.dyeType = [];

      if (fetchObject.smacsData.otherDyeApplDD.value) {
        obj.dyeApplication = Object.keys(
          fetchObject.smacsData.otherDyeApplDD.value
        )[0].toString();
      }
    } else if (obj.materialType === 'Label') {
      obj.labelArticleNum = fetchObject.smacsData.labelArticleNum;
      // TODO: labelConstruction is a Deprecated field, remove after no longer in use
      obj.labelConstruction = fetchObject.smacsData.labelConstruction;
      obj.labelEndUse = fetchObject.smacsData.labelEndUse;
      obj.labelLabelBrand = fetchObject.smacsData.labelLabelBrand;
      obj.labelLabelType = fetchObject.smacsData.labelLabelType;
      obj.materialComments = fetchObject.smacsData.materialComments;
      obj.labelMaterialStatus = fetchObject.smacsData.labelMaterialStatus;
      obj.labelSustainableComments =
        fetchObject.smacsData.labelSustainableComments;

      if (
        fetchObject.smacsData.materialVersion &&
        fetchObject.smacsData.materialVersion !== 'V1'
      ) {
        obj.labelDivision = fetchObject.smacsData.labelDivision;
        obj.labelProductCategory = fetchObject.smacsData.labelProductCategory;
        obj.labelConstructionDD =
          fetchObject.smacsData.labelConstructionDD.option;
      }

      obj.labelClassificationDD =
        fetchObject.smacsData.labelClassificationDD.option;

      obj.labelDenierDD = fetchObject.smacsData.labelDenierDD.option;
      obj.labelFinishDD = fetchObject.smacsData.labelFinishDD.option;
      obj.labelWeaveDD = fetchObject.smacsData.labelWeaveDD.option;
      obj.labelExtrasMiscDD = fetchObject.smacsData.labelExtrasMisc.option;
      obj.labelMaterialDD = fetchObject.smacsData.labelMaterial.option;
      obj.labelSustainableDD = fetchObject.smacsData.labelSustainable.option;

      if (fetchObject.smacsData.labelClassificationDD.value) {
        obj.labelClassification = Object.keys(
          fetchObject.smacsData.labelClassificationDD.value
        )[0];
      }

      if (
        fetchObject.smacsData.materialVersion &&
        fetchObject.smacsData.materialVersion !== 'V1'
      ) {
        if (fetchObject.smacsData.labelConstructionDD.value) {
          obj.labelConstruction = Object.keys(
            fetchObject.smacsData.labelConstructionDD.value
          )[0];
        }
      }

      if (fetchObject.smacsData.labelDenierDD.value) {
        obj.labelDenier = Object.keys(
          fetchObject.smacsData.labelDenierDD.value
        )[0];
      }

      // TODO: labelDepartment is a deprecated field. Remove when no longer used.
      obj.labelDepartment = fetchObject.smacsData.labelDepartment;

      if (fetchObject.smacsData.labelFinishDD.value) {
        obj.labelFinish = Object.keys(
          fetchObject.smacsData.labelFinishDD.value
        )[0];
      }

      if (fetchObject.smacsData.labelWeaveDD.value) {
        obj.labelWeave = Object.keys(
          fetchObject.smacsData.labelWeaveDD.value
        )[0];
      }

      if (fetchObject.smacsData.labelExtrasMisc.valueList) {
        const labelExtras = [];

        for (
          let i = 0;
          i < fetchObject.smacsData.labelExtrasMisc.valueList.length;
          i++
        ) {
          labelExtras[i] = Object.keys(
            fetchObject.smacsData.labelExtrasMisc.valueList[i]
          )[0].toString();
        }
        obj.labelExtrasMisc = labelExtras;
      } else obj.labelExtrasMisc = [];

      if (fetchObject.smacsData.labelMaterial.valueList) {
        const labelMaterials = [];

        for (
          let i = 0;
          i < fetchObject.smacsData.labelMaterial.valueList.length;
          i++
        ) {
          labelMaterials[i] = Object.keys(
            fetchObject.smacsData.labelMaterial.valueList[i]
          )[0].toString();
        }
        obj.labelMaterials = labelMaterials;
      } else obj.labelMaterials = [];

      if (fetchObject.smacsData.labelSustainable.valueList) {
        const labelSustainable = [];

        for (
          let i = 0;
          i < fetchObject.smacsData.labelSustainable.valueList.length;
          i++
        ) {
          labelSustainable[i] = Object.keys(
            fetchObject.smacsData.labelSustainable.valueList[i]
          )[0].toString();
        }
        obj.labelSustainable = labelSustainable;
      } else obj.labelSustainable = [];
    } else if (obj.materialType === 'Packaging') {
      //copy object to get all attributes
      obj = Object.assign(obj, fetchObject.smacsData);
      //Overwriting dropdown and other fields
      if (fetchObject.smacsData.pkgClassificationDD.value) {
        obj.pkgClassification = Object.keys(
          fetchObject.smacsData.pkgClassificationDD.value
        )[0];
      }

      // TODO: pkgDepartment is a deprecated field. Remove when no longer used.
      obj.pkgDepartment = fetchObject.smacsData.pkgDepartment;

      if (
        fetchObject.smacsData.materialVersion &&
        fetchObject.smacsData.materialVersion !== 'V1'
      ) {
        if (fetchObject.smacsData.pkgConstructionDD.value) {
          obj.pkgConstruction = Object.keys(
            fetchObject.smacsData.pkgConstructionDD.value
          )[0];
        }
      }

      if (fetchObject.smacsData.pkgPrintMethodDD.value) {
        obj.pkgPrintMethod = Object.keys(
          fetchObject.smacsData.pkgPrintMethodDD.value
        )[0];
      }

      obj.pkgExtrasMiscDD = obj.pkgExtrasMisc.option;
      if (fetchObject.smacsData.pkgExtrasMisc.valueList) {
        const pkgExtrasMisc = [];

        for (
          let i = 0;
          i < fetchObject.smacsData.pkgExtrasMisc.valueList.length;
          i++
        ) {
          pkgExtrasMisc[i] = Object.keys(
            fetchObject.smacsData.pkgExtrasMisc.valueList[i]
          )[0].toString();
        }
        obj.pkgExtrasMisc = pkgExtrasMisc;
      } else obj.pkgExtrasMisc = [];

      obj.pkgFinishDD = obj.pkgFinish.option;
      if (fetchObject.smacsData.pkgFinish.valueList) {
        const pkgFinish = [];

        for (
          let i = 0;
          i < fetchObject.smacsData.pkgFinish.valueList.length;
          i++
        ) {
          pkgFinish[i] = Object.keys(
            fetchObject.smacsData.pkgFinish.valueList[i]
          )[0].toString();
        }
        obj.pkgFinish = pkgFinish;
      } else obj.pkgFinish = [];

      obj.pkgMaterialDD = obj.pkgMaterial.option;
      if (fetchObject.smacsData.pkgMaterial.valueList) {
        const pkgMaterial = [];

        for (
          let i = 0;
          i < fetchObject.smacsData.pkgMaterial.valueList.length;
          i++
        ) {
          pkgMaterial[i] = Object.keys(
            fetchObject.smacsData.pkgMaterial.valueList[i]
          )[0].toString();
        }
        obj.pkgMaterial = pkgMaterial;
      } else obj.pkgMaterial = [];

      obj.pkgSustainableDD = obj.pkgSustainable.option;
      if (fetchObject.smacsData.pkgSustainable.valueList) {
        const pkgSustainable = [];

        for (
          let i = 0;
          i < fetchObject.smacsData.pkgSustainable.valueList.length;
          i++
        ) {
          pkgSustainable[i] = Object.keys(
            fetchObject.smacsData.pkgSustainable.valueList[i]
          )[0].toString();
        }
        obj.pkgSustainable = pkgSustainable;
      } else obj.pkgSustainable = [];
    }
  };

  getOwnerState = () => {
    const fetchObject = this.state.fetchJson;
    let obj = {
      //Material.java header and hidden fields
      materialType: fetchObject.smacsData.materialType,
      materialName: fetchObject.smacsData.materialName,
      date: fetchObject.smacsData.dateGenerated,
      materialEditable: fetchObject.smacsData.materialEditable,
      colorVisible: fetchObject.smacsData.colorVisible,
      materialVersion: fetchObject.smacsData.materialVersion
    };

    if (
      fetchObject.smacsData['materialType'] !== 'Packaging' &&
      fetchObject.smacsData['materialType'] !== 'Label'
    ) {
      obj = {
        ...obj,
        finishDD: fetchObject.smacsData.materialFinish.option, //select can select an array

        //materialContent
        contentDD: fetchObject.smacsData.materialContent.materialContentDD
      };

      //multivaluelist
      if (fetchObject.smacsData.materialFinish.valueList) {
        const finish = [];

        for (
          let i = 0;
          i < fetchObject.smacsData.materialFinish.valueList.length;
          i++
        ) {
          finish[i] = Object.keys(
            fetchObject.smacsData.materialFinish.valueList[i]
          )[0].toString();
        }

        obj.finish = finish;
      } else obj.finish = [];

      const content = [];

      if (fetchObject.smacsData.materialContent.materialContent) {
        for (
          let i = 0;
          i < fetchObject.smacsData.materialContent.materialContent.length;
          i++
        ) {
          if (
            fetchObject.smacsData.materialContent.materialContent[i].content
          ) {
            content[i] = {
              content: Object.keys(
                fetchObject.smacsData.materialContent.materialContent[i].content
              )[0].toString(),
              percentage:
                fetchObject.smacsData.materialContent.materialContent[i]
                  .contentPercentage
            };
          } else {
            content[i] = {
              percentage:
                fetchObject.smacsData.materialContent.materialContent[i]
                  .contentPercentage
            };
          }
        }

        obj.contentState = content;
      }
    } else if (fetchObject.smacsData['materialType'] === 'Packaging') {
      obj = {
        ...obj
      };
    } else if (fetchObject.smacsData['materialType'] === 'Label') {
      obj = {
        ...obj
      };
    }

    this.getMaterialType(obj, fetchObject);
    return { ...obj };
  };

  getSupplierState = () => {
    const fetchObject = this.state.fetchJson;
    let obj;
    if (
      fetchObject.smacsData.type !== 'packaging' &&
      fetchObject.smacsData.type !== 'label'
    ) {
      obj = {
        materialEditable: fetchObject.smacsData.materialEditable,
        materialType: fetchObject.smacsData.materialType,
        supplierName: fetchObject.smacsData['supplierName'],
        mill: fetchObject.smacsData['supplierMill'],
        spinner: fetchObject.smacsData['supplierSpinner'],
        samplePrice: fetchObject.smacsData['supplierSamplePrice'],
        sampleUomDD: fetchObject.smacsData.supplierSampleUOMDD.option,
        sampleCurrency: fetchObject.smacsData['supplierSampleCurrency'],
        bulkPrice: fetchObject.smacsData['supplierInitialBulkPrice'],
        bulkUomDD: fetchObject.smacsData.supplierBulkUOMDD.option,
        bulkCurrency: fetchObject.smacsData['supplierBulkCurrency'],
        width: fetchObject.smacsData['cuttableWidthSize'],
        lowestAcceptable: fetchObject.smacsData['supplierLowestBulkMOQMOC'],
        fiberCountryOfOriginInformation:
          fetchObject.smacsData['fiberCountryOfOriginInformation'],
        articleName: fetchObject.smacsData['supplierArticleName'],
        sampleMinimum: fetchObject.smacsData['supplierSampleMinOrderColor'],
        sampleLead: fetchObject.smacsData['supplierSampleLeadTime'],
        bulkMinimum: fetchObject.smacsData['supplierBulkOrderMin'],
        bulkColor: fetchObject.smacsData['supplierBulkColorMin'],
        bulkLead: fetchObject.smacsData['supplierBulkLeadTime'],
        countryDD: fetchObject.smacsData.supplierCountryOfOriginDD.option,
        skinsDD: fetchObject.smacsData.supplierOriginOfSkinsDD.option,
        millCountryDD: fetchObject.smacsData.millCountryOfOriginDD.option,
        spinnerCountryDD: fetchObject.smacsData.spinnerCountryOfOriginDD.option,
        supplierComments: this.state.fetchJson.smacsData[
          'supplierMaterialSuppComments'
        ],
        recommendedCare: fetchObject.smacsData['supplierCareInstructions'],
        howToColor: fetchObject.smacsData['materialComments'],
        // Safe destructuring of OSR to keep old dtos stable.
        osrCertificationDD: fetchObject.smacsData.osrCertification
          ? fetchObject.smacsData.osrCertification.option
          : null
      };

      //updated 4 NPS Test fields
      const npsTest = [];
      for (let i = 1; i <= 4; i++) {
        let idx = 'npsTest' + i;
        if (fetchObject.smacsData[idx]) {
          let meetSpecs = '';
          if (fetchObject.smacsData[idx].doesMaterialPass.value) {
            meetSpecs = Object.keys(
              fetchObject.smacsData[idx].doesMaterialPass.value
            )[0].toString();
          }

          let nps = {
            number: fetchObject.smacsData[idx].number,
            doesMaterialPass: meetSpecs || '',
            isNoBrieflyExplain: fetchObject.smacsData[idx].isNoBrieflyExplain
          };
          npsTest.push(nps);

          obj.meetSpecsDD = fetchObject.smacsData[idx].doesMaterialPass.option;
        }
      }
      obj.npsTest = npsTest;

      if (fetchObject.smacsData.supplierSampleUOMDD.value) {
        obj.sampleUom = Object.keys(
          fetchObject.smacsData.supplierSampleUOMDD.value
        )[0];
      }

      if (fetchObject.smacsData.supplierBulkUOMDD.value) {
        obj.bulkUom = Object.keys(
          fetchObject.smacsData.supplierBulkUOMDD.value
        )[0].toString();
      }

      if (fetchObject.smacsData.supplierCountryOfOriginDD.value) {
        obj.country = Object.keys(
          fetchObject.smacsData.supplierCountryOfOriginDD.value
        )[0].toString();
      }

      if (fetchObject.smacsData.supplierOriginOfSkinsDD.value) {
        obj.skins = Object.keys(
          fetchObject.smacsData.supplierOriginOfSkinsDD.value
        )[0].toString();
      }

      if (fetchObject.smacsData.spinnerCountryOfOriginDD.value) {
        obj.spinnerCountry = Object.keys(
          fetchObject.smacsData.spinnerCountryOfOriginDD.value
        )[0].toString();
      }

      if (fetchObject.smacsData.millCountryOfOriginDD.value) {
        obj.millCountry = Object.keys(
          fetchObject.smacsData.millCountryOfOriginDD.value
        )[0].toString();
      }

      if (
        fetchObject.smacsData.osrCertification &&
        fetchObject.smacsData.osrCertification.valueList
      ) {
        const osrCertification = [];

        for (
          let i = 0;
          i < fetchObject.smacsData.osrCertification.valueList.length;
          i++
        ) {
          osrCertification[i] = Object.keys(
            fetchObject.smacsData.osrCertification.valueList[i]
          )[0].toString();
        }

        obj.osrCertification = osrCertification;
      } else obj.osrCertification = [];
    } else {
      obj = {
        materialEditable: fetchObject.smacsData.materialEditable,
        materialType: fetchObject.smacsData.materialType,
        supplierName: fetchObject.smacsData.lblPkgSupplier,
        supplierComments: fetchObject.smacsData.lblPkgSupplierComments,
        supplierTieredPricingDD:
          fetchObject.smacsData.lblPkgTieredPricing.option,
        supplierReference: fetchObject.smacsData.lblPkgSupplierRefNum,
        supplierUnitPrice: fetchObject.smacsData.lblPkgUnitPrice,
        supplierCostPerThousand: fetchObject.smacsData.lblPkgCostPerThousand,
        supplierMinOrderSkuQty: fetchObject.smacsData.lblPkgMinOrderQty,
        supplierLeadTimeInDays: fetchObject.smacsData.lblPkgLeadTime,
        supplierYesTieredPricing: fetchObject.smacsData.lblPkgYesTieredPricing,
        supplierInventoryPickPackLeadTime:
          fetchObject.smacsData.lblPkgBulkOrderSetup,
        supplierInventoryPackIncrements:
          fetchObject.smacsData.lblPkgYesBulkOrderSetup
      };

      if (fetchObject.smacsData.lblPkgTieredPricing.value) {
        obj.supplierTieredPricing = Object.keys(
          fetchObject.smacsData.lblPkgTieredPricing.value
        )[0].toString();
      }
    }
    this.getSupplierType(obj, fetchObject);

    return {
      ...obj
    };
  };

  getSupplierType = (obj, fetchObject) => {
    if (
      obj.materialType === 'Knit' ||
      obj.materialType === 'Yarn' ||
      obj.materialType === 'Woven' ||
      obj.materialType === 'Leather' ||
      obj.materialType === 'Other'
    ) {
      obj.initialPrice1 =
        fetchObject.smacsData.bulkValues[0].bulkValueInitialPrice;
      obj.orderMinimum1 =
        fetchObject.smacsData.bulkValues[0].bulkValueOrderMinimum;
      obj.colorMinimum1 =
        fetchObject.smacsData.bulkValues[0].bulkValueColorMinimum;
      obj.leadTime1 = fetchObject.smacsData.bulkValues[0].bulkValueLeadTime;
      obj.initialPrice2 =
        fetchObject.smacsData.bulkValues[1].bulkValueInitialPrice;
      obj.orderMinimum2 =
        fetchObject.smacsData.bulkValues[1].bulkValueOrderMinimum;
      obj.colorMinimum2 =
        fetchObject.smacsData.bulkValues[1].bulkValueColorMinimum;
      obj.leadTime2 = fetchObject.smacsData.bulkValues[1].bulkValueLeadTime;
      obj.initialPrice3 =
        fetchObject.smacsData.bulkValues[2].bulkValueInitialPrice;
      obj.orderMinimum3 =
        fetchObject.smacsData.bulkValues[2].bulkValueOrderMinimum;
      obj.colorMinimum3 =
        fetchObject.smacsData.bulkValues[2].bulkValueColorMinimum;
      obj.leadTime3 = fetchObject.smacsData.bulkValues[2].bulkValueLeadTime;
      obj.bulkValuesDD = fetchObject.smacsData.bulkValuesDD;

      if (fetchObject.smacsData.bulkValues[0].bulkValue) {
        obj.bulkValue1 = Object.keys(
          fetchObject.smacsData.bulkValues[0].bulkValue
        )[0].toString();
      }

      if (fetchObject.smacsData.bulkValues[1].bulkValue) {
        obj.bulkValue2 = Object.keys(
          fetchObject.smacsData.bulkValues[1].bulkValue
        )[0].toString();
      }

      if (fetchObject.smacsData.bulkValues[2].bulkValue) {
        obj.bulkValue3 = Object.keys(
          fetchObject.smacsData.bulkValues[2].bulkValue
        )[0].toString();
      }
    }
  };

  callRestApi = smacsPath => {
    let synthetic = false;
    let headers = smacsPath.substring(1).split('.');
    let [ttl, smacsId] = headers;
    let url = `/smacsRest/material/${smacsId}`;
    let smacsType = 'material';

    headers.splice(1, 1);

    if (ttl.startsWith(SYNTHETIC_URL)) {
      synthetic = true;
      ttl = ttl.substring(SYNTHETIC_URL.length);
    }

    if (ttl.startsWith(MATERIAL_URL)) {
      ttl = ttl.substring(MATERIAL_URL.length);
    } else if (ttl.startsWith(COST_URL)) {
      ttl = ttl.substring(COST_URL.length);
      url = `/smacsRest/cost/${smacsId}`;
      smacsType = 'cost';
    }

    headers[0] = ttl;
    let sig = headers.join('.');

    const payload = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Nord-Client-Id': 'APP03154',
        'Nord-Synthetic': synthetic,
        'Nord-Request-Id': uuid(),
        AUTH: sig
      }
    };

    return fetch(url, payload)
      .then(response => {
        if (!response.ok) {
          throw new HttpError(response.status, 'Error fetching data');
        } else {
          return response.json();
        }
      })
      .then(responseJson => {
        this.setState({
          token: smacsId,
          auth: sig,
          fetchStatus: 200,
          fetchJson: responseJson,
          synthetic: synthetic,
          smacsType: smacsType
        });
      })
      .catch(error => {
        if (error instanceof HttpError) {
          this.setState({ fetchStatus: error.statusCode });
        } else {
          this.setState({ fetchStatus: error.message });
        }
      });
  };

  render() {
    const { classes } = this.props;

    const path = window.location.pathname;

    if (this.state.fetchStatus === 0) {
      if (
        path !== '/' &&
        path !== '/home' &&
        path !== '/materialview' &&
        path !== '/costview'
      ) {
        this.callRestApi(path);
      }
    }

    let redirect = <Redirect to={WORKSPACE_VIEW} />;
    let authroute = <Loader />;

    if (this.state.fetchStatus === 200) {
      if (this.state.smacsType === 'material') {
        if (
          (this.state.fetchJson.smacsData.millCountryOfOriginDD == null ||
            this.state.fetchJson.smacsData.millCountryOfOriginDD == undefined ||
            this.state.fetchJson.smacsData.spinnerCountryOfOriginDD == null ||
            this.state.fetchJson.smacsData.spinnerCountryOfOriginDD ==
              undefined) &&
          this.state.fetchJson.smacsData.type !== 'label' &&
          this.state.fetchJson.smacsData.type !== 'packaging'
        ) {
          redirect = (
            <Redirect
              to={{
                pathname: FETCH_FAILED,
                state: {
                  code: this.state.fetchStatus,
                  auth: this.state.auth
                }
              }}
            />
          );
          authroute = (
            <Route path={FETCH_FAILED} exact={true} component={FetchFailed} />
          );
        } else {
          const fetchedSupplierState = this.getSupplierState();
          const fetchedOwnerState = this.getOwnerState();
          redirect = (
            <Redirect
              to={{
                pathname: MATERIAL_VIEW,
                state: {
                  originalJson: this.state.fetchJson,
                  ownerState: fetchedOwnerState,
                  supplierState: fetchedSupplierState,
                  token: this.state.token,
                  auth: this.state.auth,
                  synthetic: this.state.synthetic,
                  fetchStatus: this.state.fetchStatus
                }
              }}
            />
          );
          authroute = (
            <Route path={MATERIAL_VIEW} exact={true} component={MaterialView} />
          );
        }
      } else if (this.state.smacsType === 'cost') {
        redirect = (
          <Redirect
            to={{
              pathname: COST_VIEW,
              state: {
                originalJson: this.state.fetchJson,
                token: this.state.token,
                auth: this.state.auth,
                synthetic: this.state.synthetic,
                fetchStatus: this.state.fetchStatus
              }
            }}
          />
        );
        authroute = (
          <Route path={COST_VIEW} exact={true} component={CostView} />
        );
      }
    } else if (this.state.fetchStatus >= 400) {
      redirect = (
        <Redirect
          to={{
            pathname: FETCH_FAILED,
            state: {
              code: this.state.fetchStatus,
              auth: this.state.auth
            }
          }}
        />
      );
      authroute = (
        <Route path={FETCH_FAILED} exact={true} component={FetchFailed} />
      );
    }
    return (
      <>
        <CssBaseline />
        <main className={classes.wrapper}>
          <div className="MuiToolbar-dense" />
          <OrionCanvas>
            <ErrorBoundary>
              <Switch>
                <Route
                  path={FETCH_FAILED}
                  exact={true}
                  component={FetchFailed}
                />
                <Route
                  path={MATERIAL_VIEW}
                  exact={true}
                  component={MaterialView}
                />
                <Route path={COST_VIEW} exact={true} component={CostView} />
                <Route //TODO: Turn this into a modal.
                  path={SESSION_EXPIRED}
                  exact={true}
                  component={SessionExpired}
                />

                <Route>
                  <Initializer>
                    <Switch>
                      {authroute}
                      <Route
                        path={SUMMARY_VIEW}
                        exact={true}
                        component={SummaryView}
                      />
                      {/* Unknown route case, can be replaced with 404 */}
                      {redirect}
                    </Switch>
                  </Initializer>
                </Route>
              </Switch>
            </ErrorBoundary>
          </OrionCanvas>
        </main>
        <Notifications />
      </>
    );
  }
}

export default withStyles(styles)(App);
