import React from 'react';
import PropTypes from 'prop-types';
import { Chip, MenuItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '95%'
    },
    '& .MuiInputBase-multiline': {
      minHeight: '8ch',
      width: '40ch',
      height: 'auto'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}));

const MaterialTypeR = ({ ownerState, handleOwnerChange }) => {
  const classes = useStyles();

  var readOnly = false;
  if (ownerState.materialEditable === 'false') {
    readOnly = true;
  }

  const finish = ownerState.finishDD;
  const finishKeys = Object.keys(finish);

  const finishField = () => {
    if (ownerState.materialType !== 'Other') {
      return (
        <div>
          <TextField
            label="Finish"
            variant="outlined"
            multiline
            select
            name="finish"
            id="finish"
            SelectProps={{
              multiple: true,
              renderValue: selected => (
                <div className={classes.chips}>
                  {selected.map(value => (
                    <Chip
                      key={value}
                      label={finish[value]}
                      className={classes.chip}
                    />
                  ))}
                </div>
              )
            }}
            disabled={readOnly}
            InputProps={{
              readOnly: readOnly
            }}
            value={ownerState.finish}
            onChange={handleOwnerChange}
          >
            {finishKeys.map(option => (
              <MenuItem key={option} value={option}>
                {finish[option]}
              </MenuItem>
            ))}
          </TextField>
          <br />
        </div>
      );
    }
  };

  return (
    <div className={classes.root}>
      {/* right side of the uneditable side of the form */}
      {finishField()}

      <br />
      <br />
    </div>
  );
};

MaterialTypeR.propTypes = {
  ownerState: PropTypes.object,
  handleOwnerChange: PropTypes.func
};

export default MaterialTypeR;
