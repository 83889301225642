import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Fetcher from 'services/Fetcher';
import Notifier from 'services/Notifier';
import Loader from 'components/shared/Loader';
import { selectAuthAccessToken } from 'selectors';

export class Initializer extends Component {
  static propTypes = {
    accessToken: PropTypes.string.isRequired
  };

  state = {
    isInitialized: false
  };

  componentDidMount() {
    this.initializeServices();
    this.setState({ isInitialized: true });
  }

  initializeServices() {
    const { dispatch, accessToken } = this.props;
    Fetcher.init(dispatch, accessToken);
    Notifier.init(dispatch);
  }

  render() {
    const { isInitialized } = this.state;
    const { children } = this.props;

    if (!isInitialized) {
      return <Loader />;
    }

    return children;
  }
}

const mapStateToProps = state => ({
  accessToken: selectAuthAccessToken(state)
});

export default connect(mapStateToProps)(Initializer);
