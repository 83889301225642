import moment from 'moment';

export const ccValidationRules = {
  _defaults: {
    validators: [serverError, required]
  },
  department: {
    attribute: 'department',
    validators: ({ value }, _label, { departments }) => {
      return departments.find(d => d.departmentId === value)
        ? null
        : { state: 'error', reason: 'Department not found' };
    }
  },
  styleId: {
    attribute: 'styleId'
  },
  styleName: {
    attribute: 'styleName'
  },
  designer: {
    attribute: 'designer'
  },
  countryOfOrigin: {
    attribute: 'countryOfOrigin',
    label: 'Country of origin'
  },
  countryOfMaterialsOrigin: {
    attribute: 'countryOfMaterialsOrigin',
    label: 'Country of materials origin'
  },
  nrfColorCode: {
    attribute: 'nrfColorCode'
  },
  supplierColor: {
    attribute: 'supplierColor'
  },
  supplierSize: {
    attribute: 'supplierSize'
  },
  unitSize: {
    attribute: 'unitSize'
  },
  class: {
    attribute: 'class'
  },
  subClass: {
    attribute: 'subClass'
  },
  productLabel: {
    attribute: 'productLabel',
    label: 'Product label'
  },
  nDirect: {
    attribute: 'nDirect'
  },
  flsPackingMethod: {
    attribute: 'flsPackingMethod'
  },
  nDirectPackingMethod: {
    attribute: 'nDirectPackingMethod'
  },
  ticketType: {
    attribute: 'ticketType'
  },
  hangerType: {
    attribute: 'hangerType'
  },
  sellingStatus: {
    attribute: 'sellingStatus'
  },
  disposition: {
    attribute: 'disposition'
  },
  sourcingCategory: {
    attribute: 'sourcingCategory'
  },
  liveDate: {
    attribute: 'liveDate'
  },
  styleGroup: {
    attribute: 'styleGroup'
  },
  ndStyleName: {
    attribute: 'ndStyleName'
  },
  gender: {
    attribute: 'gender'
  },
  age: {
    attribute: 'age'
  },
  productType1: {
    attribute: 'productType1'
  },
  productType2: {
    attribute: 'productType2'
  },
  colorNumber: {
    attribute: 'colorNumber'
  },
  displayColorName: {
    attribute: 'displayColorName'
  },
  colorFamily: {
    attribute: 'colorFamily'
  },
  rmsStyle: {
    attribute: 'rmsStyle'
  },
  rmsSizeGroup: {
    attribute: 'rmsSizeGroup'
  },
  rmsSizeRange: {
    attribute: 'rmsSizeRange'
  },
  supplier: {
    attribute: 'supplier'
  },
  poDue: {
    attribute: 'poDue',
    validators: [
      //TODO: Currently UI isn't providing proper date string upon user field change. This needs attention.
      val =>
        moment(val, moment.ISO_8601, true).isValid()
          ? null
          : { state: 'error', reason: 'Not a valid date' }
    ]
  }
};

export const skuValidationRules = {
  _defaults: {
    validators: [serverError, required]
  },
  costPerUnit: {
    attribute: 'costPerUnit',
    label: 'Cost per unit'
  },
  pricePerUnit: {
    attribute: 'pricePerUnit',
    label: 'Price per unit'
  },
  markupPercentage: {
    attribute: 'markupPercentage'
  },
  specialCostPerUnit: {
    attribute: 'specialCostPerUnit',
    validators: [valueIsFinite]
  },
  specialPricePerUnit: {
    attribute: 'specialPricePerUnit',
    validators: [valueIsFinite]
  },
  mfgCost: {
    attribute: 'mfgCost',
    validators: [valueIsFinite]
  },
  countryOfOrigin: {
    attribute: 'countryOfOrigin',
    label: 'Country of origin'
  },
  countryOfMaterialsOrigin: {
    attribute: 'countryOfMaterialsOrigin',
    label: 'Country of materials origin'
  },
  supplierSize: {
    attribute: 'supplierSize'
  },
  size1: {
    attribute: 'size1'
  },
  size2: {
    attribute: 'size2'
  },
  upc: {
    attribute: 'upc'
  },
  nDirect: {
    attribute: 'nDirect'
  },
  dropship: {
    attribute: 'dropship'
  },
  fls: {
    attribute: 'fls'
  },
  sellingStatus: {
    attribute: 'sellingStatus'
  },
  liveDate: {
    attribute: 'liveDate'
  },
  ndSize1: {
    attribute: 'ndSize1'
  },
  ndSize2: {
    attribute: 'ndSize2'
  }
};

export function serverError({ error }) {
  return !error ? null : error;
}

export function required({ value }, label) {
  return !!value
    ? null
    : { state: 'error', reason: label ? `${label} required` : 'Required' };
}

export function valueIsFinite({ value }) {
  return isFinite(value) ? null : { state: 'error', reason: 'Wrong value' };
}
