import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import 'stylesheets/CostingInformation.scss';
import { Utils } from 'utils';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch'
    },
    '& .MuiInputBase-multiline': {
      minHeight: '8ch',
      width: '40ch',
      height: 'auto'
    }
  },
  root2: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },
  disabled: {
    background: '#A8A8A8',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '30ch'
    },
    '& .MuiInputBase-multiline': {
      minHeight: '8ch',
      width: '40ch',
      height: 'auto'
    }
  },
  disabled2: {
    background: '#A8A8A8',
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    }
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  }
}));

const WovenCost = props => {
  const classes = useStyles();

  const wovenList = props.wovenList;
  const currencyDD = {
    usd: 'USD',
    eur: 'EUR'
  };
  const handleWovenChange = props.handleWovenChange;

  const wovenFabricPriceYieldUOMDD = props.wovenFabricPriceYieldUOMDD;

  const type = props.type;

  const enableState = props.enableState;

  const costSheetVersion = props.costSheetVersion;

  return (
    <Grid>
      <div className={classes.root}>
        <hr />
        {wovenList.map((val, idx) => (
          <div key={`ci-${idx}`} className="column">
            <div className="ci-header">{type} Costing Information</div>
            <hr />
            <TextField
              label="Fabric Weight (Grams/Sq. Meter)"
              variant="outlined"
              type="text"
              name={`fabric-weight-${idx}`}
              data-idx={idx}
              id={`fabric-weight-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['wovenFabricWt']}
              disabled={!enableState[idx]}
              onBlur={e => handleWovenChange(e, idx, 'wovenFabricWt')}
              onChange={e => handleWovenChange(e, idx, 'wovenFabricWt')}
            />
            <br />
            <TextField
              label="UOM Fabric, Price and Yield"
              variant="outlined"
              select
              required={enableState[idx]}
              name={`fabric-price-${idx}`}
              data-idx={idx}
              id={`fabric-price-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['wovenFabricPriceYieldUOMDD']}
              disabled={!enableState[idx]}
              onChange={e =>
                handleWovenChange(e, idx, 'wovenFabricPriceYieldUOMDD')
              }
            >
              {Utils.sortObject(wovenFabricPriceYieldUOMDD)}
            </TextField>
            <br />
            <TextField
              label="Currency Denomination"
              variant="outlined"
              select
              required={enableState[idx]}
              name={`currency-denomination-${idx}`}
              data-idx={idx}
              id={`currency-denomination-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['wovenCurrency']}
              disabled={!enableState[idx]}
              onChange={e => handleWovenChange(e, idx, 'wovenCurrency')}
            >
              {Utils.sortObject(currencyDD)}
            </TextField>
            <br />
            <div className="page-break" />
            <br className="print-show" />
            <div className={classes.root2}>
              <TextField
                label="UOM"
                variant="outlined"
                type="text"
                name={`main-fabric-price-uom-${idx}`}
                data-idx={idx}
                id={`main-fabric-price-uom-${idx}`}
                className={!enableState[idx] && classes.disabled2}
                value={val['wovenMainFabricPriceUOM'] || ''}
                disabled={true}
                InputProps={{
                  readOnly: true
                }}
              />
              <TextField
                label="Main Fabric Price /"
                variant="outlined"
                type="text"
                required={enableState[idx]}
                name={`main-fabric-price-${idx}`}
                data-idx={idx}
                id={`main-fabric-price-${idx}`}
                className={!enableState[idx] && classes.disabled2}
                value={val['wovenMainFabricPrice']}
                disabled={!enableState[idx]}
                onBlur={e => handleWovenChange(e, idx, 'wovenMainFabricPrice')}
                onChange={e =>
                  handleWovenChange(e, idx, 'wovenMainFabricPrice')
                }
              />
            </div>
            <TextField
              label="Fabric Finance Charge"
              variant="outlined"
              type="text"
              required={enableState[idx]}
              name={`fabric-finance-${idx}`}
              data-idx={idx}
              id={`fabric-finance-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['wovenFabricFinChg']}
              disabled={!enableState[idx]}
              onBlur={e => handleWovenChange(e, idx, 'wovenFabricFinChg')}
              onChange={e => handleWovenChange(e, idx, 'wovenFabricFinChg')}
            />
            <br />
            <TextField
              label="Fabric Finance Charge %"
              variant="outlined"
              type="number"
              name={`fabric-finance-pct-${idx}`}
              data-idx={idx}
              id={`fabric-finance-pct-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['wovenFabricFinPct'] || ''}
              disabled={true}
              InputProps={{
                readOnly: true
              }}
            />
            <br />
            <div className={classes.root2}>
              <TextField
                label="UOM"
                variant="outlined"
                type="text"
                name={`fabric-gmt-uom-${idx}`}
                data-idx={idx}
                id={`fabric-gmt-uom-${idx}`}
                className={!enableState[idx] && classes.disabled2}
                value={val['wovenMainFabricYieldUOM'] || ''}
                disabled={true}
                InputProps={{
                  readOnly: true
                }}
              />
              <TextField
                label="Main Fabric Gmt Yield"
                variant="outlined"
                type="text"
                required={enableState[idx]}
                name={`fabric-gmt-${idx}`}
                data-idx={idx}
                id={`fabric-gmt-${idx}`}
                className={!enableState[idx] && classes.disabled2}
                value={val['wovenMainFabricYield']}
                disabled={!enableState[idx]}
                onBlur={e => handleWovenChange(e, idx, 'wovenMainFabricYield')}
                onChange={e =>
                  handleWovenChange(e, idx, 'wovenMainFabricYield')
                }
              />
            </div>
            <TextField
              label="Main Fabric Cost/Gmt."
              variant="outlined"
              type="number"
              name={`main-fabric-cost-${idx}`}
              data-idx={idx}
              id={`main-fabric-cost-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['wovenMainFabricCost'] || ''}
              disabled={true}
              InputProps={{
                readOnly: true
              }}
            />
            <br />
            <TextField
              label="Additional Fabric Cost/Gmt."
              variant="outlined"
              type="text"
              name={`add-fabric-cost-${idx}`}
              data-idx={idx}
              id={`add-fabric-cost-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['wovenAddFabricCost']}
              disabled={!enableState[idx]}
              onBlur={e => handleWovenChange(e, idx, 'wovenAddFabricCost')}
              onChange={e => handleWovenChange(e, idx, 'wovenAddFabricCost')}
            />
            <br />
            <TextField
              label="Total Fabric Cost/Gmt"
              variant="outlined"
              type="number"
              name={`total-fabric-cost-${idx}`}
              data-idx={idx}
              id={`total-fabric-cost-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['wovenTotalFabricCost'] || ''}
              disabled={true}
              InputProps={{
                readOnly: true
              }}
            />
            <br />
            <TextField
              label="Total Trims (Labels, Closures, Lining and Embellishments) and Testing"
              variant="outlined"
              type="text"
              required={enableState[idx]}
              name={`trims-emb-${idx}`}
              data-idx={idx}
              id={`trims-emb-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['wovenTotalTrims']}
              disabled={!enableState[idx]}
              onBlur={e => handleWovenChange(e, idx, 'wovenTotalTrims')}
              onChange={e => handleWovenChange(e, idx, 'wovenTotalTrims')}
            />
            <br />
            <div className="page-break" />
            <br className="print-show" />
            <TextField
              label="Cut and Make Labor (incl. O/H)"
              variant="outlined"
              type="text"
              required={enableState[idx]}
              name={`cut-labor-${idx}`}
              data-idx={idx}
              id={`cut-labor-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['wovenCutMakeLabor']}
              disabled={!enableState[idx]}
              onBlur={e => handleWovenChange(e, idx, 'wovenCutMakeLabor')}
              onChange={e => handleWovenChange(e, idx, 'wovenCutMakeLabor')}
            />
            <br />
            <TextField
              label="Wet Processing/Finishing Cost"
              variant="outlined"
              type="text"
              name={`wet-proc-cost-${idx}`}
              data-idx={idx}
              id={`wet-proc-cost-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['wovenWetProcFinish']}
              disabled={!enableState[idx]}
              onBlur={e => handleWovenChange(e, idx, 'wovenWetProcFinish')}
              onChange={e => handleWovenChange(e, idx, 'wovenWetProcFinish')}
            />
            <br />
            <TextField
              label="Total Trim, C and M and Wet Proc Cost"
              variant="outlined"
              type="number"
              name={`total-cost-${idx}`}
              data-idx={idx}
              id={`total-cost-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['wovenTotalTrimCMWetProcCost'] || ''}
              disabled={true}
              InputProps={{
                readOnly: true
              }}
            />
            <br />
            <TextField
              label="1st Cost (FOB/Package Cost)"
              variant="outlined"
              type="number"
              name={`first-cost-fob-${idx}`}
              data-idx={idx}
              id={`first-cost-fob-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['wovenFirstCost'] || ''}
              disabled={true}
              InputProps={{
                readOnly: true
              }}
            />
            <br />
            <TextField
              label="Import Cost for LDP Program"
              variant="outlined"
              type="text"
              name={`import-cost-${idx}`}
              data-idx={idx}
              id={`import-cost-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['wovenImportCostForLDP']}
              disabled={!enableState[idx]}
              onBlur={e => handleWovenChange(e, idx, 'wovenImportCostForLDP')}
              onChange={e => handleWovenChange(e, idx, 'wovenImportCostForLDP')}
            />
            <br />
            <TextField
              label="1st Cost (LDP Cost)"
              variant="outlined"
              type="number"
              name={`first-cost-${idx}`}
              data-idx={idx}
              id={`first-cost-${idx}`}
              className={!enableState[idx] && classes.disabled}
              value={val['wovenFirstCostLDP'] || ''}
              disabled={true}
              InputProps={{
                readOnly: true
              }}
            />
            <br />
            {costSheetVersion === 'V3' && (
              <TextField
                label="CA Import Cost for LDP Program"
                variant="outlined"
                type="text"
                name={`import-cost-ca-${idx}`}
                data-idx={idx}
                id={`import-cost-ca-${idx}`}
                className={!enableState[idx] && classes.disabled}
                value={val['wovenImportCostForLDPCA']}
                disabled={!enableState[idx]}
                onBlur={e =>
                  handleWovenChange(e, idx, 'wovenImportCostForLDPCA')
                }
                onChange={e =>
                  handleWovenChange(e, idx, 'wovenImportCostForLDPCA')
                }
              />
            )}
            <br />
            {costSheetVersion === 'V3' && (
              <TextField
                label="CA 1st Cost (LDP Cost)"
                variant="outlined"
                type="number"
                name={`first-cost-ca-${idx}`}
                data-idx={idx}
                id={`first-cost-ca-${idx}`}
                className={!enableState[idx] && classes.disabled}
                value={val['wovenFirstCostLDPCA'] || ''}
                disabled={true}
                InputProps={{
                  readOnly: true
                }}
              />
            )}
          </div>
        ))}
      </div>
    </Grid>
  );
};

export default WovenCost;
