import { AgGridUtils } from 'utils';
import { ccValidationRules } from '../../validationRules';
import { buildValidationSetter } from '../columnDefsShared';
import { ccCellClassRules, ccTooltipValueGetter } from './utils';

export function ccLiveDate(ctx) {
  return {
    headerName: 'Live Date',
    field: 'liveDate.value',
    cellRenderer: data => {
      return data.value ? new Date(data.value).toLocaleDateString() : '';
    },
    filter: 'agDateColumnFilter',
    headerClass: 'ag-Table__header MuiTypography-body2',
    suppressMenu: true,
    filterParams: {
      comparator: function(filterLocalDateAtMidnight, cellValue) {
        AgGridUtils.comparatorHelper(filterLocalDateAtMidnight, cellValue);
      },
      browserDatePicker: true
    },
    tooltipComponent: 'tooltip',
    tooltipValueGetter: ccTooltipValueGetter('liveDate', ctx),
    cellClassRules: ccCellClassRules('liveDate', ctx),
    valueSetter: buildValidationSetter(ctx, {
      validationRules: ccValidationRules
    })
  };
}
