import { combineReducers } from 'redux';
import { LOGOUT } from 'actiontypes/auth';
import auth from './auth';
import suppliers from './suppliers';
import summary from './summary';
import notifications from './notifications';
import { fulfilled } from './utils';

const combinedReducers = combineReducers({
  auth,
  suppliers,
  summary,
  notifications
});

const rootReducer = (state, action) => {
  if (action.type === fulfilled(LOGOUT)) {
    return combinedReducers(undefined, action);
  }
  return combinedReducers(state, action);
};

export default rootReducer;
